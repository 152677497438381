import { Radar } from "react-chartjs-2";
import { GRADING_SCALE } from "./Consts";

const options = {
	responsive: true,
	maintainAspectRatio: false,
	legend: {
		display: false,
	},
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		r: {
			min: 0,
			max: 11,
		},
	},
	tooltips: {
		callbacks: {
			title: (item) => GRADING_SCALE[item[0].yLabel],
			label: (item, ctx) => ctx.labels[item.index],
		},
	},
};
export default function SkillsRadar({ englishInstances }) {
	const skillsToGrade = englishInstances.reduce((acc, instance) => {
		Object.values(instance.skills).forEach((skills) => {
			Object.entries(skills).forEach(([name, acquired]) => {
				if (!(name in acc))
					acc[name] = { cumulativeGrade: 0, nbCycles: 0 };
				acc[name].cumulativeGrade += acquired;
				acc[name].nbCycles += 1;
			});
		});
		return acc;
	}, {});
	const labels = Object.keys(skillsToGrade).map((name) =>
		name.toLowerCase().capfirst()
	);
	const data = Object.values(skillsToGrade).map(
		({ cumulativeGrade, nbCycles }) =>
			(cumulativeGrade / nbCycles).toFixed(0)
	);
	const radarData = {
		labels,
		datasets: [
			{
				backgroundColor: "rgba(39, 174, 96, 0.5)",
				borderColor: "rgba(44, 62, 80, 1.0)",
				data,
			},
		],
	};
	return (
		<div style={{ height: 450 }} className="mt-3">
			<Radar data={radarData} options={options} />
		</div>
	);
}
