import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import MissingTranscriptsPerElements from "./MissingTranscriptsPerElements";
import { useMissingTranscriptsPerCities } from "./Queries";

export default function MissingPerCities({ cities, promotions }) {
	const {
		data = {},
		error,
		isFetching,
	} = useMissingTranscriptsPerCities(
		cities,
		promotions.map((promotion) => promotion.value)
	);
	// eslint-disable-next-line no-unused-vars
	const { Global, Europe, ...otherDatas } = data;
	requestErrorToastManager(
		"Transcripts: Missing Transcripts per Cities",
		error
	);
	return (
		<SauronChartsContainer
			title={t("missing transcripts percentage").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
		>
			<MissingTranscriptsPerElements data={otherDatas} />
		</SauronChartsContainer>
	);
}
