import {
	faCircleCheck,
	faCircleExclamation,
	faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import { requestErrorToastManager } from "../Utils/Toasts";
import { usePreviousHistory } from "./Queries";

function getIconFromHistoryStatus(history) {
	if (history.status === "succeeded")
		return (
			<FontAwesomeIcon
				size="xl"
				icon={faCircleCheck}
				style={{ color: "green" }}
			/>
		);
	if (history.status === "in_progress")
		return (
			<FontAwesomeIcon
				size="xl"
				icon={faCircleExclamation}
				style={{ color: "orange" }}
			/>
		);
	if (history.status === "failed" && history.dismissed === false)
		return (
			<FontAwesomeIcon
				size="xl"
				icon={faCircleXmark}
				style={{ color: "red" }}
			/>
		);
	return (
		<FontAwesomeIcon
			size="xl"
			icon={faCircleXmark}
			style={{ color: "grey" }}
		/>
	);
}

export default function Histories({ type, onClick, areRequestEnabled }) {
	const { data, error } = usePreviousHistory(type, areRequestEnabled);

	requestErrorToastManager("Scraping History: Last Runs", error);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("lasts runs").capfirst()} />
			</Card.Header>
			<Card.Body>
				{data?.map((history, index) => {
					const backgroundColor = index % 2 === 0 ? "gainsboro" : "";
					const color =
						backgroundColor === "gainsboro" ? "black" : "";
					return (
						<div
							key={`history_${history.id}`}
							className="history"
							style={{
								backgroundColor,
								color,
							}}
							onClick={() => onClick(history.id)}
							onKeyUp={() => 0}
							role="button"
							tabIndex={0}
						>
							<span style={{ marginLeft: "10px" }}>
								{history.scraping_name.capfirst()} -{" "}
								{history.scraping_mode.toUpperCase()}
							</span>
							<span className="historyIcon">
								{getIconFromHistoryStatus(history)}
							</span>
							<div style={{ marginLeft: "40px" }}>
								{DateTime.fromISO(history.date).toLocaleString(
									DateTime.DATETIME_SHORT
								)}
							</div>
						</div>
					);
				})}
			</Card.Body>
		</Box>
	);
}
