import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import IntuituPersonaeProgress from "../IntuituPersonae/IntuituPersonaeProgress";
import CitiesProgressions from "../TepitechProgression/CitiesProgressions";
import { areFiltersValueDifferent } from "../Utils/Filters";
import { getCurrentAcademicYear, separateFiltersOptions } from "../Utils/Utils";
import Filters from "./Filters";
import InternshipProgress from "./Graphs/InternshipProgress";
import InvestmentProgress from "./Graphs/InvestmentProgress";
import ModuleValidation from "./Graphs/ModuleValidation";
import PromotionsStatus from "./Graphs/PromotionsStatus";

export default function Cities() {
	const scraper = useContext(ScraperApiHelperContext);
	const currentYear = getCurrentAcademicYear();
	const cities = scraper.userHelper.getCities();
	const promotions = [3, 2, 1]
		.map((num, idx) => ({
			label: `PGE${num}`,
			value: currentYear + num + idx * 2,
		}))
		.reverse();
	const [activeFilters, setActiveFilters] = useState({
		cities: cities.length === 1 ? [cities[0]] : [],
		promotions,
	});
	const { labels: selectedPromotions, values: selectedPromotionYears } =
		separateFiltersOptions(activeFilters.promotions);

	const updateFilter = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};
	const national = activeFilters.cities.length === 0;

	return (
		<Section>
			<SectionHeader title={t("city overview").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-4 gap-4">
						<Filters
							cities={cities}
							promotions={promotions}
							onFilter={updateFilter}
						/>
					</Row>
					<Row className="mb-4">
						<Col lg={12}>
							<CitiesProgressions
								cities={activeFilters.cities}
								curriculums={activeFilters.promotions}
								years={[
									{
										value: getCurrentAcademicYear(),
										label: getCurrentAcademicYear(),
									},
								]}
								national={national}
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col lg={12}>
							<InternshipProgress
								cities={activeFilters.cities}
								promotions={selectedPromotions}
							/>
						</Col>
					</Row>
					{scraper.userHelper.isInGroup("dpr") && (
						<Row className="mb-4">
							<Col lg={12}>
								<IntuituPersonaeProgress
									cities={activeFilters.cities}
									promotionYears={selectedPromotionYears}
								/>
							</Col>
						</Row>
					)}
					<Row className="mb-4">
						<Col lg={12}>
							<InvestmentProgress
								cities={activeFilters.cities}
								promotions={selectedPromotionYears}
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col lg={12}>
							<ModuleValidation cities={activeFilters.cities} />
						</Col>
					</Row>
					<Row className="mb-4">
						<Col lg={12}>
							<PromotionsStatus
								cities={activeFilters.cities}
								promotions={activeFilters.promotions}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
