import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { requestErrorToastManager } from "../Utils/Toasts";
import TranscriptsDataTable from "./DataTable";
import { useTranscriptsInfiniteQuery } from "./Queries";

const getCorrespondingExportTranscripts = (semester) => {
	if (!semester) return "N/A";
	if (semester.fr.length === 0 && semester.en.length === 0)
		return "Missing both transcripts";
	if (semester.fr.length === 0) return "Missing french transcripts";
	if (semester.en.length === 0) return "Missing english transcripts";
	return "OK";
};

function TranscriptsMissingWarning({ nbMissing }) {
	if (nbMissing === 0) return null;
	return (
		<Card.Header className="card text-center">
			<h3 className="text-danger">{`${t("missingTranscriptWithCount", {
				count: nbMissing,
			})}`}</h3>
		</Card.Header>
	);
}

export default function DataTableContainer({
	cities,
	promotions,
	alertsOnly,
	areRequestEnabled,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		isLoading,
		error,
	} = useTranscriptsInfiniteQuery(cities, promotions, areRequestEnabled);
	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const requestData = data?.pages.flatMap((page) => page) ?? [];

	requestErrorToastManager(
		"Transcripts: Missing Transcripts Data Table",
		error
	);

	const totalTranscriptsMissing = requestData.reduce(
		(acc, row) => acc + row.nb_missing,
		0
	);

	const filteredRequestData = requestData.filter((row) => {
		if (alertsOnly && row.nb_missing === 0) return false;
		return true;
	});

	const exportConfig = {
		"login": "login",
		"missing transcripts": "nb_missing",
		"city": "city",
		"promotion": "promotion",
		"semester 1": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 1"]),
		"semester 2": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 2"]),
		"semester 3": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 3"]),
		"semester 4": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 4"]),
		"semester 5": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 5"]),
		"semester 6": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 6"]),
		"bachelor": (row) =>
			getCorrespondingExportTranscripts(row.transcripts.Bachelor),
		"semester 7": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 7"]),
		"semester 8": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 8"]),
		"semester 9": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 9"]),
		"semester 10": (row) =>
			getCorrespondingExportTranscripts(row.transcripts["Semester 10"]),
		"master": (row) =>
			getCorrespondingExportTranscripts(row.transcripts.Master),
	};

	return (
		<>
			<TranscriptsMissingWarning nbMissing={totalTranscriptsMissing} />
			<TranscriptsDataTable
				data={filteredRequestData}
				exportConfig={{
					columns: exportConfig,
					title: "transcripts",
				}}
				isFetching={isFetching}
				isLoading={isLoading}
			/>
		</>
	);
}
