import { useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";

const findCorrespondingOption = (filter, value) =>
	filter.options.find((option) => String(option.value) === value);

const getDefaultValue = (config) => {
	switch (config.type) {
		case "bool":
			return false;
		case "select":
			if (config.multiple) return [];
			return null;
		case "date":
			return null;
		case "search":
			return "";
		case "text":
			return "";
		default:
			console.error(`Unhandled filter type: ${config.type}`);
			return null;
	}
};

const getFormattedQuery = (filter) => {
	switch (filter.type) {
		case "select": {
			if (!filter.multiple)
				return findCorrespondingOption(filter, filter.query);
			const queries = filter.query
				?.map((query) => findCorrespondingOption(filter, query))
				?.filter((query) => query !== undefined);
			return !queries || queries.length === 0 ? undefined : queries;
		}
		case "bool":
			return filter.query;
		case "date":
			return filter.query;
		case "search":
			return filter.query;
		case "text":
			return filter.query;
		default:
			console.error(`Unhandled filter type: ${filter.type}`);
			return undefined;
	}
};

const chooseAppropriateSelected = (filter) => ({
	...filter,
	selected: getFormattedQuery(filter) ?? filter.default,
});

const addMissingFieldsAndQueryValues = (initialState, queries) =>
	Object.entries(initialState).reduce((acc, [name, config]) => {
		acc[name] = {
			...config,
			default:
				config.default === undefined
					? getDefaultValue(config)
					: config.default,
		};
		if (queries[name] !== undefined) acc[name].query = queries[name];
		acc[name] = chooseAppropriateSelected(acc[name]);
		return acc;
	}, {});

const extractFilterValueFromURL = (initialState, searchParams) => {
	const filterNames = Object.keys(initialState);
	const urlFilters = {};
	searchParams.forEach((paramValue, paramName) => {
		if (!filterNames.includes(paramName)) return;
		switch (initialState[paramName].type) {
			case "bool":
				if (paramValue !== "false" && paramValue !== "true") return;
				urlFilters[paramName] = paramValue === "true";
				break;
			case "select":
				if (initialState[paramName].multiple) {
					if (!(paramName in urlFilters)) urlFilters[paramName] = [];
					urlFilters[paramName].push(paramValue);
					break;
				}
				urlFilters[paramName] = paramValue;
				break;
			case "date": {
				const date = DateTime.fromISO(paramValue);
				if (!date.isValid) break;
				urlFilters[paramName] = date;
				break;
			}
			case "search":
				urlFilters[paramName] = paramValue;
				break;
			case "text":
				urlFilters[paramName] = paramValue;
				break;
			default:
				console.error(
					`Unhandled filter type: ${initialState[paramName].type}`
				);
				break;
		}
	});
	return urlFilters;
};

const compareOptions = (a, b) => {
	if (a === null && b === null) return true;
	if (a === null || b === null) return false;
	const { value: valueA, label: labelA } = a;
	const { value: valueB, label: labelB } = b;
	return valueA === valueB && labelA === labelB;
};

const compareFilterValues = (filterValueA, filterValueB, type, multiple) => {
	switch (type) {
		case "select": {
			if (multiple) {
				return (
					filterValueA.filter(
						(optionA) =>
							!filterValueB.some((optionB) =>
								compareOptions(optionA, optionB)
							)
					).length === 0
				);
			}
			return compareOptions(filterValueA, filterValueB);
		}
		case "bool": {
			return filterValueA === filterValueB;
		}
		case "date": {
			return filterValueA === filterValueB;
		}
		case "search": {
			return filterValueA === filterValueB;
		}
		case "text": {
			return filterValueA === filterValueB;
		}
		default: {
			console.error(`Unhandled filter type: ${type}`);
			return false;
		}
	}
};

const isQueryEqualToSelected = (filter) => {
	const query = getFormattedQuery(filter);
	const { selected, type, multiple } = filter;
	return compareFilterValues(query, selected, type, multiple);
};

const isDefaultEqualToSelected = (filter) => {
	const { default: defaultValue, selected, type, multiple } = filter;
	return compareFilterValues(defaultValue, selected, type, multiple);
};

const determineInvertedDependency = (filters) =>
	Object.entries(filters).reduce((acc, [name, filter]) => {
		if (!filter.dependsOn) return acc;
		acc[filter.dependsOn] = name;
		return acc;
	}, {});

/* TODO: Faire en sorte que les URL Params soient gérés au moment où l'on change d'options, on doit y vérifier les dépendances */
function useFilters(initialState) {
	const [searchParams] = useSearchParams();
	const hash = useLocation().hash.substring(1);
	const navigate = useNavigate();
	const invertedDependency = determineInvertedDependency(initialState);
	const queries = extractFilterValueFromURL(initialState, searchParams);
	const [filters, setFilters] = useState(
		addMissingFieldsAndQueryValues(initialState, queries)
	);

	const selectedValueToURLQueryValue = (filter, selected) => {
		switch (filter.type) {
			case "select": {
				if (filter.multiple)
					return selected.map((option) => option.value);
				return selected.value;
			}
			case "bool": {
				return selected;
			}
			case "date": {
				return selected.toISODate();
			}
			case "search": {
				return selected;
			}
			case "text": {
				return selected;
			}
			default: {
				console.error(`Unhandled filter type: ${filter.type}`);
				return null;
			}
		}
	};

	const setURLQueryFromSelectedFilters = (selectedFilters) => {
		const newQueryParams = new URLSearchParams();
		Object.entries(selectedFilters).forEach(([label, selected]) => {
			if (selected === undefined || selected === null) return;
			const filter = filters[label];
			const query = selectedValueToURLQueryValue(filter, selected);
			if (!query) return;
			if (Array.isArray(query)) {
				for (let i = 0; i < query.length; i++) {
					newQueryParams.append(label, query[i]);
				}
			} else {
				newQueryParams.append(label, query);
			}
		});
		// not using setter of useSearchParams because it removes "hash" part of the URL
		navigate({ hash, search: newQueryParams.toString() });
	};

	const setFilterDisabled = (filterKey, disabled) => {
		setFilters((oldState) => ({
			...oldState,
			[filterKey]: {
				...oldState[filterKey],
				disabled,
			},
		}));
	};

	const setSelectedOption = (filterKey, option, force) => {
		setFilters((oldState) => {
			if (
				!force &&
				option !== null &&
				!oldState[filterKey].multiple &&
				oldState[filterKey].type === "select" &&
				oldState[filterKey].options.find(
					(testedOption) => testedOption.value === option.value
				) === undefined
			) {
				return oldState;
			}
			return {
				...oldState,
				[filterKey]: {
					...oldState[filterKey],
					selected: option,
				},
			};
		});
		const dependantFilter = invertedDependency[filterKey];
		if (!dependantFilter) return;
		setFilters((prev) => ({
			...prev,
			[dependantFilter]: {
				...prev[dependantFilter],
				query: undefined,
			},
		}));
		setFilterDisabled(dependantFilter, option === null);
	};

	const findCorrectSelectValue = (filter, dependencyFilter) => {
		if (!dependencyFilter) {
			const optionStillExists =
				findCorrespondingOption(filter, filter.selected?.value) !==
				undefined;
			if (optionStillExists) return filter.selected;
			return getFormattedQuery(filter) ?? filter.default;
		}
		const query = getFormattedQuery(filter);
		if (query && isQueryEqualToSelected(dependencyFilter)) return query;
		if (isDefaultEqualToSelected(dependencyFilter)) return filter.default;
		return getDefaultValue(filter);
	};

	const setFilterOptions = (filterKey, options) => {
		setFilters((oldState) => {
			const newState = {
				...oldState,
				[filterKey]: {
					...oldState[filterKey],
					options,
				},
			};
			const dependency = oldState[filterKey].dependsOn;
			const selectedValue = findCorrectSelectValue(
				newState[filterKey],
				newState[dependency]
			);
			newState[filterKey].selected = selectedValue;
			const dependantFilter = invertedDependency[filterKey];
			if (!dependantFilter) return newState;
			newState[dependantFilter].disabled = selectedValue === null;
			return newState;
		});
	};

	const applyFilters = (onFilter, setInURL = true) => {
		const selectedFilters = {};
		Object.entries(filters).forEach(([key, value]) => {
			selectedFilters[key] = value.selected;
		});
		onFilter(selectedFilters);
		if (setInURL) setURLQueryFromSelectedFilters(selectedFilters);
	};

	return {
		filters,
		setFilters,
		setFilterOptions,
		setFilterDisabled,
		setSelectedOption,
		applyFilters,
	};
}

export default useFilters;
