import { useMemo, useState } from "react";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { getCurrentAcademicYear } from "../../../../Utils/Utils";
import PresencesInput from "./Input";
import ModulePresences from "./ModulePresences";

const isPresenceRelevant = (search, module, presences) => {
	const lowerSearch = search.toLowerCase();
	if (module.toLowerCase().includes(lowerSearch)) return true;
	if (
		Object.values(presences).some((presence) =>
			presence.name.toLowerCase().includes(lowerSearch)
		)
	)
		return true;
	return false;
};

export default function Presences({ presencesQuery }) {
	const { data: presences, isFetching } = presencesQuery;
	const [search, setSearch] = useState("");
	const formattedPresences = useMemo(() => {
		if (!presences) return null;
		const currentYearData = presences[getCurrentAcademicYear()];
		if (!currentYearData) return {};
		return Object.entries(currentYearData).reduce(
			(acc, [moduleCode, instances]) => {
				const instanceCodes = Object.keys(instances);
				if (instanceCodes.length === 1) {
					acc[moduleCode] = instances[instanceCodes[0]];
					return acc;
				}
				instanceCodes.forEach((instanceCode) => {
					acc[`${moduleCode} - ${instanceCode}`] =
						instances[instanceCode];
				});
				return acc;
			},
			{}
		);
	}, [presences]);

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!formattedPresences || Object.keys(presences).length === 0)
		return <NoDataAvailable />;
	const filteredPresences = Object.entries(formattedPresences)
		.filter(([module, modulePresences]) =>
			isPresenceRelevant(search, module, modulePresences)
		)
		.sort();

	return (
		<div>
			<PresencesInput
				value={search}
				onChange={(event) => setSearch(event.target.value)}
			/>
			{filteredPresences.map(([module, modulePresences]) => (
				<ModulePresences
					key={`${module}_presences`}
					module={module}
					presences={modulePresences}
				/>
			))}
		</div>
	);
}
