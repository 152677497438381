import {
	Card,
	Container,
	ListGroup,
	ListGroupItem,
	Row,
} from "react-bootstrap";
import { t } from "../../../../../Config/i18n";
import { Box, BoxTitle } from "../../../../AdminLTE3/Box";

const STATUS_COLORS = {
	"present": "text-green",
	"absent": "text-red",
	"N/A": "text-reset",
	"not_registered": "text-muted",
};

const STATUS_KEYS = Object.keys(STATUS_COLORS);

const sortPresencesByStatus = (a, b) =>
	STATUS_KEYS.indexOf(a.status) > STATUS_KEYS.indexOf(b.status) ? 1 : -1;

function Presence({ presence }) {
	const active = presence.status !== "not_registered";
	return (
		<ListGroupItem
			className={`col-5 mb-3 mr-3 ${
				active ? "" : "list-group-item-secondary"
			}`}
			style={{ borderTopWidth: 1 }}
		>
			<a href={presence.intra_url} target="_blank" rel="noreferrer">
				{presence.name}
			</a>
			<span
				className={`float-right ml-1 ${STATUS_COLORS[presence.status]}`}
			>
				{t(presence.status).capfirst()}
			</span>
		</ListGroupItem>
	);
}

export default function ModulePresences({ module, presences }) {
	const sortedPresences = presences.sort(sortPresencesByStatus);
	return (
		<Box type="secondary" className="card-outline collapsed-card">
			<Card.Header
				className="btn border-bottom-0"
				data-card-widget="collapse"
			>
				<BoxTitle title={module} />
			</Card.Header>
			<Card.Body>
				<Container fluid>
					<ListGroup>
						<Row className="justify-content-around">
							{sortedPresences.map((presence) => (
								<Presence
									key={`${module}_${presence.intra_url}_presences`}
									presence={presence}
								/>
							))}
						</Row>
					</ListGroup>
				</Container>
			</Card.Body>
		</Box>
	);
}
