import { useEffect, useMemo } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import {
	useConfigurationCreationMutation,
	useConfigurationsInfiniteQuery,
} from "./Queries";

export const visibilityToOption = (visibility) => ({
	label: t(visibility).capfirst(),
	value: visibility,
});

export function AdditionModal({
	displayModal,
	setDisplayModal,
	modules,
	defaultSettings,
}) {
	const yearOptions = nValues(2019, getCurrentAcademicYear() - 2019 + 1)
		.reverse()
		.toFilterOptions();
	const currentYear = getCurrentAcademicYear();
	const visibilityOptions = ["public", "draft", "private"].map(
		visibilityToOption
	);
	const { filters, setFilterOptions, setSelectedOption } = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			options: yearOptions,
			default: {
				label: currentYear,
				value: currentYear,
			},
		},
		module: {
			label: t("modules"),
			type: "select",
			multiple: false,
			options: [],
			default: null,
		},
		github_repository: {
			label: t("GitHub repository").capfirst(),
			type: "text",
			default: "",
		},
		config_filepath: {
			label: t("configuration filepath").capfirst(),
			type: "text",
			default: "",
		},
		visibility: {
			label: t("visibility").capfirst(),
			type: "select",
			placeholder: t("choose visibility").capfirst(),
			options: visibilityOptions,
			default: visibilityOptions[0],
			clearable: false,
		},
	});
	const {
		data: paginatedConfigurations,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useConfigurationsInfiniteQuery(filters.year.selected.value);
	const creationMutation = useConfigurationCreationMutation(setDisplayModal);
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const configurations = useMemo(
		() => paginatedConfigurations?.pages.flatMap((page) => page),
		[paginatedConfigurations]
	);
	const modulesOptions = useMemo(() => {
		if (!modules) return [];
		if (!configurations) return modules;
		const alreadyConfiguredModules = configurations
			.filter(
				(configuration) =>
					configuration.scholar_year === filters.year.selected?.value
			)
			.map((configuration) => configuration.unit);
		return modules.filter(
			(module) => !alreadyConfiguredModules.includes(module.value)
		);
	}, [modules, configurations]);

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "module") {
			setSelectedOption("github_repository", `Epitech/${newValue.value}`);
			setSelectedOption(
				"config_filepath",
				`${newValue.value}_validations.json`
			);
		}
	};

	const onSubmit = () => {
		const year = filters.year.selected?.value;
		const module = filters.module.selected?.value;
		const config_filepath = filters.config_filepath.selected;
		const github_repository = filters.github_repository.selected;
		const visibility = filters.visibility.selected?.value;
		if (
			!year ||
			!module ||
			!config_filepath ||
			!github_repository ||
			!visibility
		) {
			toast.error(
				`Tesseract Configuration Creation: ${t(
					"all fields must be filled to create a new config"
				).capfirst()}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
			return;
		}
		creationMutation.mutate({
			year,
			module,
			config_filepath,
			github_repository,
			visibility,
		});
	};

	useEffect(() => {
		setFilterOptions("module", modulesOptions);
	}, [modulesOptions]);

	useEffect(() => {
		if (!displayModal) return;
		const filterKeys = Object.keys(filters);
		filterKeys.forEach((filterKey) => {
			setSelectedOption(
				filterKey,
				defaultSettings[filterKey] ?? null,
				true
			);
		});
	}, [displayModal]);

	return (
		<Modal centered show={displayModal} dialogClassName="modal-50w">
			<Modal.Header>
				<Modal.Title className="text-center">
					{t("tesseract_add_configuration")}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container fluid>
					<FiltersForm filters={filters} onChange={onSelectChange} />
				</Container>
			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<Button
					variant="danger"
					onClick={() => {
						setDisplayModal(false);
					}}
				>
					{t("close")}
				</Button>
				<Button
					variant="success"
					disabled={
						!filters.year.selected?.value ||
						!filters.module.selected?.value ||
						!filters.config_filepath.selected ||
						!filters.github_repository.selected ||
						!filters.visibility.selected?.value
					}
					onClick={() => onSubmit()}
				>
					{t("create")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
