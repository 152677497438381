import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForms from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import { useCurriculumsQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const types = ["Full-time", "Part-time"].toFilterOptions();
	const { data, error } = useCurriculumsQuery();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			cities: {
				label: t("cities"),
				type: "select",
				multiple: true,
				options: citiesOptions,
				default: citiesOptions.length === 1 ? [citiesOptions[0]] : [],
			},
			curriculums: {
				label: t("curriculums"),
				type: "select",
				multiple: true,
				options: [],
				default: [],
			},
			promotions: {
				label: t("promotions"),
				type: "select",
				multiple: true,
				options: nValues(
					getCurrentAcademicYear() + 1,
					5
				).toFilterOptions(),
				default: [],
			},
			type: {
				label: t("type"),
				type: "select",
				clearable: false,
				default: types[0],
				options: types,
			},
			alerts: {
				label: t("students in alert only"),
				type: "bool",
				default: false,
			},
		});

	useEffect(() => {
		if (!data) return;
		const filteredData = data.filter(
			(curr) =>
				curr.label.startsWith("bachelor") ||
				curr.label.startsWith("master")
		);
		setFilterOptions("curriculums", filteredData);
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Internships and Part time Filters: ${t("An error has occured")}: ${
				error.message
			}`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForms filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
