export default class Config {
	constructor(api) {
		this.api = api;
	}

	getConfigurationsYears() {
		return this.api
			.processRequest("GET", `/configs/roadblocks/available_years`)
			.then((data) => data.data);
	}

	getRoadblocksPerConfig(scholarYear, tekYear, semester) {
		return this.api
			.processRequest(
				"GET",
				`/configs/roadblocks/${scholarYear}/tek${tekYear}/semester_${semester}`
			)
			.then((data) => data.data);
	}

	createConfig(
		year,
		tekYear,
		semester,
		startingDate,
		creditsThreshold,
		tepitech,
		additionalConfigs
	) {
		const data = {
			starting_date: startingDate,
			credits_threshold: creditsThreshold,
			tepitech,
			additional_configs: additionalConfigs,
		};
		return this.api.processRequest(
			"POST",
			`/configs/roadblocks/${year}/tek${tekYear}/semester_${semester}`,
			{
				data,
			}
		);
	}

	updateConfig(id, startingDate, threshold, tepitech, additionalConfigs) {
		return this.api.processRequest("PUT", `/configs/roadblocks/${id}`, {
			data: {
				starting_date: startingDate,
				credits_threshold: threshold,
				tepitech,
				additional_configs: additionalConfigs,
			},
		});
	}

	createRoadblock(configId, roadblockName, creditsThreshold, modules) {
		const data = {
			modules,
			name: roadblockName,
			credits: creditsThreshold,
		};
		return this.api.processRequest(
			"POST",
			`/configs/roadblocks/${configId}/roadblock`,
			{
				data,
			}
		);
	}

	updateRoadblock(configId, roadblockId, threshold, name) {
		return this.api.processRequest(
			"PUT",
			`/configs/roadblocks/${configId}/roadblocks/${roadblockId}`,
			{
				data: { name, threshold },
			}
		);
	}

	deleteRoadblock(configId, roadblockId) {
		return this.api.processRequest(
			"DELETE",
			`/configs/roadblocks/${configId}/roadblocks/${roadblockId}`
		);
	}

	addModuleToRoadblock(configId, roadblockId, modules) {
		return this.api.processRequest(
			"POST",
			`/configs/roadblocks/${configId}/roadblocks/${roadblockId}/units`,
			{
				data: modules,
			}
		);
	}

	deleteModuleFromRoadblock(configId, roadblockId, module) {
		return this.api.processRequest(
			"DELETE",
			`/configs/roadblocks/${configId}/roadblocks/${roadblockId}/units`,
			{ data: [module] }
		);
	}
}
