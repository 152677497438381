import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import ConfigurationsYearNavtabs from "./ConfigurationsYearNavtabs";

export default function Configurations() {
	return (
		<Section>
			<SectionHeader title={t("configuration").title()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col>
							<ConfigurationsYearNavtabs />
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
