import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ScraperApiHelperContext } from "../../contexts";

const orderActivity = (a, b) => {
	const beginDateA = DateTime.fromISO(a.begin).ts;
	const beginDateB = DateTime.fromISO(b.begin).ts;
	const endDateA = DateTime.fromISO(a.end).ts;
	const endDateB = DateTime.fromISO(b.end).ts;
	if (beginDateA === beginDateB && endDateA === endDateB && a.name === b.name)
		return 0;
	if (
		beginDateA < beginDateB ||
		(beginDateA === beginDateB && endDateA < endDateB) ||
		(beginDateA === beginDateB && endDateA === endDateB && a.name < b.name)
	)
		return -1;
	return 1;
};
export const useActivityPresencesQuery = (
	year,
	module_code,
	instance_code,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"student_presence",
			"table_data",
			"stats",
			"activity/presence",
			year,
			module_code,
			instance_code,
		],
		queryFn: () =>
			scraper.activity.getPresences(year, module_code, instance_code),
		select: (data) =>
			data.sort(orderActivity).map((activity) => ({
				...activity,
				begin: DateTime.fromISO(activity.begin).toLocaleString(
					DateTime.DATETIME_SHORT
				),
				end: DateTime.fromISO(activity.end).toLocaleString(
					DateTime.DATETIME_SHORT
				),
			})),
		enabled: areRequestEnabled,
	});
};

export const useStudentPresencesYearFiltersQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_presence", "filters_options", "years"],
		queryFn: () => scraper.activity.getPresencesAvailableYears(),
	});
};

export const useStudentPresencesModulesInstancesFiltersQuery = (year) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"student_presence",
			"filters_options",
			"instances_modules",
			year,
		],
		queryFn: () => scraper.activity.getPresencesAvailableInstances(year),
	});
};
