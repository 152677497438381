import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputGroup } from "react-bootstrap";
import { t } from "../../Config/i18n";

export default function SearchBar({ value, onChange }) {
	return (
		<InputGroup>
			<InputGroup.Prepend>
				<InputGroup.Text>
					<FontAwesomeIcon icon={faSearch} />
				</InputGroup.Text>
			</InputGroup.Prepend>
			<FormControl
				type="text"
				placeholder={t("search").capfirst()}
				value={value}
				onChange={onChange}
			/>
		</InputGroup>
	);
}
