import { useContext, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { downloadCSV, getCurrentAcademicYear } from "../Utils/Utils";
import BulkImportButton from "./BulkImport";
import ProComNotesFilters from "./Filters";
import StepCardHeader from "./StepCardHeader";

export default function ProfessionalCommunication() {
	const scraper = useContext(ScraperApiHelperContext);
	const currentYear = getCurrentAcademicYear();
	const citiesOptions = scraper.userHelper.getCities();
	const projectSelected = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		year: currentYear,
		project: null,
		project_instance: null,
	});
	const { year, project_instance } = activeFilters;
	const module_code = activeFilters.project?.split("__")[0];
	const slug = activeFilters.project?.split("__")[1];
	const updateActiveFilters = (selectedFilters) => {
		projectSelected.current =
			selectedFilters.project && selectedFilters.project_instance;
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	const [filedUploaded, setFileUploaded] = useState(false);

	return (
		<Section>
			<SectionHeader
				title={t("upload Professional Communication notes").capfirst()}
			/>
			<SectionContent>
				<Container fluid>
					<Row>
						<Col className="mb-3">
							<ProComNotesFilters
								onFilter={updateActiveFilters}
								cities={citiesOptions}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={12} className="mb-3">
							<Card
								style={{
									opacity: projectSelected.current ? 1 : 0.5,
								}}
							>
								<StepCardHeader
									label={t(
										"Step 2 : upload the assessment file to generate the final marks file"
									).capfirst()}
									success={filedUploaded}
								/>
								<Card.Body>
									<BulkImportButton
										year={year}
										module_code={module_code}
										slug={slug}
										project_instance={project_instance}
										onUploadSuccess={(data) => {
											setFileUploaded(true);
											downloadCSV(data, `${slug}_marks`);
										}}
										onUploadReset={() =>
											setFileUploaded(false)
										}
										disabled={!projectSelected.current}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col lg={12} className="mb-3">
							<Card
								style={{
									opacity: filedUploaded ? 1 : 0.5,
								}}
							>
								<StepCardHeader
									label={t(
										"Step 3 : upload marks on the intranet"
									).capfirst()}
									success={false}
								/>
								<Card.Body className="d-flex justify-content-center">
									<Button
										href={`https://intra.epitech.eu/module/${year}/${module_code}/${project_instance}`}
										target="_blank"
										disabled={!filedUploaded}
									>
										{t("open the intranet").capfirst()}
									</Button>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="pro-com-notes-tooltip" />
			<StudentTooltip tooltipId="pro-com-notes-student-tooltip" />
		</Section>
	);
}
