import { Group, MultiSelect, NumberInput, TextInput } from "@mantine/core";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { t } from "../../../Config/i18n";
import { useModules, useRoadblockCreationMutation } from "../Queries";

export default function CreateRoadlockModal({
	close,
	configId,
	scholarYear,
	tekYear,
	semester,
}) {
	const {
		data: modules = [],
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useModules();
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const [roadblockName, setRoadblockName] = useState("");
	const [roadblockCredits, setRoadblockCredits] = useState(0);
	const [selectedModules, setSelectedModules] = useState([]);
	const mutation = useRoadblockCreationMutation(
		close,
		scholarYear,
		tekYear,
		semester
	);
	const modulesData = [];

	modules.forEach((module) => {
		modulesData.push({ label: module, value: module });
	});

	function createRoadblock() {
		if (selectedModules.length !== 0 && roadblockName !== "") {
			mutation.mutate({
				roadblockName,
				creditsThreshold: roadblockCredits,
				modules: selectedModules,
				configId,
			});
		}
	}
	return (
		<>
			<TextInput
				label={t("roadblock name").capfirst()}
				withAsterisk
				value={roadblockName}
				onChange={(event) => setRoadblockName(event.target.value)}
			/>
			<NumberInput
				label={t("credits threshold").capfirst()}
				withAsterisk
				min={0}
				value={roadblockCredits}
				onChange={setRoadblockCredits}
			/>
			<MultiSelect
				label={t("modules").capfirst()}
				placeholder={t("select module").capfirst()}
				data={modulesData}
				clearable
				searchable
				dropdownPosition="bottom"
				onChange={setSelectedModules}
				limit={100}
				withAsterisk
				withinPortal
			/>
			<Group style={{ marginTop: "5%", justifyContent: "space-between" }}>
				<Button onClick={close}>{t("close").capfirst()}</Button>
				<Button onClick={() => createRoadblock()}>
					{t("validate").capfirst()}
				</Button>
			</Group>
		</>
	);
}
