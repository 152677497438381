import { Card } from "react-bootstrap";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import LoadingOverlay from "../AdminLTE3/LoadingOverlay";

export default function TesseractChartsContainer({
	title,
	subtitle,
	radioFilter,
	isLoading,
	children,
}) {
	return (
		<Box style={{ height: "100%" }}>
			<LoadingOverlay loading={isLoading} />
			<Card.Header>
				<BoxTitle title={title} subtitle={subtitle} />
				{radioFilter}
			</Card.Header>
			<Card.Body>
				<div style={{ height: 350 }}>{children}</div>
			</Card.Body>
		</Box>
	);
}
