import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { defaultBarOptions } from "../Utils/DefaultConst";

export default function InternshipAlertGraph({ stats }) {
	const datasets = [
		{
			label: t("no contracts").capfirst(),
			backgroundColor: "rgb(231, 76, 60)",
			data: [],
		},
		{
			label: t("waiting for validation").capfirst(),
			backgroundColor: "rgb(230, 126, 34)",
			data: [],
		},
		{
			label: t("waiting for signatures").capfirst(),
			backgroundColor: "#F5E829",
			data: [],
		},
		{
			label: t("not marked yet").capfirst(),
			backgroundColor: "#F2C321",
			data: [],
		},
		{
			label: "Ok",
			backgroundColor: "rgb(26, 188, 156)",
			data: [],
		},
	];
	const fields = [
		"missing",
		"waiting",
		"validated_adm",
		"student_not_marked",
		"confirmed",
	];
	const data = Object.keys(stats).reduce(
		(acc, city) => {
			acc.labels.push(city);
			fields.forEach((field, idx) => {
				acc.datasets[idx].data.push(stats[city][field] ?? 0);
			});
			return acc;
		},
		{ labels: [], datasets }
	);
	return <Bar options={defaultBarOptions} data={data} />;
}
