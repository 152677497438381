import { useContext, useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import { InvestmentContext } from "./Context";

const onCommentChange = (login, comment, setStudents) => {
	setStudents((students) => {
		const map = new Map(students);
		map.set(login, comment);
		return map;
	});
};

function CommentCell({ students, setStudents, login, isSelected }) {
	if (!isSelected) return "N/A";
	const stateComment = students.get(login) ?? "";
	return (
		<InputGroup>
			<FormControl
				type="text"
				onChange={(event) => {
					const textInputComment = event.target.value;
					if (textInputComment.length >= 1000) {
						toast.info(
							`${t("exceed character limit").capfirst()}: ${t(
								"a comment is limited to 1000 characters"
							).capfirst()}!`,
							{
								theme: "colored",
								autoClose: 2000,
							}
						);
						return;
					}
					onCommentChange(login, event.target.value, setStudents);
				}}
				value={stateComment}
			/>
		</InputGroup>
	);
}

export default function DataTable({ data, isLoading, isFetching }) {
	const { students, setStudents } = useContext(InvestmentContext);
	const [rowSelection, setRowSelection] = useState({});
	const columns = [
		{
			accessorKey: "login",
			header: "Login",
			enableEditing: false,
			Cell: ({ row }) => <LoginRow login={row.original.login} />,
			size: 200,
		},
		{
			accessorKey: "promotion",
			header: t("promotion").capfirst(),
			enableEditing: false,
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "curriculum",
			header: t("curriculum").capfirst(),
			enableEditing: false,
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "city",
			header: t("city").capfirst(),
			enableEditing: false,
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "comment",
			header: t("comment").capfirst(),
			Cell: ({ row }) => {
				const isSelected = Object.keys(rowSelection).includes(row.id);
				return (
					<CommentCell
						login={row.original.login}
						students={students}
						setStudents={setStudents}
						isSelected={isSelected}
					/>
				);
			},
			size: 300,
			enableGlobalFilter: false,
		},
	];

	useEffect(() => {
		const selectedStudents = new Map();
		Object.keys(rowSelection).forEach((studentId) =>
			selectedStudents.set(
				data[studentId].login,
				students.get(data[studentId].login) ?? ""
			)
		);
		setStudents(selectedStudents);
	}, [rowSelection]);

	return (
		<SauronMantineDataTable
			title={t("comment(s) on the investment report").capfirst()}
			subtitle={t("per students")}
			columns={columns}
			data={data}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
			tableProps={{
				enableRowSelection: true,
				onRowSelectionChange: setRowSelection,
				state: { rowSelection },
				selectAllMode: "all",
			}}
		/>
	);
}
