import {
	faExclamationTriangle,
	faCheck,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";

export const ALERT_STATUS = {
	"not_registered": {
		icon: faExclamationTriangle,
		color: "#bdc3c7",
		label: "not registered",
	},
	"na": {
		icon: faTimes,
		color: "#bdc3c7",
		label: "no Tepitech",
	},
	"no_tepitech": {
		icon: faTimes,
		color: "#2c3e50",
		label: "no Tepitech",
	},
	"> -150": {
		icon: faExclamationTriangle,
		color: "black",
		label: "not validated",
		graph_label: "-150",
	},
	"-150": {
		icon: faExclamationTriangle,
		color: "#c0392b",
		label: "not validated",
		graph_label: "-150",
	},
	"-100": {
		icon: faExclamationTriangle,
		color: "#e67e22",
		label: "not validated",
		graph_label: "-100",
	},
	"-50": {
		icon: faExclamationTriangle,
		color: "#f1c40f",
		label: "not validated",
		graph_label: "-50",
	},
	"valid": {
		icon: faCheck,
		color: "#27ae60",
		label: "validated",
	},
};
