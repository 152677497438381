import { DateTime, Duration } from "luxon";
import { Bar } from "react-chartjs-2";
import { t } from "../../../../../Config/i18n";
import { getMonth, getWeek, getYear } from "./Utils";

const options = {
	maintainAspectRatio: false,
	tooltips: {
		callbacks: {
			label: (item) => {
				const value = item.yLabel;
				if (value > 1) {
					return `${value} ${t("hours")}`;
				}
				if (value > 0) {
					return `${value * 60} ${t("minutes")}`;
				}
				return t("absent");
			},
		},
	},
};

const isInYear = (logtime, timePeriod) => getYear(logtime) === timePeriod;

const isInMonth = (logtime, timePeriod) => getMonth(logtime) === timePeriod;

const isInWeek = (logtime, timePeriod) => getWeek(logtime) === timePeriod;

const filterLogtimes = (logtimes, scale, timePeriod) => {
	if (scale === "unfiltered") return logtimes;
	const predicates = {
		yearly: isInYear,
		monthly: isInMonth,
		weekly: isInWeek,
	};
	return logtimes.filter((logtime) => predicates[scale](logtime, timePeriod));
};

const sortLogsIntoLabels = (logtimes, scale) => {
	const getKeysFunctions = {
		unfiltered: getMonth,
		yearly: (logtime) =>
			DateTime.fromISO(logtime.date).toLocaleString({ month: "long" }),
		monthly: (logtime) =>
			DateTime.fromISO(logtime.date).toLocaleString({
				weekday: "short",
				day: "numeric",
			}),
		weekly: (logtime) =>
			DateTime.fromISO(logtime.date).toLocaleString({
				weekday: "short",
				day: "numeric",
			}),
	};
	const sortedLogtimes = logtimes.reduce((acc, logtime) => {
		const key = getKeysFunctions[scale](logtime);
		if (!(key in acc))
			acc[key] = {
				student: 0,
				promo: 0,
				logs: [],
			};
		acc[key].student += Duration.fromISO(logtime.log_time).as("hours");
		acc[key].promo += Duration.fromISO(logtime.promo_log_time).as("hours");
		acc[key].logs.push(logtime);
		return acc;
	}, {});
	Object.values(sortedLogtimes).forEach((logtime) => {
		logtime.student /= logtime.logs.length;
		logtime.promo /= logtime.logs.length;
	});
	return sortedLogtimes;
};

export default function LogtimeChart({ logtimes, scale, timePeriod }) {
	const usedScale = timePeriod ? scale : "unfiltered";
	const usedLogtimes = filterLogtimes(logtimes, usedScale, timePeriod);
	const labels = sortLogsIntoLabels(usedLogtimes, usedScale);
	const data = {
		labels: Object.keys(labels),
		datasets: [
			{
				label: t("average logtime").capfirst(),
				borderColor: "#f39c12",
				backgroundColor: "#f39c12",
				fill: 0,
				type: "line",
				data: Object.values(labels).map((label) => label.promo),
			},
			{
				label: t("student logtime").capfirst(),
				backgroundColor: "#2ecc71",
				type: "bar",
				data: Object.values(labels).map((label) => label.student),
			},
		],
	};
	return <Bar data={data} options={options} />;
}
