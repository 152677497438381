import { Prism } from "@mantine/prism";
import { Accordion, AccordionCollapse, Card, Col, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BoxTitle } from "../AdminLTE3/Box";

function CheatersFunctionExpandableComponent({ data: entry, colorScheme }) {
	return (
		<Row>
			<Col xs={6}>
				<Prism withLineNumbers language="c" colorScheme={colorScheme}>
					{entry.function_1.src}
				</Prism>
			</Col>
			<Col xs={6}>
				<Prism withLineNumbers language="c" colorScheme={colorScheme}>
					{entry.function_2.src}
				</Prism>
			</Col>
		</Row>
	);
}

export default function CheatersCoupleExpandableComponent({
	data: entry,
	colorScheme,
	isExpanded,
}) {
	if (!isExpanded) return null;
	return (
		<PerfectScrollbar style={{ padding: "15px 15px" }}>
			{entry.functions.map((func) => (
				<Accordion key={`couple_${entry.id}_functions_${func.id}`}>
					<Card>
						<Card.Header>
							<Accordion.Toggle
								as={BoxTitle}
								eventKey={`${entry.id}`}
								style={{ cursor: "pointer" }}
								className="w-100"
							>
								<Row>
									<Col xs={6}>
										<span>{func.function_1.name}</span>
									</Col>
									<Col xs={6}>
										<span>{func.function_2.name}</span>
									</Col>
								</Row>
							</Accordion.Toggle>
						</Card.Header>
						<AccordionCollapse eventKey={`${entry.id}`}>
							<CheatersFunctionExpandableComponent
								data={func}
								colorScheme={colorScheme}
							/>
						</AccordionCollapse>
					</Card>
				</Accordion>
			))}
		</PerfectScrollbar>
	);
}
