import { Button } from "react-bootstrap";
import { t } from "../../Config/i18n";

export default function ExportButton(props) {
	return (
		<span className="float-right">
			<Button variant="outline-primary" type="button" {...props}>
				{t("export to CSV").capfirst()}
			</Button>
		</span>
	);
}
