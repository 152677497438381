import { createContext, useMemo, useState } from "react";
import { AdditionModal } from "./AdditionModal";

export const AdditionModalContext = createContext(undefined);

export function AdditionModalContextProvider({ children, modules }) {
	const [defaultSettings, setDefaultSettings] = useState({});
	const [displayModal, setDisplayModal] = useState(false);
	const value = useMemo(
		() => ({
			setDisplayModal,
			setDefaultSettings,
		}),
		[displayModal, defaultSettings]
	);
	return (
		<AdditionModalContext.Provider value={value}>
			<AdditionModal
				displayModal={displayModal}
				setDisplayModal={setDisplayModal}
				defaultSettings={defaultSettings}
				modules={modules}
			/>
			{children}
		</AdditionModalContext.Provider>
	);
}
