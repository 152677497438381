import { Button } from "react-bootstrap";
import { t } from "../../Config/i18n";
import LoadingComponent from "../Utils/Loading";
import {
	useClearExpiredAccessTokensMutation,
	useClearExpiredRefreshTokensMutation,
} from "./Queries";

function ClearButton({ queryFn }) {
	return (
		<Button variant="info" className="btn-sm" onClick={queryFn}>
			{t("clear")}
		</Button>
	);
}

export default function ClearTokensTable({
	accessTokensData,
	refreshTokensData,
	APITokens,
	isLoading,
}) {
	const accessTokensMutation = useClearExpiredAccessTokensMutation();
	const refreshTokensMutation = useClearExpiredRefreshTokensMutation();
	if (isLoading) return <LoadingComponent />;
	const table = [
		[
			t("expired access tokens").capfirst(),
			accessTokensData.expired_access_tokens,
			<ClearButton
				queryFn={accessTokensMutation.mutate}
				key="clear_access_tokens"
			/>,
		],
		[
			t("expired refresh tokens").capfirst(),
			refreshTokensData.expired_refresh_tokens,
			<ClearButton
				queryFn={refreshTokensMutation.mutate}
				key="clear_refresh_tokens"
			/>,
		],
		["API tokens", APITokens.length],
	];
	return (
		<table className="table table-striped table-borderless table-hover">
			<tbody>
				{table.map((list, idx) => (
					<tr key={idx}>
						{list.map((label, iidx) => (
							<td key={iidx}>{label}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}
