import { faTerminal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputGroup } from "react-bootstrap";
import { t } from "../../../../../Config/i18n";

export default function Input({ value, onChange }) {
	return (
		<InputGroup className="mb-3">
			<FormControl
				type="text"
				placeholder={t("search by module or activity").capfirst()}
				value={value}
				onChange={onChange}
			/>
			<InputGroup.Append>
				<InputGroup.Text>
					<FontAwesomeIcon icon={faTerminal} />
				</InputGroup.Text>
			</InputGroup.Append>
		</InputGroup>
	);
}
