import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";

export default function Filters({ cities, promotions, onFilter }) {
	const { filters, setSelectedOption, applyFilters } = useFilters({
		cities: {
			label: t("cities"),
			type: "select",
			multiple: true,
			options: cities,
			default: cities.length === 1 ? [cities[0]] : [],
		},
		promotions: {
			label: t("promotions"),
			type: "select",
			multiple: true,
			options: promotions,
			default: [],
		},
		alerts: {
			label: t("alerts only"),
			type: "bool",
			default: false,
		},
	});

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
