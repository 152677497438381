import { t } from "i18next";
import { Component, useEffect } from "react";
import { Toast } from "react-bootstrap";
import { toast } from "react-toastify";

let ID = 0;

export const requestErrorToastManager = (location, error) => {
	useEffect(() => {
		if (!error) return;
		toast.error(
			`${location}: ${t("An error has occured")}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);
};

export function newToast(
	header = "",
	body = "",
	variant = "light",
	delay = 1000
) {
	return {
		header,
		body,
		variant,
		delay,
		id: ID++,
	};
}

export default class Toasts extends Component {
	constructor(props) {
		super(props);
		this.style = {
			top: 15,
			right: 15,
			zIndex: 5000,
			position: "absolute",
			...props.style,
		};
	}

	render() {
		const { toasts } = this.props;
		return (
			<div style={this.style}>
				{toasts.map(
					({
						header = "",
						body = "",
						variant = "light",
						delay = 1000,
						id,
					}) => (
						<Toast
							animation
							key={id}
							className={`bg-${variant}`}
							show
							delay={delay}
							onClose={() => {
								const { onChange } = this.props;
								if (onChange) {
									onChange(
										toasts.filter((toa) => toa.id !== id)
									);
								}
							}}
							style={{ minWidth: 100 }}
							autohide
						>
							<Toast.Header>{header}</Toast.Header>
							<Toast.Body>{body}</Toast.Body>
						</Toast>
					)
				)}
			</div>
		);
	}
}
