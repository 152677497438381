import { Line } from "react-chartjs-2";
import { GRAPH_LINE_COLORS } from "./GraphColors";

const options = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			stacked: false,
			min: 0,
			max: 100,
		},
	},
};

export default function ProgressionGraph({
	stats,
	labels,
	colors = GRAPH_LINE_COLORS,
}) {
	const progressionData = stats ?? {};
	const data = { datasets: [], labels: labels ?? [] };
	Object.keys(progressionData).forEach((key, idx) => {
		data.datasets.push({
			backgroundColor: `${colors[idx % colors.length]}, 0.1)`,
			borderColor: `${colors[idx % colors.length]}, 1.0)`,
			data: progressionData[key],
			label: key,
			fill: true,
			tension: 0.3,
		});
	});
	return <Line data={data} options={options} />;
}
