import { t } from "i18next";
import { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { findCorrectFilterOption, getLastSearchParam } from "../Utils/Filters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import {
	useTesseractInstancesQuery,
	useTesseractModulesQuery,
} from "./Queries";

export default function Filters({ onFilter }) {
	const yearOptions = nValues(2020, getCurrentAcademicYear() - 2020 + 1)
		.reverse()
		.toFilterOptions();
	const [searchParams] = useSearchParams();
	const didInstanceFilterRun = useRef(false);
	const currentYear = getCurrentAcademicYear();
	const {
		filters,
		setFilterOptions,
		setSelectedOption,
		applyFilters,
		setFilterDisabled,
	} = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			clearable: false,
			options: yearOptions,
			default: { label: currentYear, value: currentYear },
		},
		module_code: {
			label: t("module code"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
			dependsOn: "year",
		},
		instance_code: {
			label: t("instance"),
			type: "select",
			multiple: false,
			options: [],
			default: null,
			disabled: true,
			dependsOn: "module_code",
		},
	});
	const { data: fetchedModules } = useTesseractModulesQuery(
		filters.year.selected.value
	);

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useTesseractInstancesQuery(
			filters.year.selected?.value,
			filters.module_code.selected?.value
		);
	if (
		filters.module_code.selected?.value &&
		!isFetchingNextPage &&
		hasNextPage
	)
		fetchNextPage();

	const requestData = data?.pages.flatMap((page) => page.data.results) ?? [];
	const fetchedInstances = requestData
		.map((instance) => instance.code_instance)
		.sort()
		.toFilterOptions();

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			onSelectChange("module_code", null);
		} else if (filterKey === "module_code") {
			if (newValue === null) {
				setFilterOptions("instance_code", []);
				onSelectChange("instance_code", null);
				setFilterDisabled("instance_code", true);
				return;
			}
			onSelectChange("instance_code", null);
			setFilterDisabled("instance_code", false);
		}
	};

	useEffect(() => {
		if (!fetchedModules) return;
		setFilterOptions("module_code", fetchedModules);
	}, [fetchedModules]);

	useEffect(() => {
		if (!fetchedInstances) return;
		setFilterOptions("instance_code", fetchedInstances, true);
		const queryInstance = !didInstanceFilterRun.current
			? getLastSearchParam(searchParams, "instance_code")
			: null;
		const selectedInstance = findCorrectFilterOption(
			fetchedInstances,
			queryInstance
		);
		didInstanceFilterRun.current = true;
		if (selectedInstance) onSelectChange("instance_code", selectedInstance);
	}, [data]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={onSelectChange} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={
						!(filters.year.selected && filters.module_code.selected)
					}
				/>
			</Card.Footer>
		</Box>
	);
}
