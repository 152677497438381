import { t } from "../../../Config/i18n";
import ExportButton from "../../Sauron/ExportButton";
import ProgressionGraph from "../../Sauron/Graphs/ProgressionGraph";
import { SauronChartsContainer } from "../../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../../Utils/Toasts";
import {
	exportProgression,
	getLabelWeeks,
	sortObjectByKeys,
} from "../../Utils/Utils";
import { useInternshipProgressionQueries } from "../Queries";

export default function InternshipProgress({ cities, promotions, detailPath }) {
	const { error, isFetching, data } = useInternshipProgressionQueries(
		cities.map((city) => city.value),
		promotions
	);
	const sortedData = sortObjectByKeys(data ?? {});
	const maxLength = Object.values(sortedData).reduce((max, dataset) => {
		if (dataset.length > max) return dataset.length;
		return max;
	}, 0);
	const weeks = getLabelWeeks(maxLength);

	requestErrorToastManager("Internship Progress", error);

	return (
		<SauronChartsContainer
			title={t("internshipProgresstime").capfirst()}
			isLoading={isFetching}
			detailPath={detailPath}
			topRightButton={
				<ExportButton
					onClick={() =>
						exportProgression(
							sortedData,
							weeks.map((week) => `W${week.weekNumber}`),
							"internship_progression"
						)
					}
					disabled={isFetching}
				/>
			}
		>
			<ProgressionGraph
				stats={sortedData}
				labels={weeks.map((week) =>
					week.toLocaleString({
						day: "numeric",
						month: "long",
					})
				)}
			/>
		</SauronChartsContainer>
	);
}
