import { Table } from "react-bootstrap";
import { t } from "../../../../../Config/i18n";
import { sortObjectByKeys } from "../../../../Utils/Utils";
import { GRADES, GRADING_SCALE } from "./Consts";

const reduceSkillsPerYear = (englishSkills) => {
	const reducedSkillsPerYear = sortObjectByKeys(
		englishSkills.reduce((acc, skill) => {
			const year = skill.instance_year;
			if (!(year in acc))
				acc[year] = {
					year,
					cycles: 0,
					projects: 0,
					skills: 0,
					grade: 0,
					level: 0,
				};
			acc[year].cycles += 1;
			acc[year].projects += skill.nb_projects;
			acc[year].skills += skill.nb_skills_validated;
			acc[year].grade += GRADES[skill.grade];
			acc[year].level += skill.average_level;
			return acc;
		}, {})
	);
	return Object.values(reducedSkillsPerYear).map((yearSkills) => ({
		...yearSkills,
		grade: yearSkills.grade / yearSkills.cycles,
		level: yearSkills.level / yearSkills.cycles,
	}));
};

function TableYearRow({ yearStats }) {
	const GRADE_LABELS = Object.keys(GRADES).splice(1);
	return (
		<tr>
			<th>{yearStats.year}</th>
			<td>{yearStats.cycles || 1}</td>
			<td>{yearStats.projects}</td>
			<td>{yearStats.skills}</td>
			<td>{GRADE_LABELS[Math.round(yearStats.grade)]}</td>
			<td>{GRADING_SCALE[Math.round(yearStats.level)]}</td>
		</tr>
	);
}

export default function SkillsTable({ englishInstances }) {
	const yearlySkills = reduceSkillsPerYear(englishInstances);

	return (
		<Table striped borderless hover responsive className="text-center">
			<thead className="thead-light">
				<tr>
					<th>{t("year").capfirst()}</th>
					<th>{t("english cycles").capfirst()}</th>
					<th>{t("projects").capfirst()}</th>
					<th>{t("skills").capfirst()}</th>
					<th>{t("grade").capfirst()}</th>
					<th>{t("level").capfirst()}</th>
				</tr>
			</thead>
			<tbody>
				{yearlySkills.map((yearStats) => (
					<TableYearRow
						key={`${yearStats.year}_english_table`}
						yearStats={yearStats}
					/>
				))}
			</tbody>
		</Table>
	);
}
