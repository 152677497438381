import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getPGEPromotionOptions } from "../Utils/Utils";
import { useCurriculumsQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const { data, error } = useCurriculumsQuery();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			city: {
				label: t("city"),
				type: "select",
				multiple: false,
				options: citiesOptions,
				default: citiesOptions.length === 1 ? citiesOptions[0] : null,
			},
			curriculum: {
				label: t("curriculum"),
				type: "select",
				multiple: false,
				options: [],
				default: null,
			},
			promotion: {
				label: t("promotion"),
				type: "select",
				multiple: false,
				options: getPGEPromotionOptions(),
				default: null,
			},
		});

	useEffect(() => {
		if (!data) return;
		setFilterOptions("curriculum", data);
	}, [data]);

	requestErrorToastManager(`Investment Reports Filters`, error);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={filters.city.selected === null}
				/>
			</Card.Footer>
		</Box>
	);
}
