export const STATUS_CONFIG = {
	stealer: {
		color: "#2c3e50",
		textColor: "text-black",
	},
	cheater: {
		color: "#7f8c8d",
		textColor: "text-secondary",
	},
	absent: {
		color: "#e74c3c",
		textColor: "text-danger",
	},
	missing: {
		color: "#e74c3c",
		textColor: "text-danger",
	},
	segv: {
		color: "#f39c12",
		textColor: "text-warning",
	},
	present: {
		color: "#2ecc71",
		textColor: "text-success",
	},
};
