import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import ConfigurationsContainer from "./ConfigurationsContainer";
import Filters from "./Filters";

export default function TesseractConfiguration() {
	const yearOptions = nValues(2019, getCurrentAcademicYear() - 2019 + 1)
		.reverse()
		.toFilterOptions();
	const currentYear = getCurrentAcademicYear();
	const [activeFilters, setActiveFilters] = useState({
		year: { label: currentYear, value: currentYear },
		errors: false,
		search: "",
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	return (
		<Section>
			<SectionHeader title={t("tesseract configuration").capfirst()}>
				<SectionContent>
					<Container fluid>
						<Row className="mt-4">
							<Filters
								onFilter={updateActiveFilters}
								yearOptions={yearOptions}
							/>
						</Row>
						<Row>
							<ConfigurationsContainer
								year={activeFilters.year}
								errorsOnly={activeFilters.errors}
								search={activeFilters.search}
							/>
						</Row>
					</Container>
				</SectionContent>
			</SectionHeader>
		</Section>
	);
}
