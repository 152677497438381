import { DateTime } from "luxon";
import { Line } from "react-chartjs-2";
import { t } from "../../Config/i18n";

const options = {
	maintainAspectRatio: false,
	scales: {
		y: {
			stacked: false,
			min: 0,
			max: 100,
		},
	},
	tooltips: {
		callbacks: {
			label: (item, ctx) =>
				`${ctx.datasets[item.datasetIndex].label}: ${item.yLabel}`,
		},
	},
};

export default function InvestmentStudentChart({ data, stats, student }) {
	const graphData = {
		labels: data.map((entry) =>
			DateTime.fromISO(entry.week).toLocaleString({
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			})
		),
		datasets: [
			{
				fill: true,
				label: t("student").capfirst(),
				backgroundColor: "rgba(39, 174, 96, 0.1)",
				borderColor: "rgba(46, 204, 113, 1.0)",
				data: data.map((entry) => entry.score),
			},
			{
				fill: true,
				label: t("promo average").capfirst(),
				backgroundColor: "rgba(192, 57, 43, 0.1)",
				borderColor: "rgba(231, 76, 60, 1.0)",
				data: Object.values(stats).map(
					(entry) =>
						entry[student.promo.promotion_year][
							student.promo.city.name
						]
				),
			},
		],
	};
	return <Line data={graphData} options={options} />;
}
