import { faDownload, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useColorScheme } from "@mantine/hooks";
import { t } from "i18next";
import { useState } from "react";
import { Button, ButtonGroup, ProgressBar, Row } from "react-bootstrap";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import CheatersCoupleExpandableComponent from "./CheatersExpendableComponent";
import {
	useCheaterStatusMutation,
	useCheatersCoupleDownloadMutation,
} from "./Queries";

function CheaterLoginRow({ student }) {
	if (student) {
		return (
			<LoginRow login={student.login} city={student.promo.city.name} />
		);
	}
	return (
		<div className="d-flex align-items-center ml-1">
			<FontAwesomeIcon icon={faUserTie} size="2x" />
			<span className="ml-2">{t("no student")}</span>
		</div>
	);
}

function cheatedValue(cheated) {
	if (cheated === null) return 0;
	return cheated ? 2 : 1;
}

export default function CheatersDataTable({
	data = [],
	showSlug = false,
	isLoading,
	isFetching,
}) {
	const cheatersCoupleMutation = useCheaterStatusMutation();
	const { colorScheme } = useColorScheme();

	const [loadingButtonId, setLoadingButtonId] = useState(null);

	const [downloadInProgressId, setDownloadInProgressId] = useState(false);

	const handleCheaterStatusChange = (entry, value) => {
		setLoadingButtonId(`${entry.id}-${value}`);
		cheatersCoupleMutation.mutate(
			{
				id: entry.id,
				is_cheater: value,
			},
			{
				onSettled: () => {
					setLoadingButtonId(null);
				},
			}
		);
	};

	const downloadCoupleDeliveryMutation = useCheatersCoupleDownloadMutation();

	const downloadDelivery = async (entry) => {
		setDownloadInProgressId(entry.id);
		downloadCoupleDeliveryMutation.mutate(
			{ id: entry.id },
			{
				onSettled: () => {
					setDownloadInProgressId(null);
				},
			}
		);
	};

	const isCheaterButtons = [
		{
			id: "confirmed",
			name: t("confirmed").capfirst(),
			value: true,
			className: "danger",
		},
		{
			id: "unmarked",
			name: t("unmarked").capfirst(),
			value: null,
			className: "secondary",
		},
		{
			id: "false_positive",
			name: t("false positive").capfirst(),
			value: false,
			className: "success",
		},
	];
	const columns = [
		{
			id: "student1",
			accessorFn: (row) => row.student_1?.login,
			header: `${t("student").capfirst()} 1`,
			Cell: ({ row }) => (
				<CheaterLoginRow student={row.original.student_1} />
			),
		},
		{
			id: "student2",
			accessorFn: (row) => row.student_2?.login,
			header: `${t("student").capfirst()} 2`,
			Cell: ({ row }) => (
				<CheaterLoginRow student={row.original.student_2} />
			),
		},
		{
			accessorKey: "percent",
			header: t("match").capfirst(),
			Cell: ({ row }) => (
				<ProgressBar
					className="w-100"
					variant="primary"
					min={0}
					max={100}
					now={row.original.percent}
				/>
			),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "is_cheater",
			header: t("actions").capfirst(),
			minSize: 310,
			enableGlobalFilter: false,
			sortingFn: (a, b) => {
				const valueA = cheatedValue(a.original.is_cheater);
				const valueB = cheatedValue(b.original.is_cheater);
				return valueA - valueB;
			},
			Cell: ({ row }) => (
				<Row className="ml-1">
					<ButtonGroup>
						{isCheaterButtons.map((button) => (
							<button
								type="button"
								className={
									row.original.is_cheater === button.value
										? `btn btn-sm btn-${button.className}`
										: `btn btn-sm btn-outline-${button.className}`
								}
								key={button.id}
								onClick={() =>
									handleCheaterStatusChange(
										row.original,
										button.value
									)
								}
								disabled={
									loadingButtonId ===
									`${row.original.id}-${button.value}`
								}
							>
								{button.name}
							</button>
						))}
					</ButtonGroup>
					<div className="ml-2">
						<Button
							className="btn-sm"
							onClick={() => downloadDelivery(row.original)}
						>
							{downloadInProgressId === row.original.id ? (
								<div className="spinner-border spinner-border-sm" />
							) : (
								<FontAwesomeIcon icon={faDownload} />
							)}
						</Button>
					</div>
				</Row>
			),
		},
	];

	if (showSlug) {
		columns.splice(0, 0, {
			Cell: ({ row }) =>
				`[${row.original.code_module}] ${row.original.slug}`,
			accessorKey: "slug",
			header: `${t("project").capfirst()}`,
			enableGlobalFilter: false,
		});
	}
	return (
		<SauronMantineDataTable
			title={t("cheating cases").capfirst()}
			data={data}
			columns={columns}
			tableProps={{
				enableExpandAll: false,
				renderDetailPanel: ({ row }) => (
					<CheatersCoupleExpandableComponent
						data={row.original}
						colorScheme={colorScheme}
						isExpanded={row.getIsExpanded()}
					/>
				),
			}}
			isFetching={isFetching}
			isLoading={isLoading}
			isCard
		/>
	);
}
