import {
	faArrowDown,
	faCheck,
	faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HoverCard } from "@mantine/core";
import { t } from "i18next";

export const getInvestmentAlertStatus = (dropPercentage) => {
	dropPercentage = -dropPercentage;
	const alertRules = [
		{
			condition: dropPercentage < -50,
			color: "#e74c3c",
			icon: faWarning,
			text: "falling behind",
		},
		{
			condition: dropPercentage < -30,
			color: "#f1c40f",
			icon: faArrowDown,
			text: "slightly falling behind",
		},
		{
			condition: true,
			color: "#27ae60",
			icon: faCheck,
			text: "Ok",
			isOk: true,
		},
	];
	return alertRules.find((rule) => rule.condition);
};

export default function InvestmentFallingAlert({ dropPercentage = 0 }) {
	const { color, icon, text, isOk } =
		getInvestmentAlertStatus(dropPercentage);

	return (
		<HoverCard shadow="md" withArrow>
			<HoverCard.Target>
				<span
					style={{
						backgroundColor: color,
						display: "block",
						fontSize: "12px",
						fontWeight: 700,
						textAlign: "center",
						whiteSpace: "nowrap",
						verticalAlign: "top",
						borderRadius: "10px",
						paddingTop: 1,
						paddingBottom: 1,
					}}
					className="text-white"
				>
					<FontAwesomeIcon icon={icon} size="sm" />
				</span>
			</HoverCard.Target>
			{!isOk && (
				<HoverCard.Dropdown>{t(text).capfirst()}</HoverCard.Dropdown>
			)}
		</HoverCard>
	);
}
