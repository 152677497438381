import { useContext } from "react";
import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { ScraperApiHelperContext } from "../../contexts";
import {
	getCurrentAcademicYear,
	getNextPageParam,
	promotionYearToBachelorPromotion,
} from "../Utils/Utils";
import { t } from "../../Config/i18n";

export const useIntuituPersonaeInfiniteQuery = (
	cityCodes,
	promotionYears,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["intuitu_personae", "data_table", cityCodes, promotionYears],
		queryFn: ({ pageParam }) =>
			scraper.students.getIntuituPersonae(
				pageParam,
				cityCodes,
				promotionYears
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: areRequestEnabled,
		select: (response) => {
			const ret = { ...response };
			ret.pages = response.pages.flatMap((page) =>
				page.data.results.map((result) => ({
					alert: result.alert,
					login: result.student.login,
					promotion: result.student.promo.promotion_year,
					adm_status: result.adm_status,
					pedago_status: result.pedago_status,
					comment: result.comment,
					last_update: DateTime.fromISO(result.date),
					id: result.id,
					editing: false,
				}))
			);
			return ret;
		},
	});
};

export const useSingleIntuituPersonaeMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: (data) => scraper.students.postIntuituPersonae(data),
		onError: (error) => {
			toast.error(
				`Intuitu Personae: ${t("upload failure").capfirst()}: ${error}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			toast.success(
				`Intuitu Personae: ${t("upload success").capfirst()}`,
				{
					theme: "colored",
					autoClose: 2000,
				}
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["intuitu_personae"],
			});
		},
	});
};

export const useCSVIntuituPersonaeMutation = (
	UPLOAD_STATUS,
	setErrorMessage,
	setUploadStatus
) => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: (data) => {
			setUploadStatus(() => UPLOAD_STATUS.pending);
			setErrorMessage(() => "");
			return scraper.students.postIntuituPersonae(data.data);
		},
		onError: (error) => {
			let errorToDisplay = error;
			if (error?.response?.data) {
				if (typeof error.response.data.detail === "string") {
					errorToDisplay = error.response.data.detail;
				} else errorToDisplay = JSON.stringify(error.response.data);
			}
			toast.error(
				`Intuitu Personae: ${t(
					"an error occured during import"
				).capfirst()} ${errorToDisplay}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
			setUploadStatus(() => UPLOAD_STATUS.error);
			setErrorMessage(() => errorToDisplay);
		},
		onSuccess: () => {
			setUploadStatus(() => UPLOAD_STATUS.success);
			toast.success(
				`Intuitu Personae: ${t("successfully imported").capfirst()}`,
				{
					theme: "colored",
					autoClose: 2000,
				}
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["intuitu_personae"],
			});
		},
	});
};

const findCityNameWithCode = (cities, code) =>
	cities.find((city) => code === city.value)?.label ?? "France";

export const useIntuituProgressionQuery = (cities, promotionYears) => {
	const year = getCurrentAcademicYear();
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useQuery({
		queryKey: ["intuitu_personae", "cities", cityCodes, promotionYears],
		queryFn: () =>
			scraper.students.getIntuituPersonaeStats(cityCodes, promotionYears),
		select: (response) =>
			Object.entries(response.data).reduce(
				(acc, [promotionYear, citiesWeeks]) => {
					const promotion = promotionYearToBachelorPromotion(
						promotionYear,
						year
					);
					Object.entries(citiesWeeks).forEach(([city, weeks]) => {
						const cityName = findCityNameWithCode(cities, city);
						const admProperty = `${cityName} - ${promotion} - ${t(
							"administrative"
						).capfirst()}`;
						const pedagoProperty = `${cityName} - ${promotion} - ${t(
							"pedagogical"
						).capfirst()}`;
						acc.graph[admProperty] = { stats: [], missing: [] };
						acc.graph[pedagoProperty] = { stats: [], missing: [] };
						if (!(promotion in acc.raw)) acc.raw[promotion] = {};
						acc.raw[promotion][cityName] = weeks;
						Object.values(weeks).forEach((stats) => {
							const { adm_status, nb_missing, pedago_status } =
								stats;
							acc.graph[admProperty].stats.push(adm_status);
							acc.graph[pedagoProperty].stats.push(pedago_status);
							acc.graph[admProperty].missing.push(nb_missing);
							acc.graph[pedagoProperty].missing.push(nb_missing);
						});
					});
					return acc;
				},
				{ graph: {}, raw: {} }
			),
	});
};
