import { Button, Card } from "react-bootstrap";

export default function Popup({ titleText, buttonText, onClose }) {
	if (!titleText || !buttonText) return null;
	return (
		<div className="popup">
			<Card className="popup_inner">
				<Card.Header>
					<h1>{titleText}</h1>
				</Card.Header>
				<Card.Body style={{ paddingTop: "1vh", paddingBottom: 0 }}>
					<Button onClick={onClose}>{buttonText}</Button>
				</Card.Body>
			</Card>
		</div>
	);
}
