import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltipWithCity from "../Utils/StudentTooltipWithCity";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear } from "../Utils/Utils";
import Filters from "./Filters";
import ProjectsDataTable from "./ProjectsDataTable";
import ProjectDetails from "./ProjectsDetails";
import ProjectsStats from "./ProjectsStats";
import { useProjectStatsQuery } from "./Queries";

export default function Projects() {
	const currentYear = getCurrentAcademicYear();
	const projectSelected = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		year: currentYear,
		project: null,
		project_instance: null,
	});
	const year = activeFilters.year?.value;
	const project_instance = activeFilters.project_instance?.value;
	const code_module = activeFilters.project?.value?.split("__")[0];
	const slug = activeFilters.project?.value?.split("__")[1];
	const updateActiveFilters = (selectedFilters) => {
		projectSelected.current =
			selectedFilters.project && selectedFilters.project_instance;
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	const {
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		data,
		isLoading,
		isFetching,
		error,
	} = useProjectStatsQuery(year, code_module, slug, project_instance);
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();

	requestErrorToastManager("Projects: Unable to get projects", error);

	return (
		<Section>
			<SectionHeader title={t("projects contributions").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-3 gap-3">
						<Col lg={3}>
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={3}>
							<ProjectDetails
								year={year}
								code_module={code_module}
								slug={slug}
								project_instance={project_instance}
								nb_registered={data?.length}
								isFetchingRegistered={isFetching}
							/>
						</Col>
						<Col lg={6}>
							<ProjectsStats
								year={year}
								code_module={code_module}
								slug={slug}
								project_instance={project_instance}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<ProjectsDataTable
								data={data}
								isFetching={isFetching}
								isLoading={isLoading}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="projects-tooltip" />
			<StudentTooltipWithCity tooltipId="projects-student-tooltip" />
		</Section>
	);
}
