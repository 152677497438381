import { t } from "../../../../../Config/i18n";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { useUnregisteredProjectsQuery } from "../../../Queries";

function ProjectCell({ project }) {
	const {
		year,
		code_module: module,
		code_instance: instance,
		project_slug: name,
	} = project;
	return (
		<tr>
			<td>{module}</td>
			<td>
				<a
					href={`https://intra.epitech.eu/module/${year}/${module}/${instance}`}
					target="_blank"
					rel="noreferrer"
				>
					{name.capfirst()}
				</a>
			</td>
		</tr>
	);
}

export default function UnregisteredProjects({ login }) {
	const {
		data: projects,
		error,
		isFetching,
	} = useUnregisteredProjectsQuery(login);

	requestErrorToastManager("Student: Unregistered projects tab", error);

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!projects || projects.length === 0) return <NoDataAvailable />;
	return (
		<table className="table table-striped table-borderless table-hover">
			<thead className="thead-light">
				<tr>
					<th scope="col">{t("module").capfirst()}</th>
					<th scope="col">{t("project").capfirst()}</th>
				</tr>
			</thead>
			<tbody>
				{projects.map((project) => (
					<ProjectCell key={project.project_slug} project={project} />
				))}
			</tbody>
		</table>
	);
}
