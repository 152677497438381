import { Doughnut } from "react-chartjs-2";
import { t } from "../../../../../Config/i18n";

const options = {
	responsive: true,
	maintainAspectRatio: false,
};

export default function RoadblocksDoughnut({ roadblocks }) {
	const data = Object.values(roadblocks).reduce(
		(acc, roadblock) => {
			if (
				roadblock?.min_required === undefined ||
				roadblock.min_required <= 0
			)
				return acc;
			const inProgress =
				roadblock.inprogress > 0 ? roadblock.inprogress : 0;
			if (roadblock.obtained >= roadblock.min_required) {
				acc.obtained += 1;
				return acc;
			}
			if (roadblock.obtained + inProgress >= roadblock.min_required) {
				acc.inProgress += 1;
				return acc;
			}
			acc.failed += 1;
			return acc;
		},
		{ obtained: 0, inProgress: 0, failed: 0 }
	);
	const chartData = {
		labels: [
			t("obtained").capfirst(),
			t("inprogress").capfirst(),
			t("failed").capfirst(),
		],
		datasets: [
			{
				data: Object.values(data),
				backgroundColor: ["#2ecc71", "#3498db", "#e74c3c"],
				hoverBackgroundColor: ["#27ae60", "#2980b9", "#c0392b"],
			},
		],
	};
	return (
		<div className="text-center">
			<h4 className="text-muted">
				{t("roadblocks overview").capfirst()}
			</h4>
			<div style={{ height: 300 }}>
				<Doughnut data={chartData} options={options} />
			</div>
		</div>
	);
}
