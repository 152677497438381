import MasterFilters from "./MasterFilters.jsx";
import { Col, Row } from "react-bootstrap";
import MasterDataTableContainer from "./MasterDataTableContainer.jsx";
import { useRef, useState } from "react";
import { areFiltersValueDifferent } from "../../Utils/Filters.jsx";
import StudentAlertsChart from "../StudentAlertsChart.jsx";
import StudentSuccessChart from "../StudentSuccessChart.jsx";

const Master = () => {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		semesters: [],
		promotion_master: [],
	});
	const promotion_master = activeFilters.promotion_master
		? activeFilters.promotion_master.map((promotion) => promotion.value)
		: [];
	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	return (
		<Row>
			<Col lg={3}>
				<MasterFilters onFilter={updateActiveFilters} />
			</Col>
			<Col lg={4} className="mb-3">
				<StudentAlertsChart
					promotion_master={promotion_master}
					type="master"
				/>
			</Col>
			<Col lg={5} className="mb-3">
				<StudentSuccessChart
					promotion_master={promotion_master}
					type="master"
				/>
			</Col>
			<Col lg={12} className="mt-3">
				<MasterDataTableContainer
					semesters={activeFilters.semesters}
					promotions={promotion_master}
					areRequestEnabled={areRequestEnabled.current}
				/>
			</Col>
		</Row>
	);
};

export default Master;
