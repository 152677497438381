import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { requestErrorToastManager } from "../Utils/Toasts";
import { useModuleStatsQuery } from "./Queries";

export function AlertsPerCities({ data }) {
	const graphDataStructure = {
		labels: [],
		datasets: [
			{
				label: t("module alerts").capfirst(),
				backgroundColor: "rgb(231, 76, 60)",
				data: [],
			},
			{
				label: t("validated modules").capfirst(),
				backgroundColor: "#33bdb6",
				data: [],
			},
		],
	};
	const graphData = Object.keys(data)
		.sort()
		.reduce((acc, city) => {
			const { alerts, normal } = data[city];
			acc.labels.push(city.capfirst());
			acc.datasets[0].data.push(alerts);
			acc.datasets[1].data.push(normal);
			return acc;
		}, graphDataStructure);
	return <Bar data={graphData} options={defaultBarOptions} />;
}

export default function AlertsPerCitiesContainer() {
	const { data = {}, isFetching, errorModules } = useModuleStatsQuery();
	// eslint-disable-next-line no-unused-vars
	const { global, europe, ...filtered } = data;

	requestErrorToastManager("Module alerts: Chart", errorModules);

	return (
		<SauronChartsContainer
			title={t("number of alerts").capfirst()}
			subtitle={t("per cities")}
			isLoading={isFetching}
		>
			<AlertsPerCities data={filtered} />
		</SauronChartsContainer>
	);
}
