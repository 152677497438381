import { requestErrorToastManager } from "../Utils/Toasts";
import DataTable from "./DataTable";
import { useInvestmentInfiniteQuery } from "./Queries";

export default function DataTableContainer({
	city,
	promotion,
	begin,
	end,
	alert,
	stats,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		error,
	} = useInvestmentInfiniteQuery(city?.value, promotion, begin, end, alert);

	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const students = data?.pages.flatMap((page) => page) ?? [];

	requestErrorToastManager("Investement", error);

	const exportConfig = {
		alert: "alert",
		login: "student.login",
		promotion: "promotion",
		curriculum: "curriculum",
		city: "city",
		logtime_count: "logtime_count",
		logtime_ratio: "logtime_ratio",
		presences_count: "presences_count",
		presences_ratio: "presences_ratio",
		commits_count: "commits_count",
		commits_ratio: "commits_ratio",
		lines_count: "lines_count",
		lines_ratio: "lines_ratio",
		score: "score",
	};

	return (
		<DataTable
			isFetching={isFetching}
			data={students}
			stats={stats}
			exportConfig={exportConfig}
		/>
	);
}
