import { useMemo } from "react";
import getPresenceStatus from "./PresenceStatus";
import StudentPresenceDataTable from "./StudentPresenceDataTable";

export default function StudentPresenceDataTableContainer({
	isLoading,
	isFetching,
	activityPresences,
}) {
	const presenceStatus = getPresenceStatus();
	const activities = activityPresences ?? [];

	const formatRows = useMemo(() => {
		if (!activityPresences) return [];
		const rows = {};

		activityPresences.forEach((activity) => {
			activity.students.forEach((student) => {
				const { login } = student.student;
				if (!rows[login]) rows[login] = { login };
				rows[login][activity.code_acti] = {};
				rows[login][activity.code_acti].status = student.status;
			});
		});
		const orderedRows = Object.keys(rows)
			.sort()
			.reduce((accumulator, login) => {
				accumulator[login] = rows[login];
				return accumulator;
			}, {});
		return Object.values(orderedRows);
	}, [activityPresences]);

	const tableData = useMemo(
		() =>
			formatRows.map((formatRow, idx) => ({
				id: idx,
				...formatRow,
			})),
		[formatRows]
	);
	const exportConfig = {
		login: "login",
		...Object.assign(
			{},
			...activities.map((activity) => ({
				[`${activity.name}`]: (row) =>
					presenceStatus[row[activity.code_acti].status].label,
			}))
		),
	};

	return (
		<StudentPresenceDataTable
			data={tableData}
			activities={activities}
			exportConfig={{
				columns: exportConfig,
				title: "student_presences",
			}}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
}
