import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import MissingTranscriptsPerElements from "./MissingTranscriptsPerElements";
import { useMissingTranscriptsPerPromotions } from "./Queries";

export default function MissingPerPromotions({ cities, promotions }) {
	if (promotions.length === 1) return null;
	const {
		data = {},
		error,
		isFetching,
	} = useMissingTranscriptsPerPromotions(
		cities.map((city) => city.value),
		promotions
	);
	requestErrorToastManager(
		"Transcripts: Missing Transcripts per Promotions",
		error
	);
	return (
		<SauronChartsContainer
			title={t("missing transcripts percentage").capfirst()}
			subtitle={`${t("by")} ${t("promotions")}`}
			isLoading={isFetching}
		>
			<MissingTranscriptsPerElements data={data} />
		</SauronChartsContainer>
	);
}
