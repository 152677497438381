import { useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import SauronBox from "../Sauron/SauronBox";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear } from "../Utils/Utils";
import Filters from "./Filters";
import FlagsStats from "./FlagsStats";
import GradesByInstances from "./GradesByInstances";
import {
	useConfigurationQuery,
	useModuleValidationStatsQuery,
} from "./Queries";
import SkillsList from "./SkillsList";
import SkillsStats from "./SkillsStats";
import TesseractDataTableContainer from "./TesseractDataTableContainer";
import ValidationCriteria from "./ValidationCriteria";

export default function Tesseract() {
	const areRequestEnabled = useRef(false);
	const currentYear = getCurrentAcademicYear();
	const [activeFilters, setActiveFilters] = useState({
		year: { label: currentYear, value: currentYear },
		module_code: null,
		instance_code: null,
	});
	const queryClient = useQueryClient();

	const updateActiveFilters = (selectedFilters) => {
		queryClient.invalidateQueries({ queryKey: ["tesseract"] });
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	const {
		data: validationStats,
		error: validationStatsError,
		isFetching: isFetchingValidationStats,
	} = useModuleValidationStatsQuery(
		activeFilters.year?.label,
		activeFilters.module_code?.label,
		activeFilters.instance_code?.label,
		areRequestEnabled.current
	);

	const {
		data: configuration,
		error: configurationError,
		isFetching: isFetchingConfiguration,
	} = useConfigurationQuery(
		activeFilters.year?.label,
		activeFilters.module_code?.label,
		areRequestEnabled.current
	);

	requestErrorToastManager(
		"Tesseract: Validations Stats",
		validationStatsError
	);

	requestErrorToastManager("Tesseract: Configuration", configurationError);

	return (
		<Section>
			<SectionHeader
				title="Tesseract"
				subtitle=" (Tool for Easy and Superb Skill Extraction and Results Associated to Charts and Tables)"
			/>
			<SectionContent>
				<Container fluid>
					<Row className="gap-3 mb-3">
						<Col lg={3}>
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9}>
							<GradesByInstances
								validationStats={validationStats}
								gradation={configuration?.gradation}
								isLoading={
									areRequestEnabled.current &&
									(isFetchingValidationStats ||
										isFetchingConfiguration)
								}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							<TesseractDataTableContainer
								year={activeFilters.year?.label}
								module_code={activeFilters.module_code?.label}
								instance_code={
									activeFilters.instance_code?.label
								}
								configuration={configuration}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={8}>
							{areRequestEnabled.current ? (
								<SauronBox
									title={t("skill").capfirst()}
									subtitle={`${t("for validation")}`}
									bodyStyle={{ height: "400px" }}
									isLoading={
										areRequestEnabled.current &&
										isFetchingConfiguration
									}
								>
									<SkillsList
										skills={configuration?.skills}
										isFlagSkills={false}
									/>
								</SauronBox>
							) : null}
						</Col>
						<Col lg={4}>
							{areRequestEnabled.current ? (
								<SauronBox
									title={t("skill").capfirst()}
									subtitle={`${t("for flags")}`}
									bodyStyle={{ height: "400px" }}
									isLoading={
										areRequestEnabled.current &&
										isFetchingConfiguration
									}
								>
									<SkillsList
										skills={configuration?.skills}
										isFlagSkills
									/>
								</SauronBox>
							) : null}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							{areRequestEnabled.current ? (
								<SauronBox
									title={t("validation criteria").capfirst()}
									isLoading={
										areRequestEnabled.current &&
										isFetchingConfiguration
									}
								>
									<ValidationCriteria
										elements={configuration?.gradation}
										areRequestEnabled={
											areRequestEnabled.current
										}
									/>
								</SauronBox>
							) : null}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							{areRequestEnabled.current ? (
								<SauronBox
									title={t(
										"flags attribution criteria"
									).capfirst()}
									isLoading={
										areRequestEnabled.current &&
										isFetchingConfiguration
									}
								>
									<ValidationCriteria
										elements={configuration?.flags}
										areRequestEnabled={
											areRequestEnabled.current
										}
									/>
								</SauronBox>
							) : null}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							{areRequestEnabled.current ? (
								<SkillsStats
									year={activeFilters.year?.label}
									module_code={
										activeFilters.module_code?.label
									}
									instance_code={
										activeFilters.instance_code?.label
									}
									areRequestEnabled={
										areRequestEnabled.current
									}
								/>
							) : null}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							{areRequestEnabled.current ? (
								<FlagsStats
									isLoading={
										areRequestEnabled.current &&
										isFetchingValidationStats
									}
									flagsStats={validationStats}
									areRequestEnabled={
										areRequestEnabled.current
									}
								/>
							) : null}
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip
				id="tesseract-tooltip"
				style={{ textAlign: "center", width: 200 }}
			/>
			<StudentTooltip tooltipId="tesseract-student-tooltip" />
		</Section>
	);
}
