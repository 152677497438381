import { t } from "../../Config/i18n";

export function getAlertLabels(curriculum = null) {
	const allAlertLabels = {
		bachelor: {
			technical_foundation: t("technical foundation"),
			technical_supplement: t("technical supplement"),
			innovation: t("innovation"),
			softskills: t("softskills"),
			technical_mastery: t("technological mastery"),
			professionalization: t("professionalization"),
			total_credits: t("credits"),
			tepitech: t("tepitech"),
			professional_writings: t("professional writings"),
			duo_stumper: "Colle Duo",
			solo_stumper: "Colle Solo",
		},
		master: {
			total_credits: t("unsufficient credits"),
			tech4_eip: t("not registered EIP Tech4"),
			tech5_eip: t("not registered EIP Tech5"),
			referent: t("no referent assigned"),
			same_time_units: t("same_time_units"),
			tepitech: "Tepitech",
			part_time: "Part Time",
			free_project: "Free Project",
		},
	};
	if (curriculum === null) return allAlertLabels;
	if (curriculum.startsWith("bachelor")) return allAlertLabels.bachelor;
	if (curriculum.startsWith("master")) return allAlertLabels.master;
	return allAlertLabels[curriculum];
}

export const getSuccessBarLabels = (curriculum = null) => {
	const allSuccessBarLabels = {
		master: {
			total_credits: t("total_credits"),
			tech4_eip: t("tech4_eip"),
			tech5_eip: t("tech5_eip"),
			referent: t("referent"),
			same_time_units: t("same_time_units"),
			tepitech: t("tepitech"),
			part_time: t("part_time"),
		},
	};
	if (curriculum === null) return allSuccessBarLabels;
	if (curriculum.startsWith("master")) return allSuccessBarLabels.master;
	return allSuccessBarLabels[curriculum];
};

export const getHubAlerts = () => ({
	ok: {
		color: "#27ae60",
		label: t("OK"),
	},
	good_progression: {
		color: "#00ba64",
		label: t("progression - good"),
	},
	too_much_credits: {
		color: "#2980b9",
		label: t("too much xp"),
	},
	late_progression: {
		color: "#f39c12",
		label: t("progression - late"),
	},
	very_late_progression: {
		color: "#e74c3c",
		label: t("progression - very late"),
	},
	incoherent_goal: {
		color: "#f1c40f",
		label: t("goal not allowed"),
	},
	no_goals: {
		color: "#bdc3c7",
		label: t("no objectives"),
	},
});

export const getDiversityCreditsAlerts = () => ({
	8: {
		color: "#127a3e",
		label: `8 ${t("credits")}`,
	},
	5: {
		color: "#27ae60",
		label: `5 ${t("credits")}`,
	},
	3: {
		color: "#2ecc71",
		label: `3 ${t("credits")}`,
	},
	1: {
		color: "#f39c12",
		label: `1 ${t("credits")}`,
	},
	0: {
		color: "#f1c40f",
		label: `0 ${t("credits")}`,
	},
});

export const getInvestmentAlerts = () => ({
	excellent: {
		color: "#2ecc71",
		label: t("excellent"),
	},
	ok: {
		color: "#27ae60",
		label: t("OK"),
	},
	can_do_better: {
		color: "#f39c12",
		label: t("can do better"),
	},
	need_help: {
		color: "#e74c3c",
		label: t("need help"),
	},
});
