import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Card, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { t } from "../../../Config/i18n";
import CreateRoadlockModal from "../Modals/CreateRoadblockModal";

export default function NewRoadblockCard({
	configId,
	scholarYear,
	tekYear,
	semester,
	backgroundColor,
	color,
}) {
	const [openedModal, { open, close }] = useDisclosure(false);
	return (
		<Card
			style={{
				height: "100%",
				minHeight: "300px",
				backgroundColor,
			}}
		>
			<Modal
				opened={openedModal}
				onClose={close}
				title={t(`add entry`).capfirst()}
				centered
				size="lg"
			>
				<CreateRoadlockModal
					close={close}
					configId={configId}
					scholarYear={scholarYear}
					tekYear={tekYear}
					semester={semester}
				/>
			</Modal>
			<ActionIcon style={{ margin: "auto", top: "45%" }} onClick={open}>
				<FontAwesomeIcon
					icon={faSquarePlus}
					size="5x"
					style={{ color }}
				/>
			</ActionIcon>
		</Card>
	);
}
