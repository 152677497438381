import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useModuleAvailableYears = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["module_validation", "available_years"],
		queryFn: () => scraper.module.getAvailableYears(),
		select: (data) => data.data.years.toFilterOptions().reverse(),
	});
};

export const useModulesPerYear = (year) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["module_validation", "modules_per_year", year],
		queryFn: () => scraper.module.getModulesPerYear(year),
		select: (data) => data.data.units.toFilterOptions(),
	});
};

export const useInstancesPerModule = (year, module_code) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"module_validation",
			"instances_per_module",
			year,
			module_code,
		],
		queryFn: ({ pageParam }) =>
			scraper.instance.getInstances(module_code, year, pageParam),
		initialPageParam: 0,
		getNextPageParam,
		enabled: !!module_code,
	});
};

export const useGradesStats = (
	year,
	module_code,
	instances,
	date,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"module_validation",
			"grades_stats",
			year,
			module_code,
			instances,
			date,
		],
		queryFn: () =>
			scraper.module.getGradesStats(module_code, year, instances, date),
		select: (data) => data.data,
		enabled: areRequestEnabled,
	});
};

export const useGradesHistoryStats = (module_code, areRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["module_validation", "grades_history_stats", module_code],
		queryFn: () => scraper.module.getGradesStats(module_code),
		select: (data) => data.data,
		enabled: areRequestEnabled,
	});
};
