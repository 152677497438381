import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useContext } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useModules = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["configurations", "modules"],
		queryFn: ({ pageParam }) => scraper.module.getAllModules(pageParam),
		select: (data) =>
			data.pages.flatMap((page) =>
				page.results.flatMap((result) => result.code_module)
			),
		initialPageParam: 0,
		getNextPageParam,
	});
};

export const useConfigsYears = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["configurations", "get_configs"],
		queryFn: () => scraper.config.getConfigurationsYears(),
	});
};

export const useRoadblockConfig = (scholarYear, promotionYear, semester) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"configurations",
			"roadblocks_config",
			`${scholarYear}_${promotionYear}_${semester}`,
		],
		queryFn: () =>
			scraper.config.getRoadblocksPerConfig(
				scholarYear,
				promotionYear,
				semester
			),
	});
};

export const useRoadblockEditMutation = (
	scholarYear,
	promotionYear,
	currentSemester
) => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ configId, roadblockId, roadblockName, newThreshold }) =>
			scraper.config.updateRoadblock(
				configId,
				roadblockId,
				newThreshold,
				roadblockName
			),
		onError: (error) => {
			toast.error(
				`Configurations: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"configurations",
					"roadblocks_config",
					`${scholarYear}_${promotionYear}_${currentSemester}`,
				],
			});
			toast.success(`Configuration: ${t("successful update")}`);
		},
	});
};

export const useRoadblockModuleAdditionMutation = (
	scholarYear,
	promotionYear,
	currentSemester,
	close
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ configId, roadblockId, modules }) =>
			scraper.config.addModuleToRoadblock(configId, roadblockId, modules),
		onSuccess: () => {
			toast.success(`Configuration: ${t("successful update")}`);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"configurations",
					"roadblocks_config",
					`${scholarYear}_${promotionYear}_${currentSemester}`,
				],
			});
			close();
		},
	});
};

export const useRoadblockModuleDeletionMutation = (
	scholarYear,
	promotionYear,
	currentSemester
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ configId, roadblockId, module }) => {
			return scraper.config.deleteModuleFromRoadblock(
				configId,
				roadblockId,
				module
			);
		},
		onSuccess: () => {
			toast.success(`Configuration: ${t("successful update")}`);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"configurations",
					"roadblocks_config",
					`${scholarYear}_${promotionYear}_${currentSemester}`,
				],
			});
		},
	});
};

export const useRoadblockCreationMutation = (
	close,
	scholarYear,
	tekYear,
	semester
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ roadblockName, creditsThreshold, modules, configId }) =>
			scraper.config.createRoadblock(
				configId,
				roadblockName,
				creditsThreshold,
				modules
			),
		onSuccess: () => {
			toast.success(`Configuration: ${t("successful update")}`);
		},
		onError: (error) => {
			toast.error(
				`Configurations: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"configurations",
					"roadblocks_config",
					`${scholarYear}_${tekYear}_${semester}`,
				],
			});
			close();
		},
	});
};

export const useRoadblockDeletionMutation = (
	scholarYear,
	tekYear,
	semester
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ configId, roadblockId }) =>
			scraper.config.deleteRoadblock(configId, roadblockId),
		onSuccess: () => {
			toast.success(`Configuration: ${t("successful deletion")}`);
		},
		onError: (error) => {
			toast.error(
				`Configurations: ${t("an error occured when deleting roadblock").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"configurations",
					"roadblocks_config",
					`${scholarYear}_${tekYear}_${semester}`,
				],
			});
		},
	});
};

export const useScholarYearCreateMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (year) => {
			const tekYears = [1, 2, 3, 4, 5];
			const semesters = [1, 2];
			tekYears.forEach((tekYear) => {
				semesters.forEach((semester) =>
					scraper.config.createConfig(
						year,
						tekYear,
						semester,
						DateTime.fromISO(`${year}-09-01`),
						-1,
						-1,
						{}
					)
				);
			});
			queryClient.invalidateQueries({
				queryKey: ["configurations", "get_configs"],
			});
		},
		onSuccess: () => {
			toast.success(`Configuration: ${t("initialized")}`);
		},
		onError: (error) => {
			toast.error(
				`Configurations: ${t("an error occured when creating new config").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
	});
};

export const useConfigEditMutation = (scholarYear, tekYear, semester) => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({
			configId,
			date,
			creditsThreshold,
			tepitech,
			additionalConfigs,
		}) =>
			scraper.config.updateConfig(
				configId,
				date,
				creditsThreshold,
				tepitech,
				additionalConfigs
			),
		onError: (error) => {
			toast.error(
				`Configurations: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [
					"configurations",
					"roadblocks_config",
					`${scholarYear}_${tekYear}_${semester}`,
				],
			});
			toast.success(`Configuration: ${t("successful update")}`);
		},
	});
};
