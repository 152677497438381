import { useEffect } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { t } from "../../../Config/i18n.jsx";
import { getInvestmentAlertStatus } from "../../InvestmentOverview/InvestmentFallingAlert.jsx";
import { useInvestmentAlertsQuery } from "../../InvestmentOverview/Queries.jsx";
import { getTekYear } from "../../Utils/Utils.jsx";
import BachelorDataTable from "./BachelorDataTable.jsx";
import { useBachelorRoadblocksQuery } from "../Queries.jsx";

export default function BachelorDataTableContainer({
	city,
	scholar_year,
	curriculum,
	promotion,
	areRequestEnabled,
	projection,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		isLoading,
		error,
	} = useBachelorRoadblocksQuery(
		city?.value,
		scholar_year?.value,
		promotion,
		curriculum,
		projection,
		areRequestEnabled
	);
	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const requestData = data?.pages.flatMap((page) => page.data.results) ?? [];

	const {
		data: investmentAlerts,
		isFetchingNextPage: isInvestmentAlertsFetchingNextPage,
		hasNextPage: hasInvestmentAlertsNextPage,
		fetchNextPage: fetchInvestmentAlertsNextPage,
	} = useInvestmentAlertsQuery(city?.value, promotion);

	if (!isInvestmentAlertsFetchingNextPage && hasInvestmentAlertsNextPage)
		fetchInvestmentAlertsNextPage();
	const investmentAlertsData =
		investmentAlerts?.pages.flatMap((page) => page.data.results) ?? [];

	let exportConfig = {
		"alerts": "alerts.length",
		"investment_alert": (row) =>
			getInvestmentAlertStatus(
				investmentAlertsData.find(
					(alert) => alert.student.login === row.student.login
				)?.drop_percentage ?? 0
			)?.text ?? "Ok",
		"login": "student.login",
		"promotion": "student.promo.promotion_year",
		"curriculum": (row) =>
			`${row.student.promo.cursus.code}/${row.student.promo.subpromo}`,
		"city": "student.promo.city.name",
		"foundation obtained": "roadblocks.technical_foundation.obtained",
		"foundation in progress": "roadblocks.technical_foundation.inprogress",
		"foundation failed": "roadblocks.technical_foundation.failed",
		"foundation required": "roadblocks.technical_foundation.min_required",
		"supplement obtained": "roadblocks.technical_supplement.obtained",
		"supplement in progress": "roadblocks.technical_supplement.inprogress",
		"supplement failed": "roadblocks.technical_supplement.failed",
		"supplement required": "roadblocks.technical_supplement.min_required",
		"innovation obtained": "roadblocks.innovation.obtained",
		"innovation in progress": "roadblocks.innovation.inprogress",
		"innovation failed": "roadblocks.innovation.failed",
		"innovation required": "roadblocks.innovation.min_required",
		"soft_skills obtained": "roadblocks.softskills.obtained",
		"soft_skills in progress": "roadblocks.softskills.inprogress",
		"soft_skills failed": "roadblocks.softskills.failed",
		"soft_skills required": "roadblocks.softskills.min_required",
		"professional_writings obtained":
			"roadblocks.professional_writings.obtained",
		"professional_writings in progress":
			"roadblocks.professional_writings.inprogress",
		"professional_writings failed":
			"roadblocks.professional_writings.failed",
		"professional_writings required":
			"roadblocks.professional_writings.min_required",
		"total_credits obtained": "roadblocks.total_credits.obtained",
		"total_credits in progress": "roadblocks.total_credits.inprogress",
		"total_credits failed": "roadblocks.total_credits.failed",
		"total_credits required": "roadblocks.total_credits.min_required",
		"english_tepitech obtained": "roadblocks.tepitech.obtained",
		"english_tepitech required": "roadblocks.tepitech.min_required",
	};
	const includeTek1Roadblocks = !promotion || getTekYear(promotion) === 1;
	if (includeTek1Roadblocks) {
		exportConfig = {
			...exportConfig,
			"solo_stumper required": "roadblocks.solo_stumper.min_required",
			"solo_stumper obtained": "roadblocks.solo_stumper.obtained",
			"duo_stumper required": "roadblocks.duo_stumper.min_required",
			"duo_stumper obtained": "roadblocks.duo_stumper.obtained",
		};
	}

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Bachelor Data Table: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<>
			<BachelorDataTable
				data={requestData}
				investmentAlertsData={investmentAlertsData}
				city={city}
				promotion={promotion}
				isFetching={isFetching}
				isLoading={isLoading}
				exportConfig={{
					columns: exportConfig,
					title: "bachelor_roadblocks",
				}}
			/>
			<Tooltip id="alerts-row-tooltip" />
		</>
	);
}
