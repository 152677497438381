import { Card } from "react-bootstrap";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import LoadingOverlay from "../AdminLTE3/LoadingOverlay";

export default function SauronBox({
	title,
	subtitle,
	bodyStyle,
	isLoading,
	children,
}) {
	return (
		<Box style={{ height: "100%" }}>
			<LoadingOverlay loading={isLoading} />
			<Card.Header>
				<BoxTitle title={title} subtitle={subtitle} />
			</Card.Header>
			<Card.Body style={bodyStyle}>{children}</Card.Body>
		</Box>
	);
}
