import { faFile } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import { requestErrorToastManager } from "../../Utils/Toasts";
import IconColoredCard from "../IconColoredCard";
import { useMissingTranscriptsQuery } from "../Queries";

export default function Transcripts({ city, promotions }) {
	const {
		data: missingTranscripts = 0,
		error,
		isFetching,
	} = useMissingTranscriptsQuery(city, promotions);

	requestErrorToastManager("Dashboard: Transcripts Card", error);

	if (error) return null;
	return (
		<IconColoredCard
			title={`${missingTranscripts}`}
			text={`${t("missing_transcript", {
				count: 0,
			})}`}
			color={missingTranscripts === 0 ? "success" : "danger"}
			detailPath={`/transcripts?cities=${city.value}`}
			icon={faFile}
			isLoading={isFetching}
		/>
	);
}
