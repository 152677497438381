import EvaluationsDataTable from "./EvaluationsDataTable";
import { getPartMark } from "./Utils";

export default function EvaluationsDataTableContainer({
	isFetching,
	isLoading,
	evaluations,
	gradingSkill,
}) {
	const exportConfig = {
		login: "student.login",
		instance: "activity.code_instance",
		activity: "activity.name",
		status: "status",
		mark: "mark",
		mark_without_status: "mark_without_status",
		...(gradingSkill
			? Object.assign(
					{},
					...gradingSkill.flatMap((page) =>
						page.parts.map((part) => ({
							[`${page.title} - ${part.title}`.capfirst()]: (
								row
							) =>
								getPartMark(
									page.title,
									part.title,
									row.result_json
								),
						}))
					)
				)
			: {}),
	};

	return (
		<EvaluationsDataTable
			data={evaluations}
			gradingSkill={gradingSkill}
			exportConfig={{ columns: exportConfig, title: "evaluations" }}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
}
