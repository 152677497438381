export const defaultBarOptions = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		x: {
			min: 0,
			max: 100,
			stacked: true,
		},
		y: {
			min: 0,
			max: 100,
			stacked: true,
		},
	},
	plugins: {
		stacked100: {
			enable: true,
		},
	},
};
