import { useContext } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";

const useAuthorizedPromotionsOptions = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const promotions = [
		{
			label: "PGE1",
			value: 1,
		},
		{
			label: "PGE2",
			value: 2,
		},
		{
			label: "PGE3",
			value: 3,
		},
	];
	if (!scraper.userHelper.isInGroup("cei")) return promotions;
	promotions.push({ label: "PGE5", value: 5 });
	return promotions;
};

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper
		.getCities()
		.filter((cityOption) => cityOption.value.startsWith("FR"));
	const yearsOptions = nValues(2012, getCurrentAcademicYear() - 2012 + 1)
		.reverse()
		.toFilterOptions();
	const promotionsOptions = useAuthorizedPromotionsOptions();
	const { filters, setSelectedOption, applyFilters } = useFilters({
		cities: {
			label: t("cities"),
			type: "select",
			multiple: true,
			options: citiesOptions,
			default: citiesOptions.length === 1 ? [citiesOptions[0]] : [],
		},
		curriculums: {
			label: t("curriculums"),
			type: "select",
			multiple: true,
			options: promotionsOptions,
			default: [],
		},
		years: {
			label: t("years"),
			type: "select",
			multiple: true,
			options: yearsOptions,
			default: [yearsOptions[0]],
		},
		national: {
			label: t("include national statistics"),
			type: "bool",
			default: true,
		},
	});

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
