import { Button, Group, MultiSelect } from "@mantine/core";
import { useState } from "react";
import { t } from "../../../Config/i18n";
import { useModules, useRoadblockModuleAdditionMutation } from "../Queries";

export default function ModalContent({ roadblock, close, configProps }) {
	const {
		data: modules = [],
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useModules();
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const [selectedModules, setSelectedModules] = useState([]);
	const mutation = useRoadblockModuleAdditionMutation(
		configProps.scholarYear,
		configProps.tekYear,
		configProps.currentSemester,
		close
	);
	const modulesWithDisabled = [];

	modules.forEach((module) => {
		const moduleObj = {
			label: module,
			value: module,
			disabled: roadblock.units.some(
				(unit) => unit.code_module === module
			),
		};
		modulesWithDisabled.push(moduleObj);
	});

	function validateModules(values) {
		if (values.length !== 0) {
			mutation.mutate({
				configId: configProps.configId,
				roadblockId: roadblock.id,
				modules: values,
			});
		}
	}

	return (
		<>
			<MultiSelect
				label={t("modules").capfirst()}
				placeholder={t("select module").capfirst()}
				data={modulesWithDisabled}
				clearable
				searchable
				dropdownPosition="bottom"
				onChange={setSelectedModules}
				limit={100}
				withinPortal
			/>
			<Group style={{ marginTop: "5%", justifyContent: "space-between" }}>
				<Button onClick={close}>{t("close").capfirst()}</Button>
				<Button onClick={() => validateModules(selectedModules)}>
					{t("validate").capfirst()}
				</Button>
			</Group>
		</>
	);
}
