import { useState } from "react";
import { areFiltersValueDifferent } from "../../../../Utils/Filters";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { useLogtime } from "../../../Queries";
import LogtimeChart from "./Chart";
import LogtimeFilters from "./Filters";

export default function Logtime({ login }) {
	const {
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		data: logtimes,
		isFetching,
		error,
	} = useLogtime(login);
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const [filters, setFilters] = useState({
		scale: "unfiltered",
		timePeriod: null,
	});
	const updateFilters = (newFilters) => {
		if (areFiltersValueDifferent(filters, newFilters)) {
			setFilters(() => newFilters);
		}
	};

	requestErrorToastManager("Student: Logtime tab", error);

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (logtimes.length === 0) return <NoDataAvailable />;
	return (
		<div style={{ height: 410 }}>
			<LogtimeFilters
				logtimes={logtimes}
				scale={filters.scale}
				timePeriod={filters.timePeriod}
				updateFilters={updateFilters}
			/>
			<LogtimeChart
				logtimes={logtimes}
				scale={filters.scale}
				timePeriod={filters.timePeriod?.value}
			/>
		</div>
	);
}
