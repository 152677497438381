import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon } from "@mantine/core";
import { useEffect, useState } from "react";
import SauronMantineNavtabs from "../Sauron/SauronMantineNavtabs";
import ConfigTab from "./ConfigTab";
import { useConfigsYears, useScholarYearCreateMutation } from "./Queries";

export default function ConfigurationsYearNavtabs() {
	const { data: configsYears = [] } = useConfigsYears();
	const mutation = useScholarYearCreateMutation();
	const initTabs = [];

	configsYears.forEach((year) => {
		initTabs.push({
			disable: false,
			display: true,
			label: `${year}`,
			year,
			component: <ConfigTab year={year} />,
		});
	});
	const [tabs, setTabs] = useState(initTabs);

	useEffect(() => {
		if (initTabs.length > tabs.length) setTabs(initTabs);
	}, [initTabs]);

	if (tabs.length === 0) return null;
	function handleYearConfig() {
		const lastYearTab = tabs.pop();
		const newYear = lastYearTab.year + 1;
		mutation.mutate(newYear);
	}

	const bonusTab = {
		disable: false,
		display: true,
		label: `+`,
		component: (
			<ActionIcon onClick={handleYearConfig}>
				<FontAwesomeIcon icon={faPlus} />
			</ActionIcon>
		),
	};
	return (
		<div>
			<SauronMantineNavtabs tabs={tabs} affectsURI bonusTab={bonusTab} />
		</div>
	);
}
