import { Button } from "@mantine/core";
import { t } from "../../Config/i18n";

export default function CheaterButtons({
	onClick,
	is_cheater,
	loadingButtonId,
	entry,
}) {
	const isCheaterButtons = [
		{
			id: "confirmed",
			name: t("confirmed").capfirst(),
			value: true,
			className: "danger",
		},
		{
			id: "unmarked",
			name: t("unmarked").capfirst(),
			value: null,
			className: "secondary",
		},
		{
			id: "false_positive",
			name: t("false positive").capfirst(),
			value: false,
			className: "success",
		},
	];

	return (
		<Button.Group>
			{isCheaterButtons.map((button) => (
				<button
					type="button"
					className={
						is_cheater === button.value
							? `btn btn-sm btn-${button.className}`
							: `btn btn-sm btn-outline-${button.className}`
					}
					key={button.id}
					onClick={() => onClick(entry, button.value)}
					disabled={loadingButtonId !== null}
				>
					{button.name}
				</button>
			))}
		</Button.Group>
	);
}
