import { Card, Col, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../../../../Config/i18n";
import { BoxTitle } from "../../../../AdminLTE3/Box";
import { GradeCell } from "../../../../Tesseract/TesseractDataTable";
import { creditsOverviewSpan } from "../../../../Utils/Utils";

export const hasValidatedEnglish = (roadblocks) => {
	if (
		roadblocks.tepitech.obtained +
			Math.max(roadblocks.tepitech.inprogress, 0) >=
		roadblocks.tepitech.min_required
	)
		return true;
	if (roadblocks.englishskills.min_required === -1) return false;
	if (
		roadblocks.englishskills.obtained +
			Math.max(roadblocks.englishskills.inprogress, 0) >=
		roadblocks.englishskills.min_required
	)
		return true;
	return false;
};

function RoadblockState({ title, roadblock, roadblocks }) {
	if (
		(title === "tepitech" || title === "englishskills") &&
		Object.prototype.hasOwnProperty.call(roadblocks, "tepitech") &&
		Object.prototype.hasOwnProperty.call(roadblocks, "englishskills") &&
		hasValidatedEnglish(roadblocks)
	)
		return <span>{creditsOverviewSpan(roadblock, true)}</span>;
	if (roadblock.is_state) {
		if (roadblock.obtained > roadblock.min_required)
			return (
				<span className="text-green">{t("acquired").capfirst()}</span>
			);
		if (roadblock.obtained + roadblock.inprogress >= roadblock.min_required)
			return (
				<span className="text-blue">{t("in progress").capfirst()}</span>
			);
		return (
			<span className="text-danger">{t("not acquired").capfirst()}</span>
		);
	}
	return <span>{creditsOverviewSpan(roadblock)}</span>;
}

function getColorOfUnit(unit) {
	if (unit.credits === 0) return "text-muted";
	if (unit.grade === "-") return "text-blue";
	if (unit.grade === "Echec") return "text-danger";
	return "text-green";
}

function RoadblockCard({ title, roadblock, roadblocks, roadblockConfig }) {
	if (!roadblockConfig[title]) return null;
	roadblockConfig[title].forEach((unit) => {
		const roadblockUnit = roadblock.units.find(
			(u) => u.unit === unit.code_module
		);
		if (roadblockUnit) {
			roadblockUnit.name = unit.name;
			roadblockUnit.total_credits = unit.credits;
		} else {
			roadblock.units.push({
				unit: unit.code_module,
				name: unit.name,
				credits: 0,
				total_credits: unit.credits,
				grade: "-",
			});
		}
	});
	return (
		<div style={{ padding: "0 15px" }} className="border">
			<Card.Header>
				<div className="d-flex justify-content-between">
					<BoxTitle title={t(title.replace("_", " ")).capfirst()} />
					<RoadblockState
						title={title}
						roadblock={roadblock}
						roadblocks={roadblocks}
					/>
				</div>
			</Card.Header>
			<Card.Body style={{ alignSelf: "center", padding: "10px 0px" }}>
				{roadblock.units.map((unit) => (
					<Row
						key={`${unit.unit}_roadblockCard`}
						style={{ padding: "2px 0px" }}
					>
						<Col lg={9}>
							<div className="d-flex align-items-center">
								<span
									className={`${getColorOfUnit(
										unit
									)} text-truncate`}
									data-tooltip-html={unit.name}
									data-place="top"
									data-tooltip-id="roadblock_name"
								>
									[{unit.unit}] {unit.name}
									<Tooltip id="roadblock_name" />
								</span>
								<span className="ml-2">
									{unit.grade !== "-" && (
										<GradeCell grade={unit.grade} chip />
									)}
								</span>
							</div>
						</Col>
						<Col>
							<div className={getColorOfUnit(unit)}>
								{unit.credits} / {unit.total_credits}{" "}
								{t("credits")}
							</div>
						</Col>
					</Row>
				))}
			</Card.Body>
		</div>
	);
}

export default function RoadblocksCards({ roadblocks, roadblockConfig }) {
	const roadblocksWithUnits = Object.entries(roadblocks).filter(
		([, roadblock]) => roadblock.units
	);
	if (!roadblockConfig) return null;
	return (
		<div style={{ padding: "15px 15px" }}>
			<Row md={2}>
				{roadblocksWithUnits.map(([title, roadblock]) => (
					<RoadblockCard
						title={title}
						roadblock={roadblock}
						roadblocks={roadblocks}
						roadblockConfig={roadblockConfig}
						key={`${title}_roadblockCard`}
					/>
				))}
			</Row>
		</div>
	);
}
