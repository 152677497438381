import {
	faCalendarAlt,
	faCalendarCheck,
	faLink,
	faUsers,
	faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { DateTime } from "luxon";
import { Button, Card } from "react-bootstrap";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import LoadingComponent from "../Utils/Loading";
import { requestErrorToastManager } from "../Utils/Toasts";
import { useProjectInstanceQuery } from "./Queries";

function ProjectDetailsBody({ data, nb_registered }) {
	if (!data) return null;
	return (
		<>
			<p>
				<strong>
					<FontAwesomeIcon icon={faLink} /> {t("slug").capfirst()}:{" "}
				</strong>
				{data.project_slug}
			</p>
			<hr />
			<p>
				<strong>
					<FontAwesomeIcon icon={faUsers} /> {t("groups registered")}:{" "}
				</strong>
				{nb_registered}
			</p>
			<hr />
			<p>
				<strong>
					<FontAwesomeIcon icon={faCalendarAlt} /> {t("start date")}:{" "}
				</strong>
				{DateTime.fromISO(data.start_date).toLocaleString(
					DateTime.DATE_FULL
				)}
			</p>
			<hr />
			<p>
				<strong>
					<FontAwesomeIcon icon={faCalendarCheck} />{" "}
					{t("delivery date")}:{" "}
				</strong>
				{DateTime.fromISO(data.delivery_date).toLocaleString(
					DateTime.DATE_FULL
				)}
			</p>
			<hr />
			<Button
				className="d-block"
				variant="primary"
				href={`https://intra.epitech.eu/module/${data.year}/${data.code_module}/${data.code_instance}/#!/proj`}
				target="_blank"
			>
				<FontAwesomeIcon icon={faExternalLink} />{" "}
				{t("see on intranet").capfirst()}
			</Button>
		</>
	);
}

export default function ProjectDetails({
	year,
	code_module,
	slug,
	project_instance,
	nb_registered = 0,
	isFetchingRegistered = false,
}) {
	const { data, error, isFetching } = useProjectInstanceQuery(
		year,
		code_module,
		slug,
		project_instance
	);

	requestErrorToastManager("Projects: Unable to fetch stats", error);
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle>{t("details").capfirst()}</BoxTitle>
			</Card.Header>
			<Card.Body>
				{isFetching || isFetchingRegistered ? (
					<div className="text-center">
						<LoadingComponent />
					</div>
				) : (
					<ProjectDetailsBody
						data={data}
						nb_registered={nb_registered}
					/>
				)}
			</Card.Body>
		</Box>
	);
}
