import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import ClearTokensTable from "./ClearTokensTable";
import DataTableContainer from "./DataTableContainer";
import Filters from "./Filters";
import {
	useAPITokens,
	useAccessTokens,
	useKeygenMutation,
	useRefreshTokens,
} from "./Queries";

export default function JwtManager() {
	const keyGenMutation = useKeygenMutation();

	const generateKey = (filters) => {
		const cities = filters.city.selected.extract("value");
		const id = filters.id.selected;
		const groups = filters.groups.selected.extract("value");
		const modules_responsible =
			filters.modules_responsible.selected.extract("value");
		const lifespan = !filters.lifespan.selected;
		keyGenMutation.mutate({
			id,
			groups,
			modules_responsible,
			lifespan,
			cities,
		});
	};

	const {
		data: accessTokensData,
		isLoading: loadingAccess,
		isFetching: fetchingAccess,
	} = useAccessTokens();
	const {
		data: refreshTokensData,
		isLoading: loadingRefresh,
		isFetching: fetchingRefresh,
	} = useRefreshTokens();
	const {
		data: APITokens,
		isLoading: loadingAPI,
		isFetching: fetchingAPI,
	} = useAPITokens();

	return (
		<Section>
			<SectionHeader title={t("JWT manager").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-3">
						<Col lg={3}>
							<Filters generateKey={generateKey} />
						</Col>
						<Col lg={4}>
							<ClearTokensTable
								accessTokensData={accessTokensData}
								refreshTokensData={refreshTokensData}
								APITokens={APITokens}
								isLoading={
									loadingAccess ||
									loadingRefresh ||
									loadingAPI
								}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							<DataTableContainer
								accessTokensData={accessTokensData}
								refreshTokensData={refreshTokensData}
								APITokens={APITokens}
								isLoading={
									loadingAccess ||
									loadingRefresh ||
									loadingAPI
								}
								isFetching={
									fetchingAccess ||
									fetchingRefresh ||
									fetchingAPI
								}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
