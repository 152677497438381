import { t } from "../../Config/i18n";
import CityGradesGraph from "../Graphs/GradeGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import { useTepitechGradesStats } from "./Queries";

export default function GradesPerYear({ areRequestEnabled }) {
	const { data, error, isFetching } =
		useTepitechGradesStats(areRequestEnabled);

	const stats = data ?? {};
	requestErrorToastManager("Tepitech Progression: Status graph", error);
	return (
		<SauronChartsContainer
			title={t("grades").capfirst()}
			subtitle={`${t("by")} ${t("year")}`}
			isLoading={isFetching}
		>
			<CityGradesGraph stats={stats} size={320} />
		</SauronChartsContainer>
	);
}
