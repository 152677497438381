import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "../../Config/i18n";
import LoginRow from "../Utils/LoginRow";
import { colorFromCreditState, compare } from "../Utils/Utils";
import { ALERT_STATUS } from "./Constants";

function missingPoints(score, goal) {
	if (score < 0) {
		return goal;
	}
	const pts = goal - score;
	return pts < 0 ? 0 : pts;
}

function alertRow(alert) {
	const opt = ALERT_STATUS[alert];
	return (
		<span
			style={{
				backgroundColor: opt.color,
				display: "block",
				width: 170,
				fontSize: "12px",
				fontWeight: 700,
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "top",
				borderRadius: "10px",
				paddingTop: 1,
				paddingBottom: 1,
			}}
			className="text-white"
		>
			<span className="mr-2">{t(opt.label).capfirst()}</span>
			<FontAwesomeIcon icon={opt.icon} size="sm" />
		</span>
	);
}

export default function getStudentColumns() {
	const columns = [
		{
			accessorKey: "alert",
			header: t("alert").capfirst(),
			size: 150,
			Cell: ({ row }) => alertRow(row.original.alert),
			sortingFn: (a, b) =>
				compare(
					a.original.score / a.original.score_goal,
					b.original.score / b.original.score_goal
				),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.login",
			header: t("login").capfirst(),
			size: 250,
			invertSorting: true,
			Cell: ({ row }) => <LoginRow login={row.original.student.login} />,
		},
		{
			accessorKey: "student.promo.promotion_year",
			header: t("promotion").capfirst(),
			size: 125,
			invertSorting: true,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.promo.cursus.code",
			Cell: ({ row }) =>
				`${row.original.student.promo.cursus.code}/${row.original.student.promo.subpromo}`,
			header: t("curriculum").capfirst(),
			size: 125,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "student.promo.city.name",
			header: t("city").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "score",
			header: t("mark").capfirst(),
			size: 150,
			Cell: ({ row }) => (
				<div
					className={colorFromCreditState(
						row.original.score,
						0,
						row.original.score_goal
					)}
				>
					{row.original.score} / {row.original.score_goal}
				</div>
			),
			sortingFn: (a, b) => -compare(a.original.score, b.original.score),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "missing_points",
			header: t("missing points").capfirst(),
			size: 150,
			Cell: ({ row }) => (
				<div className="text-green">
					{missingPoints(row.original.score, row.original.score_goal)}
				</div>
			),
			sortingFn: (a, b) =>
				-compare(
					missingPoints(a.original.score, a.original.score_goal),
					missingPoints(b.original.score, b.original.score_goal)
				),
			enableGlobalFilter: false,
		},
	];
	return columns;
}
