import { Button, CopyButton, Tooltip } from "@mantine/core";
import {
	IconCheck,
	IconCircleMinus,
	IconCopy,
	IconTrash,
} from "@tabler/icons-react";
import { DateTime } from "luxon";
import { useContext } from "react";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import { compare } from "../Utils/Utils";
import {
	useRevokeAccessTokenMutation,
	useRevokeApiTokenMutation,
	useRevokeRefreshTokenMutation,
} from "./Queries";

function unixTsToReadable(unix_ts) {
	if (!unix_ts) {
		return t("never").capfirst();
	}
	return DateTime.fromSeconds(unix_ts).toLocaleString(DateTime.DATETIME_FULL);
}

function CopyTokenButton({ token }) {
	return (
		<CopyButton value={token}>
			{({ copied, copy }) => (
				<Tooltip
					label={
						copied
							? t("token copied successfully").capfirst()
							: t("copy").capfirst()
					}
					withArrow
					position="right"
				>
					<Button
						size="sm"
						variant="filled"
						color={copied ? "teal" : "gray"}
						onClick={copy}
						title={t("copy").capfirst()}
					>
						{copied ? <IconCheck /> : <IconCopy />}
					</Button>
				</Tooltip>
			)}
		</CopyButton>
	);
}

function DeleteTokenButton({ mutate, token, account_info }) {
	const actualToken =
		token === account_info.token || token === account_info.refresh_token;
	return (
		<Tooltip
			multiline
			label={`${t("revoke").capfirst()}${
				actualToken
					? ` (${t("this is your current session token").capfirst()})`
					: ""
			}`}
			withArrow
			position="right"
		>
			<Button
				variant="filled"
				color="red"
				size="sm"
				onClick={() => mutate(token)}
			>
				{actualToken ? <IconCircleMinus /> : <IconTrash />}
			</Button>
		</Tooltip>
	);
}

export default function TokenDataTable({ data, type, isLoading, isFetching }) {
	const scraper = useContext(ScraperApiHelperContext);
	const revoke_mutations = {
		access: useRevokeAccessTokenMutation(),
		refresh: useRevokeRefreshTokenMutation(),
		api: useRevokeApiTokenMutation(),
	};

	const originalColumns = [
		{
			id: "email",
			accessorFn: (row) => row.email,
			header: t("email").capfirst(),
			Cell: ({ row }) => row.original.email || "-",
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.email, b.original.email),
		},
		{
			id: "groups",
			accessorFn: (row) => row.groups,
			header: t("groups").capfirst(),
			Cell: ({ row }) => row.original.groups.sort().join(", ") || "-",
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.groups, b.original.groups),
		},
		{
			id: "issued_date",
			accessorFn: (row) => row.iat,
			header: t("issued date").capfirst(),
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.iat, b.original.iat),
			Cell: ({ row }) => unixTsToReadable(row.original.iat),
		},
		{
			id: "expire_date",
			accessorFn: (row) => row.exp,
			header: t("expire date").capfirst(),
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.exp, b.original.exp),
			Cell: ({ row }) => unixTsToReadable(row.original.exp),
		},
		{
			id: "jwt",
			accessorFn: (row) => row.jwt,
			header: "JWT",
			Cell: ({ row }) => (
				<>
					<CopyTokenButton token={row.original.token} />
					<DeleteTokenButton
						mutate={revoke_mutations[type].mutate}
						token={row.original.token}
						account_info={scraper.api.getSauronAccountInfo()}
					/>
				</>
			),
		},
	];
	const finalColumns =
		type !== "api"
			? originalColumns
			: originalColumns
					.map((a) => ({ ...a }))
					.filter(({ id }) => id != "expire_date");
	return (
		<SauronMantineDataTable
			columns={finalColumns}
			data={data}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
