import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { separateFiltersOptions } from "../Utils/Utils";
import IntuituDataTable from "./DataTableContainer";
import IntuituFilters from "./Filters";
import IntuituProgression from "./IntuituPersonaeProgress";

export default function IntuituPersonae() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		cities: [],
		promotions: [],
		alerts: false,
	});

	const { values: selectedPromotionYears } = separateFiltersOptions(
		activeFilters.promotions
	);
	const { values: selectedCityCodes } = separateFiltersOptions(
		activeFilters.cities
	);

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	return (
		<Section>
			<SectionHeader title={"Intuitu Personae".capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<IntuituFilters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9} className="mb-3">
							<IntuituProgression
								cities={activeFilters.cities}
								promotionYears={selectedPromotionYears}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={12} className="mb-3">
							<IntuituDataTable
								cities={selectedCityCodes}
								promotions={selectedPromotionYears}
								alertsOnly={activeFilters.alerts}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="intuitu-personae-tooltip" />
			<StudentTooltip tooltipId="intuitu-personae-student-tooltip" />
		</Section>
	);
}
