import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";

const options = {
	...defaultBarOptions,
	plugins: {
		...defaultBarOptions.plugins,
		stacked100: {
			enable: true,
		},
		tooltip: {
			...defaultBarOptions.plugins.tooltip,
			callbacks: {
				afterLabel: (tooltipItem) =>
					`${t("Start date")}: ${
						tooltipItem.dataset.startDate[tooltipItem.dataIndex]
					}\n${t("End date")}: ${
						tooltipItem.dataset.endDate[tooltipItem.dataIndex]
					}`,
			},
		},
	},
};

export default function StudentPresenceChart({ activityPresences, isLoading }) {
	const statusForActivity = (activity) => {
		const initialStatus = {
			"absent": 0,
			"registered": 0,
			"present": 0,
			"N/A": 0,
			"not_registered": 0,
		};
		return activity.students.reduce((accumulator, student) => {
			accumulator[student.status] += 1;
			return accumulator;
		}, initialStatus);
	};

	const statistics = useMemo(() => {
		if (!activityPresences) return null;
		const initial = {
			name: [],
			absent: [],
			registered: [],
			present: [],
			na: [],
			not_registered: [],
			startDate: [],
			endDate: [],
		};
		return activityPresences.reduce((accumulator, activity) => {
			const status = statusForActivity(activity);
			accumulator.name.push(activity.name);
			accumulator.absent.push(status.absent);
			accumulator.registered.push(status.registered);
			accumulator.present.push(status.present);
			accumulator.na.push(status["N/A"]);
			accumulator.not_registered.push(status.not_registered);
			accumulator.startDate.push(activity.begin);
			accumulator.endDate.push(activity.end);
			return accumulator;
		}, initial);
	}, [activityPresences]);

	const data = useMemo(() => {
		if (!statistics) return null;
		return {
			labels: statistics.name,
			datasets: [
				{
					label: t("absent").capfirst(),
					backgroundColor: "#e74c3c",
					data: statistics.absent,
					startDate: statistics.startDate,
					endDate: statistics.endDate,
				},
				{
					label: t("registered").capfirst(),
					backgroundColor: "#3498db",
					data: statistics.registered,
					startDate: statistics.startDate,
					endDate: statistics.endDate,
				},
				{
					label: t("present").capfirst(),
					backgroundColor: "#2ecc71",
					data: statistics.present,
					startDate: statistics.startDate,
					endDate: statistics.endDate,
				},
				{
					label: "N/A",
					backgroundColor: "#bdc3c7",
					data: statistics.na,
					startDate: statistics.startDate,
					endDate: statistics.endDate,
				},
				{
					label: t("not registered").capfirst(),
					backgroundColor: "#ecf0f1",
					data: statistics.not_registered,
					startDate: statistics.startDate,
					endDate: statistics.endDate,
				},
			],
		};
	}, [statistics]);

	return (
		<SauronChartsContainer
			title={t("student presences").capfirst()}
			subtitle={`${t("by")} ${t("activity type")}`}
			isLoading={isLoading}
		>
			{data ? <Bar data={data} options={options} /> : null}
		</SauronChartsContainer>
	);
}
