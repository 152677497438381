import {
	faBars,
	faPencil,
	faPlus,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	ActionIcon,
	Card,
	CardSection,
	Divider,
	Group,
	Menu,
	Modal,
	Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { t } from "../../../Config/i18n";
import DeletionModal from "../Modals/DeletionModal";
import EditRoadblockModal from "../Modals/EditRoadblockModal";
import ModalContent from "../Modals/ModalContent";
import {
	useRoadblockDeletionMutation,
	useRoadblockModuleDeletionMutation,
} from "../Queries";

function RoadblockInfoLine({ roadblockId, unit, configProps }) {
	const [hovered, setHovered] = useState(false);
	const mutation = useRoadblockModuleDeletionMutation(
		configProps.scholarYear,
		configProps.tekYear,
		configProps.currentSemester
	);

	function deleteModule() {
		mutation.mutate({
			configId: configProps.configId,
			roadblockId: roadblockId,
			module: unit.code_module,
		});
	}

	return (
		<Group
			style={{ justifyContent: "space-between", height: "30px" }}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<Text style={{ maxWidth: "80%" }} truncate>
				{unit.code_module}: {unit.name}
			</Text>
			{hovered ? (
				<ActionIcon onClick={() => deleteModule()}>
					<FontAwesomeIcon icon={faTrash} color="white" />
				</ActionIcon>
			) : (
				<Text size="sm">
					({unit.credits} {t("credits")}.)
				</Text>
			)}
		</Group>
	);
}

export default function RoadblockCard({
	roadblock,
	validateChange,
	backgroundColor,
	className = "",
	baseKey,
	configProps,
}) {
	const [openedModalEdit, { open: openEdit, close: closeEdit }] =
		useDisclosure(false);
	const [openedModalModules, { open: openModules, close: closeModules }] =
		useDisclosure(false);
	const [
		openedConfirmModal,
		{ open: openConfirmModal, close: closeConfirmModal },
	] = useDisclosure(false);
	const [roadblockThreshold, setRoadblockThreshold] = useState(
		roadblock.threshold ?? 0
	);
	const mutation = useRoadblockDeletionMutation(
		configProps.scholarYear,
		configProps.tekYear,
		configProps.currentSemester
	);

	useEffect(() => {
		setRoadblockThreshold(roadblock.threshold);
	}, [roadblock]);

	return (
		<Card
			key={`${baseKey}_${roadblock.name}`}
			className={className}
			style={{ height: "100%", backgroundColor }}
		>
			<CardSection inheritPadding py="xs" style={{ height: "98%" }}>
				<Group style={{ justifyContent: "space-between" }}>
					<Text fw={700} style={{ marginRight: "auto" }}>
						{`${t(roadblock.name.replace("_", " ")).title()}`} (
						{roadblockThreshold} {t("required")})
					</Text>
					<Modal
						opened={openedModalEdit}
						onClose={closeEdit}
						title={`${t("edit roadblock").capfirst()} "${roadblock.name.capfirst()}"`}
						centered
						size="lg"
					>
						<EditRoadblockModal
							roadblock={roadblock}
							close={closeEdit}
							validateChange={validateChange}
						/>
					</Modal>
					<Modal
						opened={openedModalModules}
						onClose={closeModules}
						title={`${t("add modules in roadblock").capfirst()} "${roadblock.name.capfirst()}"`}
						centered
						size="lg"
					>
						<ModalContent
							roadblock={roadblock}
							close={closeModules}
							configProps={configProps}
						/>
					</Modal>
					<Modal
						opened={openedConfirmModal}
						onClose={closeConfirmModal}
						title={`${t("delete roadblock").capfirst()} "${roadblock.name.capfirst()}"`}
						centered
						size="lg"
					>
						<DeletionModal
							configId={configProps.configId}
							mutation={mutation}
							roadblock={roadblock}
							close={closeConfirmModal}
						/>
					</Modal>
					<Menu shadow="md">
						<Menu.Target>
							<ActionIcon>
								<FontAwesomeIcon icon={faBars} color="white" />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								icon={<FontAwesomeIcon icon={faPencil} />}
								onClick={openEdit}
							>
								{t("edit").capfirst()}
							</Menu.Item>
							<Menu.Item
								icon={<FontAwesomeIcon icon={faTrash} />}
								onClick={openConfirmModal}
								style={{ color: "red" }}
							>
								{t("delete").capfirst()}
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
				<Divider my="md" />
				{roadblock.units.map((unit) => (
					<RoadblockInfoLine
						key={`${baseKey}_${roadblock.name}_${unit.code_module}`}
						roadblockId={roadblock.id}
						unit={unit}
						configProps={configProps}
					/>
				))}
			</CardSection>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					textAlign: "center",
				}}
			>
				<ActionIcon
					style={{
						backgroundColor: "white",
						color: "black",
						width: "100%",
					}}
					onClick={openModules}
				>
					<FontAwesomeIcon icon={faPlus} />
				</ActionIcon>
			</div>
		</Card>
	);
}
