import { getCurrentAcademicYear } from "../../Components/Utils/Utils";

export default class Students {
	constructor(api) {
		this.api = api;
	}

	getStudents(
		offset = 0,
		city = null,
		promotions = null,
		cursus = "bachelor",
		scholar_year = getCurrentAcademicYear(),
		active_only = false,
		limit = 100
	) {
		const params = {
			city,
			promotion: promotions instanceof Array ? promotions : [promotions],
			cursus,
			offset,
			limit,
			scholar_year,
			active_only,
		};
		return this.api.processRequest("GET", "/students", {
			params,
		});
	}

	searchStudent = (partialName) =>
		this.api.processRequest("GET", "/students", {
			params: { search: partialName },
		});

	getAlertsByCity = (
		city = null,
		cursus = null,
		promo = null,
		curriculum = null,
		projected = false
	) =>
		this.api.processRequest("GET", `/students/alerts/stats`, {
			params: { city, cursus, promo, curriculum, projected },
		});

	getDiversity(
		offset = 0,
		cities = null,
		promotions = null,
		curriculums = null,
		year = getCurrentAcademicYear(),
		limit = 100
	) {
		return this.api.processRequest("GET", "/students/diversity", {
			params: {
				city: cities,
				promotion: promotions,
				curriculum: curriculums,
				year,
				offset,
				limit,
			},
		});
	}

	getTepitechAlerts = (
		cities,
		cursus,
		promotions,
		scholarYears,
		offset = 0,
		limit = 500
	) => {
		const params = {
			city: cities,
			cursus,
			promotion: promotions,
			scholar_year: scholarYears,
			offset,
			limit,
		};
		return this.api
			.processRequest("GET", "/students/english/tepitech/alerts", {
				params,
			})
			.then((res) => res.data);
	};

	getStudentGrade(year = null, module = null, instances = null, offset = 0) {
		return this.api.processRequest("GET", "/students/grades", {
			params: {
				year,
				module,
				instance: instances,
				offset,
			},
		});
	}

	getInternshipsAlerts(
		offset = 0,
		cities = null,
		cursus = null,
		promotions = null,
		full_time = null,
		limit = 500
	) {
		const params = {
			city: cities,
			promotion: promotions,
			cursus,
			full_time,
			offset,
			limit,
		};
		return this.api.processRequest("GET", "/students/internships/alerts", {
			params,
		});
	}

	getRoadblocks(
		offset = 0,
		city = null,
		scholar_year = getCurrentAcademicYear(),
		promotions = null,
		cursus = "bachelor",
		projected = false,
		semesters = null,
		referents = null,
		limit = 100
	) {
		const params = {
			city,
			scholar_year,
			promotion: promotions instanceof Array ? promotions : [promotions],
			cursus,
			projected,
			semester: semesters instanceof Array ? semesters : [semesters],
			referent: referents instanceof Array ? referents : [referents],
			offset,
			limit,
		};
		return this.api.processRequest("GET", "/students/roadblocks", {
			params,
		});
	}

	getRoadblocksAlertStats(
		city = null,
		promotion_bachelor = null,
		curriculum = "bachelor",
		projected = false
	) {
		const params = {
			city,
			promotion: promotion_bachelor,
			curriculum,
			projected,
		};
		return this.api.processRequest(
			"GET",
			"/students/roadblocks/alerts/stats",
			{
				params,
			}
		);
	}

	getIntuituPersonae(
		offset,
		cities = null,
		promotions = null,
		scholar_year = null,
		limit = 100
	) {
		return this.api.processRequest("GET", "/students/intuitu_personae", {
			params: {
				offset,
				city: cities,
				promotion: promotions,
				scholar_year,
				limit,
			},
		});
	}

	postIntuituPersonae(params) {
		return this.api.processRequest("POST", `/students/intuitu_personae`, {
			data: params,
		});
	}

	updateIntuituPersonae(id, comment, adm_status, pedago_status) {
		const params = {
			comment,
			adm_status,
			pedago_status,
		};
		return this.api.processRequest(
			"PUT",
			`/students/intuitu_personae/${id}`,
			{
				data: params,
			}
		);
	}

	getIntuituPersonaeStats(cities, promotions, scholar_year) {
		const params = {
			city: cities,
			promotion: promotions,
			scholar_year,
		};
		return this.api.processRequest(
			"GET",
			`/students/intuitu_personae/stats`,
			{
				params,
			}
		);
	}

	getIntuituPersonaeAlertsCount(city, promotions) {
		const params = {
			city,
			promotion: promotions,
		};
		return this.api.processRequest(
			"GET",
			"/students/intuitu_personae/alerts/stats",
			{
				params,
			}
		);
	}

	getMissingTranscripts(cities, promotions) {
		const params = {
			city: cities,
			promotion: promotions,
		};
		return this.api.processRequest(
			"GET",
			"/students/transcripts/missing/stats",
			{
				params,
			}
		);
	}

	getMissingTranscriptsPerCities(cities, promotions) {
		const params = {
			city: cities,
			promotion: promotions,
		};
		return this.api.processRequest(
			"GET",
			"/students/transcripts/cities/missing/stats",
			{
				params,
			}
		);
	}

	getMissingTranscriptsPerPromotions(cities, promotions) {
		const params = {
			city: cities,
			promotion: promotions,
		};
		return this.api.processRequest(
			"GET",
			"/students/transcripts/promotions/missing/stats",
			{
				params,
			}
		);
	}

	getTranscripts(offset, cities, promotions, limit = 100) {
		const params = {
			city: cities,
			promotion: promotions,
			offset,
			limit,
		};
		return this.api.processRequest("GET", "/students/transcripts", {
			params,
		});
	}

	getInvestment(offset, city, promotion, begin, end, alert, limit = 100) {
		const params = {
			city,
			promotion,
			begin,
			end,
			offset,
			alert,
			limit,
		};
		return this.api.processRequest("GET", "/students/investment", {
			params,
		});
	}

	getInvestmentProgressionStats(
		year = null,
		city = null,
		promotion = null,
		national = false
	) {
		return this.api.processRequest(
			"GET",
			"/students/investment/progressions/stats",
			{
				params: {
					year,
					city,
					promotion,
					national,
				},
			}
		);
	}

	getInvestmentAlerts(offset, city = null, promotion = null, limit = 100) {
		const params = {
			offset,
			city,
			promotion,
			limit,
		};
		return this.api.processRequest("GET", "/students/investment/alerts", {
			params,
		});
	}
}
