import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import Filters from "./Filters";
import ProgressionGraph from "./Progression";
import StatusGraph from "./StatusGraph";

export default function InternshipProgression() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		cities: [],
		curriculums: [],
		years: [],
		national: false,
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	return (
		<Section>
			<SectionHeader title={t("internships progression").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-3 gap-3">
						<Col lg={3}>
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9}>
							<StatusGraph
								cities={activeFilters.cities}
								curriculums={activeFilters.curriculums}
								national={activeFilters.national}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col lg={12}>
							<ProgressionGraph
								cities={activeFilters.cities}
								curriculums={activeFilters.curriculums}
								years={activeFilters.years}
								national={activeFilters.national}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
