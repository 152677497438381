import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import { separateFiltersOptions } from "../Utils/Utils";
import Filters from "./Filters";
import GradesByInstances from "./GradesByInstances";
import GradesByYear from "./GradesByYear";

export default function ModuleValidation() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		year: null,
		module: null,
		instances: [],
		date: null,
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	const { labels: selectedInstances } = separateFiltersOptions(
		activeFilters.instances
	);

	return (
		<Section>
			<SectionHeader title={t("module validations").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9} className="mb-3">
							<GradesByInstances
								year={activeFilters.year?.label}
								module_code={activeFilters.module?.label}
								instances={selectedInstances}
								date={activeFilters.date?.toISODate()}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
						<Col lg={12} className="mb-3">
							<GradesByYear
								module_code={activeFilters.module?.label}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
