import { Card, Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { t } from "../../../Config/i18n";
import { Box, BoxTitle } from "../../AdminLTE3/Box";
import { useInvestmentAlertsQuery } from "../../InvestmentOverview/Queries";
import { degrade } from "../../Utils/Utils";
import { useAlertsPerCityQuery } from "../Queries";

const COLORS = Array(8)
	.fill(0)
	.map((_, idx) => degrade(7, idx));

function getDoughnutOptions(displayLegend) {
	return {
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		plugins: {
			legend: {
				display: displayLegend,
			},
		},
	};
}

export function AlertsDoughnut({ alerts, displayLegend = false }) {
	const labels = Object.keys(alerts).map(
		(nbAlerts) => `${t("alertWithCount", { count: Number(nbAlerts) })}`
	);
	const options = getDoughnutOptions(displayLegend);
	if (labels.length === 0) {
		return (
			<p className="text-red">
				{t("there is no available data").capfirst()}
			</p>
		);
	}
	const data = {
		labels,
		datasets: [
			{
				data: Object.values(alerts),
				backgroundColor: COLORS.slice(0, labels.length),
			},
		],
	};
	options.responsive = true;
	return <Doughnut height={150} data={data} options={options} />;
}

export function CityPromotionStatus({
	cityOption,
	promotionOption,
	displayCityName,
}) {
	const { label: city, value: cityCode } = cityOption;
	const { label: promotion, value: promotionYear } = promotionOption;
	const { data = {} } = useAlertsPerCityQuery(cityCode, promotionYear);
	const {
		data: investmentAlerts,
		isFetchingNextPage: isInvestmentAlertsFetchingNextPage,
		hasNextPage: hasInvestmentAlertsNextPage,
		fetchNextPage: fetchInvestmentAlertsNextPage,
	} = useInvestmentAlertsQuery(cityCode, promotionYear);

	if (!isInvestmentAlertsFetchingNextPage && hasInvestmentAlertsNextPage)
		fetchInvestmentAlertsNextPage();
	const investmentAlertsData = (
		investmentAlerts?.pages.flatMap((page) => page.data.results) ?? []
	).filter((alert) => alert.drop_percentage >= 50);
	const showAlerts = Object.values(data).some((value) => value > 0);
	return (
		<div style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle
					title={`${
						displayCityName ? `${city.capfirst()} - ` : ""
					}${promotion}`}
				/>
			</Card.Header>
			<Card.Body style={{ alignSelf: "center", height: "85%" }}>
				<Row className="mb-3 h-100">
					<Col
						xs={6}
						className="d-flex flex-column align-items-center"
						style={{
							borderRight: "1px solid #e9ecef",
						}}
					>
						<h5>{t("alerts").capfirst()}</h5>
						{showAlerts ? (
							<div>
								<AlertsDoughnut alerts={data} />
							</div>
						) : (
							<p className="text-red text-center">
								{t("there is no available data").capfirst()}
							</p>
						)}
					</Col>
					<Col
						xs={6}
						className="d-flex flex-column align-items-center"
					>
						<h5>{t("investment").capfirst()}</h5>
						<div className="mt-4">
							<div>
								<h2
									style={{
										display: "inline-block",
										marginRight: "8px",
										color:
											investmentAlertsData.length > 0
												? "red"
												: "green",
									}}
								>
									{investmentAlertsData.length}
								</h2>
								<span>{t("students")}</span>
							</div>
							<p>{t("falling behind")}</p>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</div>
	);
}

function NeedsPromotionMessage() {
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("promotions status").capfirst()} />
			</Card.Header>
			<Card.Body>
				<Col key="empty_promotion_field_message">
					<p>
						{t(
							"you must specify promotion(s) to load these graphs"
						).capfirst()}
					</p>
				</Col>
			</Card.Body>
		</Box>
	);
}

export default function CitiesPromotionsStatusCard({ cities, promotions }) {
	const citiesWithNational = [...cities, { label: "Global", value: null }];
	if (promotions.length === 0) return <NeedsPromotionMessage />;
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("promotions status").capfirst()} />
			</Card.Header>
			<Card.Body
				style={{ paddingLeft: "0.9rem", paddingRight: "0.9rem" }}
			>
				<Row md={promotions.length}>
					{citiesWithNational.flatMap((city) =>
						promotions.map((promotion) => (
							<div
								style={{ borderRight: "1px solid #e9ecef" }}
								key={`Promotion_status_${city.value}_${promotion.label}`}
							>
								<CityPromotionStatus
									cityOption={city}
									promotionOption={promotion}
									displayCityName
								/>
							</div>
						))
					)}
				</Row>
			</Card.Body>
		</Box>
	);
}
