import { useState } from "react";
import { t } from "../../Config/i18n";
import NavTabs from "../AdminLTE3/NavTabs";
import TepitechAlertsDataTableContainer from "./TepitechAlertsDataTableContainer";
import TepitechCheatersDataTableContainer from "./TepitechCheatersDataTableContainer";

export default function DatatablesContainer(props) {
	const [search, setSearch] = useState("");

	const tabsToDisplay = [
		{
			display: true,
			label: `${t("tepitech").capfirst()} ${t("per students")}`,
			component: (
				<TepitechAlertsDataTableContainer
					{...props}
					search={search}
					setSearch={setSearch}
				/>
			),
			hash: "alerts",
		},
		{
			display: true,
			label: `${t("cheating suspicion").capfirst()}`,
			component: (
				<TepitechCheatersDataTableContainer
					{...props}
					search={search}
					setSearch={setSearch}
				/>
			),
			hash: "cheaters",
		},
	];
	return <NavTabs tabs={tabsToDisplay} compact affectsURI />;
}
