import { Badge, HoverCard, Text, rem } from "@mantine/core";
import { IconAlertTriangleFilled, IconCheck } from "@tabler/icons-react";
import { degrade } from "./Utils";

export function AlertBadge({ max_alerts, alerts }) {
	const icon = alerts.length ? (
		<IconAlertTriangleFilled style={{ width: rem(12), height: rem(12) }} />
	) : (
		<IconCheck style={{ width: rem(12), height: rem(12) }} />
	);
	return (
		<HoverCard shadow="md" withArrow>
			<HoverCard.Target>
				<Badge
					variant="filled"
					styles={{
						root: {
							backgroundColor: degrade(max_alerts, alerts.length),
						},
					}}
					leftSection={icon}
					fullWidth
				>
					{alerts.length}
				</Badge>
			</HoverCard.Target>
			{alerts.length > 0 ? (
				<HoverCard.Dropdown>
					{alerts.map((alert, index) => (
						<Text key={index}>{`${alert.capfirst()}`}</Text>
					))}
				</HoverCard.Dropdown>
			) : (
				<></>
			)}
		</HoverCard>
	);
}
