function removeUselessCategories(data) {
	const ret = data;
	Object.keys(data).forEach((category) => {
		if (
			category === "labels" ||
			category === "size" ||
			category === "length" ||
			category === "total"
		)
			return;
		const isOneUniqueElement = new Set(data[category]).size === 1;
		if (isOneUniqueElement && data[category][0] === 0) delete ret[category];
	});
	return ret;
}

function sortStatsByNewLabels(stats) {
	const labelsToIndex = stats.labels.reduce((accumulator, label, index) => {
		accumulator[label] = index;
		return accumulator;
	}, {});
	const orderedLabels = [...stats.labels].sort();
	const franceIndex = orderedLabels.findIndex((e) => e === "France");
	const europeIndex = orderedLabels.findIndex((e) => e === "Europe");
	if (franceIndex !== -1) {
		orderedLabels.push(orderedLabels.splice(franceIndex, 1)[0]);
	}
	if (europeIndex !== -1) {
		orderedLabels.push(orderedLabels.splice(europeIndex, 1)[0]);
	}
	const statsKeys = Object.keys(stats);
	statsKeys.splice(statsKeys.indexOf("labels"), 1);
	return orderedLabels.reduce(
		(accumulator, label) => {
			const index = labelsToIndex[label];
			statsKeys.forEach((statKey) => {
				if (!accumulator[statKey]) accumulator[statKey] = [];
				accumulator[statKey].push(stats[statKey][index]);
			});
			return accumulator;
		},
		{ labels: orderedLabels }
	);
}

function checkTypeOfData(stats) {
	for (let i = 0; i < stats.labels.length; i++) {
		if (typeof stats.labels[i] === "number") {
			stats.labels[i] = stats.labels[i].toString();
		} else if (typeof stats.labels[i] === "string") continue;
		else return null;
	}
	return stats;
}

export { checkTypeOfData, removeUselessCategories, sortStatsByNewLabels };
