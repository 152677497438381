import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { ScraperApiHelperContext } from "../../contexts";
import { Avatar } from "@mantine/core";

function useEpitechAvatarQuery(login) {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["avatar", login],
		queryFn: () => scraper.unlabeled.getAvatar(login),
	});
}

export default function EpitechAvatar({ login, name, ...restProps }) {
	const { data } = useEpitechAvatarQuery(login);
	const avatar = data ? URL.createObjectURL(data) : null;
	const initial =
		name
			?.split(" ")
			.map((n) => n[0])
			.join("") ?? "";

	return (
		<Avatar {...restProps} src={avatar} color="red" radius="md" alt={name}>
			{initial}
		</Avatar>
	);
}
