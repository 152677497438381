import { faGears } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import IconColoredCard from "../IconColoredCard";

export default function UndismissedHistory({ data }) {
	if (data.undismissed > 0) {
		return (
			<IconColoredCard
				title={`"${data.scraper_name.capfirst()}" scraping`}
				text={`${t("undismissedCount", { count: data.undismissed })}`}
				color="danger"
				detailPath={`/scraping_history?type=${data.scraper_name}`}
				icon={faGears}
			/>
		);
	}
}
