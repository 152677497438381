import { requestErrorToastManager } from "../Utils/Toasts";
import CheatersDataTable from "./CheatersDataTable";
import { useCheatersQuery } from "./Queries";

export default function CheatersDataTableContainer({
	areRequestEnabled,
	showSlug,
	year,
	code_module,
	slug,
	cities,
	unmarked_only,
}) {
	const {
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		data,
		isLoading,
		isFetching,
		error,
	} = useCheatersQuery(
		year,
		code_module,
		slug,
		cities,
		unmarked_only,
		areRequestEnabled
	);
	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();

	requestErrorToastManager("Cheaters: Unable to get cheaters", error);

	return (
		<CheatersDataTable
			data={data}
			showSlug={showSlug}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
}
