import Select from "react-select";
import { t } from "../../../Config/i18n";
import {
	createIntuituPedagoOption,
	pedago_status_comments,
	pedago_status_values,
} from "../../Utils/Utils";

export function PedagoStatus({ pedagoStatus }) {
	return t(pedago_status_comments[pedagoStatus]);
}

export function PedagoStatusEditable({ entry, editingRow, setEditingRows }) {
	const options = pedago_status_values.map((admStatus) =>
		createIntuituPedagoOption(admStatus)
	);

	const setPedagoStatus = (option) => {
		setEditingRows((prev) => ({
			...prev,
			[entry.id]: {
				...prev[entry.id],
				pedagoStatus: option.value,
			},
		}));
	};

	if (!editingRow) return null;

	return (
		<Select
			classNamePrefix="multiSelect"
			name={`Select_PedagoStatus_${entry.id}`}
			onChange={setPedagoStatus}
			options={options}
			value={
				editingRow.pedagoStatus === null
					? options[0]
					: createIntuituPedagoOption(editingRow.pedagoStatus)
			}
			menuPortalTarget={document.body}
		/>
	);
}
