import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import { requestErrorToastManager } from "../../Utils/Toasts";
import IconColoredCard from "../IconColoredCard";
import { useIntuitusInAlertQuery } from "../Queries";

const determineColor = (late, very_late, not_defined) => {
	if (late === 0) return "success";
	if (very_late === 0 && not_defined === 0) return "warning";
	return "danger";
};

const getText = (very_late, not_defined) => {
	if (very_late === 0 && not_defined === 0)
		return `${t("late Intuitu Personae")}`;
	return `${t("late Intuitu Personae")} ${t("of which")} ${
		very_late === 0
			? ""
			: ` ${t("veryLateWithCount", { count: very_late })}`
	}${very_late !== 0 && not_defined !== 0 ? ` ${t("and")}` : ""}${
		not_defined === 0
			? ""
			: ` ${t("notDefinedWithCount_other", { count: not_defined })}`
	}`;
};

export default function IntuituPersonae({ city, promotions }) {
	const {
		data = {},
		error,
		isFetching,
	} = useIntuitusInAlertQuery(city, promotions);

	const { late = 0, very_late = 0, not_defined = 0 } = data;
	requestErrorToastManager("Dashboard: Intuitu Personae Card", error);

	const color = determineColor(late, very_late);

	return (
		<IconColoredCard
			title={`${late}`}
			text={getText(very_late, not_defined)}
			color={color}
			detailPath={`/intuitu_personae?cities=${city.value}`}
			icon={faUsers}
			isLoading={isFetching}
		/>
	);
}
