import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import { useCurriculumsQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const currentYear = getCurrentAcademicYear();
	const { data, error } = useCurriculumsQuery();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			year: {
				label: t("year"),
				type: "select",
				multiple: false,
				clearable: false,
				options: nValues(2019, currentYear - 2019 + 1)
					.reverse()
					.toFilterOptions(),
				default: { label: currentYear, value: currentYear },
			},
			cities: {
				label: t("cities"),
				type: "select",
				multiple: true,
				options: citiesOptions,
				default: citiesOptions.length === 1 ? [citiesOptions[0]] : [],
			},
			curriculums: {
				label: t("curriculums"),
				type: "select",
				multiple: true,
				options: [],
				default: [],
			},
			promotions: {
				label: t("promotions"),
				type: "select",
				multiple: true,
				options: nValues(
					getCurrentAcademicYear() + 1,
					5
				).toFilterOptions(),
				default: [],
			},
		});

	useEffect(() => {
		if (!data) return;
		setFilterOptions("curriculums", data);
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Diversity Filters: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
