import { Line } from "react-chartjs-2";
import { GRAPH_LINE_COLORS } from "./GraphColors";

const options = {
	bezierCurve: true,
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			stacked: false,
			min: 0,
			suggestedMax: 100,
		},
	},
	plugins: {
		tooltip: {
			callbacks: {
				afterLabel(tooltipItem) {
					return `Number missing : ${
						tooltipItem.dataset.missing[tooltipItem.dataIndex]
					}`;
				},
			},
		},
	},
};

export default function IntuituProgressionGraph({
	stats,
	labels,
	colors = GRAPH_LINE_COLORS,
}) {
	const progressionData = stats ?? {};
	const data = { datasets: [], labels: labels ?? [] };
	Object.keys(progressionData).forEach((key, idx) => {
		data.datasets.push({
			backgroundColor: `${colors[idx % colors.length]}, 0.1)`,
			borderColor: `${colors[idx % colors.length]}, 1.0)`,
			data: progressionData[key].stats,
			missing: progressionData[key].missing,
			label: key,
			fill: true,
			tension: 0.3,
		});
	});
	return <Line data={data} options={options} />;
}
