import { useContext } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { ScraperApiHelperContext } from "../../contexts";
import { promotionYearToCurriculum } from "../Utils/Utils";

export const useInternshipStatusQuery = (cities, years, national, enabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"internship_progression",
			"internship_status",
			cities,
			years,
			national,
		],
		queryFn: () =>
			scraper.internships.getStatus(
				cities,
				"bachelor/classic",
				years,
				true
			),
		select: (data) => data.data,
		enabled,
	});
};

const calculateContractPercentage = ({ total, contracts }) => {
	if (total === 0) return 0;
	return (contracts / total) * 100;
};

export const useInternshipProgressionQueries = (
	years,
	cities,
	curriculums,
	national,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQueries({
		queries: years.map((scholarYear) => ({
			queryKey: [
				"internship_progression",
				"stats",
				"internship_evolution",
				scholarYear,
				cities,
				curriculums,
				national,
			],
			queryFn: () =>
				scraper.internships.getProgression(
					[scholarYear],
					cities,
					curriculums,
					national
				),
			enabled: areRequestEnabled,
			select: (data) => {
				const response = data.data;
				return Object.keys(response).reduce((acc, isoWeek) => {
					Object.keys(response[isoWeek]).forEach((promotionYear) => {
						const promotion = promotionYearToCurriculum(
							promotionYear,
							scholarYear
						);
						Object.keys(response[isoWeek][promotionYear]).forEach(
							(city) => {
								const key = `${city} - ${promotion} - ${scholarYear}`;
								if (!(key in acc)) acc[key] = [];
								const percentage = calculateContractPercentage(
									response[isoWeek][promotionYear][city]
								);
								acc[key].push(percentage.toFixed(2));
							}
						);
					});
					return acc;
				}, {});
			},
		})),
	});
};
