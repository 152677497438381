import {
	faCheck,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { BoxTitle } from "../AdminLTE3/Box";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import { degrade, getCurrentAcademicYear } from "../Utils/Utils";
import { LinkedTitle } from "./DataTable";
import { instanceDataToLink } from "./DataTableContainer";

const transformEntries = (instances) => {
	const modules = instances.reduce((acc, instance) => {
		const {
			module_code,
			module_title,
			instance_code,
			end_date,
			registered,
			missing,
			year,
		} = instance;
		if (!(module_code in acc)) {
			acc[module_code] = {
				module_code,
				module_title,
				link: `https://intra.epitech.eu/module/${getCurrentAcademicYear()}/${module_code}`,
				instances: [],
			};
		}
		acc[module_code].instances.push({
			instance_code,
			end_date,
			missing,
			registered,
			link: instanceDataToLink(year, module_code, instance_code),
		});
		return acc;
	}, {});
	return Object.values(modules);
};

function MissingInstancesSpan({ maxMissing, nbMissing }) {
	return (
		<span
			style={{
				backgroundColor: degrade(
					maxMissing > 10 ? maxMissing / 10 + 2 : maxMissing + 1,
					maxMissing > 10 ? nbMissing / 10 + 1 : nbMissing
				),
				display: "block",
				width: 80,
				fontSize: "12px",
				fontWeight: 700,
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "top",
				borderRadius: "10px",
				paddingTop: 1,
				paddingBottom: 1,
			}}
			className="text-white"
		>
			<FontAwesomeIcon
				icon={nbMissing > 0 ? faExclamationTriangle : faCheck}
				size="sm"
			/>
			<span className="ml-1">{nbMissing}</span>
		</span>
	);
}

function ExpandableComponent({ data }) {
	const { instances } = data;
	const columns = [
		{
			accessorKey: "instance_code",
			header: t("instance").capfirst(),
			Cell: ({ row }) => (
				<LinkedTitle
					title={row.original.instance_code}
					link={row.original.link}
				/>
			),
			enableSorting: false,
			enableColumnActions: false,
		},
		{
			accessorKey: "end_date",
			header: t("end date").capfirst(),
			Cell: ({ row }) =>
				row.original.end_date.toLocaleString(DateTime.DATE_SHORT),
			enableSorting: false,
			enableColumnActions: false,
		},
		{
			accessorKey: "missing",
			header: t("students not validated").capfirst(),
			enableSorting: false,
			enableColumnActions: false,
		},
		{
			accessorKey: "registered",
			header: t("number of registrants").capfirst(),
			enableSorting: false,
			enableColumnActions: false,
		},
	];

	const table = useMantineReactTable({
		columns,
		data: instances,
		enableToolbarInternalActions: false,
		mantineTableProps: { striped: true, sx: { tableLayout: "fixed" } },
		mantineTableHeadCellProps: { bg: "#5ca1fa" },
	});

	return (
		<div className="instances_table">
			<Card.Header>
				<BoxTitle
					className="skillTitle"
					title={`${t("Instances").capfirst()}`}
				/>
			</Card.Header>
			<Card.Body style={{ width: "100%" }}>
				<MantineReactTable table={table} />
			</Card.Body>
		</div>
	);
}

export default function OverviewDataTable({
	instances = [],
	exportConfig,
	isLoading,
	isFetching,
}) {
	const modules = transformEntries(instances);
	const maxInstanceMissing = modules.reduce(
		(max, module) => Math.max(module.instances.length, max),
		0
	);
	const columns = [
		{
			accessorKey: "instances.length",
			header: t("missing instances").capfirst(),
			size: 100,
			Cell: ({ row }) => (
				<MissingInstancesSpan
					maxMissing={maxInstanceMissing}
					nbMissing={row.original.instances.length}
				/>
			),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "module_code",
			header: t("module code").capfirst(),
		},
		{
			accessorKey: "module_title",
			header: t("title").capfirst(),
			size: 400,
			Cell: ({ row }) => (
				<LinkedTitle
					title={row.original.module_title}
					link={row.original.link}
				/>
			),
		},
	];

	return (
		<SauronMantineDataTable
			columns={columns}
			data={modules}
			exportConfig={{ ...exportConfig, data: instances }}
			tableProps={{
				renderDetailPanel: ({ row }) => (
					<ExpandableComponent data={row.original} />
				),
			}}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
