import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

export default function StepCardHeader({ label, success }) {
	return (
		<Card.Header className="d-flex align-items-center">
			<FontAwesomeIcon
				icon={success ? faCheck : faArrowRight}
				size="xl"
				color={success ? "green" : "orange"}
			/>
			<h3 className="card-title ml-2">{label}</h3>
		</Card.Header>
	);
}
