import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { requestErrorToastManager } from "../Utils/Toasts";
import { sortObjectByKeysWithFranceAtEnd } from "../Utils/Utils";
import { useCheatersStatsQuery } from "./Queries";

function Alert({ data }) {
	if (!data) return null;
	const sortedData = sortObjectByKeysWithFranceAtEnd(data);
	const colors = ["#dc3545", "#6c757d", "#28a745"];
	const chartsData = {
		datasets: ["confirmed", "unmarked", "false_positive"].map(
			(range, idx) => ({
				label: t(range.replaceAll("_", " ")).capfirst(),
				data: Object.values(sortedData).map(
					(stats) => stats[range] ?? 0
				),
				backgroundColor: colors[idx],
			}),
			[]
		),
		labels: Object.keys(sortedData),
	};
	return <Bar data={chartsData} options={defaultBarOptions} />;
}

export default function CheatersStats({ year, code_module, slug, cities }) {
	const {
		data = {},
		error,
		isFetching,
	} = useCheatersStatsQuery(year, code_module, slug, cities);
	// eslint-disable-next-line no-unused-vars
	const { Global, Europe, ...filtered } = data;
	requestErrorToastManager("Cheaters: Unable to fetch stats", error);
	return (
		<SauronChartsContainer
			title={t("cheating cases percentage").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
		>
			<Alert data={filtered} />
		</SauronChartsContainer>
	);
}
