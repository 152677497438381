import { Table } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../../../../Config/i18n";
import { useProjectStatsStudentQuery } from "../../../../Projects/Queries";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { getCurrentAcademicYear } from "../../../../Utils/Utils";

function GroupStatsOverviewTableLine({ groupStat, login }) {
	const members = Object.keys(groupStat.stats);
	const totalLinesOfCode = members.reduce(
		(acc, member) =>
			acc +
			(groupStat.stats[member]?.a || 0) -
			(groupStat.stats[member]?.d || 0),
		0
	);
	const averageLinesOfCode = totalLinesOfCode / (members.length || 1);
	return (
		<tr>
			<th
				data-tooltip-html={
					(groupStat.group.members.length > 1 &&
						"<b>" +
							t("members").capfirst() +
							"</b><br>" +
							groupStat.group.members
								.map((m) => m.login)
								.join("<br>")) ||
					t("solo project").capfirst()
				}
				data-place="top"
				data-tooltip-id="group_stats"
			>
				{groupStat.group.project.project_slug}
			</th>
			<td>{groupStat.stats[login]?.c || 0}</td>
			<td>
				{(groupStat.stats[login]?.a || 0) -
					(groupStat.stats[login]?.d || 0)}
			</td>
			<td>{averageLinesOfCode}</td>
		</tr>
	);
}

function GroupStatsOverviewTable({ groupStats, login }) {
	return (
		<Table striped hover>
			<thead className="thead-light">
				<tr>
					<th>{t("project").capfirst()}</th>
					<th>{"commits".capfirst()}</th>
					<th>{t("lines of code").capfirst()}</th>
					<th>{t("average lines of code per member").capfirst()}</th>
				</tr>
			</thead>
			<tbody>
				{groupStats.map((groupStat) => (
					<GroupStatsOverviewTableLine
						key={groupStat.group.project.project_slug}
						login={login}
						groupStat={groupStat}
					/>
				))}
			</tbody>
		</Table>
	);
}

export default function GroupStats({ student }) {
	const {
		fetchNextPage,
		data: data,
		isFetching: isFetching,
		error: error,
		isFetchingNextPage,
		hasNextPage,
	} = useProjectStatsStudentQuery(getCurrentAcademicYear(), student.login);

	requestErrorToastManager("Student: GroupStats tab", error);

	if (!isFetchingNextPage && hasNextPage) fetchNextPage();

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!data) return <NoDataAvailable />;
	return (
		<div>
			<GroupStatsOverviewTable groupStats={data} login={student.login} />
			<Tooltip id="group_stats" />
		</div>
	);
}
