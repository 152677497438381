import { Button, Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import LoadingOverlay from "../AdminLTE3/LoadingOverlay";
import ProgressionGraph from "../Sauron/Graphs/ProgressionGraph";
import { requestErrorToastManager } from "../Utils/Toasts";
import {
	exportProgression,
	getLabelWeeks,
	sortObjectByKeys,
} from "../Utils/Utils";
import { useInternshipProgressionQueries } from "./Queries";

function ProgressionGraphContainer({
	isLoading,
	disabled,
	stats,
	labels,
	children,
}) {
	return (
		<Box style={{ height: "100%" }}>
			<LoadingOverlay loading={isLoading} />
			<Card.Header>
				<BoxTitle
					title={t("internshipProgresstime").capfirst()}
					subtitle="bachelor"
				/>
				<h5 className="font-weight-normal">
					<span className="float-right">
						<Button
							variant="outline-primary"
							type="button"
							onClick={() => {
								exportProgression(
									stats,
									labels,
									"internship_progression"
								);
							}}
							disabled={disabled}
						>
							{t("export to CSV").capfirst()}
						</Button>
					</span>
				</h5>
			</Card.Header>
			<Card.Body>
				<div style={{ height: 350 }}>{children}</div>
			</Card.Body>
		</Box>
	);
}

export default function Progression({
	cities,
	curriculums,
	years,
	national,
	areRequestEnabled,
}) {
	const results = useInternshipProgressionQueries(
		years?.map((year) => year.value),
		cities?.map((city) => city.value),
		curriculums?.map((curriculum) => `PGE${curriculum.value}`),
		national,
		areRequestEnabled
	);

	const isFetching = results.some((query) => query.isFetching);
	const error = results.find((query) => query.error !== null);
	const requestData = results.map((query) => query?.data);
	const datasets = sortObjectByKeys(
		requestData.reduce(
			(acc, objects) => ({
				...acc,
				...objects,
			}),
			{}
		)
	);
	const maxLength = Object.values(datasets).reduce((max, dataset) => {
		if (dataset.length > max) return dataset.length;
		return max;
	}, 0);
	const weeks = getLabelWeeks(maxLength);

	requestErrorToastManager(
		"Internship Progression: Progression graph",
		error
	);

	return (
		<ProgressionGraphContainer
			isLoading={isFetching}
			disabled={Object.keys(datasets).length === 0}
			stats={datasets}
			labels={weeks.map((week) => `W${week.weekNumber}`)}
		>
			<ProgressionGraph
				stats={datasets}
				labels={weeks.map((week) =>
					week.toLocaleString({
						day: "numeric",
						month: "long",
					})
				)}
			/>
		</ProgressionGraphContainer>
	);
}
