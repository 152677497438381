import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts.js";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useUserQuery = (enabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		enabled,
		queryKey: ["users"],
		queryFn: () => scraper.getUsers(),
		select: (response) => response.data,
	});
};

export const useGenerateTokenMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: (email) => scraper.token.generateToken(email),
		onError: (error) => {
			console.log(error);
		},
	});
};
