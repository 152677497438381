export const GRADING_SCALE = [
	"N/A",
	"A1",
	"A1+",
	"A2",
	"A2+",
	"B1",
	"B1+",
	"B2",
	"B2+",
	"C1",
	"C1+",
	"C2",
];

export const GRADES = { "-": 0, "Echec": 0, "D": 1, "C": 2, "B": 3, "A": 4 };
