import Select from "react-select";
import { t } from "../../../Config/i18n";
import {
	adm_status_comments,
	adm_status_values,
	createIntuituADMOption,
} from "../../Utils/Utils";

export function ADMStatus({ admStatus }) {
	return t(adm_status_comments[admStatus]);
}

export function ADMStatusEditable({ entry, editingRow, setEditingRows }) {
	const options = adm_status_values.map((admStatus) =>
		createIntuituADMOption(admStatus)
	);

	const setADMStatus = (option) => {
		setEditingRows((prev) => ({
			...prev,
			[entry.id]: {
				...prev[entry.id],
				admStatus: option.value,
			},
		}));
	};

	if (!editingRow) return null;

	return (
		<Select
			classNamePrefix="multiSelect"
			name={`Select_ADMStatus_${entry.id}`}
			onChange={setADMStatus}
			options={options}
			value={
				editingRow.admStatus === null
					? options[0]
					: createIntuituADMOption(editingRow.admStatus)
			}
			menuPortalTarget={document.body}
		/>
	);
}
