import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear } from "../Utils/Utils";
import Filters from "./Filters";
import { useActivityPresencesQuery } from "./Queries";
import StudentPresenceChart from "./StudentPresenceChart";
import StudentPresenceDataTableContainer from "./StudentPresenceDataTableContainer";

export default function StudentPresence() {
	const currentYear = getCurrentAcademicYear();
	const isRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		year: { label: currentYear, value: currentYear },
		module_code: null,
		instance_code: null,
	});
	const {
		data: activityPresences,
		isFetching,
		isLoading,
		errorPresences,
	} = useActivityPresencesQuery(
		activeFilters.year?.value,
		activeFilters.module_code?.value,
		activeFilters.instance_code?.value,
		isRequestEnabled.current
	);

	requestErrorToastManager(
		"Student Presence: Modules/Instances Filter",
		errorPresences
	);
	const updateActiveFilters = (selectedFilters) => {
		isRequestEnabled.current =
			selectedFilters.year !== null &&
			selectedFilters.module_code !== null &&
			selectedFilters.instance_code !== null;
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	return (
		<Section>
			<SectionHeader title={t("student presences").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-3 gap-3">
						<Col lg={3}>
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9}>
							<StudentPresenceChart
								isLoading={isFetching}
								activityPresences={activityPresences}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<StudentPresenceDataTableContainer
								isLoading={isLoading}
								isFetching={isFetching}
								activityPresences={activityPresences}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="student-presence-tooltip" />
			<StudentTooltip tooltipId="student-presence-student-tooltip" />
		</Section>
	);
}
