import { DateTime } from "luxon";
import { FormControl, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { t } from "../../Config/i18n";

export function FilterTextInput({ name, filter, onChange }) {
	const onTextChange = (event) => {
		const key = event.target.attributes.optionkey.value;
		const text = event.target.value;
		onChange(key, text);
	};
	return (
		<>
			<h6>{filter.label.capfirst()}</h6>
			<InputGroup>
				<FormControl
					type="text"
					optionkey={name}
					onChange={onTextChange}
					value={filter.selected || ""}
					placeholder={filter.placeholder || ""}
					disabled={filter.disabled}
				/>
			</InputGroup>
		</>
	);
}

export function FilterSelect({ name, filter, onChange, title = true }) {
	const options = filter.options || [];
	const onSelectChange = (option, event) => {
		onChange(event.name, option);
	};
	return (
		<div>
			{title ? <h6 className="mb-1">{filter.label.capfirst()}</h6> : null}
			<Select
				className="text-nowrap"
				classNamePrefix="multiSelect"
				name={name}
				placeholder={
					filter.placeholder ||
					`${t("filter by").capfirst()} ${filter.label}`
				}
				onChange={onSelectChange}
				closeMenuOnSelect={!filter.multiple}
				isMulti={filter.multiple}
				isLoading={!filter.disabled && options.length === 0}
				options={options}
				isClearable={filter.clearable ?? true}
				value={filter.selected}
				isDisabled={filter.disabled}
				menuPortalTarget={document.body}
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: 9999 }),
				}}
			/>
		</div>
	);
}

export function FilterCheckbox({ name, filter, onChange }) {
	const onCheckChange = (event) => {
		const key = event.target.attributes.optionkey.value;
		const { checked } = event.target;
		onChange(key, checked);
	};
	return (
		<InputGroup>
			<InputGroup.Prepend>
				<InputGroup.Checkbox
					optionkey={name}
					onChange={onCheckChange}
					checked={filter.selected || false}
				/>
			</InputGroup.Prepend>
			<div
				className="form-control text-nowrap"
				style={{ overflow: "hidden" }}
			>
				{typeof filter.label === "string"
					? filter.label.capfirst()
					: filter.label}
			</div>
		</InputGroup>
	);
}

export function FilterDateBox({ name, filter, onChange }) {
	const onDateChange = (event) => {
		const key = event.target.attributes.optionkey.value;
		const date = DateTime.fromISO(event.target.value);
		onChange(key, date);
	};
	return (
		<>
			<h6 className="mb-1">{filter.label.capfirst()}</h6>
			<InputGroup>
				<FormControl
					type="date"
					optionkey={name}
					onChange={onDateChange}
					value={filter.selected ? filter.selected.toISODate() : ""}
					min={filter.min}
					max={filter.max}
				/>
			</InputGroup>
		</>
	);
}

function Filter({ name, filter, onChange }) {
	switch (filter.type) {
		case "text":
			return (
				<FilterTextInput
					name={name}
					filter={filter}
					onChange={onChange}
				/>
			);
		case "select":
			return (
				<FilterSelect name={name} filter={filter} onChange={onChange} />
			);
		case "bool":
			return (
				<FilterCheckbox
					name={name}
					filter={filter}
					onChange={onChange}
				/>
			);
		case "date":
			return (
				<FilterDateBox
					name={name}
					filter={filter}
					onChange={onChange}
				/>
			);
		default:
			return null;
	}
}

/*
 ** filters: an object like this:
 ** {
 **    filter_name: { label: string, type: string},
 **    type: "select" || "bool" || "date" || "text",
 **    selected: currently selected value(s)
 **    options: array of {label, value} objects for "select" type only
 **    min: min date for "date" type only
 **    max: max date for "date" type only
 **    multiple: bool, for "select" type only
 **    placeholder: string, for "text" and "select" types only
 ** }
 **
 ** onChange(filter_name, options): a callback that will be triggered on change
 **
 ** selected:
 ** - if type is "select": an array of {value, label} if multiple is true, otherwise a single {value, label} object
 ** - if type is "bool": true or false
 ** - if type is "date": a Date object
 ** - if type is "text": a string
 */
export default function FiltersForms({
	filters = {},
	onChange = () => {},
	inline = false,
}) {
	return inline ? (
		<div className={`${inline ? "d-flex flex-row flex-wrap" : ""}`}>
			{Object.entries(filters).map(([name, filter]) => (
				<div className="flex-grow-1 mr-3" key={`filter_name_${name}`}>
					<Filter name={name} filter={filter} onChange={onChange} />
				</div>
			))}
		</div>
	) : (
		<>
			{Object.entries(filters).map(([name, filter], index) => (
				<div
					className={`${index !== 0 ? "mt-3" : ""}`}
					key={`filter_name_${name}`}
				>
					<Filter name={name} filter={filter} onChange={onChange} />
				</div>
			))}
		</>
	);
}
