import { useContext } from "react";
import { Col } from "react-bootstrap";
import { ScraperApiHelperContext } from "../../contexts";
import { requestErrorToastManager } from "../Utils/Toasts";
import { hasPermissions } from "../Utils/Utils";
import CheatersToCheck from "./Cards/CheatersToCheck";
import IntuituPersonae from "./Cards/IntuituPersonae";
import ModulesToValidate from "./Cards/ModulesToValidate";
import UndismissedHistory from "./Cards/ScrapersUndismissed";
import SuspiciousTepitech from "./Cards/SuspiciousTepitech";
import TesseractUnstableModule from "./Cards/TesseractUnstableModule";
import Transcripts from "./Cards/Transcripts";
import {
	useTesseractUnstableModulesQuery,
	useUndismissedHistory,
} from "./Queries";

const MAXIMUM_CARDS_PER_LINE = 4;

function CardsLine({ cards, line }) {
	const lg = 12 / MAXIMUM_CARDS_PER_LINE;
	return cards.map((card, idx) => (
		<Col key={`line_${line}_card_${idx}`} lg={lg} className="mb-3">
			{card.component}
		</Col>
	));
}

const getCards = (
	city,
	promotions,
	curriculum,
	tesseractUnstableModules,
	undismissedHistory
) => {
	const account = useContext(ScraperApiHelperContext).api.getSauronUserInfo();
	return [
		...undismissedHistory.map((historyData) => ({
			display: true,
			component: <UndismissedHistory data={historyData} />,
			authorized_groups: ["admin"],
		})),
		{
			display: true,
			component: <Transcripts city={city} promotions={promotions} />,
			authorized_groups: ["pedago"],
		},
		{
			display: city.value.startsWith("FR") && promotions.length !== 2,
			component: <IntuituPersonae city={city} promotions={promotions} />,
			authorized_groups: ["dpr"],
		},
		{
			display: true,
			component: (
				<ModulesToValidate city={city} curriculum={curriculum} />
			),
			authorized_groups: ["pedago"],
		},
		{
			display: curriculum === "bachelor",
			component: <CheatersToCheck city={city} />,
			authorized_groups: ["pedago"],
		},
		{
			display: curriculum === "bachelor",
			component: <SuspiciousTepitech city={city} />,
			authorized_groups: ["pedago", "english"],
		},
		...tesseractUnstableModules.map((moduleCode) => ({
			display: true,
			component: <TesseractUnstableModule moduleCode={moduleCode} />,
			authorized_groups: ["pedago"],
		})),
	].filter(
		(card) =>
			card.display && card.component && hasPermissions(card, account)
	);
};

export default function AlertCards({ city, isCEI, promotions }) {
	const scraper = useContext(ScraperApiHelperContext);
	const { data: tesseractUnstableModules = [], error } =
		useTesseractUnstableModulesQuery(
			scraper.userHelper.getSupervisedModules()
		);
	const { data: undismissedHistory = [] } = useUndismissedHistory(
		scraper.userHelper.isAdmin()
	);
	const curriculum = isCEI ? "master" : "bachelor";
	const cards = getCards(
		city,
		promotions,
		curriculum,
		tesseractUnstableModules,
		undismissedHistory
	);
	const lines = cards.reduce(
		(acc, card) => {
			if (!acc.lines[acc.idx]) acc.lines[acc.idx] = [];
			acc.lines[acc.idx].push(card);
			if (acc.lines[acc.idx].length === MAXIMUM_CARDS_PER_LINE)
				acc.idx += 1;
			return acc;
		},
		{ lines: [], idx: 0 }
	);

	requestErrorToastManager("Dashboard: Tesseract Unstable Modules", error);
	return lines.lines.map((line, idx) => (
		<CardsLine cards={line} line={idx} key={`line_card_${idx}`} />
	));
}
