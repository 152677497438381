import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n.jsx";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer.jsx";
import { defaultBarOptions } from "../Utils/DefaultConst.jsx";
import { formatDataForSuccessCharts } from "../Utils/FormatDataForCharts.jsx";
import { getAlertLabels, getSuccessBarLabels } from "../Utils/Labels.jsx";
import { getCurrentAcademicYear } from "../Utils/Utils.jsx";
import {
	useRoadblocksAlertsStatsBachelorQuery,
	useRoadblocksAlertsStatsMasterQuery,
} from "./Queries.jsx";

export default function StudentSuccessChart({
	city,
	curriculum,
	promotion_bachelor,
	promotion_master,
	projection,
	type = "bachelor", // 'bachelor' or 'master'
}) {
	const currentYear = getCurrentAcademicYear();
	const isBachelor = type === "bachelor";

	// Get the appropriate alert labels based on the type
	const alertLabels = isBachelor
		? getAlertLabels(curriculum)
		: getSuccessBarLabels("master");

	// Define promotions based on the type
	const promos = isBachelor
		? promotion_bachelor
			? [promotion_bachelor]
			: [currentYear + 3, currentYear + 4, currentYear + 5]
		: promotion_master && promotion_master.length > 0
			? promotion_master
			: [currentYear, currentYear + 1, currentYear + 2];

	// Fetch data based on the type
	const queryResult = isBachelor
		? useRoadblocksAlertsStatsBachelorQuery(
				city?.value,
				promotion_bachelor,
				curriculum,
				projection
			)
		: useRoadblocksAlertsStatsMasterQuery(promos);

	const isFetching = isBachelor
		? queryResult.isFetching
		: queryResult.some((query) => query.isFetching);

	const error = isBachelor
		? queryResult.error
		: queryResult.find((query) => query.error !== null);

	// For master, process stats manually
	const successData = useMemo(() => {
		if (isBachelor) {
			// Check if data exists before proceeding
			const bachelorData = queryResult?.data?.data;
			if (!bachelorData || Object.keys(bachelorData).length <= 0) {
				return formatDataForSuccessCharts(undefined, alertLabels, city);
			} else {
				// eslint-disable-next-line no-unused-vars
				const { Global, Europe, ...filtered } = bachelorData || {};
				return formatDataForSuccessCharts(filtered, alertLabels, city);
			}
		} else {
			// Master handling
			const promotionsStats = queryResult.map(
				(query) => query?.data?.data || {}
			);
			const sums = { France: {} };

			promotionsStats.forEach((promotionStat) => {
				// Only proceed if promotionStat.France exists
				if (promotionStat?.France) {
					delete promotionStat.France.free_project;
					Object.keys(promotionStat.France).forEach((property) => {
						sums.France[property] = sums.France[property] || {
							OK: 0,
							KO: 0,
							in_progress: 0,
						};
						sums.France[property].OK +=
							promotionStat.France[property].OK || 0;
						sums.France[property].KO +=
							promotionStat.France[property].KO || 0;
						sums.France[property].in_progress +=
							promotionStat.France[property].in_progress || 0;
					});
				}
			});
			return formatDataForSuccessCharts(sums, alertLabels, "National");
		}
	}, [queryResult, isBachelor, alertLabels, city]);

	// Error handling with toast notifications
	useEffect(() => {
		if (!error) return;
		toast.error(
			`${isBachelor ? "Bachelor" : "Master"} Success Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error, isBachelor]);

	return (
		<SauronChartsContainer
			title={t("success rate").capfirst()}
			subtitle={`${t("by")} ${t("roadblocks")}`}
			isLoading={isFetching}
			height={isBachelor ? 410 : undefined} // Customize height for bachelor chart
		>
			{successData ? (
				<Bar data={successData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
