import { faCheck, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	ActionIcon,
	Card,
	CardSection,
	Group,
	NumberInput,
	Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { t } from "../../../Config/i18n";

export default function AdditionalConfigCard({
	configName,
	configThreshold,
	backgroundColor,
	validateChange,
}) {
	const [isEditing, setIsEditing] = useState(false);
	const [threshold, setThreshold] = useState(configThreshold ?? 0);

	useEffect(() => {
		setThreshold(configThreshold);
	}, [configThreshold]);

	function changeEditingStatus() {
		setIsEditing(!isEditing);
		validateChange(configName, threshold);
	}

	return (
		<Card style={{ height: "100%", backgroundColor }}>
			<CardSection inheritPadding py="xs" style={{ height: "98%" }}>
				<Group
					style={{
						justifyContent: "space-between",
						fontWeight: "bold",
					}}
				>
					<Text>{`${t(configName.replace("_", " ")).title()}`}</Text>
					<span style={{ marginRight: "auto" }}>
						{!isEditing ? (
							<Text>
								({threshold} {t("required")})
							</Text>
						) : (
							<NumberInput
								value={threshold}
								onChange={setThreshold}
								min={-1}
							/>
						)}
					</span>
					<ActionIcon onClick={changeEditingStatus}>
						{!isEditing ? (
							<FontAwesomeIcon icon={faPencil} />
						) : (
							<FontAwesomeIcon icon={faCheck} />
						)}
					</ActionIcon>
				</Group>
			</CardSection>
		</Card>
	);
}
