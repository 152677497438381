import NavTabs from "../../../../AdminLTE3/NavTabs";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import {
	getCurrentAcademicYear,
	sortObjectByKeys,
} from "../../../../Utils/Utils";
import { useIntuituPersonaeInfiniteQuery } from "../../../Queries";
import IntuituYearTab from "./YearTab/YearTab";

export default function IntuituPersonae({ login }) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		error,
	} = useIntuituPersonaeInfiniteQuery(login);

	requestErrorToastManager("Student: Intuitu Personae tab", error);

	if (!isFetchingNextPage && hasNextPage) fetchNextPage();

	if (isFetching || isFetchingNextPage)
		return <LoadingComponent className="text-blue" />;

	const intuituPersonaes = data?.pages.flatMap((page) => page);

	if (!intuituPersonaes || intuituPersonaes.length === 0)
		return <NoDataAvailable />;

	const intuituPerYears = sortObjectByKeys(
		intuituPersonaes.reduce((acc, intuituPersonae) => {
			const { scholar_year: year } = intuituPersonae;
			if (!(year in acc)) acc[year] = [];
			acc[year].push(intuituPersonae);
			return acc;
		}, {})
	);

	const yearTabs = Object.entries(intuituPerYears).map(
		([year, intuitus]) => ({
			disable: false,
			display: true,
			label: parseInt(year, 10),
			component: <IntuituYearTab intuituPersonaes={intuitus} />,
		})
	);

	return <NavTabs tabs={yearTabs} activeTab={getCurrentAcademicYear()} />;
}
