export default class Unlabeled {
	constructor(api) {
		this.api = api;
	}

	getAvatar(epitech_login) {
		return this.api
			.processRequest(
				"GET",
				`user/${epitech_login}/avatar`,
				{
					params: { login: epitech_login },
				},
				null,
				"blob"
			)
			.then((res) => res.data);
	}
}
