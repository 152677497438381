function checkIfThereIsOnlyOneElement(arr) {
	return new Set(arr).size === 1;
}

function removeNoElements(data) {
	Object.keys(data).forEach((key) => {
		if (
			key !== "labels" &&
			key !== "size" &&
			key !== "length" &&
			key !== "total" &&
			checkIfThereIsOnlyOneElement(data[key]) &&
			data[key][0] === 0
		) {
			delete data[key];
		}
	});
	return data;
}

function alphabeticalOrder(stats) {
	for (let i = 0; i < stats.labels.length - 1; ) {
		if (stats.labels[i + 1].localeCompare(stats.labels[i]) < 0) {
			Object.values(stats).forEach((value) => {
				if (Array.isArray(value)) {
					const copy = value[i];
					value[i] = value[i + 1];
					value[i + 1] = copy;
				}
			});
			i = 0;
			continue;
		}
		i++;
	}
	return stats;
}

function checkTypeOfData(stats) {
	for (let i = 0; i < stats.labels.length; i++) {
		if (typeof stats.labels[i] === "number") {
			stats.labels[i] = stats.labels[i].toString();
		} else if (typeof stats.labels[i] === "string") continue;
		else return null;
	}
	return stats;
}

export { checkTypeOfData, alphabeticalOrder, removeNoElements };
