import { Card, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { t } from "../../Config/i18n";
import { BoxTitle } from "../AdminLTE3/Box";

function StudentSkillTags({ tags }) {
	if (!tags) return null;
	return (
		<Row className="mb-3">
			<span>
				<span className="bold">{t("tag(s)").capfirst()}:</span>{" "}
				<code>{tags.join(", ")}</code>
			</span>
		</Row>
	);
}

function StudentSkillResults({ results }) {
	const hasValueProp = results.value !== undefined;
	const hasValidatedProp = results.validated !== undefined;
	const validationContent = results.validated ? (
		<div className="bold flatGreen">{t("validated").capfirst()}</div>
	) : (
		<div className="bold flatRed">{t("failed").capfirst()}</div>
	);
	return (
		<div className="my-1">
			{hasValueProp ? (
				<div>
					<span>
						<span className="bold">{t("value").capfirst()}:</span>{" "}
						{Math.round(results.value * 1000) / 1000}
					</span>
				</div>
			) : null}
			{hasValidatedProp ? validationContent : null}
		</div>
	);
}

function StudentSkillTableHeader({ hasBTTF, hasProvisional }) {
	if (!hasBTTF && !hasProvisional) return null;
	return (
		<thead>
			<tr>
				<th className="discreetBorder">
					<div className="my-2">Final</div>
				</th>
				{hasBTTF || hasProvisional ? (
					<th className="discreetBorder">
						<div className="my-2">{t("delivery").capfirst()}</div>
					</th>
				) : null}
				{hasBTTF ? (
					<th className="discreetBorder">
						<div className="my-2">BTTF</div>
					</th>
				) : null}
				{hasProvisional ? (
					<th className="discreetBorder">
						<div className="my-2">
							{t("provisional").capfirst()}
						</div>
					</th>
				) : null}
			</tr>
		</thead>
	);
}

function StudentSkillTableBody({ skill, hasBTTF, hasProvisional }) {
	return (
		<tbody>
			<tr style={{ lineHeight: "normal" }}>
				<td className="discreetBorder">
					<StudentSkillResults results={skill.final} />
				</td>
				{hasBTTF || hasProvisional ? (
					<td className="discreetBorder">
						<StudentSkillResults results={skill.delivery} />
					</td>
				) : null}
				{hasBTTF ? (
					<td className="discreetBorder">
						<StudentSkillResults results={skill.bttf} />
					</td>
				) : null}
				{hasProvisional ? (
					<td className="discreetBorder">
						<StudentSkillResults results={skill.provisional} />
					</td>
				) : null}
			</tr>
		</tbody>
	);
}

function StudentSkillTable({ skill }) {
	const hasBTTF = skill.bttf !== undefined;
	const hasProvisional = skill.provisional !== undefined;

	return (
		<Row className="mb-3">
			<table
				className="discreetBorder text-center"
				style={{
					width: "100%",
					borderCollapse: "collapse",
				}}
			>
				<StudentSkillTableHeader
					hasBTTF={hasBTTF}
					hasProvisional={hasProvisional}
				/>
				<StudentSkillTableBody
					skill={skill}
					hasBTTF={hasBTTF}
					hasProvisional={hasProvisional}
				/>
			</table>
		</Row>
	);
}

function StudentSkill({ login, skill }) {
	return (
		<div key={login + skill.name} style={{ padding: "0 15px" }}>
			<Card.Header>
				<BoxTitle className="skillTitle" title={skill.name} />
			</Card.Header>
			<Card.Body style={{ alignSelf: "center", lineHeight: "10px" }}>
				<StudentSkillTags tags={skill.tags} />
				<StudentSkillTable skill={skill} />
			</Card.Body>
		</div>
	);
}

function StudentSkillsValidatedPerTagsTable({ data }) {
	const headers = Object.keys(data);
	const entries = Object.entries(data);
	return (
		<Row className="mb-3">
			<table
				className="discreetBorder text-center"
				style={{
					width: "100%",
					borderCollapse: "collapse",
				}}
			>
				<thead>
					{headers.map((header) => (
						<th
							key={`${header}_skillsPerTags_th`}
							className="discreetBorder"
						>
							<div
								key={`${header}_skillsPerTags_th_div`}
								className="my-2"
							>
								{header}
							</div>
						</th>
					))}
				</thead>
				<tbody>
					{entries.map(([header, value]) => (
						<td
							key={`${header}_skillsPerTags_tb`}
							className="discreetBorder"
						>
							<div
								key={`${header}_skillsPerTags_tb_div`}
								className="my-2"
							>
								{value}
							</div>
						</td>
					))}
				</tbody>
			</table>
		</Row>
	);
}

function StudentSkillsValidatedPerTags({ entry }) {
	if (!entry.display_skills_per_tag) return null;
	return (
		<div style={{ padding: "0 15px" }}>
			<Card.Header>
				<BoxTitle
					className="skillTitle"
					title={`${t("number of validated skills").capfirst()} ${t(
						"by"
					)} ${t("tag")}`}
				/>
			</Card.Header>
			<Card.Body style={{ alignSelf: "center", lineHeight: "10px" }}>
				<StudentSkillsValidatedPerTagsTable
					data={entry.skills_per_tag}
				/>
			</Card.Body>
		</div>
	);
}

function StudentSkills({ entry }) {
	return Object.values(entry.skills).map((skill) => (
		<StudentSkill
			key={`${entry.login}_skill_${skill.name}`}
			login={entry.login}
			skill={skill}
		/>
	));
}

export default function TesseractExpandableComponent({ data: entry }) {
	return (
		<PerfectScrollbar style={{ padding: "0 15px" }}>
			<Row md={1} xl={1}>
				<StudentSkillsValidatedPerTags entry={entry} />
			</Row>
			<Row md={1} xl={2}>
				<StudentSkills entry={entry} />
			</Row>
		</PerfectScrollbar>
	);
}
