export default class Module {
	constructor(api) {
		this.api = api;
	}

	getModules = (offset = 0, limit = 100) => {
		const params = { offset, limit };
		return this.api.processRequest("GET", "/modules", { params });
	};

	getAllModules = (offset = 0, limit = 100) => {
		const params = {
			offset,
			limit,
		};
		return this.api
			.processRequest("GET", "/modules", { params })
			.then((data) => data.data);
	};

	getAlertsStats = () =>
		this.api.processRequest("GET", "/modules/alerts/stats");

	getAvailableYears = () =>
		this.api.processRequest("GET", "/modules/available_years");

	getModulesPerYear = (year) =>
		this.api.processRequest("GET", `/modules/${year}`);

	getGradesStats(
		module,
		year = null,
		instances = null,
		date = null,
		percent = 0
	) {
		const params = {
			percent,
		};
		if (year) params.year = year;
		if (instances) params.instance = instances;
		if (date) params.date = date;
		return this.api.processRequest(
			"GET",
			`/modules/${module}/grades/stats`,
			{
				params,
			}
		);
	}

	getRegistrationsStats(year, module, city = null) {
		return this.api.processRequest(
			"GET",
			`/modules/${year}/${module}/registration/stats`,
			{
				params: { city },
			}
		);
	}

	getGradesStatsByCity(year, cities, module) {
		return this.api.processRequest(
			"GET",
			`/modules/${year}/${module}/cities/grades/stats`,
			{
				params: { city: cities },
			}
		);
	}

	getNonValidatedCount(city, curriculum) {
		return this.api.processRequest("GET", `/modules/non_validated/stats`, {
			params: { city, curriculum },
		});
	}
}
