import { t } from "i18next";
import { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForms from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { findCorrectFilterOption, getLastSearchParam } from "../Utils/Filters";
import { requestErrorToastManager } from "../Utils/Toasts";
import { useEvaluationsAvailableInstancesQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const didFilterRun = useRef(false);
	const [searchParams] = useSearchParams();
	const {
		filters,
		setFilterOptions,
		setSelectedOption,
		applyFilters,
		setFilterDisabled,
	} = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
		},
		module_code: {
			label: t("module code"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
			dependsOn: "year",
		},
		scale_id: {
			label: t("scale id"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
			dependsOn: "module_code",
		},
		bttf: {
			label: "BTTF",
			type: "select",
			options: ["only", "with", "without"].map((s) => ({
				label: t(s),
				value: s,
			})),
		},
	});

	const { data: fetchedInstances, errorInstances } =
		useEvaluationsAvailableInstancesQuery();

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			if (newValue === null) {
				setFilterOptions("module_code", []);
				setFilterOptions("scale_id", []);
				setFilterDisabled("module_code", true);
				setFilterDisabled("scale_id", true);
				return;
			}
			const yearInstances = fetchedInstances[newValue.value];
			if (!yearInstances) return;
			setFilterOptions(
				"module_code",
				Object.keys(yearInstances).map((module_code) => ({
					label: module_code,
					value: module_code,
				}))
			);
			setFilterDisabled("module_code", false);
			onSelectChange("module_code", null);
		} else if (filterKey === "module_code") {
			if (newValue === null) {
				setFilterOptions("scale_id", []);
				onSelectChange("scale_id", null);
				setFilterDisabled("scale_id", true);
				return;
			}
			if (!filters.year.selected) return;
			const moduleInstances =
				fetchedInstances[filters.year.selected.value][newValue.value];
			if (!moduleInstances) return;
			setFilterOptions(
				"scale_id",
				Object.keys(moduleInstances).map((scale_id) => ({
					label: scale_id,
					value: moduleInstances[scale_id],
				}))
			);
			setFilterDisabled("scale_id", false);
			onSelectChange("scale_id", null);
		}
	};

	useEffect(() => {
		if (!fetchedInstances) return;
		const yearOptions = Object.keys(fetchedInstances)
			.sort()
			.reverse()
			.map((year) => ({
				label: year,
				value: year,
			}));
		setFilterOptions("year", yearOptions);
		const queryYear = !didFilterRun.current
			? getLastSearchParam(searchParams, "year")
			: null;
		const selectedYear =
			findCorrectFilterOption(yearOptions, queryYear) || yearOptions[0];
		if (!selectedYear) {
			didFilterRun.current = true;
			return;
		}
		onSelectChange("year", selectedYear);
		const moduleOptions = Object.keys(
			fetchedInstances[selectedYear.value]
		).map((module_code) => ({
			label: module_code,
			value: module_code,
		}));
		setFilterOptions("module_code", moduleOptions);
		const queryModule = !didFilterRun.current
			? getLastSearchParam(searchParams, "module_code")
			: null;
		const selectedModule = findCorrectFilterOption(
			moduleOptions,
			queryModule
		);
		if (!selectedModule) {
			didFilterRun.current = true;
			return;
		}
		onSelectChange("module_code", selectedModule);
		const scaleOptions = Object.keys(
			fetchedInstances[selectedYear.value][selectedModule.value]
		).map((scale_id) => ({
			label: scale_id,
			value: fetchedInstances[selectedYear.value][selectedModule.value][
				scale_id
			],
		}));
		setFilterOptions("scale_id", scaleOptions);
		const queryScale = !didFilterRun.current
			? getLastSearchParam(searchParams, "scale_id")
			: null;
		const selectedScale = findCorrectFilterOption(scaleOptions, queryScale);
		if (!selectedScale) {
			didFilterRun.current = true;
			return;
		}
		onSelectChange("scale_id", selectedScale);
	}, [fetchedInstances]);

	requestErrorToastManager("Evaluations: Filters", errorInstances);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForms filters={filters} onChange={onSelectChange} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={
						!(
							filters.year.selected &&
							filters.module_code.selected &&
							filters.scale_id.selected
						)
					}
				/>
			</Card.Footer>
		</Box>
	);
}
