import { t } from "../../../Config/i18n";

export default function RedirectButton({ student }) {
	const buttonInfo =
		student?.additional_infos?.gandalf_id !== undefined
			? {
					link: `https://gandalf.epitech.eu/local/graph/view.php?userid=${student.additional_infos.gandalf_id}`,
					title: "gandalf profile",
				}
			: {
					link: `https://intra.epitech.eu/user/${student.login}`,
					title: "intranet profile",
				};

	return (
		<a
			href={buttonInfo.link}
			target="_blank"
			className="btn btn-block btn-primary text-white"
			rel="noreferrer"
		>
			{t(buttonInfo.title).capfirst()}
		</a>
	);
}
