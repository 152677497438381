export function partNameToBackendJsonFormat(string) {
	const s = string
		.trim()
		.replace(/é|è|ê/g, "e")
		.replace(/[\W_]+/g, "-");
	if (s.endsWith("-")) {
		return s.slice(0, -1);
	}
	return s;
}

export function getPartMark(title, part_title, result) {
	for (let i = 0; i < result.length; ++i)
		if (result[i].title === title)
			for (let j = 0; j < result[i].parts.length; ++j)
				if (result[i].parts[j].title === part_title)
					return result[i].parts[j].mark;
	return "-";
}
