export const GRAPH_LINE_COLORS = [
	"rgb(255, 87, 34",
	"rgb(33, 150, 243",
	"rgb(76, 175, 80",
	"rgb(255, 235, 59",
	"rgb(156, 39, 176",
	"rgb(233, 30, 99",
	"rgb(63, 81, 181",
	"rgb(255, 152, 0",
	"rgb(0, 188, 212",
	"rgb(139, 195, 74",
	"rgb(244, 67, 54",
	"rgb(103, 58, 183",
	"rgb(255, 193, 7",
	"rgb(121, 85, 72",
	"rgb(0, 150, 136",
	"rgb(205, 220, 57",
	"rgb(158, 158, 158",
	"rgb(255, 184, 184",
];
