import { useEffect, useState } from "react";

import {
	formatFileSize,
	lightenDarkenColor,
	useCSVReader,
} from "react-papaparse";

import { Button, FormControl, InputGroup } from "react-bootstrap";
import { t } from "../../Config/i18n";
import LoadingComponent from "./Loading";
import { UPLOAD_STATUS } from "./Utils";

const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
	DEFAULT_REMOVE_HOVER_COLOR,
	40
);

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const GREY_DIM = "#686868";

const styles = {
	zone: {
		alignItems: "center",
		border: `2px dashed ${GREY}`,
		borderRadius: 20,
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		padding: 20,
	},
	file: {
		background: "linear-gradient(to bottom, #EEE, #DDD)",
		borderRadius: 20,
		display: "flex",
		height: 120,
		width: 120,
		position: "relative",
		zIndex: 10,
		flexDirection: "column",
		justifyContent: "center",
	},
	info: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		paddingLeft: 10,
		paddingRight: 10,
	},
	size: {
		backgroundColor: GREY_LIGHT,
		borderRadius: 3,
		marginBottom: "0.5em",
		justifyContent: "center",
		display: "flex",
	},
	name: {
		backgroundColor: GREY_LIGHT,
		borderRadius: 3,
		fontSize: 12,
		marginBottom: "0.5em",
	},
	progressBar: {
		bottom: 14,
		position: "absolute",
		width: "100%",
		paddingLeft: 10,
		paddingRight: 10,
	},
	zoneHover: {
		borderColor: GREY_DIM,
	},
	default: {
		borderColor: GREY,
	},
	remove: {
		height: 23,
		position: "absolute",
		right: 6,
		top: 6,
		width: 23,
	},
};

function Example({ expectedFormat, allFieldsRequired, example }) {
	return (
		<h6 style={{ textAlign: "center" }}>
			{t("expected format").capfirst()}:<br />
			{expectedFormat}{" "}
			{allFieldsRequired
				? `(${t("all fields are required").capfirst()})`
				: ""}
			<br />
			{t("example").capfirst()}: {example}
		</h6>
	);
}

export default function UploadCSV({
	onUploadSuccess,
	errorMessage,
	uploadStatus,
	resetStatus,
	expectedFormat,
	allFieldsRequired,
	example,
	buttonLabel = t("upload").capfirst(),
	disabled = false,
	children,
}) {
	const { CSVReader } = useCSVReader();
	const [zoneHover, setZoneHover] = useState(false);
	const [removeHoverColor, setRemoveHoverColor] = useState(
		DEFAULT_REMOVE_HOVER_COLOR
	);

	const [isUploading, setIsUploading] = useState(false);

	const [uploadFile, setUploadFile] = useState(null);

	const [delimiter, setDelimiter] = useState(";");

	useEffect(() => {
		if (
			uploadStatus === UPLOAD_STATUS.success ||
			uploadStatus === UPLOAD_STATUS.error
		) {
			setIsUploading(false);
		}
	}, [uploadStatus]);

	const [errorMessagePrefix, errorMessageDetails] = errorMessage
		? errorMessage.split(": ")
		: ["", ""];

	return (
		<div>
			<Example
				expectedFormat={expectedFormat}
				allFieldsRequired={allFieldsRequired}
				example={example}
			/>
			<div className="mt-3 mb-3 d-flex justify-content-center flex-column flex-xl-row">
				<InputGroup style={{ width: "150px" }}>
					<InputGroup.Prepend>
						<InputGroup.Text>
							{t("delimiter").capfirst()}
						</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl
						disabled={disabled}
						type="text"
						className="text-center"
						value={delimiter}
						onChange={(event) => setDelimiter(event.target.value)}
					/>
				</InputGroup>
				{children && (
					<div className="mt-3 mt-xl-0 ml-0 ml-xl-3">{children}</div>
				)}
			</div>
			<CSVReader
				disabled={disabled}
				onUploadAccepted={(file) => {
					resetStatus();
					setUploadFile(file);
				}}
				config={{
					skipEmptyLines: "greedy",
					delimiter,
					header: true,
				}}
				onDragOver={(event) => {
					event.preventDefault();
					setZoneHover(true);
				}}
				onDragLeave={(event) => {
					event.preventDefault();
					setZoneHover(false);
				}}
			>
				{({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
					Remove,
				}) => (
					<div
						{...getRootProps()}
						style={{
							...styles.zone,
							...(zoneHover && styles.zoneHover),
						}}
					>
						{acceptedFile ? (
							<div style={styles.file}>
								<div style={styles.info}>
									<span style={styles.size}>
										{formatFileSize(acceptedFile.size)}
									</span>
									<span style={styles.name}>
										{acceptedFile.name}
									</span>
								</div>
								<div style={styles.progressBar}>
									<ProgressBar />
								</div>
								<div
									className="Remove"
									{...getRemoveFileProps()}
									style={styles.remove}
									onMouseOver={(event) => {
										event.preventDefault();
										setRemoveHoverColor(
											REMOVE_HOVER_COLOR_LIGHT
										);
									}}
									onFocus={(event) => {
										event.preventDefault();
										setRemoveHoverColor(
											REMOVE_HOVER_COLOR_LIGHT
										);
									}}
									onMouseOut={(event) => {
										event.preventDefault();
										setRemoveHoverColor(
											DEFAULT_REMOVE_HOVER_COLOR
										);
									}}
									onBlur={(event) => {
										event.preventDefault();
										setRemoveHoverColor(
											DEFAULT_REMOVE_HOVER_COLOR
										);
									}}
									onClick={(event) => {
										getRemoveFileProps().onClick(event);
										resetStatus();
										setUploadFile(null);
									}}
									role="presentation"
								>
									<Remove color={removeHoverColor} />
								</div>
							</div>
						) : (
							t("drop CSV file here or click to upload")
						)}
					</div>
				)}
			</CSVReader>
			<Button
				block
				className="mt-3"
				style={{ "white-space": "pre-line" }}
				variant={uploadStatus.colorVariant}
				disabled={!uploadFile}
				onClick={() => {
					if (uploadFile) {
						setIsUploading(true);
						onUploadSuccess(uploadFile);
					}
				}}
			>
				{(isUploading && <LoadingComponent />) || buttonLabel}
			</Button>
			{errorMessage && (
				<div className="mt-3 p-2 rounded bg-danger">
					<p>{t("an error occured during import").capfirst()}</p>
					<span>
						{t(errorMessagePrefix, {
							details: errorMessageDetails,
						})}
					</span>
				</div>
			)}
		</div>
	);
}
