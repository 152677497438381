import { t } from "i18next";
import { DateTime } from "luxon";
import { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForms from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear } from "../Utils/Utils";
import {
	useInstancesPerModule,
	useModuleAvailableYears,
	useModulesPerYear,
} from "./Queries";

export default function Filters({ onFilter }) {
	const currentDate = DateTime.now();
	const currentYear = getCurrentAcademicYear();
	const { data: available_years } = useModuleAvailableYears();
	const didInstanceFilterRun = useRef(false);
	const [searchParams] = useSearchParams();
	const {
		filters,
		setFilterOptions,
		setSelectedOption,
		applyFilters,
		setFilterDisabled,
	} = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: {
				label: currentYear,
				value: currentYear,
			},
		},
		module: {
			label: t("module"),
			type: "select",
			multiple: false,
			options: [],
			default: null,
			clearable: false,
			dependsOn: "year",
		},
		instances: {
			label: t("instances"),
			type: "select",
			multiple: true,
			options: [],
			default: [],
			disabled: true,
			dependsOn: "module",
		},
		date: {
			label: t("date"),
			type: "date",
			min: "2014-09-01",
			max: currentDate.toISODate(),
			default: currentDate,
		},
	});
	const { data: modulesPerYear } = useModulesPerYear(
		filters.year.selected.value
	);

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInstancesPerModule(
			filters.year.selected?.value,
			filters.module.selected?.value
		);

	if (filters.module.selected?.value && !isFetchingNextPage && hasNextPage)
		fetchNextPage();

	const requestData = data?.pages.flatMap((page) => page.data.results) ?? [];
	const instancesPerModule = requestData
		.map((instance) => instance.code_instance)
		.sort()
		.toFilterOptions();

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			onSelectChange("module", null);
		} else if (filterKey === "module") {
			if (newValue === null) {
				setFilterOptions("instances", []);
				onSelectChange("instances", []);
				setFilterDisabled("instances", true);
				return;
			}
			onSelectChange("instances", []);
			setFilterDisabled("instances", false);
		}
	};

	useEffect(() => {
		if (!available_years) return;
		setFilterOptions("year", available_years);
	}, [available_years]);

	useEffect(() => {
		if (!modulesPerYear) return;
		setFilterOptions("module", modulesPerYear);
	}, [modulesPerYear]);

	useEffect(() => {
		if (!instancesPerModule) return;
		setFilterOptions("instances", instancesPerModule, true);
		const queryInstances = !didInstanceFilterRun.current
			? searchParams.getAll("instances")
			: [];
		const selectedInstances = instancesPerModule.filter((option) =>
			queryInstances.includes(String(option.value))
		);
		didInstanceFilterRun.current = true;
		if (selectedInstances.length > 0)
			onSelectChange("instances", selectedInstances);
	}, [data]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForms filters={filters} onChange={onSelectChange} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={
						!(filters.year?.selected && filters.module?.selected)
					}
				/>
			</Card.Footer>
		</Box>
	);
}
