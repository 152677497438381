import { ActionIcon, Tooltip } from "@mantine/core";
import { IconFileDownload } from "@tabler/icons-react";
import { useState } from "react";
import { t } from "../../Config/i18n";
import { defaultOnExportFunction } from "../Sauron/DataTableExportButton";
import Popup from "../Sauron/Popup";

export default function TesseractExportButton({
	data,
	exportConfig,
	titleCSV,
	isDraft,
}) {
	const [isPopupShowing, setIsPopupShowing] = useState(false);

	return (
		<Tooltip label={t("export to CSV").capfirst()} withinPortal>
			<span>
				<ActionIcon
					aria-label={t("export to CSV").capfirst()}
					color="gray"
					size="lg"
					variant="subtle"
					onClick={() => {
						if (isDraft) setIsPopupShowing(true);
						else
							defaultOnExportFunction(
								data,
								exportConfig,
								titleCSV
							);
					}}
				>
					<IconFileDownload />
				</ActionIcon>
				{isPopupShowing ? (
					<Popup
						titleText={t(
							"[Draft] Warning! These results are not final and must not be uploaded!"
						).capfirst()}
						buttonText={t("all right!").capfirst()}
						onClose={() => {
							setIsPopupShowing(false);
							defaultOnExportFunction(
								data,
								exportConfig,
								titleCSV
							);
						}}
					/>
				) : null}
			</span>
		</Tooltip>
	);
}
