import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useTesseractModulesQuery = (year) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tesseract", "filters_options", "modules", year],
		queryFn: () => scraper.tesseract.getAvailableModules(year),
		select: (data) =>
			data.data
				.map((module) => module.module_code)
				.sort()
				.toFilterOptions(),
	});
};

export const useTesseractInstancesQuery = (year, module_code) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"tesseract",
			"filters_options",
			"instances",
			year,
			module_code,
		],
		queryFn: ({ pageParam }) =>
			scraper.instance.getInstances(module_code, year, pageParam),
		initialPageParam: 0,
		getNextPageParam,
		enabled: !!module_code,
	});
};

export const useValidationLogsQuery = (year, module_code, isEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tesseract", "validation_logs", year, module_code],
		queryFn: () =>
			scraper.tesseract.getModuleValidationLogs(year, module_code),
		select: (data) => data.data.log_elements,
		enabled: isEnabled,
	});
};

export const useSkillValidationStatsQuery = (
	year,
	module_code,
	instance_code,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"tesseract",
			"stats",
			"skill_validation",
			year,
			module_code,
			instance_code,
		],
		queryFn: () =>
			scraper.tesseract.getSkillValidationStats(
				year,
				module_code,
				instance_code
			),
		select: (data) => data.data,
		enabled: areRequestEnabled,
	});
};

export const useModuleValidationStatsQuery = (
	year,
	module_code,
	instance_code,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"tesseract",
			"stats",
			"unit_validation",
			year,
			module_code,
			instance_code,
		],
		queryFn: () =>
			scraper.tesseract.getModuleValidationStats(
				year,
				module_code,
				instance_code
			),
		select: (data) => data.data,
		enabled: areRequestEnabled,
	});
};

export const useConfigurationQuery = (year, module_code, areRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tesseract", "configurations", year, module_code],
		queryFn: () => scraper.tesseract.getModuleSkills(year, module_code),
		select: (data) => data.data,
		enabled: areRequestEnabled,
	});
};

function hasStudentAttemptedBTTF(student) {
	return student.skills.some((skill) => skill.bttf !== undefined);
}

function parseSkillsPerTags(skills, configuration) {
	let shouldDisplay = false;
	if (!configuration) return [shouldDisplay, {}];
	const skillsPerTags = skills.reduce(
		(acc, skill) => {
			if (skill.delivery?.validated === undefined) return acc;
			const configurationSkill = configuration.skills.find(
				(cfgSkill) => cfgSkill.name === skill.name
			);
			if (configurationSkill.flag_only) return acc;
			shouldDisplay = true;
			if (skill.delivery.validated) acc.Total += 1;
			if (skill.tags === undefined) return acc;
			skill.tags.forEach((tag) => {
				if (acc[tag] === undefined) acc[tag] = 0;
				if (skill.delivery.validated) acc[tag] += 1;
			});
			return acc;
		},
		{ Total: 0 }
	);
	return [shouldDisplay, skillsPerTags];
}

export const useStudentsValidationsQuery = (
	year,
	module_code,
	instance_code,
	areRequestEnabled,
	configuration
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"tesseract",
			"table_data",
			"validations",
			year,
			module_code,
			instance_code,
			configuration,
		],
		queryFn: ({ pageParam }) =>
			scraper.tesseract.getModuleValidations(
				pageParam,
				year,
				module_code,
				instance_code
			),
		initialPageParam: 0,
		getNextPageParam,
		select: (data) => {
			const ret = { ...data };
			ret.pages = data.pages.map((page) =>
				page.data.results.map((apiRow, idx) => {
					const [displaySkillPerTag, skillsPerTag] =
						parseSkillsPerTags(apiRow.skills, configuration);
					return {
						id: idx,
						login: apiRow.login,
						instance: apiRow.instance,
						grade_final: apiRow.grades.final,
						grade_delivery: apiRow.grades.delivery,
						grade_bttf: hasStudentAttemptedBTTF(apiRow)
							? apiRow.grades.bttf
							: "-",
						grade_provisional: apiRow.grades.provisional,
						flags: apiRow.flags,
						skills: apiRow.skills,
						display_skills_per_tag: displaySkillPerTag,
						skills_per_tag: skillsPerTag,
						has_attempted_bttf: hasStudentAttemptedBTTF(apiRow),
					};
				})
			);
			return ret;
		},
		enabled: areRequestEnabled,
	});
};
