import { t } from "../../../Config/i18n";

function Chip({ color, text }) {
	return (
		<div
			style={{
				border: "solid 1.5px",
				borderRadius: "16px",
				padding: "2px 10px",
				paddingBottom: "2px",
				fontWeight: 700,
				color,
			}}
		>
			{text}
		</div>
	);
}

function NotApplicable() {
	return <Chip color="#bdc3c7" text="N/A" />;
}

function Transcript({ language, ok, login, documents }) {
	const color = ok ? "#2ecc71" : "#c0392b";
	const text = `${language}: ${ok ? "OK" : "KO"}`;
	return (
		<div style={{ margin: "5px" }}>
			<a
				href={
					ok
						? `https://intra.epitech.eu/user/${login}/document/${documents[0]}`
						: null
				}
			>
				<Chip color={color} text={text} />
			</a>
		</div>
	);
}

export default function Semester({ semester, login }) {
	if (!semester) return <NotApplicable />;
	const frenchOK = semester.fr.length !== 0;
	const englishOK = semester.en.length !== 0;
	return (
		<div>
			<Transcript
				language={t("french").capfirst()}
				ok={frenchOK}
				documents={semester.fr}
				login={login}
			/>
			<Transcript
				language={t("english").capfirst()}
				ok={englishOK}
				documents={semester.en}
				login={login}
			/>
		</div>
	);
}
