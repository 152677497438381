import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box } from "../AdminLTE3/Box";
import { FilterCheckbox, FilterSelect } from "../Sauron/FiltersForm";
import SearchBar from "../Sauron/SearchBar";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear } from "../Utils/Utils";

export default function Filters({ onFilter, yearOptions }) {
	const currentYear = getCurrentAcademicYear();
	const { filters, setSelectedOption, applyFilters } = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			options: yearOptions,
			default: { label: currentYear, value: currentYear },
			clearable: false,
		},
		errors: {
			label: t("configurations with problems only"),
			type: "bool",
			default: false,
		},
		search: {
			label: t("curriculum"),
			type: "search",
			default: "",
		},
	});

	useEffect(() => {
		applyFilters(onFilter);
	}, [filters]);

	return (
		<Box className="p-3 w-100">
			<Row>
				<Col lg={4}>
					<FilterSelect
						name="year"
						filter={filters.year}
						onChange={setSelectedOption}
						title={false}
					/>
				</Col>
				<Col lg={4}>
					<FilterCheckbox
						name="errors"
						filter={filters.errors}
						onChange={setSelectedOption}
					/>
				</Col>
				<Col lg={4}>
					<SearchBar
						value={filters.search.selected}
						onChange={(event) =>
							setSelectedOption("search", event.target.value)
						}
					/>
				</Col>
			</Row>
		</Box>
	);
}
