import { t } from "../../Config/i18n";
import { degrade } from "./Utils";

export function formatDataForAlertsCharts(requestData, cities, promotions) {
	if (!requestData) return null;

	const alertsByCity = [];
	for (let i = 0; i < cities.length; i++) {
		const alertsSum = {};
		for (let j = 0; j < promotions.length; j++) {
			if (!requestData[j + i * promotions.length]) continue;
			Object.keys(requestData[j + i * promotions.length]).forEach(
				(alertNb) => {
					alertsSum[alertNb] =
						alertNb in alertsSum
							? alertsSum[alertNb] +
								requestData[j + i * promotions.length][alertNb]
							: requestData[j + i * promotions.length][alertNb];
				}
			);
		}
		alertsByCity.push(alertsSum);
	}
	const numberOfAlerts = Object.keys(alertsByCity[0]).length;
	const COLORS = Array(numberOfAlerts)
		.fill(0)
		.map((_, idx) => degrade(numberOfAlerts - 1, idx));
	return {
		datasets: [...Array(numberOfAlerts).keys()].map((alertKey) => ({
			label: `${t("alertWithCount", { count: alertKey })}`,
			data: alertsByCity.map((alertsForCity) =>
				`${alertKey}` in alertsForCity
					? alertsForCity[`${alertKey}`]
					: 0
			),
			backgroundColor: COLORS[alertKey],
		})),
		labels: cities.map((location) => location.label),
	};
}

export function formatDataForSuccessCharts(requestData, alertLabels, city) {
	if (!requestData) return null;
	const dataToTreat =
		Object.keys(requestData).length === 1
			? requestData.France
			: requestData[city?.label];
	const finalStats = {
		OK: [],
		KO: [],
		in_progress: [],
	};
	formatDataForSuccessCharts;
	Object.values(dataToTreat).forEach((value) => {
		finalStats.OK.push(value.OK);
		finalStats.KO.push(value.KO);
		finalStats.in_progress.push(value.in_progress);
	});
	return {
		labels: Object.keys(dataToTreat).map((label) =>
			alertLabels[label]?.capfirst()
		),
		datasets: [
			{
				label: "OK",
				data: finalStats.OK,
				backgroundColor: "rgb(26, 188, 156)",
			},
			{
				label: t("in progress").capfirst(),
				data: finalStats.in_progress,
				backgroundColor: "rgb(116, 185, 255)",
			},
			{
				label: "KO",
				data: finalStats.KO,
				backgroundColor: "rgb(231, 76, 60)",
			},
		],
	};
}
