import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Settings } from "luxon";
import capitalize from "capitalize";
import { initReactI18next, useTranslation } from "react-i18next";

import { Config } from "./Config";
import en from "../../locales/en.json";
import fr from "../../locales/fr.json";

const resources = {
	en,
	fr,
};

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en",
		debug: Config.debug,
		detection: {
			caches: [],
		},
		interpolation: {
			escapeValue: false,
		},
	})
	.then(() => {
		[Settings.defaultLocale] = i18next.language.split("-");
	});

Object.defineProperty(String.prototype, "title", {
	value() {
		return capitalize.words(this);
	},
});

Object.defineProperty(String.prototype, "capfirst", {
	value(preserve, opts) {
		let effective_opts = {};
		if (opts) {
			effective_opts = { ...opts };
		}
		effective_opts.preserve = preserve !== undefined ? preserve : true;
		return capitalize(this, effective_opts);
	},
});

let t;
let i18n;

export function GlobalTranslation() {
	const object = useTranslation();
	t = object.t;
	i18n = object.i18n;
	return null;
}

export { t, i18n };
export default i18next;
