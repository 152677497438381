import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import RoadblocksCards from "./Cards";
import RoadblocksDoughnut from "./Doughtnut";

export default function Roadblocks({ roadblocksQuery, roadblockConfigQuery }) {
	const { data: roadblocks, isFetching } = roadblocksQuery;
	const { data: roadblockConfig } = roadblockConfigQuery;

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!roadblocks) return <NoDataAvailable />;

	return (
		<div>
			<RoadblocksCards
				roadblocks={roadblocks.roadblocks}
				roadblockConfig={roadblockConfig}
			/>
			<RoadblocksDoughnut roadblocks={roadblocks.roadblocks} />
		</div>
	);
}
