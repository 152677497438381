import { ActionIcon, Tooltip } from "@mantine/core";
import { IconFileUpload } from "@tabler/icons-react";
import { useState } from "react";
import { t } from "../../Config/i18n";
import UploadCSVModal from "../Utils/UploadCSVModal";
import { UPLOAD_STATUS } from "../Utils/Utils";
import { useCSVIntuituPersonaeMutation } from "./Queries";

export default function BulkImportButton() {
	const [isModalShowing, setIsModalShowing] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.pending);
	const resetStatus = () => {
		setErrorMessage("");
		setUploadStatus(UPLOAD_STATUS.pending);
	};
	const mutation = useCSVIntuituPersonaeMutation(
		UPLOAD_STATUS,
		setErrorMessage,
		setUploadStatus
	);

	return (
		<>
			<Tooltip label={t("bulk import").capfirst()} withinPortal>
				<ActionIcon
					aria-label={t("bulk import").capfirst()}
					color="gray"
					size="lg"
					variant="subtle"
					onClick={() => {
						setIsModalShowing(true);
					}}
				>
					<IconFileUpload />
				</ActionIcon>
			</Tooltip>
			<UploadCSVModal
				show={isModalShowing}
				onHide={() => {
					setIsModalShowing(false);
					setErrorMessage("");
					setUploadStatus(UPLOAD_STATUS.pending);
				}}
				onUploadSuccess={mutation.mutate}
				uploadStatus={uploadStatus}
				errorMessage={errorMessage}
				resetStatus={resetStatus}
				expectedFormat="login;adm_status;pedago_status;comment"
				allFieldsRequired
				example="name.surname@epitech.eu;100;20;all good"
			/>
		</>
	);
}
