import { DateTime } from "luxon";
import { Line } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import { useProjectStatsGlobalQuery } from "./Queries";

const options = {
	maintainAspectRatio: false,
	scales: {
		y: {
			stacked: false,
			min: 0,
			ticks: {
				precision: 0,
			},
		},
	},
	tooltips: {
		callbacks: {
			label: (item, ctx) =>
				`${ctx.datasets[item.datasetIndex].label}: ${item.yLabel}`,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
	},
};

function Alert({ data }) {
	if (!data) return null;
	const labels = data.weeks.map((entry) =>
		DateTime.fromISO(entry.week).toLocaleString({
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		})
	);
	const graphLinesData = {
		labels,
		datasets: [
			{
				label: t("commits").capfirst(),
				data: data.weeks.map((entry) => entry.c),
				backgroundColor: "rgba(54, 162, 235, 0.2)",
				borderColor: "rgba(54, 162, 235, 1)",
				fill: true,
				tension: 0.3,
			},
		],
	};

	return <Line data={graphLinesData} options={options} />;
}

export default function ProjectsStats({
	year,
	code_module,
	slug,
	project_instance,
}) {
	const { data, error, isFetching } = useProjectStatsGlobalQuery(
		year,
		code_module,
		slug,
		project_instance
	);
	requestErrorToastManager("Projects: Unable to fetch stats", error);
	return (
		<SauronChartsContainer
			title={t("groups commits overview").capfirst()}
			isLoading={isFetching}
		>
			<Alert data={data} />
		</SauronChartsContainer>
	);
}
