import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

export default function RadioFilters({ options, value, onChange }) {
	return (
		<ToggleButtonGroup
			type="radio"
			name="radio"
			size="sm"
			className="float-right"
			value={value}
			onChange={onChange}
		>
			{options.map((option, idx) => (
				<ToggleButton
					key={idx}
					id={`stats-delivery-radio-${idx}`}
					variant="outline-primary"
					value={option.value}
				>
					{option.label}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
}
