import { t } from "i18next";
import { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { findCorrectFilterOption, getLastSearchParam } from "../Utils/Filters";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import {
	useStudentPresencesModulesInstancesFiltersQuery,
	useStudentPresencesYearFiltersQuery,
} from "./Queries";

export default function Filters({ onFilter }) {
	const didModuleFilterRun = useRef(false);
	const currentYear = getCurrentAcademicYear();
	const defaultYearOptions = nValues(2006, currentYear - 2006 + 1)
		.reverse()
		.toFilterOptions();
	const [searchParams] = useSearchParams();
	const {
		filters,
		setFilterOptions,
		setSelectedOption,
		applyFilters,
		setFilterDisabled,
	} = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			clearable: false,
			options: defaultYearOptions,
			default: { label: currentYear, value: currentYear },
		},
		module_code: {
			label: t("module code"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
			dependsOn: "year",
		},
		instance_code: {
			label: t("instance"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
			disabled: true,
			dependsOn: "module_code",
		},
	});
	const { data: fetchedYears, errorYears } =
		useStudentPresencesYearFiltersQuery();

	const { data: fetchedModulesInstances, errorModulesInstances } =
		useStudentPresencesModulesInstancesFiltersQuery(
			filters.year.selected.value
		);

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			onSelectChange("module_code", null);
		} else if (filterKey === "module_code") {
			if (newValue === null) {
				setFilterOptions("instance_code", []);
				onSelectChange("instance_code", null);
				return;
			}
			const instanceOptions = fetchedModulesInstances[newValue.value]
				.sort()
				.toFilterOptions();
			onSelectChange("instance_code", null);
			setFilterDisabled("instance_code", false);
			setFilterOptions("instance_code", instanceOptions, true);
		} else if (filterKey === "instance_code" && newValue === null) {
			setFilterDisabled("instance_code", true);
		}
	};

	useEffect(() => {
		if (!fetchedYears) return;
		const yearOptions = fetchedYears.sort().reverse().toFilterOptions();
		setFilterOptions("year", yearOptions);
		if (
			!findCorrectFilterOption(yearOptions, filters.year.selected.value)
		) {
			setSelectedOption("year", yearOptions[0]);
		}
	}, [fetchedYears]);

	useEffect(() => {
		if (!fetchedModulesInstances) return;
		const moduleOpts = Object.keys(fetchedModulesInstances)
			.sort()
			.toFilterOptions();
		setFilterOptions("module_code", moduleOpts);
		const queryModule = !didModuleFilterRun.current
			? getLastSearchParam(searchParams, "module_code")
			: null;
		const selectedModule = findCorrectFilterOption(moduleOpts, queryModule);
		if (!selectedModule) {
			didModuleFilterRun.current = true;
			return;
		}
		onSelectChange("module_code", selectedModule);
		const instancesOpts = fetchedModulesInstances[selectedModule.value]
			.sort()
			.toFilterOptions();
		setFilterOptions("instance_code", instancesOpts);
		const queryInstance = !didModuleFilterRun.current
			? getLastSearchParam(searchParams, "instance_code")
			: null;
		const selectedInstance = findCorrectFilterOption(
			instancesOpts,
			queryInstance
		);
		didModuleFilterRun.current = true;
		if (!selectedInstance) return;
		onSelectChange("instance_code", selectedInstance);
	}, [fetchedModulesInstances]);

	requestErrorToastManager("Student Presence: Year Filter", errorYears);
	requestErrorToastManager(
		"Student Presence: Modules/Instances Filter",
		errorModulesInstances
	);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={onSelectChange} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={
						!(
							filters.year.selected &&
							filters.module_code.selected &&
							filters.instance_code.selected
						)
					}
				/>
			</Card.Footer>
		</Box>
	);
}
