import { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";

export function Comment({ comment }) {
	return comment;
}

export function CommentEditable({ entry, editingRow, setEditingRows }) {
	const setCommentRow = ({ target: option }) => {
		setEditingRows((prev) => ({
			...prev,
			[entry.id]: {
				...prev[entry.id],
				comment: option.value,
			},
		}));
	};

	if (!editingRow) return null;

	const [comment, setComment] = useState(editingRow.comment);

	return (
		<InputGroup>
			<FormControl
				type="text"
				onChange={(event) => {
					setComment(event.target.value);
					setCommentRow(event);
				}}
				value={comment}
			/>
		</InputGroup>
	);
}
