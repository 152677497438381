import { t } from "../../Config/i18n";
import NavTabs from "../AdminLTE3/NavTabs";
import TokenDataTable from "./TokenDataTable";

export default function DataTableContainer({
	accessTokensData,
	refreshTokensData,
	APITokens,
	isLoading,
	isFetching,
}) {
	const navitems = [
		{
			display: true,
			label: t("access tokens").capfirst(),
			component: (
				<TokenDataTable
					isFetching={isFetching}
					isLoading={isLoading}
					data={accessTokensData?.access_tokens ?? []}
					type="access"
				/>
			),
			hash: "access_tokens",
		},
		{
			display: true,
			label: t("refresh tokens").capfirst(),
			component: (
				<TokenDataTable
					isFetching={isFetching}
					isLoading={isLoading}
					data={refreshTokensData?.refresh_tokens ?? []}
					type="refresh"
				/>
			),
			hash: "refresh_tokens",
		},
		{
			display: true,
			label: t("API tokens").capfirst(),
			component: (
				<TokenDataTable
					isFetching={isFetching}
					isLoading={isLoading}
					data={APITokens ?? []}
					type="api"
				/>
			),
			hash: "api_tokens",
		},
	];

	return <NavTabs tabs={navitems} compact affectsURI />;
}
