import { t } from "../../Config/i18n";
import LoginRow from "../Utils/LoginRow";
import { compare } from "../Utils/Utils";
import CommonDatatableContainer from "./CommonDatatableContainer";
import { ALERTS, ALERT_LABELS, internshipAlert } from "./InternshipAlertUtils";
import { useInternshipsAlerts } from "./Queries";

export default function StudentDatatableContainer({
	cities,
	curriculums,
	promotions,
	full_time,
	alertsOnly,
	areRequestEnabled,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		isLoading,
		error,
	} = useInternshipsAlerts(
		cities.extract("value"),
		curriculums,
		promotions,
		full_time,
		areRequestEnabled
	);

	const reqData = data?.pages.flatMap((page) => page.data.results) ?? [];

	const filteredRequestData = reqData.filter((row) => {
		if (alertsOnly && (row.alert === "confirmed" || row.alert === ""))
			return false;
		return true;
	});

	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();

	const student_columns = [
		{
			accessorKey: "alert",
			header: t("alert").capfirst(),
			Cell: ({ row }) => {
				const spec = ALERT_LABELS[row.original.alert];
				return internshipAlert(spec, row.original.login);
			},
			sortingFn: (a, b) =>
				-compare(
					ALERTS.indexOf(b.original.alert),
					ALERTS.indexOf(a.original.alert)
				),
			enableGlobalFilter: false,
		},
		{
			accessorKey: "login",
			header: t("login").capfirst(),
			size: 250,
			Cell: ({ row }) => <LoginRow login={row.original.login} />,
		},
		{
			accessorKey: "promo.promotion_year",
			header: t("promotion").capfirst(),
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "curriculum",
			header: t("curriculum").capfirst(),
			Cell: ({ row }) => {
				if (row.original.promo.subpromo)
					return `${row.original.promo.cursus.code}/${row.original.promo.subpromo}`;
				return row.original.promo.cursus.code;
			},
			enableGlobalFilter: false,
		},
		{
			accessorKey: "promo.city.name",
			header: t("city").capfirst(),
			enableGlobalFilter: false,
		},
	];

	const exportConfig = {
		login: "login",
		status: "alert",
		promotion: "promo.promotion_year",
		curriculum: (row) => `${row.promo.cursus.code}/${row.promo.subpromo}`,
		city: "promo.city.name",
	};

	return (
		<CommonDatatableContainer
			data={filteredRequestData}
			isFetching={isFetching}
			isLoading={isLoading}
			error={error}
			columns={student_columns}
			exportConfig={{
				columns: exportConfig,
				title: "internship_student_alerts",
			}}
		/>
	);
}
