import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { degrade, sortObjectByKeysWithFranceAtEnd } from "../Utils/Utils";

const ranges = ["1-5", "6-10", "11-15", "16-20", "21-24"];

const statsToRangesStats = (stats) =>
	Object.entries(stats).reduce((acc, [numberMissing, stat]) => {
		if (numberMissing === "0") {
			acc["0"] = stat;
			return acc;
		}
		const range = ranges[Math.floor((Number(numberMissing) - 1) / 5)];
		if (!(range in acc)) acc[range] = 0;
		acc[range] += stat;
		return acc;
	}, {});

const dataToRangesData = (data) =>
	Object.entries(data).reduce((acc, [element, stats]) => {
		acc[element] = statsToRangesStats(stats);
		return acc;
	}, {});

export default function MissingTranscriptsPerElements({ data }) {
	if (!data) return null;
	const rangesData = dataToRangesData(data);
	const sortedRangesData = sortObjectByKeysWithFranceAtEnd(rangesData);
	const colors = Array(ranges.length + 2)
		.fill(0)
		.map((_, idx) => degrade(ranges.length + 1, idx));
	const chartsData = {
		datasets: ["0", ...ranges].map((range, idx) => {
			const rangeValues = range.split("-");
			const maxValue =
				rangeValues.length > 1 ? rangeValues[1] : rangeValues[0];
			return {
				label: `${range} ${t("missing_transcript", {
					count: parseInt(maxValue, 10),
				})}`,
				data: Object.values(sortedRangesData).map(
					(stats) => stats[range] ?? 0
				),
				backgroundColor: colors[idx],
			};
		}, []),
		labels: Object.keys(sortedRangesData),
	};
	return <Bar data={chartsData} options={defaultBarOptions} />;
}
