import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useCurriculumsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["internshipparttime", "filters_options", "curriculums"],
		queryFn: () => scraper.getPromotions(),
		select: (data) => data.toFilterOptions(),
	});
};

export const useInternshipsStatus = (
	cities,
	curriculums,
	promotions,
	full_time
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"internshipparttime",
			"status",
			...cities,
			...curriculums,
			...promotions,
			full_time,
		],
		queryFn: () =>
			scraper.internships.getStatus(
				cities,
				curriculums,
				promotions,
				full_time
			),
	});
};

export const useContracts = (
	cities,
	promotions,
	curriculums,
	full_time,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"internshipparttime",
			"table_data",
			"contracts",
			...cities,
			...promotions,
			...curriculums,
			full_time,
		],
		queryFn: ({ pageParam = 0 }) =>
			scraper.internships.getContracts(
				pageParam,
				cities,
				promotions,
				curriculums,
				full_time
			),
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};

export const useInternshipsAlerts = (
	cities,
	promotions,
	curriculums,
	full_time,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"internshipparttime",
			"table_data",
			"internships_alerts",
			...cities,
			...promotions,
			...curriculums,
			full_time,
		],
		queryFn: ({ pageParam = 0 }) =>
			scraper.students.getInternshipsAlerts(
				pageParam,
				cities,
				promotions,
				curriculums,
				full_time
			),
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};
