import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { useTranscriptsQuery } from "../../../Queries";
import MissingTranscripts from "./Missing";
import TranscriptsTable from "./Table";

export default function Transcripts({ login }) {
	const { data: transcripts, error, isFetching } = useTranscriptsQuery(login);

	requestErrorToastManager("Student: Transcripts tab", error);

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!transcripts) return <NoDataAvailable />;

	return (
		<>
			<MissingTranscripts nbMissing={transcripts.nb_missing} />
			<TranscriptsTable
				transcripts={transcripts.transcripts}
				login={login}
			/>
		</>
	);
}
