import { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { t } from "../../Config/i18n";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import {
	useAvailableProjectInstancesQuery,
	useAvailableProjectsQuery,
	useProjectsAvailableYearQuery,
} from "../Projects/Queries";
import FiltersForms from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { findCorrectFilterOption, getLastSearchParam } from "../Utils/Filters";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";

export default function Filters({ onFilter }) {
	const didProjectFilterRun = useRef(false);
	const currentYear = getCurrentAcademicYear();
	const defaultYearOptions = nValues(2023, currentYear - 2023 + 1)
		.reverse()
		.toFilterOptions();
	const [searchParams] = useSearchParams();
	const {
		filters,
		setFilterOptions,
		setSelectedOption,
		applyFilters,
		setFilterDisabled,
	} = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			clearable: false,
			options: defaultYearOptions,
			default: { label: currentYear, value: currentYear },
		},
		project: {
			label: t("project"),
			type: "select",
			multiple: false,
			clearable: false,
			options: [],
			default: null,
			dependsOn: "year",
		},
		project_instance: {
			label: t("instance"),
			type: "select",
			multiple: false,
			clearable: false,
			disabled: true,
			options: [],
			default: null,
			dependsOn: "project",
		},
	});

	const { data: fetchedYears, errorYears } = useProjectsAvailableYearQuery();

	const {
		data: fetchedProjects,
		error: errorProjects,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useAvailableProjectsQuery(filters.year.selected.value);
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();

	const { data: fetchedInstances } = useAvailableProjectInstancesQuery(
		filters.year.selected.value,
		filters.project.selected?.value.split("__")[0],
		filters.project.selected?.value.split("__")[1]
	);

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			setFilterOptions("project", []);
			onSelectChange("project", null);
			setFilterDisabled("project", true);
		} else if (filterKey === "project") {
			if (newValue === null) {
				setFilterOptions("project_instance", []);
				onSelectChange("project_instance", null);
				setFilterDisabled("project_instance", true);
				return;
			}
			if (filters.project.selected?.value === newValue.value) return;
			filters.project.selected = newValue;
			setFilterDisabled("project_instance", false);
			onSelectChange("project_instance", null);
		} else if (filterKey === "project_instance") {
			setFilterDisabled("project_instance", newValue === null);
			onFilter({
				year: filters.year.selected.value,
				project: filters.project.selected.value,
				project_instance: newValue?.value,
			});
		}
	};

	useEffect(() => {
		if (!fetchedYears) return;
		const yearOptions = fetchedYears.sort().reverse().toFilterOptions();
		setFilterOptions("year", yearOptions);
		setSelectedOption("year", yearOptions[0]);
	}, [fetchedYears]);

	useEffect(() => {
		if (!fetchedProjects || fetchedProjects.length === 0) return;
		const projectOpts = [];

		fetchedProjects.forEach((project) => {
			const label = `[${project.code_module}] ${project.slug}`;
			const value = `${project.code_module}__${project.slug}`;
			projectOpts.push({
				label,
				value,
			});
		});

		projectOpts.sort((a, b) => a.label.localeCompare(b.label));
		setFilterOptions("project", projectOpts);
		setFilterDisabled("project", false);
		const queryProject = !didProjectFilterRun.current
			? getLastSearchParam(searchParams, "project")
			: null;
		const selectedProject = findCorrectFilterOption(
			projectOpts,
			queryProject
		);
		if (!selectedProject) {
			didProjectFilterRun.current = true;
			return;
		}
		onSelectChange("project", selectedProject);
		didProjectFilterRun.current = true;
	}, [fetchedProjects]);

	useEffect(() => {
		if (!fetchedInstances || fetchedInstances.length === 0) return;
		const instancesOpts = [];
		fetchedInstances.forEach((instance) => {
			instancesOpts.push({
				label: instance.code_instance,
				value: instance.code_instance,
			});
		});
		instancesOpts.sort((a, b) => a.label.localeCompare(b.label));
		setFilterOptions("project_instance", instancesOpts);
		setFilterDisabled("project_instance", instancesOpts.length === 0);
		const queryInstance = getLastSearchParam(
			searchParams,
			"project_instance"
		);
		const selectedInstance = findCorrectFilterOption(
			instancesOpts,
			queryInstance
		);
		if (!selectedInstance) return;
		onSelectChange("project_instance", selectedInstance);
	}, [fetchedInstances]);

	requestErrorToastManager(
		"Projects: Unable to get available years",
		errorYears
	);
	requestErrorToastManager(
		"Projects: Unable to get available projects",
		errorProjects
	);
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForms filters={filters} onChange={onSelectChange} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={
						!filters.year.selected ||
						!filters.project.selected ||
						!filters.project_instance.selected
					}
				/>
			</Card.Footer>
		</Box>
	);
}
