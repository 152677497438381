import { t } from "../../../Config/i18n";
import LoadingComponent from "../../Utils/Loading";
import { creditsOverviewSpan } from "../../Utils/Utils";
import { hasValidatedEnglish } from "../NavTabs/Tabs/Roadblocks/Cards";
import ListEntry from "./ListEntry";

function getRoadblockString({ title, roadblock, roadblocks }) {
	if (
		(title === "tepitech" || title === "englishskills") &&
		Object.prototype.hasOwnProperty.call(roadblocks, "tepitech") &&
		Object.prototype.hasOwnProperty.call(roadblocks, "englishskills") &&
		hasValidatedEnglish(roadblocks)
	)
		return <span>{creditsOverviewSpan(roadblock, true)}</span>;
	if (roadblock.is_state) {
		if (roadblock.obtained > roadblock.min_required)
			return (
				<span className="text-green">{t("acquired").capfirst()}</span>
			);
		if (roadblock.obtained + roadblock.inprogress >= roadblock.min_required)
			return (
				<span className="text-blue">{t("in progress").capfirst()}</span>
			);
		return (
			<span className="text-danger">{t("not acquired").capfirst()}</span>
		);
	}
	return <span>{creditsOverviewSpan(roadblock)}</span>;
}

const getRoadblockTooltip = (roadblock) => {
	if (!roadblock) return null;
	return `${t("acquired").capfirst()}: ${roadblock.obtained}<br />${
		roadblock.inprogress > -1
			? `${t("on going").capfirst()}: ${roadblock.inprogress}<br />`
			: ""
	}${
		roadblock.min_required > -1
			? `${t("required").capfirst()}: ${roadblock.min_required}`
			: ""
	}`;
};

export default function RoadblocksEntries({ roadblocks, isLoading }) {
	if (isLoading)
		return (
			<div className="text-center mt-3">
				<LoadingComponent />
			</div>
		);
	if (!roadblocks) return null;
	return Object.entries(roadblocks)
		.sort(([title_a], [title_b]) => {
			if (title_a === "total_credits") return -1;
			if (title_b.includes("stumper")) return -1;
			return 0;
		})
		.map(([title, roadblock]) => (
			<ListEntry
				key={title}
				label={title.replace("_", " ")}
				value={getRoadblockString({ title, roadblocks, roadblock })}
				tooltipContent={getRoadblockTooltip(roadblock)}
				tooltipId="student-overview-roadblocks-tooltip"
			/>
		));
}
