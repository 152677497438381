import { EventType } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { useEffect } from "react";
import { Config } from "../../Config/Config";
import LoadingComponent from "../Utils/Loading";

function Logout() {
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});

	useEffect(() => {
		let ignore = false;
		const idEvent = instance.addEventCallback((event) => {
			if (event.eventType === EventType.LOGOUT_SUCCESS) {
				localStorage.removeItem("@sauron_account");
				localStorage.removeItem("@msal_account");
				window.location.replace(Config.redirect_uri);
			}
		});
		if (!ignore && account && inProgress === "none") {
			instance
				.logoutRedirect({
					onRedirectNavigate: () => false,
				})
				.then(() => {})
				.catch(() => {});
		}
		return () => {
			if (idEvent) instance.removeEventCallback(idEvent);
			ignore = true;
		};
	}, []);

	return (
		<div className="text-center pt-3">
			<h3>{t("currently logging you out").capfirst()}...</h3>
			<LoadingComponent />
		</div>
	);
}

export default Logout;
