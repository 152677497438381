import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Widget({
	icon = null,
	title = "",
	subtitle = "",
	type = "success",
	size = "sm",
	color = "white",
	className = "",
	...props
}) {
	return (
		<div className={`small-box bg-${type} ${className}`} {...props}>
			<div className="inner">
				<h4>{title}</h4>
				<h5>{subtitle}</h5>
			</div>
			<div
				style={{
					position: "absolute",
					top: "50%",
					right: 0,
					WebkitTransform: "translate(-50%, -50%)",
				}}
			>
				<FontAwesomeIcon icon={icon} size={size} color={color} />
			</div>
		</div>
	);
}
