import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { getCurrentAcademicYear, setFranceToRight } from "../Utils/Utils";
import { useInternshipsStatus } from "./Queries";

function treatData(initialContent) {
	if (!initialContent) return initialContent;
	// eslint-disable-next-line no-unused-vars
	const { Global, Europe, ...content } = initialContent;
	let cities = Object.keys(content);
	if ("france" in content && content.france === false) {
		cities = cities.filter((e) => e !== "France");
	}
	let data = {
		labels: cities,
		datasets: [
			{
				label: t("no contracts").capfirst(),
				backgroundColor: "rgb(231, 76, 60)",
				data: cities.map((key) => content[key].missing || 0),
			},
			{
				label: t("waiting for validation").capfirst(),
				backgroundColor: "rgb(230, 126, 34)",
				data: cities.map((key) => content[key].waiting || 0),
			},
			{
				label: t("waiting for signatures").capfirst(),
				backgroundColor: "#F5E829",
				data: cities.map(
					(key) =>
						content[key].validated_adm +
							content[key].validated_pedago || 0
				),
			},
			{
				label: t("not marked yet").capfirst(),
				backgroundColor: "#F2C321",
				data: cities.map((key) => content[key].student_not_marked || 0),
			},
			{
				label: "Ok",
				backgroundColor: "rgb(26, 188, 156)",
				data: cities.map((key) => content[key].confirmed || 0),
			},
		],
	};
	data = setFranceToRight(data);
	return data;
}

export default function InternshipAlertChart({
	cities,
	curriculums,
	promotions,
	type,
}) {
	const currentYear = getCurrentAcademicYear();
	const full_time = type.value === "Full-time";
	const promos =
		promotions && promotions.length > 0
			? promotions
			: [
					currentYear + 1,
					currentYear + 2,
					currentYear + 3,
					currentYear + 4,
					currentYear + 5,
				];

	const { data, isFetching, error } = useInternshipsStatus(
		cities.extract("value"),
		curriculums,
		promos,
		full_time
	);
	const requestData = treatData(data?.data);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Internships and Part time Alerts Chart: ${t(
				"An error had occured"
			)}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("internships status").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
		>
			{requestData ? (
				<Bar data={requestData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
