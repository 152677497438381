import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n.jsx";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer.jsx";
import { defaultBarOptions } from "../Utils/DefaultConst.jsx";
import { formatDataForAlertsCharts } from "../Utils/FormatDataForCharts.jsx";
import { getCurrentAcademicYear } from "../Utils/Utils.jsx";
import {
	useMasterAlertsStatsQuery,
	useBachelorAlertsStatsQuery,
} from "./Queries.jsx";

export default function StudentAlertsChart({
	promotion_master,
	promotion_bachelor,
	cities,
	curriculum,
	projection,
	type = "master", // Type: 'master' or 'bachelor'
}) {
	const currentYear = getCurrentAcademicYear();
	const isMaster = type === "master";

	const promos = isMaster
		? promotion_master && promotion_master.length > 0
			? promotion_master
			: [currentYear, currentYear + 1, currentYear + 2]
		: promotion_bachelor
			? [promotion_bachelor]
			: [currentYear + 3, currentYear + 4, currentYear + 5];

	const requestedCities = isMaster
		? [{ label: "France", value: null }]
		: [{ label: "France", value: null }, ...(cities ? [cities] : [])];

	const results = isMaster
		? useMasterAlertsStatsQuery(promos)
		: useBachelorAlertsStatsQuery(
				requestedCities,
				promos,
				curriculum,
				projection
			);

	const isFetching = results.some((query) => query.isFetching);
	const error = results.find((query) => query.error !== null);
	const requestData = results.map((query) => query.data?.data);

	const alertData = useMemo(
		() => formatDataForAlertsCharts(requestData, requestedCities, promos),
		[requestData]
	);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`${isMaster ? "Master" : "Bachelor"} Alerts Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error, isMaster]);

	return (
		<SauronChartsContainer
			title={t("students percentage").capfirst()}
			subtitle={`${t("by")} ${t("number of alerts")}`}
			isLoading={isFetching}
			height={isMaster ? undefined : 410} // Customize height for bachelor chart
		>
			{alertData ? (
				<Bar data={alertData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
