import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import {
	getCurrentAcademicYear,
	selectInternshipProgression,
} from "../Utils/Utils";

const year = getCurrentAcademicYear();

export const useInternshipProgressionQueries = (cities, promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["cities", "internship_progression", cities, promotions],
		queryFn: () =>
			scraper.internships.getProgression(
				[year],
				cities,
				promotions,
				true
			),
		select: (data) => selectInternshipProgression(data, year),
	});
};

const findCityNameWithCode = (cities, code) =>
	cities.find((city) => code === city.value)?.label ?? "France";

export const useModuleFilterOptionsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["cities", "module_filters"],
		queryFn: () =>
			scraper.module.getModulesPerYear(getCurrentAcademicYear()),
		select: (response) => response.data.units.toFilterOptions(),
	});
};

export const useGradeStatsPerCityQueries = (cities, module) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useQuery({
		queryKey: ["cities", "grade_stats_per_city", cityCodes, module],
		queryFn: () =>
			scraper.module.getGradesStatsByCity(year, cityCodes, module),
		select: (response) =>
			Object.keys(response.data).reduce((acc, code) => {
				if (code === "cities") {
					Object.keys(response.data.cities).forEach((city_code) => {
						acc[findCityNameWithCode(cities, city_code)] =
							response.data.cities[city_code];
						return acc;
					});
				}
				acc[findCityNameWithCode(cities, code)] = response.data[code];
				return acc;
			}, {}),
	});
};

export const useAlertsPerCityQuery = (cityCode, promotionYear) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["cities", "alers_per_city", cityCode, promotionYear],
		queryFn: () =>
			scraper.students.getAlertsByCity(
				cityCode,
				"epitech",
				promotionYear
			),
		select: (response) => response.data,
	});
};
