import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import TesseractRadioFilters from "../Sauron/RadioFilters";
import SauronBox from "../Sauron/SauronBox";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { requestErrorToastManager } from "../Utils/Toasts";
import NoModuleSpecified from "./NoModuleSpecified";
import { useSkillValidationStatsQuery } from "./Queries";
import TesseractChartsContainer from "./TesseractChartsContainer";

const options = {
	...defaultBarOptions,
	...{
		legend: {
			reverse: true,
		},
	},
};

function NoValidableSkill() {
	return (
		<div className="text-center">
			<em className="text-gray">
				{t("no skill can be validated for this module").capfirst()}
			</em>
		</div>
	);
}

export default function SkillsStats({
	year,
	module_code,
	instance_code,
	areRequestEnabled,
}) {
	const filtersOptions = [
		{
			label: t("final results").capfirst(true),
			value: "final",
		},
		{
			label: t("delivery results").capfirst(true),
			value: "delivery",
		},
		{
			label: t("BTTF results").capfirst(true),
			value: "bttf",
		},
		{
			label: t("provisional results").capfirst(true),
			value: "provisional",
		},
	];
	const [selectedOption, setSelectedOption] = useState(
		filtersOptions[0].value
	);
	const {
		data: skillValidationStats,
		error: skillValidationStatsError,
		isFetching: isFetchingSkillValidationStats,
	} = useSkillValidationStatsQuery(
		year,
		module_code,
		instance_code,
		areRequestEnabled
	);
	const isFetching = areRequestEnabled && isFetchingSkillValidationStats;

	requestErrorToastManager(
		"Tesseract: Validation Stats par Skill",
		skillValidationStatsError
	);

	if (!skillValidationStats)
		return (
			<SauronBox
				title={t("skills stats").capfirst()}
				isLoading={isFetching}
			>
				<NoModuleSpecified />
			</SauronBox>
		);
	const validableSkills = Object.keys(skillValidationStats);
	if (validableSkills.length === 0)
		return (
			<SauronBox
				title={t("skills stats").capfirst()}
				isLoading={isFetching}
			>
				<NoValidableSkill />
			</SauronBox>
		);
	const data = {
		labels: validableSkills,
		datasets: [
			{
				label: t("failed").capfirst(),
				data: validableSkills.map(
					(skill) =>
						skillValidationStats[skill][selectedOption].failed
				),
				backgroundColor: "#c0392b",
			},
			{
				label: t("validated").capfirst(),
				data: validableSkills.map(
					(skill) =>
						skillValidationStats[skill][selectedOption].validated
				),
				backgroundColor: "#2ecc71",
			},
		],
	};

	return (
		<TesseractChartsContainer
			title={t("skills stats").capfirst()}
			isLoading={isFetching}
			radioFilter={
				<TesseractRadioFilters
					options={filtersOptions}
					value={selectedOption}
					onChange={setSelectedOption}
				/>
			}
		>
			<Bar data={data} options={options} />
		</TesseractChartsContainer>
	);
}
