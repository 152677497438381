import { useMantineColorScheme } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { t } from "../../Config/i18n";

export function usePreferedColorScheme() {
	const preferedColor = useColorScheme();
	const colorScheme = localStorage.getItem("colorScheme");
	if (
		(colorScheme && colorScheme === "dark") ||
		(!colorScheme && preferedColor === "dark")
	)
		return "dark";
	return "light";
}

export default function SwitchDarkMode() {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();

	return (
		<div className="custom-control custom-switch">
			<input
				type="checkbox"
				className="custom-control-input"
				id="enabled"
				checked={colorScheme === "dark"}
				onChange={() => toggleColorScheme()}
				readOnly
			/>
			<label
				className="custom-control-label text-white"
				htmlFor="enabled"
			>
				{t("dark theme").capfirst()}
			</label>
		</div>
	);
}
