import {
	faCheck,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { t } from "../../../Config/i18n";
import LoadingComponent from "../../Utils/Loading";
import { requestErrorToastManager } from "../../Utils/Toasts";
import { useIntershipsQuery } from "../Queries";
import ListEntry from "./ListEntry";

function getContractTooltip(contract, begin, end) {
	return (
		`${contract.contract_type.toUpperCase()} <br />` +
		`${t("begin").capfirst()}: ${begin.toLocaleString(
			DateTime.DATE_SHORT
		)} <br />` +
		`${t("end").capfirst()}: ${end.toLocaleString(
			DateTime.DATE_SHORT
		)} <br />` +
		`${contract.days_per_week} ${t("days per week")} <br />` +
		`${t("tutor").capfirst()}: ${contract.tutor_email} <br />` +
		`${t("entrepreneur").capfirst()}: ${contract.master_email}`
	);
}

function ContractStatus({ status }) {
	if (status === "confirmed") {
		return (
			<span className="text-green">
				{` ${status} `}
				<FontAwesomeIcon icon={faCheck} color="green" />
			</span>
		);
	}
	return (
		<span className="text-orange">
			{` ${status} `}
			<FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
		</span>
	);
}

function ContractLength({ contract, begin, end }) {
	const diff = end.diff(begin, ["years", "months", "days"]);
	const months =
		diff.months !== 0
			? `${t("monthWithCount", {
					count: diff.months,
				})}`
			: "";
	const days =
		diff.days !== 0
			? `${t("daysWithCount", {
					count: diff.days,
				})}`
			: "";
	return (
		<div className="float-right">
			{`${months} ${days} (${contract.total_hour_duration}h) `}
		</div>
	);
}

function Contract({ contract, login }) {
	const begin = DateTime.fromISO(contract.begin);
	const end = DateTime.fromISO(contract.end);
	return (
		<ListGroupItem key={contract.hash}>
			<a
				href={`https://intra.epitech.eu/stage/#search=${login}`}
				target="_blank"
				rel="noreferrer"
				data-tooltip-id="student-overview-tooltip"
				data-tooltip-html={getContractTooltip(contract, begin, end)}
			>
				<div className="text-muted">
					{`${
						contract.full_time
							? t("internship").capfirst()
							: "Part-time"
					}:`}
					<ContractStatus status={contract.status} />
					<ContractLength
						contract={contract}
						begin={begin}
						end={end}
					/>
				</div>
			</a>
		</ListGroupItem>
	);
}

function ContractList({ contracts, login }) {
	return (
		<ListGroup variant="flush">
			{contracts.map((contract) => (
				<Contract
					key={contract.hash}
					contract={contract}
					login={login}
				/>
			))}
		</ListGroup>
	);
}

export default function InternshipEntry({ student }) {
	const {
		data: contracts,
		error,
		isFetching,
	} = useIntershipsQuery(student.login);

	requestErrorToastManager("Student Overview: Internships", error);

	if (isFetching)
		return (
			<div className="text-center mt-3">
				<LoadingComponent />
			</div>
		);
	if (!contracts) return null;
	if (contracts.length === 0) {
		if (student.promo.cursus.code !== "bachelor") return null;
		return (
			<ListEntry
				label="contracts"
				value={
					<FontAwesomeIcon
						icon={faExclamationTriangle}
						className="float-right text-orange"
					/>
				}
				tooltipContent={t("no internship").capfirst()}
			/>
		);
	}
	return (
		<ListGroupItem>
			<b>{t("contracts").capfirst()}</b>
			<ContractList contracts={contracts} login={student.login} />
		</ListGroupItem>
	);
}
