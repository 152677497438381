import { requestErrorToastManager } from "../Utils/Toasts";
import { compare } from "../Utils/Utils";
import DataTable from "./DataTable";
import OverviewDataTable from "./OverviewDataTable";
import { useModuleAlertsInfiniteQuery } from "./Queries";

export const instanceDataToLink = (year, module_code, instance_code) =>
	`https://intra.epitech.eu/module/${year}/${module_code}/${instance_code}/registered`;

const getAllowedModuleCodeInitial = (curriculums) => {
	const initials = [];
	if (curriculums.includes("Master")) initials.push("M");
	if (curriculums.includes("Bachelor"))
		return [...initials, ...["B", "S", "G"]];
	return initials;
};

export default function DataTableContainer({
	cities,
	curriculums,
	overview,
	isRequestEnabled,
	boxed = false,
}) {
	const allowedModuleCodeInitial = getAllowedModuleCodeInitial(
		curriculums.map((curriculum) => curriculum.value)
	);
	const {
		data: dividedData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading,
		isFetchingNextPage,
		error,
	} = useModuleAlertsInfiniteQuery(cities, isRequestEnabled);

	requestErrorToastManager("Module Alerts: Data Table", error);
	if (isRequestEnabled && !isFetchingNextPage && hasNextPage) fetchNextPage();
	const data = (dividedData?.pages ?? [])
		.flatMap((page) => page)
		.sort((a, b) => compare(a.module_code, b.module_code));

	const filteredData = data.filter((instance) => {
		const initial = instance.module_code[0];
		if (
			curriculums.length !== 0 &&
			!allowedModuleCodeInitial.includes(initial)
		)
			return false;
		return true;
	});

	const exportConfigColumns = {
		module_code: "module_code",
		module_title: "module_title",
		instance_code: "instance_code",
		end_date: (row) => row.end_date.toISODate(),
		missing: "missing",
		registered: "registered",
	};
	const exportConfig = {
		columns: exportConfigColumns,
		title: "module_alerts",
	};

	return (
		<>
			{boxed ? (
				<DataTable
					data={filteredData}
					exportConfig={exportConfig}
					isLoading={isLoading}
					isFetching={isFetching}
					boxed
				/>
			) : (
				<>
					{overview ? (
						<OverviewDataTable
							instances={filteredData}
							exportConfig={exportConfig}
							isLoading={isLoading}
							isFetching={isFetching}
						/>
					) : (
						<DataTable
							isLoading={isLoading}
							isFetching={isFetching}
							data={filteredData}
							exportConfig={exportConfig}
						/>
					)}
				</>
			)}
		</>
	);
}
