import { useInfiniteQuery, useQueries, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getCurrentAcademicYear, getNextPageParam } from "../Utils/Utils";

export const useCurriculumsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["bachelor", "filters_options", "curriculums"],
		queryFn: () => scraper.getPromotions("bachelor"),
		select: (data) => data.toFilterOptions(),
	});
};

export const useSemesters = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["master", "filters_options", "semesters"],
		queryFn: () => scraper.getMasterSemesters(),
		select: (data) => data.toFilterOptions(),
	});
};

export const useRoadblocksAlertsStatsMasterQuery = (promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const curriculum = "master";
	return useQueries({
		queries: promotions.map((promotion) => ({
			queryKey: [
				"master",
				"stats",
				"roadblocks_alerts_stats",
				"FR/PAR",
				promotion,
				curriculum,
			],
			queryFn: () =>
				scraper.students.getRoadblocksAlertStats(
					"FR/PAR",
					promotion,
					curriculum
				),
		})),
	});
};

export const useRoadblocksAlertsStatsBachelorQuery = (
	city,
	promotion_bachelor,
	curriculum,
	projection
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"bachelor",
			"stats",
			"roadblocks_alerts_stats",
			city,
			promotion_bachelor,
			curriculum,
			projection,
		],
		queryFn: () =>
			scraper.students.getRoadblocksAlertStats(
				city,
				promotion_bachelor,
				curriculum,
				projection
			),
	});
};

export const useBachelorAlertsStatsQuery = (
	cities,
	promotions,
	curriculum,
	projection
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQueries({
		queries: cities.flatMap((location) =>
			promotions.map((promotion) => ({
				queryKey: [
					"bachelor",
					"stats",
					"students_alerts_stats",
					location.label,
					promotion,
					curriculum,
					projection,
				],
				queryFn: () =>
					scraper.students.getAlertsByCity(
						location.value,
						"epitech",
						promotion,
						curriculum,
						projection
					),
			}))
		),
	});
};

export const useMasterAlertsStatsQuery = (promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const curriculum = "master";

	return useQueries({
		queries: promotions.map((promotion) => ({
			queryKey: [
				"master",
				"stats",
				"students_alerts_stats",
				promotion,
				curriculum,
			],
			queryFn: () =>
				scraper.students.getAlertsByCity(
					"FR/PAR",
					"epitech",
					promotion,
					curriculum
				),
		})),
	});
};

export const useMasterRoadblocksQuery = (
	semesters,
	promotions,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const curriculum = "master";
	return useInfiniteQuery({
		queryKey: ["master", "table_data", semesters, promotions, curriculum],
		queryFn: ({ pageParam }) =>
			scraper.students.getRoadblocks(
				pageParam,
				"FR/PAR",
				getCurrentAcademicYear(),
				promotions,
				curriculum,
				false,
				semesters
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: areRequestEnabled,
	});
};

export const useBachelorRoadblocksQuery = (
	city,
	scholar_year,
	promotion,
	curriculum,
	projection,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"bachelor",
			"table_data",
			"students_roadblocks",
			city,
			scholar_year,
			promotion,
			curriculum,
			projection,
		],
		queryFn: ({ pageParam }) =>
			scraper.students.getRoadblocks(
				pageParam,
				city,
				scholar_year,
				promotion,
				curriculum,
				projection
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};
