import { Config } from "../../Config/Config";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
	auth: {
		clientId: Config.client_id,
		authority: `https://login.microsoftonline.com/${Config.epitech_active_directory_id}`,
		redirectUri: Config.redirect_uri,
		postLogoutRedirectUri: Config.redirect_uri,
		validateAuthority: true,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: isIE || isEdge || isFirefox,
		tokenRenewalOffsetSeconds: 600,
	},
	system: {
		allowRedirectInIframe: true,
	},
};

export const loginRequest = {
	scopes: ["User.Read"],
};

export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
