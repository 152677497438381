import { t } from "../../Config/i18n";

const STATUS_COLORS = {
	succeeded: "#2ecc71",
	in_progress: "#f1c40f",
	failed: "#c0392b",
};

const GRADE_CHIP_STYLING = {
	border: "solid 1.5px",
	borderRadius: "16px",
	padding: "2px 10px",
	fontWeight: 700,
};

export default function StatusCell({ status }) {
	const color = STATUS_COLORS[status];
	const style = {
		color,
		...GRADE_CHIP_STYLING,
	};
	return <span style={style}>{t(status)}</span>;
}
