import {
	faCheck,
	faExclamationTriangle,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "../../Config/i18n";

export const ALERT_LABELS = {
	"missing": {
		label: "no internship",
		color: "#e74c3c",
		icon: faTimes,
	},
	"student_not_marked": {
		label: "not marked yet",
		color: "#e67e22",
		icon: faExclamationTriangle,
	},
	"validated_pedago": {
		label: "waiting for adm validation",
		color: "#e67e22",
		icon: faExclamationTriangle,
	},
	"validated_adm": {
		label: "waiting for signatures",
		color: "#f39c12",
		icon: faExclamationTriangle,
	},
	"waiting": {
		label: "waiting for pedago validation",
		color: "#f39c12",
		icon: faExclamationTriangle,
	},
	"confirmed": { label: `OK`, color: "#27ae60", icon: faCheck },
	"N/A": { label: `OK`, color: "#27ae60", icon: faCheck },
};

export const ALERTS = Object.keys(ALERT_LABELS);

export function internshipAlert({ label, color, icon }, login) {
	return (
		<span
			style={{
				backgroundColor: color,
				display: "block",
				width: 220,
				fontSize: "12px",
				fontWeight: 700,
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "top",
				borderRadius: "10px",
				paddingTop: 1,
				paddingBottom: 1,
			}}
			className="text-white"
		>
			<FontAwesomeIcon icon={icon} size="sm" />
			<span className="ml-1">
				<a
					href={`https://intra.epitech.eu/stage/#search=${login}`}
					className="text-white"
					target="_blank"
					rel="noreferrer"
				>
					{t(label).capfirst()}
				</a>
			</span>
		</span>
	);
}
