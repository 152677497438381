import { t } from "i18next";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CityGradesGraph from "../Graphs/GradeGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { filterModuleStatsData } from "../Utils/Utils";
import { useGradesStats } from "./Queries";

function filterData(data) {
	if (!data) return data;
	const filtered = filterModuleStatsData(data);
	const idx = filtered.labels.findIndex((key) => key === "Global");
	Object.keys(filtered).forEach((key) => {
		filtered[key].splice(idx, 2);
	});
	return filtered;
}

export default function GradesByInstances({
	year,
	module_code,
	instances,
	date,
	areRequestEnabled,
}) {
	const { data, error, isFetching } = useGradesStats(
		year,
		module_code,
		instances,
		date,
		areRequestEnabled
	);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Module Validations Grades by cities: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("grades").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
		>
			{data ? <CityGradesGraph stats={filterData(data)} /> : null}
		</SauronChartsContainer>
	);
}
