import { Modal } from "react-bootstrap";

import { t } from "../../Config/i18n";
import UploadCSV from "./UploadCSV";

export default function UploadCSVModal({
	show,
	onHide,
	onUploadSuccess,
	errorMessage,
	uploadStatus,
	resetStatus,
	expectedFormat,
	allFieldsRequired,
	example,
}) {
	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{t("import CSV").capfirst()}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<UploadCSV
					onHide={onHide}
					onUploadSuccess={onUploadSuccess}
					errorMessage={errorMessage}
					uploadStatus={uploadStatus}
					resetStatus={resetStatus}
					expectedFormat={expectedFormat}
					allFieldsRequired={allFieldsRequired}
					example={example}
				/>
			</Modal.Body>
		</Modal>
	);
}
