import { Card, Group, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { t } from "../../Config/i18n";

export default function StartingDate({
	config,
	validateChange,
	backgroundColor,
}) {
	const [value, setValue] = useState(config.starting_date);

	useEffect(() => {
		if (config.starting_date) setValue(config.starting_date);
	}, [config.starting_date]);

	const onDateChange = (event) => {
		const date = DateTime.fromISO(event.target.value);
		validateChange("date", date);
	};

	return (
		<Card style={{ backgroundColor }}>
			<Group position="center">
				<Text fw={700}>{t("Start date")}</Text>
				<InputGroup>
					<FormControl
						type="date"
						value={value}
						onChange={onDateChange}
					/>
				</InputGroup>
			</Group>
		</Card>
	);
}
