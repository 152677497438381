import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import LoadingOverlay from "../AdminLTE3/LoadingOverlay";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear } from "../Utils/Utils";
import EvaluationsDataTableContainer from "./EvaluationsDataTableContainer";
import Filters from "./Filters";
import { useEvaluationsQuery, useGradingSkillQuery } from "./Queries";
import SideStats from "./SideStats";
import StatsChart from "./StatsChart";
import { STATUS_CONFIG } from "./StatusConfig";

export default function Evaluations() {
	const currentYear = getCurrentAcademicYear();

	const [activeFilters, setActiveFilters] = useState({
		year: { label: currentYear, value: currentYear },
		module_code: null,
		scale_id: null,
		bttf: null,
	});

	const {
		data: gradingSkill,
		isFetching: isFetchingGradingSkill,
		isLoading: isLoadingGradingSkill,
		error: errorGradingSkill,
	} = useGradingSkillQuery(activeFilters.scale_id?.value);

	requestErrorToastManager(
		"Grading skill: Module/Scale Id",
		errorGradingSkill
	);

	const {
		data: evaluations = [],
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading,
		isFetchingNextPage,
		error: errorEvaluations,
	} = useEvaluationsQuery(
		activeFilters.year?.value,
		activeFilters.scale_id?.value,
		activeFilters.module_code?.value
	);
	if (
		!!activeFilters.year?.value &&
		!!activeFilters.scale_id?.value &&
		!!activeFilters.module_code?.value &&
		!isFetchingNextPage &&
		hasNextPage
	)
		fetchNextPage();

	requestErrorToastManager("Evaluations: Module/Scale Id", errorEvaluations);

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	const filteredEvaluations = evaluations?.filter((evaluation) => {
		if (!activeFilters.bttf) return true;
		switch (activeFilters.bttf.value) {
			case "only":
				return evaluation.activity.type === "BTTF";
			case "without":
				return evaluation.activity.type !== "BTTF";
			default:
				return true;
		}
	});

	return (
		<Section>
			<SectionHeader title={t("evaluations").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-3 gap-3">
						<Col lg={3}>
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={4}>
							<Box style={{ height: "100%" }}>
								<LoadingOverlay loading={isFetching} />
								<Card.Header>
									<BoxTitle title={t("stats").capfirst()} />
								</Card.Header>
								<Card.Body>
									<StatsChart
										config={STATUS_CONFIG}
										data={filteredEvaluations}
									/>
								</Card.Body>
							</Box>
						</Col>
						<Col lg={5}>
							<Box style={{ height: "100%" }}>
								<LoadingOverlay loading={isFetching} />
								<Card.Header>
									<BoxTitle
										title={t("global stats").capfirst()}
									/>
								</Card.Header>
								<Card.Body>
									<SideStats data={filteredEvaluations} />
								</Card.Body>
							</Box>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<EvaluationsDataTableContainer
								isFetching={
									isFetching || isFetchingGradingSkill
								}
								isLoading={isLoading || isLoadingGradingSkill}
								evaluations={filteredEvaluations}
								gradingSkill={gradingSkill}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="evaluation-tooltip" />
			<StudentTooltip tooltipId="evaluation-student-tooltip" />
		</Section>
	);
}
