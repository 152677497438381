export default class English {
	constructor(api) {
		this.api = api;
	}

	getTepitechAlertsStats = (
		city = null,
		promotions = null,
		curriculums = null
	) =>
		this.api.processRequest("GET", "english/tepitech/alerts/stats", {
			params: {
				city,
				promotion: promotions,
				curriculum: curriculums,
			},
		});

	getTepitechProgressions = (city, year, curriculum) =>
		this.api.processRequest("GET", "/english/tepitech/progressions/stats", {
			params: { city, year, cursus: curriculum },
		});

	getInstances = () => this.api.processRequest("GET", "/english/instances");
}
