export default class Activity {
	constructor(api) {
		this.api = api;
	}

	getActivities = (year, module, instance = null, offset = 0) =>
		this.api.processRequest("GET", "/modules/instances/activities", {
			params: {
				year,
				module,
				instance,
				offset,
			},
		});

	getPresences = async (year, module, instance, login = null) => {
		const response = await this.api.processRequest(
			"GET",
			"/modules/instances/activities/presences",
			{
				params: {
					year,
					module,
					instance,
					login,
				},
			}
		);
		return response.data;
	};

	getPresencesAvailableYears = async () => {
		const response = await this.api.processRequest(
			"GET",
			"/modules/instances/activities/presences/available_years"
		);
		return response.data;
	};

	getPresencesAvailableInstances = async (year) => {
		const response = await this.api.processRequest(
			"GET",
			"/modules/instances/activities/presences/available_instances",
			{
				params: { year },
			}
		);
		if (year) return response.data[year];
		return response.data;
	};
}
