import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { IconFileDownload } from "@tabler/icons-react";
import {
	MRT_ShowHideColumnsButton,
	MRT_ToggleDensePaddingButton,
	MRT_ToggleFiltersButton,
	MRT_ToggleGlobalFilterButton,
	MantineReactTable,
	useMantineReactTable,
} from "mantine-react-table";
import { MRT_Localization_FR } from "mantine-react-table/locales/fr";
import i18next, { t } from "../../Config/i18n";
import { defaultOnExportFunction } from "./DataTableExportButton";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
	return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach((key) => {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		});
	}

	return mergeDeep(target, ...sources);
}

export default function SauronMantineDataTable({
	columns,
	data,
	title = null,
	subtitle = null,
	isCard = false,
	tableProps = {},
	exportConfig,
	isLoading = false,
	isFetching = false,
	toolbarInternalActionsBonus,
	preToolBarInternalActionsBonus,
}) {
	const finalTableProps = mergeDeep(
		{
			columns,
			data,
			enableFullScreenToggle: false,
			mantineTableProps: {
				striped: true,
				sx: { tableLayout: "fixed" },
			},
			mantineTopToolbarProps: {
				...((isCard && {
					className: "card-header card-outline card-primary",
					style: {
						padding: "0.25rem 0.25rem 0.25rem 0.75rem",
					},
				}) ||
					{}),
			},
			initialState: {
				density: "xs",
				pagination: { pageSize: 20 },
			},
			mantinePaginationProps: {
				rowsPerPageOptions: ["20", "30", "50", "100", "150"],
			},
			state: {
				showLoadingOverlay: isLoading,
				showProgressBars: isFetching,
			},
			renderTopToolbarCustomActions: () => (
				<Flex gap="xs" align="center">
					{title ? (
						<h5 className="card-title">
							{title}
							{subtitle ? <small> {subtitle}</small> : null}
						</h5>
					) : null}
					{preToolBarInternalActionsBonus}
				</Flex>
			),
			renderToolbarInternalActions: ({ table }) => (
				<Flex gap="xs" align="center">
					<MRT_ToggleGlobalFilterButton table={table} />
					<MRT_ToggleFiltersButton table={table} />
					<MRT_ShowHideColumnsButton table={table} />
					<MRT_ToggleDensePaddingButton table={table} />
					{toolbarInternalActionsBonus}
					{exportConfig ? (
						<Tooltip
							withinPortal
							label={t("export to CSV").capfirst()}
						>
							<ActionIcon
								aria-label={t("export to CSV").capfirst()}
								color="gray"
								size="lg"
								variant="subtle"
								onClick={() =>
									defaultOnExportFunction(
										exportConfig.data ?? data,
										exportConfig.columns,
										exportConfig.title
									)
								}
							>
								<IconFileDownload />
							</ActionIcon>
						</Tooltip>
					) : null}
				</Flex>
			),
		},
		tableProps
	);
	const language = i18next.language.split("-")[0];
	if (language === "fr") finalTableProps.localization = MRT_Localization_FR;
	const mantineTable = useMantineReactTable(finalTableProps);
	return <MantineReactTable table={mantineTable} />;
}
