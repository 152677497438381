import { Button, Group, NumberInput, TextInput } from "@mantine/core";
import { useState } from "react";
import { t } from "../../../Config/i18n";

export default function EditRoadblockModal({
	roadblock,
	close,
	validateChange,
}) {
	const [newThreshold, setNewThreshold] = useState(roadblock.threshold);
	const [newName, setNewName] = useState(roadblock.name);

	function validateEdit() {
		validateChange(newThreshold, newName, roadblock);
		close();
	}

	return (
		<>
			<TextInput
				label={t("roadblock name").capfirst()}
				value={newName}
				onChange={(event) => setNewName(event.target.value)}
				withAsterisk
			/>
			<NumberInput
				label={t("roadblock credits").capfirst()}
				value={newThreshold}
				min={0}
				onChange={setNewThreshold}
				withAsterisk
			/>
			<Group style={{ marginTop: "5%", justifyContent: "space-between" }}>
				<Button onClick={close}>{t("close").capfirst()}</Button>
				<Button onClick={() => validateEdit()}>
					{t("validate").capfirst()}
				</Button>
			</Group>
		</>
	);
}
