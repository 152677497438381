import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LoadingOverlay({ loading }) {
	if (loading !== true) return null;
	return (
		<div className="overlay">
			<FontAwesomeIcon icon={faSyncAlt} className="fa-spin" size="3x" />
		</div>
	);
}
