import { useState } from "react";
import { t } from "../../Config/i18n";
import UploadCSV from "../Utils/UploadCSV";
import { UPLOAD_STATUS } from "../Utils/Utils";
import { useCSVProComNotesMutation } from "./Queries";
import { InputGroup } from "react-bootstrap";

export default function BulkImportButton({
	year,
	module_code,
	slug,
	project_instance,
	onUploadSuccess,
	onUploadReset,
	disabled = false,
}) {
	const [errorMessage, setErrorMessage] = useState("");
	const [bttfMode, setBttfMode] = useState(false);
	const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.pending);
	const resetStatus = () => {
		setErrorMessage("");
		setUploadStatus(UPLOAD_STATUS.pending);
		onUploadReset();
	};
	const mutation = useCSVProComNotesMutation(
		UPLOAD_STATUS,
		setErrorMessage,
		setUploadStatus,
		onUploadSuccess,
		year,
		module_code,
		slug,
		project_instance,
		bttfMode
	);

	const formatFloatsInData = (originalData) => {
		const formattedData = originalData.data.map((item) => {
			const newItem = { ...item };
			Object.keys(newItem).forEach((key) => {
				if (key.startsWith("bloc")) {
					newItem[key] = newItem[key].replace(",", ".");
				}
			});
			return newItem;
		});
		return formattedData;
	};

	return (
		<UploadCSV
			onUploadSuccess={(data) =>
				mutation.mutate({ data: formatFloatsInData(data) })
			}
			uploadStatus={uploadStatus}
			errorMessage={errorMessage}
			resetStatus={resetStatus}
			expectedFormat="login;comment;bloc 1;bloc 2;bloc 3;bloc 4;bloc 5"
			example="name.surname@epitech.eu;Very good work;8;6;3;2;1"
			buttonLabel={t(
				"upload the assessment file and download marks file"
			).capfirst()}
			disabled={disabled}
		>
			<InputGroup>
				<InputGroup.Prepend>
					<InputGroup.Checkbox
						disabled={disabled}
						className="bg-primary"
						checked={bttfMode}
						onChange={() => setBttfMode(!bttfMode)}
					/>
				</InputGroup.Prepend>
				<div
					className="form-control text-nowrap"
					style={{ overflow: "hidden" }}
				>
					{t("pro_com_bttf_upload").capfirst()}
				</div>
			</InputGroup>
		</UploadCSV>
	);
}
