import { Bar } from "react-chartjs-2";
import { t } from "../../../../../../Config/i18n";

export default function Summary({ hub }) {
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			annotation: {
				enable: true,
				annotations: {
					hredline: {
						mode: "horizontal",
						type: "line",
						yMin: hub.credits_goal * 10,
						yMax: hub.credits_goal * 10,
						borderColor: "rgb(255, 0, 0)",
						borderWidth: 4,
						label: {
							display: true,
							content: t("goal").capfirst(),
						},
					},
				},
			},
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
				beginAtZero: true,
				suggestedMax: hub.credits_goal * 10 + 5,
			},
		},
	};
	const data = {
		labels: ["XP"],
		datasets: [
			{
				label: t("involvement").capfirst(),
				backgroundColor: "#ffcc00",
				data: [hub.participation_xp_acquired],
			},
			{
				label: t("organization").capfirst(),
				backgroundColor: "#00ff6d",
				data: [hub.organization_xp_acquired],
			},
			{
				label: t("projects").capfirst(),
				backgroundColor: "#0098ff",
				data: [hub.project_xp_acquired],
			},
			{
				label: `${t("involvement").capfirst()} ${t("in progress")}`,
				backgroundColor: "#ffe375",
				data: [hub.participation_xp_in_progress],
			},
			{
				label: `${t("organization").capfirst()} ${t("in progress")}`,
				backgroundColor: "#74ffb0",
				data: [hub.organization_xp_in_progress],
			},
			{
				label: `${t("projects").capfirst()} ${t("in progress")}`,
				backgroundColor: "#75c7ff",
				data: [hub.project_xp_in_progress],
			},
		],
	};
	return (
		<div>
			<h5 className="text-muted">{t("summary").capfirst()}</h5>
			<div style={{ minHeight: "350px" }}>
				<Bar data={data} options={options} />
			</div>
		</div>
	);
}
