import { Button, Grid, JsonInput, useMantineColorScheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { t } from "../../Config/i18n";
import AdditionalConfigCard from "./Cards/AdditionalConfigCard";
import NewRoadblockCard from "./Cards/NewRoadblockCard";
import RoadblockCard from "./Cards/RoadblockCard";
import {
	useConfigEditMutation,
	useRoadblockConfig,
	useRoadblockEditMutation,
} from "./Queries";
import StartingDate from "./StartingDate";

const Colors = ["#74ab43", "#4170bf", "#eb7d34", "#a3a6a9", "#5e9dd0"];

function SemesterButton({ onClick, currentValue }) {
	const semesterButtons = [
		{
			id: "1",
			value: 1,
		},
		{
			id: "2",
			value: 2,
		},
	];

	return (
		<div style={{ textAlign: "center" }}>
			{semesterButtons.map((semester) => (
				<Button
					className={
						semester.value === currentValue
							? "btn btn-sm btn-primary"
							: "btn btn-sm btn-outline-secondary"
					}
					key={semester.id}
					onClick={() => onClick(semester.value)}
					style={{
						padding: "5px 20%",
					}}
				>
					{`${t("semester").capfirst()} ${semester.id}`}
				</Button>
			))}
		</div>
	);
}

export default function RoadblockConfig({ scholarYear, tekYear }) {
	const [currentSemester, setCurrentSemester] = useState(1);
	const { data = {} } = useRoadblockConfig(
		scholarYear,
		tekYear,
		currentSemester
	);
	const form = useForm({
		initialValues: {
			content: "{}",
		},
	});
	const { colorScheme } = useMantineColorScheme();
	const roadblockMutation = useRoadblockEditMutation(
		scholarYear,
		tekYear,
		currentSemester
	);
	const configMutation = useConfigEditMutation(
		scholarYear,
		tekYear,
		currentSemester
	);
	let currentColorId = 0;

	const changeCurrentSemester = (semester) => {
		if (semester !== currentSemester) {
			setCurrentSemester(semester);
		}
	};

	useEffect(() => {
		if (data && "additional_configs" in data) {
			form.setValues({
				content: JSON.stringify(data.additional_configs, null, 2),
			});
		}
	}, [data]);

	if (!data || Object.keys(data).length === 0) return null;

	const validateChangeConfig = (key, value) => {
		let tempObj = {
			configId: data.id,
			date: data.starting_date,
			creditsThreshold: data.credits_threshold,
			tepitech: data.tepitech,
			additionalConfigs: data.additional_configs,
		};
		if (key in tempObj && value !== tempObj[key]) {
			tempObj[key] = value;
			configMutation.mutate(tempObj);
		}
	};

	const validateChangeRoadblock = (threshold, name, roadblock) => {
		if (threshold !== roadblock.threshold || name !== roadblock.name) {
			roadblockMutation.mutate({
				configId: data.id,
				roadblockId: roadblock?.id,
				roadblockName: name,
				newThreshold: threshold,
			});
		}
	};

	return (
		<div
			key={`${scholarYear}_${tekYear}_${currentSemester}`}
			className="insideTab"
		>
			<SemesterButton
				onClick={changeCurrentSemester}
				currentValue={currentSemester}
			/>
			<Grid className="spaceFromTop">
				<Grid.Col>
					<StartingDate
						config={data}
						validateChange={validateChangeConfig}
						backgroundColor={
							colorScheme === "light" ? "#DBDBDB" : ""
						}
					/>
				</Grid.Col>
				<Grid.Col span={6}>
					<AdditionalConfigCard
						configName="tepitech"
						configThreshold={data.tepitech}
						validateChange={validateChangeConfig}
						backgroundColor={
							colorScheme === "light" ? "#DBDBDB" : ""
						}
					/>
				</Grid.Col>
				<Grid.Col span={6}>
					<AdditionalConfigCard
						configName="creditsThreshold"
						configThreshold={data.credits_threshold}
						validateChange={validateChangeConfig}
						backgroundColor={
							colorScheme === "light" ? "#DBDBDB" : ""
						}
					/>
				</Grid.Col>
				{data.roadblocks.length !== 0
					? data.roadblocks.map((roadblock) => {
							const color = Colors[currentColorId];
							currentColorId += 1;
							currentColorId %= 5;
							return (
								<Grid.Col
									span={4}
									key={`${scholarYear}_${tekYear}_${currentSemester}_${roadblock.name}`}
								>
									<RoadblockCard
										roadblock={roadblock}
										validateChange={validateChangeRoadblock}
										backgroundColor={color}
										className="configCard"
										baseKey={`${scholarYear}_${tekYear}_${currentSemester}`}
										configProps={{
											scholarYear,
											tekYear,
											currentSemester,
											configId: data.id,
										}}
									/>
								</Grid.Col>
							);
						})
					: null}
				<Grid.Col span={4}>
					<NewRoadblockCard
						configId={data.id}
						scholarYear={scholarYear}
						tekYear={tekYear}
						semester={currentSemester}
						backgroundColor={
							colorScheme === "light" ? "#DBDBDB" : ""
						}
						color={colorScheme === "light" ? "black" : "white"}
					/>
				</Grid.Col>
			</Grid>
			<form
				onSubmit={form.onSubmit((values) => {
					if (form.isTouched("content") && form.isDirty("content")) {
						validateChangeConfig(
							"additionalConfigs",
							JSON.parse(values.content)
						);
					}
					return null;
				})}
			>
				<JsonInput
					{...form.getInputProps("content")}
					label={t("additional configs").capfirst()}
					formatOnBlur
					autosize
					style={{ marginTop: "1%" }}
					validationError={t("invalid JSON").capfirst()}
				/>
				<Button type="submit" fullWidth>
					{t("save").capfirst()}
				</Button>
			</form>
		</div>
	);
}
