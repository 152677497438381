import InvestmentProgression from "../../InvestmentOverview/InvestmentProgression";
import { useInvestmentProgressionStatsQuery } from "../../InvestmentOverview/Queries";
import { requestErrorToastManager } from "../../Utils/Toasts";

export default function InvestmentProgress({ cities, promotions }) {
	const {
		data: data,
		error: error,
		isFetching: statsFetching,
	} = useInvestmentProgressionStatsQuery(
		cities.map((city) => city.value),
		promotions,
		true
	);

	requestErrorToastManager("Investement Progression", error);
	return (
		<InvestmentProgression
			data={data}
			isFetching={statsFetching}
		></InvestmentProgression>
	);
}
