import { t } from "../../Config/i18n";

export default function getPresenceStatus() {
	return {
		"not_registered": {
			text: "text-muted",
			label: t("not registered"),
		},
		"registered": {
			text: "text-blue",
			label: t("registered"),
		},
		"absent": {
			text: "text-red",
			label: t("absent"),
		},
		"present": {
			text: "text-green",
			label: t("present"),
		},
		"N/A": {
			text: "text-secondary",
			label: "N/A",
		},
	};
}
