import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { useScrapingTypes } from "./Queries";

export default function Filters({ onFilter }) {
	const { data, error } = useScrapingTypes();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			type: {
				label: t("type"),
				type: "select",
				multiple: false,
				clearable: false,
				options: [],
				default: null,
			},
		});

	useEffect(() => {
		if (!data) return;
		setFilterOptions("type", data.toFilterOptions());
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Scraping history Filters: ${t("An error has occured")}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
