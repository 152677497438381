import { getCurrentAcademicYear } from "../../Components/Utils/Utils";

export default class Instance {
	constructor(api) {
		this.api = api;
	}

	getInstances = (module, year, offset = 0) =>
		this.api.processRequest("GET", "/modules/instances", {
			params: { module, year, offset },
		});

	getGradesInAlerts(
		offset = 0,
		cities = null,
		modules = null,
		scholar_year = getCurrentAcademicYear(),
		alert_only = true,
		limit = 100
	) {
		const params = {
			offset,
			scholar_year,
			alert_only,
			limit,
			city: cities,
			module: modules,
		};
		return this.api.processRequest("GET", "/modules/instances/grades", {
			params,
		});
	}
}
