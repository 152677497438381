import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import { compare } from "../Utils/Utils";

export default function DiversityDataTable({
	data,
	exportConfig,
	isLoading,
	isFetching,
}) {
	const columns = [
		{
			accessorKey: "login",
			header: t("login").capfirst(),
			size: 200,
			sortingFn: (a, b) => -compare(a.original.login, b.original.login),
			Cell: ({ row }) => <LoginRow login={row.original.login} />,
		},
		{
			accessorKey: "promotion",
			header: t("promotion").capfirst(),
			size: 100,
			sortingFn: (a, b) =>
				-compare(a.original.promotion, b.original.promotion),
			Cell: ({ row }) => row.original.promotion,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "curriculum",
			header: t("curriculum").capfirst(),
			size: 100,
			sortingFn: (a, b) =>
				-compare(a.original.curriculum, b.original.curriculum),
			Cell: ({ row }) => row.original.curriculum,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "city",
			header: t("city").capfirst(),
			size: 100,
			sortingFn: (a, b) => -compare(a.original.city, b.original.city),
			Cell: ({ row }) => row.original.city,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "obtained_xp",
			header: "XP",
			size: 100,
			sortingFn: (a, b) =>
				-compare(a.original.obtained_xp, b.original.obtained_xp),
			Cell: ({ row }) => row.original.obtained_xp,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "duration_acquired",
			header: t("duration acquired").capfirst(),
			size: 100,
			sortingFn: (a, b) =>
				-compare(
					a.original.duration_acquired,
					b.original.duration_acquired
				),
			Cell: ({ row }) => row.original.duration_acquired,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "duration_progress",
			header: t("duration progress").capfirst(),
			size: 100,
			sortingFn: (a, b) =>
				-compare(
					a.original.duration_progress,
					b.original.duration_progress
				),
			Cell: ({ row }) => row.original.duration_progress,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "credits",
			header: t("credits").capfirst(),
			size: 100,
			sortingFn: (a, b) =>
				-compare(a.original.credits, b.original.credits),
			Cell: ({ row }) => row.original.credits,
			enableGlobalFilter: false,
		},
	];
	return (
		<SauronMantineDataTable
			title={t("diversity_overview").capfirst()}
			subtitle={t("per students")}
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
		/>
	);
}
