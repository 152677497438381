import { Row } from "react-bootstrap";
import { t } from "../../../../../../Config/i18n";
import { BoxFooterButton } from "../../../../../AdminLTE3/Box";
import { downloadCSV } from "../../../../../Utils/Utils";
import ADMStatus from "./ADMStatus";
import IntuituPersonaeTable from "./Table";

function onExport(intuitus) {
	const table = [
		["login", "adm_status", "pedago_status", "comment", "author", "date"],
	];
	const title = `${intuitus[0].student.login}_${intuitus[0].scholar_year}_intuitu_personae`;

	intuitus.reverse().forEach((intuitu) => {
		table.push([
			intuitu.student.login,
			intuitu.adm_status,
			intuitu.pedago_status,
			intuitu.comment,
			intuitu.author,
			intuitu.date,
		]);
	});
	downloadCSV(table, title);
}

export default function YearTab({ intuituPersonaes }) {
	return (
		<div>
			<BoxFooterButton
				label={t("export to CSV").capfirst()}
				onClick={() => onExport(intuituPersonaes)}
				style={{
					width: "170px",
					position: "absolute",
					top: "3px",
					right: "3px",
				}}
			/>
			<Row style={{ height: 400 }}>
				<ADMStatus intuitus={intuituPersonaes} />
			</Row>
			<Row>
				<IntuituPersonaeTable intuitus={intuituPersonaes} />
			</Row>
		</div>
	);
}
