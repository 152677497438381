import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useAvailableGroups = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["jwt_manager", "available_groups"],
		queryFn: () => scraper.token.getAvailableRoles(),
		select: (data) => data.data.toFilterOptions(),
	});
};

export const useModules = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["jwt_manager", "modules"],
		queryFn: ({ pageParam }) => scraper.module.getModules(pageParam),
		initialPageParam: 0,
		getNextPageParam,
	});
};

export const useAccessTokens = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["jwt_manager", "tokens", "access"],
		queryFn: () => scraper.token.getAccessTokens(),
		select: (data) => data.data,
	});
};

export const useRefreshTokens = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["jwt_manager", "tokens", "refresh"],
		queryFn: () => scraper.token.getRefreshTokens(),
		select: (data) => data.data,
	});
};

export const useAPITokens = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["jwt_manager", "tokens", "API"],
		queryFn: () => scraper.token.getAPITokens(),
		select: (data) => data.data.api_tokens,
	});
};

export const useClearExpiredAccessTokensMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: () => scraper.token.clearExpiredAccessTokens(),
		onError: () => {
			toast.error(
				`Sauron: ${t("failed to clear all access tokens").capfirst()}`
			);
		},
		onSuccess: () => {
			toast.success(
				`Sauron: ${t("successfully cleared all access tokens").capfirst()}`
			);
			queryClient.invalidateQueries({
				queryKey: ["jwt_manager", "tokens"],
			});
		},
	});
};

export const useClearExpiredRefreshTokensMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: () => scraper.token.clearExpiredRefreshTokens(),
		onError: () => {
			toast.error(
				`Sauron: ${t("failed to clear all refresh tokens").capfirst()}`
			);
		},
		onSuccess: () => {
			toast.success(
				`Sauron: ${t("successfully cleared all refresh tokens").capfirst()}`
			);
			queryClient.invalidateQueries({
				queryKey: ["jwt_manager", "tokens"],
			});
		},
	});
};

export const useRevokeAccessTokenMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (token) => scraper.token.revokeAccessToken(token),
		onError: () => {
			toast.error(
				`Sauron: ${t("failed to revoke refresh token").capfirst()}`
			);
		},
		onSuccess: () => {
			toast.success(
				`Sauron: ${t("access token revoked successfully").capfirst()}`
			);
			queryClient.invalidateQueries({
				queryKey: ["jwt_manager", "tokens"],
			});
		},
	});
};

export const useRevokeRefreshTokenMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (token) => scraper.token.revokeRefreshToken(token),
		onError: () => {
			toast.error(
				`Sauron: ${t("failed to revoke refresh token").capfirst()}`
			);
		},
		onSuccess: () => {
			toast.success(
				`Sauron: ${t("refresh token revoked successfully").capfirst()}`
			);
			queryClient.invalidateQueries({
				queryKey: ["jwt_manager", "tokens"],
			});
		},
	});
};

export const useRevokeApiTokenMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (token) => scraper.token.revokeAPIToken(token),
		onError: () => {
			toast.error(
				`Sauron: ${t("failed to revoke api token").capfirst()}`
			);
		},
		onSuccess: () => {
			toast.success(
				`Sauron: ${t("api token revoked successfully").capfirst()}`
			);
			queryClient.invalidateQueries({
				queryKey: ["jwt_manager", "tokens"],
			});
		},
	});
};

export const useKeygenMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ id, groups, modules_responsible, lifespan, cities }) =>
			scraper.token.keyGen(
				id,
				groups,
				modules_responsible,
				lifespan,
				cities
			),
		onError: (error) => toast.error(`Sauron: ${error.response.data}`),
		onSuccess: () => {
			toast.success(`Sauron: ${t("token successfully generated")}`);
			queryClient.invalidateQueries({
				queryKey: ["jwt_manager", "tokens"],
			});
		},
	});
};
