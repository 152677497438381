import {
	faCheck,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { degrade } from "../../Utils/Utils";

export default function MissingTranscriptsSpan({ nbMissing }) {
	return (
		<span
			style={{
				backgroundColor: degrade(25, nbMissing),
				display: "block",
				width: 80,
				fontSize: "12px",
				fontWeight: 700,
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "top",
				borderRadius: "10px",
				paddingTop: 1,
				paddingBottom: 1,
			}}
			className="text-white"
		>
			<FontAwesomeIcon
				icon={nbMissing > 0 ? faExclamationTriangle : faCheck}
				size="sm"
			/>
			<span className="ml-1">{nbMissing}</span>
		</span>
	);
}
