import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForms from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { useAvailableGroups, useModules } from "./Queries";

export default function Filters({ generateKey }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const { data: groups } = useAvailableGroups();
	const {
		data: fetchedModulesData,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useModules();
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const { filters, setFilterOptions, setSelectedOption, setFilterDisabled } =
		useFilters({
			id: {
				label: t("identifier"),
				type: "text",
				placeholder: t("enter a token identifier").capfirst(),
			},
			groups: {
				label: t("groups"),
				type: "select",
				multiple: true,
				options: [],
				placeholder: t("select groups").capfirst(),
			},
			modules_responsible: {
				label: t("concerned modules (units responsible)"),
				type: "select",
				multiple: true,
				options: [],
				default: [],
				dependsOn: "groups",
				placeholder: t("modules").capfirst(),
				disabled: true,
			},
			lifespan: {
				label: t("infinite lifespan token"),
				type: "bool",
			},
			city: {
				label: t("city"),
				type: "select",
				multiple: true,
				options: citiesOptions,
				default: [],
			},
		});

	useEffect(() => {
		if (!groups) return;
		setFilterOptions("groups", groups);
	}, [groups]);

	useEffect(() => {
		if (!fetchedModulesData) return;
		const requestDataModules =
			fetchedModulesData?.pages.flatMap((page) => page?.data.results) ??
			[];
		const modulesOptions = requestDataModules.map((module) => {
			return { label: module.code_module, value: module.code_module };
		});
		setFilterOptions("modules_responsible", modulesOptions);
	}, [fetchedModulesData]);

	useEffect(() => {
		if (
			filters.groups.selected.some(
				(group) => group.label === "units_responsible"
			)
		) {
			setFilterDisabled("modules_responsible", false);
			setSelectedOption("modules_responsible", []);
		} else {
			setFilterDisabled("modules_responsible", true);
			setSelectedOption("modules_responsible", []);
		}
	}, [filters.groups]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("API keygen").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForms filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("generate").capfirst()}
					onClick={() => generateKey(filters)}
				/>
			</Card.Footer>
		</Box>
	);
}
