import { t } from "../../Config/i18n";

export default function NoModuleSpecified() {
	return (
		<div className="text-center">
			<em className="text-red">
				{t("you must specify an unit to load this").capfirst()}
			</em>
		</div>
	);
}
