import { Box } from "../../AdminLTE3/Box";
import { CityPromotionStatus } from "../../Cities/Graphs/PromotionsStatus";
import FindOutMoreFooter from "../FindOutMoreFooter";

export default function PromotionStatus({ city, promotion, detailPath }) {
	return (
		<Box style={{ height: "100%" }}>
			<CityPromotionStatus
				key={`Promotion_status_${city.value}_${promotion.label}`}
				cityOption={city}
				promotionOption={promotion}
			/>
			<FindOutMoreFooter detailPath={detailPath} />
		</Box>
	);
	/** return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("promotions status").capfirst()} />
			</Card.Header>
			<Card.Body>
				<Row md={promotions.length}>
					{citiesWithNational.flatMap((city) =>
						promotions.map((promotion) => (
							<CityPromotionStatus
								key={`Promotion_status_${city.value}_${promotion.label}`}
								cityOption={city}
								promotionOption={promotion}
								displayCityName
							/>
						))
					)}
				</Row>
			</Card.Body>
		</Box>
	); */
}
