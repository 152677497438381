import { useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import CommonDatatableContainer from "../Internship-PartTime/CommonDatatableContainer";
import { useTepitechAlertsQuery } from "./Queries";
import getStudentColumns from "./TepitechAlertsDataTable";

function missingPoints(score, goal) {
	if (score < 0) {
		return goal;
	}
	const pts = goal - score;
	return pts < 0 ? 0 : pts;
}

export default function TepitechAlertsDataTableContainer({
	cities,
	curriculums,
	promotions,
	alertsOnly,
	scholarYears,
	areRequestEnabled,
}) {
	const columns = getStudentColumns();
	const citiesValues = [];
	cities.forEach((city) => {
		citiesValues.push(city.value);
	});
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		error,
		isLoading,
		isFetching,
	} = useTepitechAlertsQuery(
		cities.extract("value"),
		curriculums,
		promotions,
		scholarYears,
		areRequestEnabled
	);
	if (areRequestEnabled && !isFetchingNextPage && hasNextPage) {
		fetchNextPage();
	}
	const requestData = data?.pages.flatMap((page) => page.results) ?? [];
	const filteredRequestData = requestData.filter((row) => {
		if (alertsOnly && row.alert === "valid") return false;
		return true;
	});

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Tepitech alerts Data Table: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	const exportConfig = {
		alert: "alert",
		login: "student.login",
		promotion: "student.promo.promotion_year",
		curriculum: (row) =>
			`${row.student.promo.cursus.code}/${row.student.promo.subpromo}`,
		city: "student.promo.city.name",
		mark: "score",
		missing_points: (row) => missingPoints(row.score, row.score_goal),
	};

	return (
		<CommonDatatableContainer
			data={filteredRequestData}
			error={error}
			columns={columns}
			exportConfig={{ columns: exportConfig, title: "tepitech_alerts" }}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
