import { createContext, useMemo, useState } from "react";
import ErrorModal from "./ErrorModal";

export const ErrorModalContext = createContext(undefined);

export function ErrorModalContextProvider({ children }) {
	const [modalOptions, setModalOptions] = useState({
		logs: [],
		validation: false,
	});
	const [displayModal, setDisplayModal] = useState(false);

	const value = useMemo(
		() => ({
			setDisplayModal,
			setModalOptions,
		}),
		[displayModal, modalOptions]
	);

	return (
		<ErrorModalContext.Provider value={value}>
			<ErrorModal
				validation={modalOptions.validation}
				logs={modalOptions.logs}
				displayModal={displayModal}
				setDisplayModal={setDisplayModal}
			/>
			{children}
		</ErrorModalContext.Provider>
	);
}
