export default class Roadblocks {
	constructor(api) {
		this.api = api;
	}

	getRoablockConfig(tek_year = null) {
		return this.api.processRequest("GET", "/roadblocks/config", {
			params: {
				tek_year,
			},
		});
	}
}
