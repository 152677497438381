import { faChartBar } from "@fortawesome/free-solid-svg-icons";

import { t } from "../../Config/i18n";
import { Widget } from "../AdminLTE3/Widget";

export default function SideStats({ data }) {
	if (!data) {
		return null;
	}
	const values = data
		.map(({ mark_without_status }) => mark_without_status)
		.sort((a, b) => a - b);
	const med = values[Math.floor(values.length / 2)];
	const avg = values.reduce((a, b) => a + b, 0) / values.length;
	return (
		<div>
			<Widget
				icon={faChartBar}
				title={`${t("median").capfirst()} (${t(
					"mark without status"
				)})`}
				subtitle={`${t("the median is").capfirst()} ${(
					med || 0
				).toFixed(2)}`}
				size="5x"
				color="rgba(255, 255, 255, 0.5)"
			/>
			<Widget
				icon={faChartBar}
				title={`${t("average").capfirst()} (${t(
					"mark without status"
				)})`}
				subtitle={`${t("the average is").capfirst()} ${(
					avg || 0
				).toFixed(2)}`}
				size="5x"
				color="rgba(255, 255, 255, 0.5)"
				type="cyan"
			/>
		</div>
	);
}
