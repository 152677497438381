import { t } from "../../../../../Config/i18n";

export default function MissingTranscripts({ nbMissing }) {
	if (nbMissing === 0) return null;
	return (
		<h3 className="text-center text-red">
			{`${t("missingTranscriptWithCount", { count: nbMissing })}`}
		</h3>
	);
}
