import { Duration } from "luxon";
import { useEffect, useMemo, useRef } from "react";
import DiversityDataTable from "./DiversityDataTable";
import { useStudentsDiversityQuery } from "./Queries";

function getHoursFromDeltaTime(deltatime) {
	return Math.floor(Duration.fromISO(deltatime).as("hours"));
}

export default function DiversityDataTableContainer({
	year,
	cities,
	curriculums,
	promotions,
}) {
	const isRequestEnabled = useRef(false);
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		isLoading,
	} = useStudentsDiversityQuery(
		cities,
		promotions,
		curriculums,
		year,
		isRequestEnabled.current
	);

	if (isRequestEnabled.current && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const requestData = data?.pages.flatMap((page) => page.data.results);
	const tableData = useMemo(() => {
		if (!requestData || !isRequestEnabled.current) return [];
		return requestData.map((apiRow, idx) => ({
			id: idx,
			login: apiRow.student.login,
			promotion: apiRow.student.promo.promotion_year,
			curriculum: `${apiRow.student.promo.cursus.code}/${apiRow.student.promo.subpromo}`,
			city: apiRow.student.promo.city.name,
			obtained_xp: apiRow.obtained_xp,
			duration_progress: `${getHoursFromDeltaTime(
				apiRow.duration_progress
			)}h`,
			duration_acquired: `${getHoursFromDeltaTime(
				apiRow.duration_acquired
			)}h`,
			credits: apiRow.credits,
		}));
	}, [requestData]);

	const exportConfig = {
		"login": "login",
		"promotion": "promotion",
		"curriculum": "curriculum",
		"city": "city",
		"xp": "obtained_xp",
		"duration in progress": "duration_progress",
		"duration acquired": "duration_acquired",
		"credits": "credits",
	};

	useEffect(() => {
		isRequestEnabled.current = true;
	}, [year, cities, curriculums, promotions]);

	return (
		<DiversityDataTable
			data={tableData}
			exportConfig={{
				columns: exportConfig,
				title: "diversity_stats",
			}}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
}
