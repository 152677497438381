import {
	faCircleXmark,
	faInfoCircle,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Center, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { t } from "../../Config/i18n";
import LoadingOverlay from "../AdminLTE3/LoadingOverlay";
import { useValidationLogsQuery } from "./Queries";

function CategorySelector({ state: [state, stateSetter] }) {
	return (
		<>
			<Button
				variant="outline-primary"
				type="button"
				onClick={() => {
					stateSetter(null);
				}}
			>
				{t("display all").capfirst()}
			</Button>
			<Button
				variant={state === "warning" ? "warning" : "outline-warning"}
				type="button"
				onClick={() => {
					stateSetter(state === "warning" ? null : "warning");
				}}
			>
				<FontAwesomeIcon
					icon={faTriangleExclamation}
					style={{ color: "#FF964F" }}
				/>{" "}
				{t("warnings only").capfirst()}
			</Button>
			<Button
				variant={state === "error" ? "danger" : "outline-danger"}
				type="button"
				onClick={() => {
					stateSetter(state === "error" ? null : "error");
				}}
			>
				<FontAwesomeIcon
					icon={faCircleXmark}
					style={{ color: "#FF6961" }}
				/>{" "}
				{t("errors only").capfirst()}
			</Button>
		</>
	);
}

export function ModalContent({ isLoading, error, logs }) {
	if (isLoading) return <LoadingOverlay loading={isLoading} />;
	if (error || !logs)
		return (
			<h6 className="text-red" style={{ textAlign: "center" }}>
				{`${t(
					"an error occurred while requesting the logs"
				).capfirst()}: ${error.message}`}
			</h6>
		);
	const [category, setCategory] = useState(null);
	const filteredLogs = logs.filter((log) => {
		if (!category) return true;
		return category === log.level;
	});
	return (
		<>
			{filteredLogs.length !== 0 ? (
				filteredLogs.map((log) => (
					<h6
						key={`log_message_${log.message}`}
						style={{ textAlign: "center" }}
					>
						{log.level === "warning" ? (
							<FontAwesomeIcon
								icon={faTriangleExclamation}
								style={{ color: "#FF964F" }}
							/>
						) : (
							<FontAwesomeIcon
								icon={faCircleXmark}
								style={{ color: "#FF6961" }}
							/>
						)}{" "}
						{log.message}
					</h6>
				))
			) : (
				<h6 style={{ textAlign: "center" }}>
					{t("no log to display!").capfirst()}
				</h6>
			)}
			<Center>
				<CategorySelector state={[category, setCategory]} />
			</Center>
		</>
	);
}

function ValidationLogsModal({ year, module_code, openedModal, close }) {
	const {
		isFetching,
		error,
		data: logs,
	} = useValidationLogsQuery(year, module_code, openedModal);
	return (
		<Modal
			opened={openedModal}
			onClose={close}
			title={t("validation's logs").capfirst()}
			centered
			size="lg"
		>
			<ModalContent isLoading={isFetching} error={error} logs={logs} />
		</Modal>
	);
}

export default function TesseractValidationLogsButton({ year, module_code }) {
	const [openedModal, { open, close }] = useDisclosure(false);

	return (
		<>
			<Tooltip label={t("validation's logs").capfirst()} withinPortal>
				<ActionIcon onClick={open}>
					<FontAwesomeIcon icon={faInfoCircle} />
				</ActionIcon>
			</Tooltip>
			<ValidationLogsModal
				year={year}
				module_code={module_code}
				openedModal={openedModal}
				close={close}
			/>
		</>
	);
}
