import SauronMantineNavtabs from "../Sauron/SauronMantineNavtabs";
import RoadblockConfig from "./RoadblockConfig";

export default function ConfigTab({ year }) {
	const promotionsYear = [1, 2, 3, 4, 5];

	const subtabs = [];
	promotionsYear.forEach((promotionYear) => {
		subtabs.push({
			disable: false,
			display: true,
			label: `pge${promotionYear}`,
			component: (
				<RoadblockConfig scholarYear={year} tekYear={promotionYear} />
			),
		});
	});
	return <SauronMantineNavtabs tabs={subtabs} affectsURI />;
}
