export default function LoadingOverlay({ loading }) {
	const parentStyle = {
		position: "absolute",
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		width: "100%",
		height: "100%",
		zIndex: 50,
	};
	const childStyle = { placeSelf: "center" };

	if (loading) {
		return (
			<div style={parentStyle} className="d-flex justify-content-center">
				<div
					style={childStyle}
					className="spinner-border"
					role="status"
				>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}
	return null;
}
