import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ScraperApiHelperContext } from "../../contexts";
import { t } from "../../Config/i18n";

export const useCSVProComNotesMutation = (
	UPLOAD_STATUS,
	setErrorMessage,
	setUploadStatus,
	onUploadSuccess,
	year,
	module_code,
	slug,
	project_instance,
	bttfMode
) => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: (data) => {
			setUploadStatus(() => UPLOAD_STATUS.pending);
			setErrorMessage(() => "");
			return scraper.professional_communication.postMarks(
				year,
				module_code,
				slug,
				project_instance,
				bttfMode,
				data.data
			);
		},
		onError: (error) => {
			let errorToDisplay = error;
			if (error?.response?.data) {
				if (typeof error.response.data.detail === "string") {
					errorToDisplay = error.response.data.detail;
				} else errorToDisplay = JSON.stringify(error.response.data);
			}
			toast.error(
				`Professional Communication: ${t(
					"an error occured during import"
				).capfirst()} ${errorToDisplay}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
			setUploadStatus(() => UPLOAD_STATUS.error);
			setErrorMessage(() => errorToDisplay);
		},
		onSuccess: (data) => {
			setUploadStatus(() => UPLOAD_STATUS.success);
			toast.success(
				`Professional Communication: ${t(
					"successfully imported"
				).capfirst()}`,
				{
					theme: "colored",
					autoClose: 2000,
				}
			);
			onUploadSuccess(data.data);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["professional_communication"],
			});
		},
	});
};
