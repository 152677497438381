import { t } from "i18next";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BoxTitle } from "../AdminLTE3/Box";
import { displayRange } from "../Utils/Utils";
import NoModuleSpecified from "./NoModuleSpecified";

function NoCriteriaDefined() {
	return (
		<Col>
			<em className="text-gray">{t("no criteria defined").capfirst()}</em>
		</Col>
	);
}

function SkillAndRange({ skillAndRange }) {
	return (
		<div
			className="tab1 flexColumn"
			key={`${skillAndRange.skill}_div_displaySkillAndRange`}
		>
			{skillAndRange.skill_list !== undefined ? (
				<div>
					{t(
						skillAndRange.combination_mode === "mean"
							? "mean of"
							: "sum of"
					).capfirst()}
					<br />
					<table className="mt-1">
						<tbody>
							{skillAndRange.skill_list.map((skillName) => (
								<tr
									key={`_displaySkillAndRange_tr_${skillName}`}
								>
									<td
										key={`_displaySkillAndRange_td_${skillName}`}
										className="discreetBorder blue bold"
									>
										{skillName}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div
					className="blue bold"
					key={`${skillAndRange.skill}_displaySkillAndRange`}
				>
					{skillAndRange.skill}
				</div>
			)}
			{displayRange(skillAndRange, true)}
		</div>
	);
}

function TagAndRange({ category, criteria, tagAndRange }) {
	return (
		<div
			className="tab1 flexColumn"
			key={`${category}_${criteria}_${tagAndRange.tag}_div_displayTagAndRange`}
		>
			<code
				className="bold"
				key={`${category}_${criteria}_${tagAndRange.tag}_displayTagAndRange`}
			>
				{tagAndRange.tag}
			</code>
			{displayRange(tagAndRange, true)}
		</div>
	);
}

function NumberOfSkillsCriteria({ category, criteria, specifications }) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_number_of_skills`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_number_of_skills`}
			>
				{t("number of skills").capfirst()}
			</span>
			{displayRange(specifications, true)}
		</div>
	);
}

function NumberOfSkillsWithTagsCriteria({
	category,
	criteria,
	specifications,
}) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_number_of_skills_with_tags`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_number_of_skills_with_tags`}
			>
				{t("number of skills with specific tags").capfirst()}
			</span>
			{Object.values(specifications).map((tagAndRange) => (
				<TagAndRange
					category={category}
					criteria={criteria}
					tagAndRange={tagAndRange}
					key={`${category}_${criteria}_${tagAndRange.tag}_tagAndRange`}
				/>
			))}
		</div>
	);
}

function NumberOfTagsWithAtLeastOneSkill({
	category,
	criteria,
	specifications,
}) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_number_of_tags_with_at_least_one_skill`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_number_of_tags_with_at_least_one_skill`}
			>
				{t(
					"number of tags with at least one validated skill"
				).capfirst()}
			</span>
			{displayRange(specifications, true)}
		</div>
	);
}

function SkillValidated({ category, criteria, specifications }) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_skills_validated`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_skills_validated`}
			>
				{t("validated skills").capfirst()}
			</span>
			{specifications.map((skill) => (
				<p key={`${category}_${criteria}_skills_validated_p`}>
					{skill}
				</p>
			))}
		</div>
	);
}

function SkillFailed({ category, criteria, specifications }) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_skills_failed`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_skills_failed`}
			>
				{t("failed skills").capfirst()}
			</span>
			{specifications.map((skill) => (
				<p key={`${category}_${criteria}_skills_failed_p`}>{skill}</p>
			))}
		</div>
	);
}

function SkillsWithValuesInRange({ category, criteria, specifications }) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_skills_with_values_in_range`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_skills_with_values_in_range`}
			>
				{t("skills with specific values").capfirst()}
			</span>
			{Object.values(specifications).map((skillAndRange) => (
				<SkillAndRange
					key={`${category}_${criteria}_${skillAndRange.skill}_skill_and_range`}
					skillAndRange={skillAndRange}
				/>
			))}
		</div>
	);
}

function AtLeastOneOf({ category, criteria, specifications }) {
	return (
		<div
			className="flexColumn"
			key={`${category}_${criteria}_div_at_least_one_of`}
		>
			<span
				className="bold"
				key={`${category}_${criteria}_at_least_one_of`}
			>
				{t("at least one of the criteria set met").capfirst()}
			</span>
			<span className="tab1" key={`${category}_${criteria}_criteria`}>
				{Object.values(specifications).map((data, index) => (
					<div key={`${category}_${criteria}_criterion_${index}`}>
						{index !== 0 && <em>{t("or").capfirst()}</em>}
						<div className="discreetBorder">
							{Object.entries(data).map(([key, skill]) => (
								<Criteria
									criteria={key}
									specifications={skill}
									key={key}
								/>
							))}
						</div>
					</div>
				))}
			</span>
		</div>
	);
}

function Criteria({ category, criteria, specifications }) {
	switch (criteria) {
		case "number_of_skills":
			return (
				<NumberOfSkillsCriteria
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		case "number_of_skills_with_tags":
			return (
				<NumberOfSkillsWithTagsCriteria
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		case "number_of_tags_with_at_least_one_skill":
			return (
				<NumberOfTagsWithAtLeastOneSkill
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		case "skills_validated":
			return (
				<SkillValidated
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		case "skills_failed":
			return (
				<SkillFailed
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		case "skills_with_values_in_range":
			return (
				<SkillsWithValuesInRange
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		case "at_least_one_of":
			return (
				<AtLeastOneOf
					category={category}
					criteria={criteria}
					specifications={specifications}
				/>
			);
		default:
			return null;
	}
}

function Category({ title, criterias, maxBTTFGrade }) {
	return (
		<div style={{ padding: "0 15px" }} key={`${title}_div_displayElement`}>
			<Card.Header>
				<BoxTitle
					className="skillTitle"
					title={t(title).capfirst()}
					subtitle={
						title &&
						maxBTTFGrade === title &&
						`(${t("highest grade for BTTF")})`
					}
				/>
			</Card.Header>
			<Card.Body className="flexColumn">
				{Object.entries(criterias).map(([criteria, specifications]) => (
					<Fragment key={`${title}_${criteria}_conditions_fragment`}>
						<Criteria
							category={title}
							criteria={criteria}
							specifications={specifications}
						/>
						<br />
					</Fragment>
				))}
			</Card.Body>
		</div>
	);
}

export default function ValidationCriteria({ elements, areRequestEnabled }) {
	if (!areRequestEnabled) return <NoModuleSpecified />;
	if (!elements) return <NoCriteriaDefined />;
	return (
		<Row sm={1} md={2} xl={4}>
			{Object.entries(elements)
				.filter(([key]) => key !== "max_bttf_grade")
				.map(([key, data]) => (
					<Category
						title={key}
						criterias={data}
						maxBTTFGrade={elements.max_bttf_grade}
						key={`${key}_category`}
					/>
				))}
		</Row>
	);
}
