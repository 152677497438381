import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { useEnglishSkillsQuery } from "../../../Queries";
import SkillsRadar from "./SkillsRadar";
import SkillsTable from "./SkillsTable";

export default function EnglishResults({ login }) {
	const {
		data: englishInstances,
		error,
		isFetching,
	} = useEnglishSkillsQuery(login);

	requestErrorToastManager("Student: English skills tab", error);

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!englishInstances || englishInstances.length === 0)
		return <NoDataAvailable />;
	return (
		<div>
			<SkillsTable englishInstances={englishInstances} />
			<SkillsRadar englishInstances={englishInstances} />
		</div>
	);
}
