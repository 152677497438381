export default class Student {
	constructor(api) {
		this.api = api;
	}

	getStudent = (login) =>
		this.api.processRequest("GET", "/students", {
			params: { login },
		});

	getEnglishSkills = (login) =>
		this.api.processRequest("GET", "/students/english/skills", {
			params: { login },
		});

	getTepitech = (login) =>
		this.api.processRequest("GET", "/students/english/tepitech", {
			params: { login },
		});

	getPresences = (login) =>
		this.api.processRequest("GET", `/students/${login}/presences`);

	getHub(login) {
		return this.api.processRequest("GET", "/students/hub", {
			params: { login },
		});
	}

	getLogtime(offset = 0, login = null, limit = 100) {
		return this.api
			.processRequest("GET", "/students/logtime", {
				params: {
					offset,
					limit,
					login,
				},
			})
			.then((res) => res.data);
	}

	getUnregisteredProjects(login) {
		return this.api.processRequest(
			"GET",
			"/students/projects/not_registered",
			{
				params: { login },
			}
		);
	}

	getRoadblocks(login, projected = false) {
		return this.api.processRequest("GET", "/students/roadblocks", {
			params: { login, projected },
		});
	}

	getIntuituPersonae(offset, login, last_only = false, limit = 100) {
		return this.api.processRequest("GET", "/students/intuitu_personae", {
			params: {
				offset,
				login,
				last_only,
				limit,
			},
		});
	}

	getTranscripts(login) {
		const params = {
			login,
		};
		return this.api.processRequest("GET", "/students/transcripts", {
			params,
		});
	}

	getInvestment(login) {
		const params = {
			login,
		};
		return this.api.processRequest("GET", "/students/investment", {
			params,
		});
	}
}
