import { requestErrorToastManager } from "../Utils/Toasts";
import DataTable from "./DataTable";
import { useStudentsInfiniteQuery } from "./Queries";

export default function DataTableContainer({
	city,
	promotion,
	curriculum,
	isRequestEnabled,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
		isFetching,
		error,
	} = useStudentsInfiniteQuery(
		city?.value,
		promotion,
		curriculum,
		isRequestEnabled
	);

	if (isRequestEnabled && !isFetchingNextPage && hasNextPage) fetchNextPage();
	const students = data?.pages.flatMap((page) => page) ?? [];

	requestErrorToastManager("Investement Report", error);

	return (
		<DataTable
			data={students}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
