import {
	faCheck,
	faClone,
	faExclamationTriangle,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useContext } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { useValidationLogsQuery } from "../Tesseract/Queries";
import { getCurrentAcademicYear, monoOption } from "../Utils/Utils";
import { visibilityToOption } from "./AdditionModal";
import { AdditionModalContext } from "./AdditionModalContext";
import { ErrorModalContext } from "./ErrorModalContext";
import {
	useDeleteConfigurationMutation,
	useUpdateConfigurationMutation,
} from "./Queries";

const onDuplicateClick = (
	setDefaultSettings,
	setDisplayModal,
	configuration
) => {
	setDefaultSettings({
		year: monoOption(getCurrentAcademicYear()),
		module: monoOption(configuration.unit),
		github_repository: configuration.github_repository,
		config_filepath: configuration.config_filepath,
		visibility: visibilityToOption(configuration.visibility),
	});
	setDisplayModal(true);
};

const onUpdateClick = (configuration, filters, updateMutation) => {
	const key = configuration.id;
	const config_filepath = filters.config_filepath.selected;
	const github_repository = filters.github_repository.selected;
	const visibility = filters.visibility.selected?.value;
	updateMutation.mutate({
		key,
		config_filepath,
		github_repository,
		visibility,
	});
};

const getVariant = (time, logs) => {
	if (!time) return "secondary";
	if (logs.length === 0) return "success";
	return logs.find((log) => log.level === "error") !== undefined
		? "danger"
		: "warning";
};

function TimeInformation({ time, logs, validation }) {
	const { setDisplayModal, setModalOptions } = useContext(ErrorModalContext);
	const variant = getVariant(time, logs);
	const isError = !(variant === "success" || variant === "secondary");
	return (
		<Row className="align-items-center">
			<Col lg={9}>
				<h6>
					{t(
						!validation ? "last configuration:" : "last validation:"
					).capfirst()}
				</h6>
				{!time
					? "N/A"
					: time.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
			</Col>
			<Col className="text-right">
				<Button
					variant={variant}
					disabled={!isError}
					onClick={() => {
						setDisplayModal(true);
						setModalOptions({
							logs,
							validation,
						});
					}}
				>
					<FontAwesomeIcon
						icon={isError ? faExclamationTriangle : faCheck}
					/>
				</Button>
			</Col>
		</Row>
	);
}

function TimeInformations({ configuration }) {
	const { data: validationLogs = [] } = useValidationLogsQuery(
		configuration.scholar_year,
		configuration.unit,
		true
	);
	return (
		<>
			<div className="mb-4">
				<TimeInformation
					time={configuration.last_unit_responsibles_update}
					logs={
						configuration.error_message
							? [
									{
										level: "error",
										message: configuration.error_message,
									},
								]
							: []
					}
					validation={false}
				/>
			</div>
			<TimeInformation
				time={configuration.last_tesseract_update}
				logs={validationLogs}
				validation
			/>
		</>
	);
}

export default function ConfigurationCard({ configuration }) {
	const { setDisplayModal, setDefaultSettings } =
		useContext(AdditionModalContext);
	const deletionMutation = useDeleteConfigurationMutation();
	const updateMutation = useUpdateConfigurationMutation();
	const visibilityOptions = ["public", "draft", "private"].map(
		visibilityToOption
	);
	const { filters, setSelectedOption } = useFilters({
		github_repository: {
			label: t("GitHub repository").capfirst(),
			type: "text",
			default: configuration.github_repository,
		},
		config_filepath: {
			label: t("configuration filepath").capfirst(),
			type: "text",
			default: configuration.config_filepath,
		},
		visibility: {
			label: t("visibility").capfirst(),
			type: "select",
			placeholder: t("choose visibility").capfirst(),
			options: visibilityOptions,
			default: visibilityToOption(configuration.visibility),
			clearable: false,
		},
	});

	return (
		<Col lg={3} md="auto" className="mt-4">
			<Box style={{ height: "100%" }}>
				<Card.Header>
					<Row className="align-items-center">
						<Col>
							<BoxTitle
								title={configuration.unit}
								className="text-left"
							/>
						</Col>
						<Col>
							<div className="text-right">
								<Button
									className="mr-2"
									onClick={() =>
										onDuplicateClick(
											setDefaultSettings,
											setDisplayModal,
											configuration
										)
									}
								>
									<FontAwesomeIcon icon={faClone} />
								</Button>
								<Button
									disabled={configuration.disabled}
									variant="danger"
									onClick={() => {
										deletionMutation.mutate(
											configuration.id
										);
									}}
								>
									<FontAwesomeIcon icon={faTrashCan} />
								</Button>
							</div>
						</Col>
					</Row>
				</Card.Header>
				<Card.Header>
					<TimeInformations configuration={configuration} />
				</Card.Header>
				<Card.Body>
					<FiltersForm
						filters={filters}
						onChange={setSelectedOption}
					/>
				</Card.Body>
				<Card.Footer>
					<BoxFooterButton
						disabled={configuration.disabled}
						label={t("update").capfirst()}
						onClick={() =>
							onUpdateClick(
								configuration,
								filters,
								updateMutation
							)
						}
					/>
				</Card.Footer>
			</Box>
		</Col>
	);
}
