import { useQueries, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getCurrentAcademicYear } from "../Utils/Utils";

export const useMissingTranscriptsQuery = (city, promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const code = city.value;
	return useQuery({
		queryKey: [
			"dashboard",
			"transcripts",
			"missing_trascripts",
			code,
			promotions,
		],
		queryFn: () =>
			scraper.students.getMissingTranscripts([code], promotions),
		select: (response) => response.data.missing_transcripts,
	});
};

export const useIntuitusInAlertQuery = (city, promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const code = city.value;
	return useQuery({
		queryKey: [
			"dashboard",
			"intuitu_personae",
			"intuitus_in_alert",
			code,
			promotions,
		],
		queryFn: () =>
			scraper.students.getIntuituPersonaeAlertsCount(code, promotions),
		select: (response) => response.data,
	});
};

export const useModulesToValidateQuery = (city, curriculum) => {
	const scraper = useContext(ScraperApiHelperContext);
	const code = city.value;
	return useQuery({
		queryKey: ["dashboard", "modules_to_validate", code, curriculum],
		queryFn: () => scraper.module.getNonValidatedCount(code, curriculum),
		select: (response) => response.data.non_validated_modules,
	});
};

export const useTesseractUnstableModulesQuery = (supervisedModules) => {
	const scraper = useContext(ScraperApiHelperContext);
	const year = getCurrentAcademicYear();
	return useQuery({
		queryKey: ["dashboard", "tesseract_unstable_modules", year],
		queryFn: () => scraper.tesseract.getUnstableModules(year),
		select: (response) => response.data.unstable_modules,
		enabled: supervisedModules.length !== 0,
	});
};

const generateValidationLogQuery = (scraper, year, moduleCode) => ({
	queryKey: ["dashboard", "tesseract", "validation_logs", year, moduleCode],
	queryFn: () => scraper.tesseract.getModuleValidationLogs(year, moduleCode),
	select: (data) => {
		const logs = data.data.log_elements;
		return logs.reduce(
			(acc, log) => {
				acc[log.level] += 1;
				return acc;
			},
			{ warning: 0, error: 0 }
		);
	},
});

export const useValidationLogsQueries = (moduleCodes) => {
	const scraper = useContext(ScraperApiHelperContext);
	const year = getCurrentAcademicYear();
	return useQueries({
		queries: moduleCodes.map((moduleCode) =>
			generateValidationLogQuery(scraper, year, moduleCode)
		),
	});
};

export const useValidationLogsQuery = (year, moduleCode) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery(generateValidationLogQuery(scraper, year, moduleCode));
};

export const useUndismissedHistory = (enabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["dashboard", "scraping_history"],
		queryFn: () => scraper.scraperInfos.getScrapingUndismissed(),
		enabled,
	});
};
