import { DateTime } from "luxon";
import { t } from "../../Config/i18n";
import ExportButton from "../Sauron/ExportButton";
import ProgressionGraph from "../Sauron/Graphs/ProgressionGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import * as Utils from "../Utils/Utils";
import { sortObjectByKeys } from "../Utils/Utils";
import { useTepitechProgressionsStats } from "./Queries";

export default function CitiesProgressions({
	cities,
	curriculums,
	years,
	areRequestEnabled,
	national,
}) {
	const { data, error, isFetching } = useTepitechProgressionsStats(
		cities.extract("value"),
		years.extract("value"),
		curriculums.extract("label"),
		areRequestEnabled
	);

	let dates = [];
	const filtered = {};
	if (data && Object.keys(data).length > 0) {
		dates =
			data[
				Object.keys(data).filter((key) => key.startsWith("global"))[0]
			].extract("date");
		Object.keys(data).forEach((key) => {
			if (!national && key.startsWith("france")) return;
			if (!key.startsWith("global") && !key.startsWith("europe")) {
				filtered[key.capfirst()] = data[key].extract("value");
			}
		});
	}
	dates = dates.map((date) => DateTime.fromISO(date).toLocaleString());
	const datasets = sortObjectByKeys(filtered);
	const csvLabels = [
		"september",
		"october",
		"november",
		"december",
		"january",
		"february",
		"march",
		"april",
		"may",
		"june",
		"july",
	];

	requestErrorToastManager("Tepitech Progress", error);

	return (
		<SauronChartsContainer
			title={t("Tepitech progress").capfirst()}
			isLoading={isFetching}
			topRightButton={
				<ExportButton
					onClick={() =>
						Utils.exportProgression(
							datasets,
							csvLabels,
							"tepitech_progression"
						)
					}
					disabled={isFetching}
				/>
			}
		>
			<ProgressionGraph stats={datasets} labels={dates} />
		</SauronChartsContainer>
	);
}
