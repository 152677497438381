import { Table } from "react-bootstrap";
import { t } from "../../../../../../Config/i18n";

function ProjectOverviewTableLine({ project }) {
	return (
		<tr>
			<th>{project.name}</th>
			<td>{project.xp}</td>
			<td>{project.mark}</td>
		</tr>
	);
}

function ProjectOverviewTable({ projects }) {
	return (
		<Table striped hover>
			<thead className="thead-light">
				<tr>
					<th>{t("hub project's names").capfirst()}</th>
					<th>{"xp".capfirst()}</th>
					<th>{t("marks").capfirst()}</th>
				</tr>
			</thead>
			<tbody>
				{projects.map((project) => (
					<ProjectOverviewTableLine
						key={project.name + project.xp}
						project={project}
					/>
				))}
			</tbody>
		</Table>
	);
}

export default function ProjectOverview({ projects }) {
	if (Object.keys(projects).length === 0) {
		return (
			<h5 className="text-info mb-3">
				{t("no ongoing hub project").capfirst()}
			</h5>
		);
	}
	return (
		<div>
			<h5 className="text-muted">
				{t("hub projects overview").capfirst()}
			</h5>
			<ProjectOverviewTable projects={projects} />
		</div>
	);
}
