import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useConfigurationsInfiniteQuery = (year) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["tesseract_configuration", "configurations", year],
		queryFn: ({ pageParam }) =>
			scraper.tesseract.getConfigurations(pageParam, year, 100),
		initialPageParam: 0,
		getNextPageParam,
		select: (response) => ({
			...response,
			pages: response.pages.map((page) => page.data.results),
		}),
	});
};

export const useConfigurationCreationMutation = (setDisplayModal) => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({
			year = null,
			module = null,
			config_filepath = null,
			github_repository = null,
			visibility = null,
		}) =>
			scraper.tesseract.createConfiguration(
				year,
				module,
				config_filepath,
				github_repository,
				visibility
			),
		onError: (error) => {
			toast.error(
				`${t("creation failure").capfirst()}: ${
					error?.response?.data?.detail ?? error
				}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			toast.success(`${t("successful creation").capfirst()}`, {
				theme: "colored",
				autoClose: 2000,
			});
			setDisplayModal(false);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["tesseract_configuration"],
			});
		},
	});
};

export const useDeleteConfigurationMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: (key) => scraper.tesseract.deleteConfiguration(key),
		onError: (error) => {
			toast.error(
				`${t("deletion failure").capfirst()}: ${
					error?.response?.data?.detail ?? error
				}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			toast.success(`${t("successful deletion").capfirst()}`, {
				theme: "colored",
				autoClose: 2000,
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["tesseract_configuration"],
			});
		},
	});
};

export const useUpdateConfigurationMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({
			key = null,
			config_filepath = null,
			github_repository = null,
			visibility = null,
		}) =>
			scraper.tesseract.updateConfiguration(
				key,
				github_repository,
				config_filepath,
				visibility
			),
		onError: (error) => {
			toast.error(
				`${t("update failure").capfirst()}: ${
					error?.response?.data?.detail ?? error
				}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			toast.success(`${t("successful update").capfirst()}`, {
				theme: "colored",
				autoClose: 2000,
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["tesseract_configuration"],
			});
		},
	});
};

export const useTesseractUnstableModulesQuery = (year) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"tesseract_configuration",
			"tesseract_unstable_modules",
			year,
		],
		queryFn: () => scraper.tesseract.getUnstableModules(year),
		select: (response) => response.data.unstable_modules,
	});
};
