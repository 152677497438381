import { downloadCSV } from "../Utils/Utils";
import ExportButton from "./ExportButton";

const objectByString = (object, string) => {
	const property = string.replace(/\[(\w+)\]/g, ".$1").replace(/^\./, ""); // convert indexes to properties then strip leading dot
	const properties = property.split(".");
	let element = object;
	for (let i = 0; i < properties.length; ++i) {
		const k = properties[i];
		if (!(k in element)) return null;
		element = element[k];
	}
	return element;
};

const getEntryValue = (row, pathOrFunction) => {
	const entryValue =
		typeof pathOrFunction === "function"
			? pathOrFunction(row)
			: objectByString(row, pathOrFunction);
	return entryValue === undefined || entryValue === null ? "N/A" : entryValue;
};

const createTableFromConfig = (data, filteredExportConfig) => {
	const headers = filteredExportConfig.map(([header]) => header);
	const table = data.map((row) =>
		filteredExportConfig.flatMap(([, path]) => getEntryValue(row, path))
	);
	return [headers].concat(table);
};

const defaultOnExportFunction = (data, exportConfig, titleCSV) => {
	if (!data || data.length === 0) return;
	downloadCSV(
		createTableFromConfig(data, Object.entries(exportConfig)),
		titleCSV
	);
};

export default function DataTableExportButton({
	data,
	exportConfig,
	titleCSV,
	...otherProps
}) {
	return (
		<ExportButton
			onClick={() =>
				defaultOnExportFunction(data, exportConfig, titleCSV)
			}
			{...otherProps}
		/>
	);
}

export { defaultOnExportFunction, objectByString };
