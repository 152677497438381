import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { requestErrorToastManager } from "../Utils/Toasts";
import DataTableContainer from "./DataTableContainer";
import Filters from "./Filters";
import InvestmentProgression from "./InvestmentProgression";
import { useInvestmentProgressionStatsQuery } from "./Queries";

export default function InvestmentOverview() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		city: null,
		promotion: null,
		begin: null,
		end: null,
	});
	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	const {
		data: stats,
		error: statsError,
		isFetching: statsFetching,
	} = useInvestmentProgressionStatsQuery(
		[activeFilters.city?.value],
		[activeFilters.promotion?.value],
		true
	);

	requestErrorToastManager("Investement Progression", statsError);

	useEffect(() => {
		areRequestEnabled.current = true;
	}, [activeFilters]);

	return (
		<Section>
			<SectionHeader title={t("investment overview").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9} className="mb-3">
							<InvestmentProgression
								data={stats}
								isFetching={statsFetching}
							></InvestmentProgression>
						</Col>
					</Row>
					<Row>
						<Col lg={12} className="mb-3">
							<DataTableContainer
								city={activeFilters.city}
								promotion={activeFilters.promotion?.value}
								begin={activeFilters.begin}
								end={activeFilters.end}
								alert={activeFilters.alert}
								isRequestEnabled={areRequestEnabled.current}
								stats={stats}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<StudentTooltip tooltipId="investment-student-tooltip" />
		</Section>
	);
}
