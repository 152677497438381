import { Card, Nav, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, BoxTitle } from "./Box";

const determineActiveTabHash = (tabs, activeTabHash) => {
	const activeTab = tabs.find(
		(tab) => (tab?.hash ?? tab.label) === activeTabHash
	);
	if (activeTabHash && activeTab) return activeTab?.hash ?? activeTab.label;
	const firstEnabledTab = tabs.find((tab) => !tab.disable);
	if (!firstEnabledTab) return null;
	return firstEnabledTab?.hash ?? firstEnabledTab.label;
};

function Title({ title }) {
	if (!title) return null;
	return (
		<li className="pt-2 px-3">
			<BoxTitle title={title} />
		</li>
	);
}

function TabsHeader({ tabs }) {
	return tabs.map((tab) => {
		const hash = tab?.hash ?? tab.label;
		return (
			<Nav.Item key={`${hash}_tab_header`}>
				<Nav.Link disable={`${tab.disable}`} eventKey={hash}>
					{tab.label}
				</Nav.Link>
			</Nav.Item>
		);
	});
}

function TabsBody({ tabs }) {
	return tabs.map((tab) => {
		const hash = tab?.hash ?? tab.label;
		return (
			<Tab.Content id={`${hash}`} key={`${hash}_tab_content`}>
				<Tab.Pane eventKey={hash} disabled={tab.disable}>
					{tab.component}
				</Tab.Pane>
			</Tab.Content>
		);
	});
}

export default function NavTabs({
	title,
	tabs,
	activeTab,
	compact,
	affectsURI,
}) {
	if (!tabs) return null;
	const location = useLocation();
	const firstActiveTab = affectsURI ? location.hash.substring(1) : activeTab;
	const searchParams = location.search.substring(1);
	const navigate = affectsURI ? useNavigate() : () => {};
	const displayedTabs = tabs.filter((entry) => !!entry.display);
	const activeTabHash = determineActiveTabHash(tabs, firstActiveTab);
	if (activeTabHash === null) return null;

	return (
		<Box className="card-tabs card-outline-tabs" style={{ height: "100%" }}>
			<Tab.Container
				defaultActiveKey={activeTabHash}
				onSelect={(key) =>
					navigate({ hash: key, search: searchParams })
				}
			>
				<Card.Header className="p-0 pt-0 border-bottom-0">
					<Nav variant="nav nav-tabs">
						<Title title={title} />
						<TabsHeader
							tabs={displayedTabs}
							activeTabHash={activeTabHash}
						/>
					</Nav>
				</Card.Header>
				<Card.Body className={compact ? "px-0" : ""}>
					<TabsBody
						tabs={displayedTabs}
						activeTabHash={activeTabHash}
					/>
				</Card.Body>
			</Tab.Container>
		</Box>
	);
}
