import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import { useUnmarkedTepitechCheating } from "../../TepitechAlerts/Queries";
import { requestErrorToastManager } from "../../Utils/Toasts";
import IconColoredCard from "../IconColoredCard";

export default function SuspiciousTepitech({ city }) {
	const {
		data: suspiciousTepitechs = 0,
		error,
		isFetching,
	} = useUnmarkedTepitechCheating([city], [], []);

	requestErrorToastManager("Dashboard: Cheaters Card", error);

	if (error) return null;
	return (
		<IconColoredCard
			title={`${suspiciousTepitechs}`}
			text={`${t("suspicious_tepitech", {
				count: suspiciousTepitechs,
			})}`}
			color={suspiciousTepitechs === 0 ? "success" : "danger"}
			detailPath={`/tepitech_alerts?cities=${city.value}#cheaters`}
			icon={faLanguage}
			isLoading={isFetching}
		/>
	);
}
