import { DateTime } from "luxon";
import { Line } from "react-chartjs-2";
import { t } from "../../../../../Config/i18n";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { getTekYear } from "../../../../Utils/Utils";
import { useTepitechQuery } from "../../../Queries";

const options = {
	maintainAspectRatio: false,
	scales: {
		y: {
			stacked: false,
			min: 0,
			max: 990,
		},
	},
	tooltips: {
		callbacks: {
			label: (item, ctx) =>
				`${ctx.datasets[item.datasetIndex].label}: ${
					item.yLabel
				} points`,
		},
	},
};

const getMinimumTepitechScore = (promotionYear, year) => {
	switch (getTekYear(promotionYear, year)) {
		case 3:
			return 750;
		case 2:
			return 700;
		case 1:
			return 600;
		default:
			return 600;
	}
};

export default function TepitechOverview({ login }) {
	const { data: tepitechs, error, isFetching } = useTepitechQuery(login);

	requestErrorToastManager("Student: Tepitech tab", error);

	if (isFetching) return <LoadingComponent className="text-blue" />;

	if (!tepitechs) return <NoDataAvailable />;
	const filteredTepitechs = tepitechs.filter(
		(tepitech) => tepitech.score !== -1
	);
	if (filteredTepitechs.length === 0) return <NoDataAvailable />;

	const data = {
		labels: filteredTepitechs.map((entry) =>
			DateTime.fromISO(entry.date).toLocaleString({
				month: "short",
				year: "numeric",
			})
		),
		datasets: [
			{
				fill: true,
				label: t("grade required").capfirst(),
				backgroundColor: "rgba(192, 57, 43, 0.1)",
				borderColor: "rgba(231, 76, 60, 1.0)",
				data: filteredTepitechs.map((tepitech) =>
					getMinimumTepitechScore(
						tepitech.student.promo.promotion_year,
						tepitech.scholar_year
					)
				),
			},
			{
				fill: true,
				label: t("student grade").capfirst(),
				backgroundColor: "rgba(39, 174, 96, 0.1)",
				borderColor: "rgba(46, 204, 113, 1.0)",
				data: filteredTepitechs.extract("score"),
			},
		],
	};
	return (
		<div style={{ height: 500 }}>
			<Line data={data} options={options} />
		</div>
	);
}
