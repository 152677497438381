import { Fragment } from "react";
import { Card, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { t } from "../../Config/i18n";
import { BoxTitle } from "../AdminLTE3/Box";
import { displayRange } from "../Utils/Utils";
import NoModuleSpecified from "./NoModuleSpecified";
import getSkillSources from "./SkillSources";

function SkillTags({ tags }) {
	if (!tags) return null;
	return (
		<div className="mb-1">
			<div>
				<strong>{t("tag(s)").capfirst()}</strong>:
			</div>
			<div className="flexColumn">
				{tags.map((tag) => (
					<span className="tab1" key={`skill_tags_${tag}`}>
						<code>{tag}</code>
					</span>
				))}
			</div>
		</div>
	);
}

function SkillOutput({ output }) {
	if (!output) return <span>{t("gives a value").capfirst()}</span>;
	const outputName = Object.keys(output)[0];
	if (outputName === "binary_validation")
		return (
			<span>
				{t("binary validation (validated/failed)").capfirst()}
				<br />
				{t("validated if the result is in the range below").capfirst()}
				<div className="flexColumn">
					{displayRange(output[outputName], true)}
				</div>
			</span>
		);
	if (outputName === "plain_value")
		return <span>{t("gives a value").capfirst()}</span>;
	return null;
}

function SkillDescription({ skill }) {
	return (
		<Row
			className="mb-3"
			style={{ display: "flex", flexDirection: "column" }}
		>
			<h6 className="skillCardTitle">Description</h6>
			<SkillTags tags={skill.tags} />
			<div>
				<strong>{t("output").capfirst()}</strong>:
			</div>
			<SkillOutput output={skill.output} />
		</Row>
	);
}

function displaySourceOutput(type, output, displayOutputLabel = true) {
	const SOURCES = getSkillSources();
	const sourceTypeMedatata = SOURCES[type];
	if (!output && sourceTypeMedatata?.outputs?.[output.type]) return null;
	const outputMetadata = sourceTypeMedatata.outputs[output.type];
	const { name, display } = outputMetadata;

	return (
		<div>
			<div>
				{displayOutputLabel && (
					<>
						<strong>{t("output").capfirst()}</strong>:{" "}
					</>
				)}
				{name.capfirst()}
			</div>
			{display && display(output)}
		</div>
	);
}

function displayGeneralSourceParameters(source) {
	return (
		<>
			{source.module && (
				<div>
					<strong>{t("module").capfirst()}</strong>
					{`: ${source.module}`}
				</div>
			)}
			{source.year && (
				<div>
					<strong>{t("year").capfirst()}</strong>
					{`: ${source.year}`}
				</div>
			)}
			{source.slug && (
				<div>
					<strong>{t("slug").capfirst()}</strong>:{" "}
					<code>{source.slug}</code>
				</div>
			)}
			{source.grading_scale_id && (
				<div>
					<strong>{t("grading scale ID").capfirst()}</strong>:{" "}
					<code>{source.grading_scale_id}</code>
				</div>
			)}
			{source.valid_if && (
				<div className="discreetRedBorder">
					<strong>
						{t(
							"source valid only if the following condition returns 1"
						).capfirst()}
						:
					</strong>
					<br />
					{displaySourceOutput(source.type, source.valid_if, false)}
					<strong>
						{t("source result if invalid").capfirst()}:{" "}
					</strong>
					{source.output_if_invalid}
				</div>
			)}
		</>
	);
}

function SkillSource({ source }) {
	if (!source.type) return null;
	const SOURCES = getSkillSources();
	return (
		<div className="mt-2">
			<div className="skillCardSubTitle underline">
				{`${SOURCES[source.type].name.capfirst()}:`}
			</div>

			{displayGeneralSourceParameters(source)}
			{displaySourceOutput(source.type, source.output)}
			{Object.prototype.hasOwnProperty.call(source, "multiplier") &&
				source.multiplier !== 1 && (
					<div>
						{t("value multiplied by").capfirst()}{" "}
						{source.multiplier}
					</div>
				)}
			<br />
		</div>
	);
}

function SkillSources({ sources }) {
	return (
		<Row
			className="mb-3"
			style={{ display: "flex", flexDirection: "column" }}
		>
			<h6 className="skillCardTitle">Sources</h6>
			{sources.map((source, idx) => (
				<Fragment key={`source_key_${idx}`}>
					<SkillSource source={source} />
				</Fragment>
			))}
		</Row>
	);
}

function SkillCard({ skill }) {
	if (!skill) return <NoModuleSpecified />;
	return (
		<div style={{ padding: "0 15px" }}>
			<Card.Header>
				<BoxTitle className="skillTitle" title={skill.name} />
			</Card.Header>
			<Card.Body style={{ alignSelf: "center" }}>
				<SkillDescription skill={skill} />
				<SkillSources sources={skill.sources} />
			</Card.Body>
		</div>
	);
}

export default function SkillsList({ skills, isFlagSkills }) {
	if (!skills) return <NoModuleSpecified />;
	const filteredSkills = skills.filter(
		(skill) => !!skill?.flag_only === isFlagSkills
	);
	if (isFlagSkills) {
		return (
			<PerfectScrollbar style={{ padding: "0 15px" }}>
				<Row md={1}>
					{filteredSkills.map((skill) => (
						<SkillCard
							skill={skill}
							key={`${skill.name}_FlagsSkills_SkillCard`}
						/>
					))}
				</Row>
			</PerfectScrollbar>
		);
	}
	return (
		<PerfectScrollbar style={{ padding: "0 15px" }}>
			<Row lg={1} xl={2}>
				{filteredSkills.map((skill) => (
					<SkillCard
						skill={skill}
						key={`${skill.name}_MainSkills_SkillCard`}
					/>
				))}
			</Row>
		</PerfectScrollbar>
	);
}
