import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { t } from "../../Config/i18n";
import LoadingComponent from "../Utils/Loading";
import { getSauronLogo } from "../Utils/Utils";

export default function AzureLoadingPage({
	message = "",
	isDisconnected = false,
	callback,
}) {
	const logo = getSauronLogo();

	return (
		<div
			className="w-100 d-flex justify-content-center p-fixed"
			style={{ backgroundColor: "#f4f6f9", height: "100vh" }}
		>
			<div className="mt-5 col-12 col-md-8 col-xl-6">
				<div className="card card-outline card-primary">
					<div className="card-header text-center">
						<h2>
							<img
								src={logo}
								className="img-fluid mb-1 mr-2"
								style={{ width: "50px" }}
								alt="Sauron Logo"
							/>
							{t("welcome to").capfirst()} <b>Sauron</b>
						</h2>
					</div>
					<div className="card-body text-center">
						<div>
							{message.length > 0 && (
								<div>
									<h4>{t(message).capfirst()}</h4>
								</div>
							)}
							{!isDisconnected && <LoadingComponent />}
							{isDisconnected && (
								<Button onClick={callback}>
									<FontAwesomeIcon
										icon={faSignIn}
										className="mr-2"
									/>
									{t("login with Microsoft").capfirst()}
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
