import { DateTime } from "luxon";

export const getYear = (logtime) =>
	DateTime.fromISO(logtime.date).toLocaleString({
		year: "numeric",
	});

export const getMonth = (logtime) =>
	DateTime.fromISO(logtime.date)
		.toLocaleString({
			month: "long",
			year: "numeric",
		})
		.capfirst();

export const getWeek = (logtime) => {
	const logtimeDate = DateTime.fromISO(logtime.date);
	const { weekNumber, year, weekday } = logtimeDate;
	const monday = logtimeDate.minus({ days: weekday - 1 });
	const sunday = monday.plus({ days: 6 });
	return `${year}W${weekNumber} (${monday.toLocaleString(
		DateTime.DATE_SHORT
	)} - ${sunday.toLocaleString(DateTime.DATE_SHORT)})`;
};
