import { Link } from "react-router-dom";

import { Center, HoverCard, Text } from "@mantine/core";
import EpitechLogo from "../../Assets/logo_epitech_blue.png";
import EpitechAvatar from "./EpitechAvatar.jsx";

export default function LoginRow({ login, gandalfId, city = null }) {
	const externalLink = () => {
		let link = `https://intra.epitech.eu/user/${login}`;
		if (gandalfId) {
			link = `https://gandalf.epitech.eu/local/graph/view.php?userid=${gandalfId}`;
		}
		return (
			<a className="mr-1" href={link}>
				<img
					height={20}
					src={EpitechLogo}
					alt="epitech intra redirection"
				/>
			</a>
		);
	};

	return (
		<HoverCard position="right" withArrow>
			<HoverCard.Target>
				<div
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{externalLink()}
					<Link to={`/student/${login}`}>{login}</Link>
				</div>
			</HoverCard.Target>
			<HoverCard.Dropdown>
				<EpitechAvatar login={login} size={128} />
				{city && (
					<Center>
						<Text mt="xs" size="sm">
							{city}
						</Text>
					</Center>
				)}
			</HoverCard.Dropdown>
		</HoverCard>
	);
}
