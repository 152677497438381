import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { InvestmentContextProvider } from "./Context";
import DataTableContainer from "./DataTableContainer";
import Filters from "./Filters";
import PDFGenerator from "./PDFGenerator";

export default function InvestmentReports() {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		city: null,
		curriculum: null,
		promotion: null,
	});
	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};
	return (
		<InvestmentContextProvider>
			<Section>
				<SectionHeader title={t("investment report").capfirst()} />
				<SectionContent>
					<Container fluid>
						<Row>
							<Col lg={3} className="mb-3">
								<Filters onFilter={updateActiveFilters} />
							</Col>
							<Col lg={9} className="mb-3">
								<PDFGenerator />
							</Col>
						</Row>
						<Row>
							<Col lg={12} className="mb-3">
								<DataTableContainer
									city={activeFilters.city}
									promotion={activeFilters.promotion?.value}
									curriculum={
										activeFilters.curriculum?.value ??
										"bachelor"
									}
									isRequestEnabled={areRequestEnabled.current}
								/>
							</Col>
						</Row>
					</Container>
				</SectionContent>
				<StudentTooltip tooltipId="investment-student-tooltip" />
			</Section>
		</InvestmentContextProvider>
	);
}
