import {
	Button,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Modal,
	ProgressBar,
	Row,
} from "react-bootstrap";
import { t } from "../../Config/i18n";

function PDFErrorList({ creationErrors }) {
	if (creationErrors.length === 0) return null;
	return (
		<div>
			<Row className="mt-3">
				<h5 className="text-center w-100">
					{t("crashStudent", { count: creationErrors.length })}:
				</h5>
			</Row>
			<ListGroup className="px-1 mt-3 scrollable-container-7">
				{creationErrors.map((item) => (
					<ListGroupItem className="d-flex" key={item.login}>
						<div>{item.login}</div>
						<div className="ml-auto text-red">{item.error}</div>
					</ListGroupItem>
				))}
			</ListGroup>
		</div>
	);
}

export default function ProgressModal({
	displayModal,
	setDisplayModal,
	creationSuccesses,
	creationErrors,
	totalReports,
}) {
	return (
		<Modal centered show={displayModal} dialogClassName="modal-50w">
			<Modal.Header>
				<Modal.Title className="text-center">
					{t("creatingReports")}.
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container fluid>
					<ProgressBar
						now={
							((creationSuccesses + creationErrors.length) /
								totalReports) *
							100
						}
						label={`${
							creationSuccesses + creationErrors.length
						} / ${totalReports}`}
					/>
					<Row className="mt-3">
						<Col className="text-success text-center">
							{t("successReport")}: {creationSuccesses}
						</Col>
						<Col className="text-danger text-center">
							{t("errorReport")}: {creationErrors.length}
						</Col>
					</Row>
				</Container>
				<Container>
					<PDFErrorList
						className="mt-3"
						creationErrors={creationErrors}
					/>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={
						creationSuccesses + creationErrors.length !==
						totalReports
					}
					onClick={() => {
						setDisplayModal(false);
					}}
				>
					{t("close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
