import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import {
	getCurrentAcademicYear,
	getNextPageParam,
	promotionYearToBachelorPromotion,
} from "../Utils/Utils";

export const useCurriculumsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["investment", "filters_options", "curriculums"],
		queryFn: () => scraper.getPromotions("bachelor"),
		select: (data) => data.toFilterOptions(),
	});
};

export const useStudentsInfiniteQuery = (
	city,
	promotion,
	curriculum,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"investment_reports",
			"table_data",
			"students",
			city,
			promotion,
			curriculum,
		],
		queryFn: ({ pageParam }) =>
			scraper.students.getStudents(
				pageParam,
				city,
				promotion,
				curriculum
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
		select: (data) => ({
			...data,
			pages: data.pages.map((page) =>
				page.data.results.reduce((acc, student) => {
					acc.push({
						login: student.login,
						promotion: promotionYearToBachelorPromotion(
							student.promo.promotion_year,
							getCurrentAcademicYear()
						),
						city: student.promo.city.name,
						curriculum: student.promo.cursus.name,
						comment: "",
					});
					return acc;
				}, [])
			),
		}),
	});
};

export const useCreatePDFMutation = (onSuccess, onError) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ header, payload }) =>
			scraper.createStudentsReports(payload, header),
		onSuccess,
		onError,
	});
};

export const useDownloadPDFMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ header, hashFolder }) =>
			scraper.downloadStudentReports(hashFolder, header),
		onSuccess: ({ data }) => {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "investment_report.zip");
			document.body.appendChild(link);
			link.click();
		},
	});
};
