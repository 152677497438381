import { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import Filters from "./Filters";
import Histories from "./Histories";
import Logs from "./Logs";

export default function ScrapingHistory() {
	const areRequestEnabled = useRef(false);
	const areLoggingEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		type: null,
		history_id: null,
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
		areLoggingEnabled.current = false;
	};

	const updateHistoryId = (id) => {
		if (activeFilters.history_id?.value !== id) {
			const selectedFilters = { ...activeFilters, history_id: id };
			setActiveFilters(selectedFilters);
		}
		areLoggingEnabled.current = true;
	};

	return (
		<Section>
			<SectionHeader title={t("scraping history").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3}>
							<Row>
								<Col className="mb-3">
									<Filters onFilter={updateActiveFilters} />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Histories
										type={activeFilters.type?.value}
										onClick={updateHistoryId}
										areRequestEnabled={
											areRequestEnabled.current
										}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Logs
								history_id={activeFilters.history_id}
								areRequestEnabled={areLoggingEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
