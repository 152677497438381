import { Tooltip } from "@mantine/core";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import Select from "react-select";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { requestErrorToastManager } from "../Utils/Toasts";
import { useStudentsValidationsQuery } from "./Queries";
import TesseractDataTable from "./TesseractDataTable";
import TesseractExportButton from "./TesseractExportButton";
import TesseractValidationLogsButton from "./TesseractValidationLogsButton";

function BTTFFilter({ onChange, selected }) {
	const options = [
		{
			label: t("BTTF attempts only").capfirst(true),
			value: "bttf_attempts",
		},
		{
			label: t("BTTF improvements only").capfirst(true),
			value: "bttf_improvements",
		},
	];
	return (
		<div style={{ minWidth: "350px" }}>
			<Select
				className="text-nowrap"
				classNamePrefix="multiSelect"
				name="grade_table_bttf_filter"
				placeholder={t("BTTF filter").capfirst(t)}
				onChange={onChange}
				closeMenuOnSelect
				isMulti={false}
				options={options}
				isClearable
				value={selected}
				menuPortalTarget={document.body}
			/>
		</div>
	);
}

function getLastUpdateDate(configuration) {
	if (!configuration || !configuration.skills) return "";
	if (!configuration.last_validated) return `(${t("forthcoming update")}...)`;
	const lastUpdateDate = DateTime.fromSeconds(
		configuration.last_validated
	).toLocaleString(DateTime.DATETIME_SHORT);
	return `(${t("last update")}: ${lastUpdateDate})`;
}

const STATUS_CHIP_STYLING = {
	border: "solid 1.5px",
	borderRadius: "16px",
	padding: "2px 10px",
	marginLeft: "4px",
};

function getStatusChip(configuration) {
	const STATUS_DATA = {
		public: {
			name: "validated",
			description:
				"validation criteria have been validated and are final",
			colour: "#2ecc71",
		},
		draft: {
			name: "draft",
			description:
				"validation criteria have NOT been validated yet and can be subject to modifications; they must NOT be uploaded",
			colour: "#e67e22",
		},
		private: {
			name: "private",
			description:
				"validation criteria and results are only visible to the module designers",
			colour: "#ac7bd1",
		},
	};
	if (!configuration || !STATUS_DATA?.[configuration.status])
		return undefined;
	const currentStatus = STATUS_DATA[configuration.status];
	return (
		<span
			data-tooltip-content={t(currentStatus.description).capfirst(true)}
			data-tooltip-id="tesseract-tooltip"
			style={{
				color: currentStatus.colour,
				...STATUS_CHIP_STYLING,
			}}
		>
			{t(currentStatus.name).capfirst(true)}
		</span>
	);
}

function getLastValidationAttemptStateChip(configuration, statusConfig) {
	const LAST_VALIDATION_ATTEMPT_STATE_DATA = {
		unstable: {
			name: "potentially unreliable",
			description:
				"one or several warnings have occurred during the last validation attempt, the results may be unreliable; it is normal for this to happen in the course of a module, but not after it has ended",
			colour: "#e67e22",
		},
		failed: {
			name: "last validation attempt failed",
			description:
				"an error has occurred during the last validation attempt, which could not complete; the results shown are those of the last completed validation",
			colour: "#c0392b",
		},
	};
	if (
		!statusConfig.shouldDisplay ||
		!configuration ||
		!LAST_VALIDATION_ATTEMPT_STATE_DATA?.[
			configuration.last_validation_attempt_state
		]
	)
		return undefined;
	const currentStatus =
		LAST_VALIDATION_ATTEMPT_STATE_DATA[
			configuration.last_validation_attempt_state
		];
	return (
		<Tooltip
			label={t(currentStatus.description).capfirst()}
			id="tesseract-tooltip"
			multiline
			width={300}
		>
			<span
				style={{
					color: currentStatus.colour,
					display: "flex",
					alignItems: "center",
					...STATUS_CHIP_STYLING,
				}}
			>
				{t(currentStatus.name).capfirst(true)}
				<TesseractValidationLogsButton
					year={statusConfig.year}
					module_code={statusConfig.module_code}
				/>
			</span>
		</Tooltip>
	);
}

function getCreditsTotalWithSkills(entry, validationType) {
	const creditsSkills = entry.skills.filter(
		(skill) =>
			(skill.tags && skill.tags.includes("credits")) ||
			(skill.tag && skill.tag === "credits")
	);
	const credits = creditsSkills.reduce(
		(acc, skill) =>
			acc +
			((validationType ? skill[validationType] : skill)?.validated
				? 1
				: 0),
		0
	);
	if (creditsSkills.length === 0) {
		return "-";
	}
	if (entry[`grade_${validationType}`] === "Echec") {
		return creditsSkills.length;
	}
	return credits;
}

export default function TesseractDataTableContainer({
	year,
	module_code,
	instance_code,
	configuration,
	areRequestEnabled,
}) {
	const scraper = useContext(ScraperApiHelperContext);
	const [selectedBTTFFilter, setSelectedBTTFFilter] = useState(null);
	const {
		data: validations,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading,
		isFetchingNextPage,
		error: validationError,
	} = useStudentsValidationsQuery(
		year,
		module_code,
		instance_code,
		areRequestEnabled,
		configuration
	);

	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const requestData = validations?.pages.flatMap((page) => page);

	const filteredRequestData = requestData?.filter((row) => {
		if (
			selectedBTTFFilter?.value === "bttf_improvements" &&
			row.grade_final === row.grade_delivery
		)
			return false;
		if (
			selectedBTTFFilter?.value === "bttf_attempts" &&
			!row.has_attempted_bttf
		)
			return false;
		return true;
	});

	const tags = configuration?.skills.reduce(
		(acc, skill) => {
			if (!skill.tags) return acc;
			skill.tags.forEach((tag) => {
				if (!acc.includes(tag)) acc.push(tag);
			});
			return acc;
		},
		["Total"]
	);

	const filteredRequestDataWithCredits = filteredRequestData?.map((row) => ({
		...row,
		credits_final: getCreditsTotalWithSkills(row, "final"),
	}));

	const exportConfig = configuration
		? {
				login: "login",
				instance: "instance",
				...(getCreditsTotalWithSkills(
					{ skills: configuration.skills },
					"final"
				) !== "-"
					? {
							credits: "credits_final",
						}
					: {}),
				grade: "grade_final",
				delivery: "grade_delivery",
				bttf: "grade_bttf",
				provisional: "grade_provisional",
				medal: (row) => (row.flags.includes("medal") ? 1 : 0),
				remarkable: (row) => (row.flags.includes("remarkable") ? 1 : 0),
				difficulty: (row) => (row.flags.includes("difficulty") ? 1 : 0),
				ghost: (row) => (row.flags.includes("ghost") ? 1 : 0),
				...configuration.skills.reduce((accumulator, skill, idx) => {
					const isBinaryValidation =
						skill?.output?.binary_validation !== undefined;
					if (isBinaryValidation) {
						accumulator[`${skill.name} - validated`] =
							`skills[${idx}].final.validated`;
					}
					if (
						!isBinaryValidation ||
						skill.output.binary_validation.output_value
					)
						accumulator[`${skill.name} - value`] =
							`skills[${idx}].final.value`;
					return accumulator;
				}, {}),
				...tags.reduce((accumulator, tag) => {
					accumulator[`${tag} - skills validated`] = (row) =>
						row.skills_per_tag[tag];
					return accumulator;
				}, {}),
			}
		: null;

	requestErrorToastManager("Tesseract: Student data", validationError);

	const shouldDisplayValidationLogs = () => {
		const validationHasLogs = !!(
			configuration &&
			configuration.last_validation_attempt_state &&
			configuration.last_validation_attempt_state !== "success"
		);
		const shouldUserSeeLogs = !!(
			scraper.userHelper.isAdmin() ||
			scraper.userHelper.getSupervisedModules().includes(module_code)
		);
		return validationHasLogs && shouldUserSeeLogs;
	};

	return (
		<TesseractDataTable
			title={t("grades").capfirst()}
			subtitle={
				<div style={{ display: "flex", alignItems: "center" }}>
					{`${t("per students")} ${getLastUpdateDate(configuration)}`}{" "}
					{getStatusChip(configuration)}{" "}
					{getLastValidationAttemptStateChip(configuration, {
						shouldDisplay: shouldDisplayValidationLogs(),
						year: year,
						module_code: module_code,
					})}
				</div>
			}
			data={filteredRequestDataWithCredits}
			showFinalCreditsColumn={
				configuration &&
				getCreditsTotalWithSkills({
					skills: configuration.skills,
				}) !== "-"
			}
			preToolbarInternalActionsBonus={
				<BTTFFilter
					onChange={setSelectedBTTFFilter}
					selected={selectedBTTFFilter}
				/>
			}
			toolbarInternalActionsBonus={
				<>
					<TesseractExportButton
						data={filteredRequestDataWithCredits}
						titleCSV={`tesseract_${year}_${module_code}`}
						exportConfig={exportConfig}
						isDraft={configuration?.status === "draft"}
					/>
				</>
			}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
}
