import { useContext, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { InvestmentContext } from "./Context";
import ProgressModal from "./ProgressModal";
import { useCreatePDFMutation, useDownloadPDFMutation } from "./Queries";

export default function PDFGenerator() {
	const languageOptions = [
		{ value: "fr", label: t("french") },
		{ value: "en", label: t("english") },
	];
	const { filters, setSelectedOption, applyFilters } = useFilters({
		language: {
			label: t("language"),
			type: "select",
			multiple: false,
			options: languageOptions,
			default: languageOptions[0],
		},
		begin: {
			label: t("begin"),
			type: "date",
		},
		end: {
			label: t("end"),
			type: "date",
		},
	});
	const { students } = useContext(InvestmentContext);
	const [displayModal, setDisplayModal] = useState(false);
	const [creationSuccesses, setCreationSuccesses] = useState(0);
	const [creationErrors, setCreationErrors] = useState([]);

	const hashFolder = useRef("");
	const totalReports = useRef(0);

	const onSuccess = () => {
		setCreationSuccesses(
			(oldCreationSuccesses) => oldCreationSuccesses + 1
		);
	};
	const onError = (error, variables) => {
		const errorData = {
			login: variables.payload.login,
			error: error.message,
		};
		setCreationErrors((oldCreationErrors) => [
			...oldCreationErrors,
			errorData,
		]);
	};
	const createPDFMutation = useCreatePDFMutation(onSuccess, onError);
	const downloadPDFMutation = useDownloadPDFMutation();
	const onFilter = async (selectedFilters) => {
		const { language, begin, end } = selectedFilters;
		if (begin >= end) {
			toast.info(
				`${t("invalid dates").capfirst()}: ${t(
					"the begin date must be before the end date"
				).capfirst()}`,
				{
					theme: "colored",
					autoClose: 2000,
				}
			);
			return;
		}
		setDisplayModal(true);
		totalReports.current = students.size;
		hashFolder.current = "";
		setCreationSuccesses(0);
		setCreationErrors([]);
		const studentsToCreate = students.entries();
		const [firstLogin, firstComment] = studentsToCreate.next().value;
		const header = { "Accept-Language": language.value };
		const payload = {
			begin: begin.toISODate(),
			end: end.toISODate(),
			login: firstLogin,
			comment: firstComment,
		};
		try {
			const response = await createPDFMutation.mutateAsync({
				header,
				payload,
			});
			hashFolder.current = response.data.hash;
		} catch (error) {
			toast.error(`${t("errorGeneration")}: ${error.message}`, {
				theme: "colored",
				autoClose: 2000,
			});
		}
		const mutations = [];
		studentsToCreate.forEach(([login, comment]) => {
			mutations.push(
				createPDFMutation.mutateAsync({
					header,
					payload: {
						begin: begin.toISODate(),
						end: end.toISODate(),
						login,
						comment,
						hashFolder: hashFolder.current,
					},
				})
			);
		});
		await Promise.allSettled(mutations);
		downloadPDFMutation.mutate({ header, hashFolder: hashFolder.current });
	};

	return (
		<Box style={{ height: "100%" }}>
			<ProgressModal
				displayModal={displayModal}
				setDisplayModal={setDisplayModal}
				totalReports={totalReports.current}
				creationSuccesses={creationSuccesses}
				creationErrors={creationErrors}
			/>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("generate the investment reports").capfirst()}
					onClick={() => applyFilters(onFilter, false)}
					disabled={
						students.size === 0 ||
						filters.begin.selected === null ||
						filters.end.selected === null
					}
				/>
			</Card.Footer>
		</Box>
	);
}
