import axios from "axios";

import { deployToken } from "../Components/Utils/Utils";
import { Config } from "../Config/Config";

export default class ScraperAPI {
	constructor() {
		this.cancel_source_token = axios.CancelToken.source();
		this.requesting_for_refresh = false;
		this.requests_to_refresh = [];
		this.axios = this.initAxios();
		this.SAURON_ACCOUNT_KEY = "@sauron_account";
		this.SAURON_PREVIOUS_ACCOUNT_KEY = "@sauron_previous_account";
		this.sauron_account = this.getSauronAccountInfo();
		this.sauron_user_info = {};
		this.MSAL_ACCOUNT_KEY = "@msal_account";
		this.msal_account = this.getMicrosoftAccountInfo();
	}

	initAxios() {
		const instance = axios.create({
			baseURL: Config.scraper_uri,
			cancelToken: this.cancel_source_token.token,
		});
		instance.interceptors.response.use(null, (err) => {
			const { response, config } = err;
			if (
				!response ||
				[401, 403].includes(response.status) === false ||
				response.request.responseURL.endsWith("/token/info") ||
				response.request.responseURL.endsWith("/token/renew")
			)
				return Promise.reject(err);
			if (!this.requesting_for_refresh) {
				this.requesting_for_refresh = true;
				if (
					!this.sauron_account ||
					!this.sauron_account.refresh_token
				) {
					this.requesting_for_refresh = false;
					return Promise.reject(err);
				}
				instance
					.post("/token/renew", null, {
						headers: {
							...deployToken(this.sauron_account.refresh_token),
						},
					})
					.then((response) => {
						this.setSauronToken(response.data);
						this.requests_to_refresh.forEach((request) =>
							request(this.sauron_account.token)
						);
					})
					.catch(() => {
						this.logout();
					})
					.finally(() => {
						this.requests_to_refresh = [];
						this.requesting_for_refresh = false;
					});
			}
			return new Promise((resolve, reject) => {
				this.requests_to_refresh.push((new_token) => {
					if (new_token) {
						config.headers.Authorization = new_token;
						resolve(instance(config));
					}
					reject(err);
				});
			});
		});
		return instance;
	}

	getSauronUserInfo() {
		return this.sauron_user_info;
	}

	setSauronUserInfos(infos) {
		this.sauron_user_info = infos;
	}

	getSauronAccountInfo() {
		const sauron_account = localStorage.getItem(this.SAURON_ACCOUNT_KEY);
		if (!sauron_account) return null;
		return JSON.parse(sauron_account);
	}

	setSauronAccountInfo(sauronAccount) {
		localStorage.setItem(
			this.SAURON_ACCOUNT_KEY,
			JSON.stringify(sauronAccount)
		);
		this.sauron_account = this.getSauronAccountInfo();
	}

	setSauronToken(token) {
		this.sauron_account.token = token;
		this.setSauronAccountInfo(this.sauron_account);
	}

	setLogAsSauronToken(sauronAccount) {
		const previous_account = this.getSauronAccountInfo();
		localStorage.setItem(
			this.SAURON_PREVIOUS_ACCOUNT_KEY,
			JSON.stringify(previous_account)
		);
		this.setSauronAccountInfo(sauronAccount);
	}

	setOriginalSauronAccount() {
		const previous_account = localStorage.getItem(
			this.SAURON_PREVIOUS_ACCOUNT_KEY
		);
		if (!previous_account) return;
		localStorage.setItem(this.SAURON_ACCOUNT_KEY, previous_account);
		this.sauron_account = this.getSauronAccountInfo();
		localStorage.removeItem(this.SAURON_PREVIOUS_ACCOUNT_KEY);
	}

	getMicrosoftAccountInfo() {
		const msal_account = localStorage.getItem(this.MSAL_ACCOUNT_KEY);
		if (!msal_account) return null;
		return JSON.parse(msal_account);
	}

	setMicrosoftAccountInfo(account) {
		localStorage.setItem(this.MSAL_ACCOUNT_KEY, JSON.stringify(account));
		this.msal_account = this.getMicrosoftAccountInfo();
	}

	async login(microsoft_jwt_token) {
		const response = this.processRequest(
			"POST",
			"/token/auth",
			null,
			microsoft_jwt_token
		);
		return response;
	}

	logout() {
		localStorage.removeItem(this.MSAL_ACCOUNT_KEY);
		localStorage.removeItem(this.SAURON_ACCOUNT_KEY);
	}

	verify() {
		if (!this.sauron_account || !this.sauron_account.token)
			return Promise.reject(
				new Error("No access token in locale storage to verify access")
			);
		return this.processRequest(
			"GET",
			"/token/info",
			null,
			this.sauron_account.token
		);
	}

	refresh() {
		if (!this.sauron_account || !this.sauron_account.refresh_token)
			return Promise.reject(
				new Error(
					"No refresh token in locale storage to refresh access"
				)
			);
		return this.processRequest(
			"POST",
			"/token/renew",
			null,
			this.sauron_account.refresh_token
		);
	}

	setAxiosHeaders(headers, token) {
		if (!headers) headers = {};
		if (!Object.prototype.hasOwnProperty.call(headers, "Content-Type"))
			headers["Content-Type"] = "application/json";
		if (!token) {
			if (!this.sauron_account || !this.sauron_account.token)
				return Promise.reject(
					new Error(`No access token in locale storage for request`)
				);
			token = this.sauron_account.token;
		}
		Object.assign(headers, deployToken(token));
		return headers;
	}

	getUserName() {
		const name =
			this.sauron_user_info.firstname && this.sauron_user_info.lastname
				? this.sauron_user_info.firstname +
					" " +
					this.sauron_user_info.lastname
				: this.sauron_user_info.email.replace(/\.|@.*$/g, " ");
		const cleanedName = name.replace(/\d/g, "");
		return (
			cleanedName
				?.split(" ")
				?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				?.join(" ") ?? ""
		);
	}

	/**
	 * Perform a request to Sauron Scraper API using axios
	 * @param {string} method		GET, POST, PUT, DELETE
	 * @param {string} route		See Sauron Scraper available routes
	 * @param {Object} config		Axios configurations
	 * @param {string} token		Access token or Refresh token depending of the Sauron Scraper request, if not specified we will try to get an access token from localStorage
	 */
	processRequest(
		method,
		route,
		config = null,
		token = null,
		content_type = null
	) {
		if (!config) {
			config = {
				headers: {},
				data: {},
				params: {},
				stringify: false,
			};
		}
		const axios_config = {
			method,
			url: route,
			data: config.data,
			headers: this.setAxiosHeaders(config.headers, token),
			params: config.params,
		};
		if (content_type != null) axios_config.responseType = content_type;
		return this.axios.request(axios_config);
	}

	cancelRequests() {
		this.cancel_source_token.cancel("canceled");
		this.cancel_source_token = axios.CancelToken.source();
		this.axios.defaults.cancelToken = this.cancel_source_token.token;
	}
}
