import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { ScraperApiHelperContext } from "../../contexts";
import { NativeSelect } from "@mantine/core";
import Bachelor from "./BachelorComponent/Bachelor.jsx";
import Master from "./MasterComponent/Master.jsx";

export default function StudentsOverview() {
	const scraper = useContext(ScraperApiHelperContext);
	const userGroup = scraper.userHelper.isAdmin()
		? "admin"
		: scraper.userHelper.isInGroup("cei")
			? "cei"
			: "bachelor";
	const [value, setValue] = useState(
		userGroup === "admin" ? "bachelor" : userGroup
	);

	return (
		<Section>
			<SectionHeader title={t("students overview").capfirst()} />
			<SectionContent>
				<Container fluid>
					{userGroup === "admin" ? (
						<NativeSelect
							sx={{ marginBottom: "1rem" }}
							placeholder="Pick one"
							value={value}
							onChange={(event) => setValue(event.target.value)}
							data={[
								{
									value: "bachelor",
									label: t("firstCycle"),
								},
								{
									value: "master",
									label: t("secondCycle"),
								},
							]}
						/>
					) : null}
					{value === "bachelor" ? <Bachelor /> : <Master />}
				</Container>
			</SectionContent>
		</Section>
	);
}
