import { useContext } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getPGEPromotionOptions } from "../Utils/Utils";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper
		.getCities()
		.filter(({ value }) => value.startsWith("FR"));
	const promotionsOptions = getPGEPromotionOptions();
	const { filters, setSelectedOption, applyFilters } = useFilters({
		cities: {
			label: t("cities"),
			type: "select",
			multiple: true,
			options: citiesOptions,
			default: citiesOptions.length === 1 ? [citiesOptions[0]] : [],
		},
		promotions: {
			label: t("promotions"),
			type: "select",
			multiple: true,
			options: promotionsOptions,
			default: [],
		},
		alerts: {
			label: t("alerts only"),
			type: "bool",
			default: false,
		},
	});

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={filters.cities.selected.length === 0}
				/>
			</Card.Footer>
		</Box>
	);
}
