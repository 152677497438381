import { Modal } from "react-bootstrap";
import { ModalContent } from "../Tesseract/TesseractValidationLogsButton";

export default function ErrorModal({
	displayModal,
	setDisplayModal,
	logs,
	validation,
}) {
	return (
		<Modal
			centered
			show={displayModal}
			onHide={() => {
				setDisplayModal(false);
			}}
			aria-labelledby="contained-modal-title-vcenter"
		>
			<ModalContent logs={logs} validation={validation} />
		</Modal>
	);
}
