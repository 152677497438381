import { t } from "i18next";
import { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import {
	useAvailableProjectsQuery,
	useProjectsAvailableYearQuery,
} from "../Projects/Queries";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { findCorrectFilterOption, getLastSearchParam } from "../Utils/Filters";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";

export default function Filters({ cities, onFilter }) {
	const didProjectFilterRun = useRef(false);
	const currentYear = getCurrentAcademicYear();
	const defaultYearOptions = nValues(2023, currentYear - 2023 + 1)
		.reverse()
		.toFilterOptions();
	const [searchParams] = useSearchParams();
	const {
		filters,
		setFilterOptions,
		setSelectedOption,
		applyFilters,
		setFilterDisabled,
	} = useFilters({
		year: {
			label: t("year"),
			type: "select",
			multiple: false,
			clearable: false,
			options: defaultYearOptions,
			default: { label: currentYear, value: currentYear },
		},
		project: {
			label: t("project"),
			type: "select",
			multiple: false,
			clearable: true,
			options: [],
			default: null,
			dependsOn: "year",
		},
		cities: {
			label: t("cities"),
			type: "select",
			multiple: true,
			options: cities,
			default: cities.length === 1 ? [cities[0]] : [],
		},
		unmarked_only: {
			label: `${t("unmarked")} ${t("only")}`,
			type: "bool",
			default: false,
		},
	});
	const { data: fetchedYears, errorYears } = useProjectsAvailableYearQuery();

	const {
		data: fetchedProjects,
		error: errorProjects,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useAvailableProjectsQuery(filters.year.selected.value);
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			setFilterOptions("project", []);
			onSelectChange("project", null);
			setFilterDisabled("project", true);
		}
	};
	useEffect(() => {
		if (!fetchedYears) return;
		const yearOptions = fetchedYears.sort().reverse().toFilterOptions();
		setFilterOptions("year", yearOptions);
	}, [fetchedYears]);

	useEffect(() => {
		if (!fetchedProjects || fetchedProjects.length === 0) return;
		const projectOpts = [];

		fetchedProjects.forEach((project) => {
			const label = `[${project.code_module}] ${project.slug}`;
			const value = `${project.code_module}__${project.slug}`;
			projectOpts.push({
				label,
				value,
			});
		});

		projectOpts.sort((a, b) => a.label.localeCompare(b.label));
		setFilterOptions("project", projectOpts);
		setFilterDisabled("project", false);
		const queryProject = !didProjectFilterRun.current
			? getLastSearchParam(searchParams, "project")
			: null;
		const selectedProject = findCorrectFilterOption(
			projectOpts,
			queryProject
		);
		if (!selectedProject) {
			didProjectFilterRun.current = true;
			return;
		}
		onSelectChange("project", selectedProject);
		didProjectFilterRun.current = true;
	}, [fetchedProjects]);

	requestErrorToastManager(
		"Cheaters: Unable to get available years",
		errorYears
	);
	requestErrorToastManager(
		"Cheaters: Unable to get available projects",
		errorProjects
	);
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={onSelectChange} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={!filters.year.selected}
				/>
			</Card.Footer>
		</Box>
	);
}
