import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t } from "../../Config/i18n";

export default function FindOutMoreFooter({ detailPath, colorCard }) {
	if (!detailPath) return null;
	return (
		<Card.Footer>
			<Link
				className={colorCard ? "findOutMoreCard" : "findOutMore"}
				to={detailPath}
			>
				{t("find out more").capfirst()}
			</Link>
		</Card.Footer>
	);
}
