import { useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box } from "../AdminLTE3/Box";
import { FilterSelect } from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";

function Filter({ name, filter, onFilter }) {
	return (
		<Box style={{ height: "100%" }}>
			<Card.Body>
				<FilterSelect name={name} filter={filter} onChange={onFilter} />
			</Card.Body>
		</Box>
	);
}

export default function Filters({ cities, promotions, onFilter }) {
	const { filters, setSelectedOption, applyFilters } = useFilters({
		cities: {
			label: t("cities"),
			type: "select",
			multiple: true,
			options: cities,
			default: cities.length === 1 ? [cities[0]] : [],
		},
		promotions: {
			label: t("promotions"),
			type: "select",
			multiple: true,
			options: promotions,
			default: promotions,
		},
	});

	useEffect(() => {
		applyFilters(onFilter);
	}, [filters]);

	return (
		<>
			<Col xl={2} lg={0} />
			<Col xl={4} lg={6}>
				<Filter
					name="cities"
					filter={filters.cities}
					onFilter={setSelectedOption}
				/>
			</Col>
			<Col xl={4} lg={6}>
				<Filter
					name="promotions"
					filter={filters.promotions}
					onFilter={setSelectedOption}
				/>
			</Col>
		</>
	);
}
