import { useState } from "react";
import { t } from "../../Config/i18n";
import NavTabs from "../AdminLTE3/NavTabs";
import ContractDatatableContainer from "./ContractDatatableContainer";
import StudentDatatableContainer from "./StudentDatatableContainer";

export default function DatatablesContainer(props) {
	const [search, setSearch] = useState("");

	const tabsToDisplay = [
		{
			display: true,
			label: `${t("alerts").capfirst()} ${t("by")} ${t("students")}`,
			component: (
				<StudentDatatableContainer
					{...props}
					search={search}
					setSearch={setSearch}
				/>
			),
			hash: "alerts_by_students",
		},
		{
			display: true,
			label: `${t("alerts").capfirst()} ${t("by")} ${t("contracts")}`,
			component: <ContractDatatableContainer {...props} />,
			hash: "alerts_by_contracts",
		},
	];
	return <NavTabs tabs={tabsToDisplay} compact affectsURI />;
}
