import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useStudentQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "student", login],
		queryFn: () => scraper.student.getStudent(login),
		select: (data) => {
			const students = data.data.results;
			if (students.length === 0) return null;
			return students[0];
		},
	});
};

export const useIntershipsQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "internships", login],
		queryFn: () => scraper.internships.getInternships(login),
		select: (data) => data.data.results,
	});
};

export const useRoadblocksQuery = (login, projected = false) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useQuery({
		queryKey: ["student_overview", "roadblocks", projected, login],
		queryFn: () => scraper.student.getRoadblocks(login, projected),
		select: (data) => data.data.results[0],
		enabled: !!login,
	});
};

export const useRoadblockConfigQuery = (pgeYear) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useQuery({
		queryKey: ["student_overview", "roadblock_config", pgeYear],
		queryFn: () => scraper.roadblocks.getRoablockConfig(pgeYear),
		select: (data) => data.data,
		enabled: !!pgeYear,
	});
};

export const useLogtime = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["student_overview", "logtime", login],
		queryFn: ({ pageParam }) =>
			scraper.student.getLogtime(pageParam, login),
		select: (data) => data.pages.flatMap((page) => page.results),
		initialPageParam: 0,
		getNextPageParam,
		isEnabled: !!login,
	});
};

export const usePresencesQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "presences", login],
		queryFn: () => scraper.student.getPresences(login),
		select: (data) => data.data,
	});
};

export const useUnregisteredProjectsQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "unregistered_projects", login],
		queryFn: () => scraper.student.getUnregisteredProjects(login),
		select: (data) => data.data,
	});
};

export const useHubQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "hub", login],
		queryFn: () => scraper.student.getHub(login),
		select: (data) => data.data.results,
	});
};

export const useTepitechQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "tepitech", login],
		queryFn: () => scraper.student.getTepitech(login),
		select: (data) => data.data.results,
	});
};

export const useEnglishSkillsQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "english_skills", login],
		queryFn: () => scraper.student.getEnglishSkills(login),
		select: (data) => data.data.results,
	});
};

export const useIntuituPersonaeInfiniteQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["student_overview", "intuitu_personae", login],
		queryFn: ({ pageParam }) =>
			scraper.student.getIntuituPersonae(pageParam, login, false),
		initialPageParam: 0,
		getNextPageParam,
		select: (data) => ({
			...data,
			pages: data.pages.map((page) => page.data.results),
		}),
	});
};

export const useIntuituPersonaeMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id, comment, admStatus, pedagoStatus }) =>
			scraper.students.updateIntuituPersonae(
				id,
				comment,
				admStatus,
				pedagoStatus
			),
		onError: (error) => {
			toast.error(
				`Intuitu Personae: ${t(
					"an error occurred when saving your edits"
				).capfirst()}: ${error}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			toast.success(
				`Intuitu Personae: ${t(
					"successfully saved your edits"
				).capfirst()}`,
				{
					theme: "colored",
					autoClose: 2000,
				}
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["student_overview", "intuitu_personae"],
			});
		},
	});
};

export const useTranscriptsQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["student_overview", "transcripts", login],
		queryFn: () => scraper.student.getTranscripts(login),
		select: (data) => data.data.results[0],
	});
};
