import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "react-bootstrap";
import LoadingComponent from "../Utils/Loading";
import FindOutMoreFooter from "./FindOutMoreFooter";

export default function IconColoredCard({
	title,
	text,
	color = "primary",
	icon,
	detailPath,
	isLoading,
}) {
	const background = isLoading ? "secondary" : color;
	return (
		<Card
			bsPrefix={background === "warning" ? "warningCard" : undefined}
			bg={background === "warning" ? undefined : background}
			style={{
				height: "100%",
			}}
		>
			<Card.Body>
				<Row>
					<Col md={8}>
						<h3 style={{ color: "white" }}>
							{isLoading ? "N/A" : title}
						</h3>
						<Card.Text style={{ color: "white" }}>{text}</Card.Text>
					</Col>
					<Col xs="auto">
						{isLoading ? (
							<LoadingComponent className="ml-3" />
						) : (
							<FontAwesomeIcon
								icon={icon}
								color="#FFFFFF"
								className="fa-4x"
							/>
						)}
					</Col>
				</Row>
			</Card.Body>
			<FindOutMoreFooter detailPath={detailPath} colorCard />
		</Card>
	);
}
