import { t } from "../../Config/i18n";
import InternshipAlertGraph from "../Sauron/InternshipAlertGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import {
	getCurrentAcademicYear,
	getTekPromotionYear,
	sortObjectByKeysWithFranceAtEnd,
} from "../Utils/Utils";
import { useInternshipStatusQuery } from "./Queries";

export default function StatusGraph({
	cities,
	curriculums,
	national,
	areRequestEnabled,
}) {
	const {
		data: requestStats,
		error,
		isFetching,
	} = useInternshipStatusQuery(
		cities?.map((city) => city.value),
		curriculums?.map((curr) =>
			getTekPromotionYear(getCurrentAcademicYear(), 5, curr.value)
		),
		national,
		areRequestEnabled
	);

	const stats = requestStats ?? {};

	if (stats.France) {
		const franceStats = { ...stats.France };
		delete stats.France;
		if (national) stats.France = franceStats;
	}
	// eslint-disable-next-line no-unused-vars
	const { Global, Europe, ...filtered } = stats;

	requestErrorToastManager("Internship Progression: Status graph", error);

	return (
		<SauronChartsContainer
			title={t("internships status").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
		>
			<InternshipAlertGraph
				stats={sortObjectByKeysWithFranceAtEnd(filtered)}
			/>
		</SauronChartsContainer>
	);
}
