import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mantine/core";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import { compare } from "../Utils/Utils";
import { STATUS_CONFIG } from "./StatusConfig";
import { getPartMark, partNameToBackendJsonFormat } from "./Utils";

export default function EvaluationsDataTable({
	data,
	gradingSkill,
	exportConfig,
	isFetching,
	isLoading,
}) {
	const columns = [
		{
			accessorKey: "student.login",
			header: t("login").capfirst(),
			size: 250,
			Cell: ({ row }) => <LoginRow login={row.original.student.login} />,
		},
		{
			accessorKey: "activity.code_instance",
			header: t("instance").capfirst(),
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "activity",
			header: t("activity").capfirst(),
			Cell: ({ row }) => (
				<a
					href={`https://intra.epitech.eu/module/${row.original.activity.year}/${row.original.activity.code_module}/${row.original.activity.code_instance}/${row.original.activity.code_acti}`}
					target="_blank"
					rel="noreferrer"
					data-tooltip-content={row.original.activity.name}
					data-tooltip-id="evaluation-tooltip"
				>
					{row.original.activity.name}
				</a>
			),
			sortingFn: (a, b) =>
				compare(a.original.activity.name, b.original.activity.name),
			size: 250,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "status",
			header: t("status").capfirst(),
			size: 100,
			Cell: ({ row }) => {
				const conf = STATUS_CONFIG[row.original.status];
				return (
					<div className={conf.textColor}>
						<b>{t(row.original.status)}</b>
					</div>
				);
			},
			sortingFn: (a, b) => {
				const keys = Object.keys(STATUS_CONFIG);
				return compare(
					keys.indexOf(a.original.status),
					keys.indexOf(b.original.status)
				);
			},
			enableGlobalFilter: false,
		},
		{
			accessorKey: "mark",
			header: t("final mark").capfirst(),
			mantineTableBodyCellProps: { align: "center" },
			mantineTableHeadCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "mark_without_status",
			header: t("mark without status").capfirst(),
			mantineTableBodyCellProps: { align: "center" },
			mantineTableHeadCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		...(gradingSkill
			? gradingSkill.flatMap((page) =>
					page.parts.map((part) => {
						const formatted = partNameToBackendJsonFormat(
							`${page.title}-${part.title}`
						);
						return {
							id: formatted,
							accessorFn: (row) =>
								getPartMark(
									page.title,
									part.title,
									row.result_json
								),
							Cell: ({ row }) => {
								const mark = getPartMark(
									page.title,
									part.title,
									row.original.result_json
								);
								return (
									<div className="d-flex justify-content-center w-100">
										<span>{mark}</span>
									</div>
								);
							},
							enableGlobalFilter: false,
							minSize: 250,
							mantineTableHeadCellProps: { align: "center" },
							header: `${page.title}-${part.title}`,
							Header: () => (
								<div className="w-100">
									<div className="d-flex">
										<div
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
												maxWidth: "80%",
											}}
										>
											{`${page.title} - ${part.title}`.capfirst()}
										</div>
										<Tooltip
											label={part.comments}
											withinPortal
											sx={{ whiteSpace: "pre-wrap" }}
										>
											<FontAwesomeIcon
												icon={faCircleQuestion}
												style={{ opacity: 0.5 }}
											/>
										</Tooltip>
									</div>
									<div className="text-center">
										/ {part.marks.slice(-1)[0]}
									</div>
								</div>
							),
						};
					})
				)
			: []),
	];

	return (
		<SauronMantineDataTable
			title={t("students evaluations").capfirst()}
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
		/>
	);
}
