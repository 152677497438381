export default class Internships {
	constructor(api) {
		this.api = api;
	}

	getInternships = (login) =>
		this.api.processRequest("GET", "/internships", {
			params: { student__login: login },
		});

	getProgression = (
		year = null,
		city = null,
		curriculum = null,
		national = false
	) =>
		this.api.processRequest("GET", "/internships/progressions/stats", {
			params: {
				year,
				city,
				curriculum,
				national,
			},
		});

	getStatus = (
		city = null,
		cursus = null,
		promotion = null,
		full_time = null
	) =>
		this.api.processRequest("GET", "/internships/status/stats", {
			params: {
				city,
				promotion,
				cursus,
				full_time,
			},
		});

	getContracts = (
		offset = 0,
		cities = null,
		cursus = null,
		promotions = null,
		full_time = null,
		limit = 500
	) => {
		const params = {
			city: cities,
			promotion: promotions,
			cursus,
			full_time,
			offset,
			limit,
		};
		return this.api.processRequest("GET", "/internships/contracts", {
			params,
		});
	};
}
