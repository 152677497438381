import { useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";

export default function CommonDatatableContainer({
	data,
	error,
	columns,
	exportConfig,
	isLoading,
	isFetching,
}) {
	useEffect(() => {
		if (!error) return;
		toast.error(
			`Internships and Part Time Data Table: ${t(
				"An error has occured"
			)}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<>
			<SauronMantineDataTable
				columns={columns}
				data={data}
				exportConfig={exportConfig}
				isLoading={isLoading}
				isFetching={isFetching}
			/>
		</>
	);
}
