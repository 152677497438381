import {
	faCheck,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "../../Config/i18n";
import { getInvestmentAlerts } from "../Utils/Labels";

export default function InvestmentAlert({ alertLabel }) {
	const alert = alertLabel ?? "ok";
	const displayInfo = getInvestmentAlerts()[alert];
	return (
		<span
			style={{
				backgroundColor: displayInfo.color,
				display: "block",
				fontSize: "12px",
				fontWeight: 700,
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "top",
				borderRadius: "10px",
				paddingTop: 1,
				paddingBottom: 1,
			}}
			className="text-white"
		>
			<FontAwesomeIcon
				icon={
					alert !== "ok" && alert !== "excellent"
						? faExclamationTriangle
						: faCheck
				}
				size="sm"
			/>
			<span className="ml-2">{t(displayInfo.label).title()}</span>
		</span>
	);
}
