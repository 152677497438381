import { faCode } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import { useTotalUnmarkedCheatersStatsQuery } from "../../Cheaters/Queries";
import { requestErrorToastManager } from "../../Utils/Toasts";
import { getCurrentAcademicYear } from "../../Utils/Utils";
import IconColoredCard from "../IconColoredCard";

export default function CheatersToCheck({ city }) {
	const {
		data: toBeConfirmedCouples = 0,
		error,
		isFetching,
	} = useTotalUnmarkedCheatersStatsQuery(
		getCurrentAcademicYear(),
		null,
		null,
		[city]
	);

	requestErrorToastManager("Dashboard: Cheaters Card", error);

	if (error) return null;
	return (
		<IconColoredCard
			title={`${toBeConfirmedCouples}`}
			text={`${t("cheating cases to verify", {
				count: toBeConfirmedCouples,
			})}`}
			color={toBeConfirmedCouples === 0 ? "success" : "danger"}
			detailPath={`/cheaters?year=${getCurrentAcademicYear()}&cities=${
				city.value
			}&unmarked_only=true`}
			icon={faCode}
			isLoading={isFetching}
		/>
	);
}
