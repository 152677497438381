import { useState } from "react";
import { Col, Container, Dropdown, InputGroup, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

import { faCircleQuestion, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import LoadingComponent from "../Utils/Loading";
import { requestErrorToastManager } from "../Utils/Toasts";
import {
	getCurrentAcademicYear,
	promotionYearToBachelorPromotion,
} from "../Utils/Utils";
import NavTabs from "./NavTabs/NavTabs";
import Overview from "./Overview/Overview";
import {
	useRoadblockConfigQuery,
	useRoadblocksQuery,
	useStudentQuery,
} from "./Queries";

function StudentLoading() {
	return (
		<div className="text-center mt-3">
			<LoadingComponent />
		</div>
	);
}

function StudentNotFound({ login }) {
	return (
		<div className="text-center">
			<h3>{`Student "${login}" not found`}</h3>
		</div>
	);
}

export default function Student({ params }) {
	const login = params.login.includes("@")
		? params.login
		: `${params.login}@epitech.eu`;
	const {
		data: student,
		error: studentError,
		isFetching: isStudentFetching,
	} = useStudentQuery(login);

	const [projected, setProjected] = useState(false);

	const roadblocksQuery = useRoadblocksQuery(login);
	const roadblockQueryProjected = useRoadblocksQuery(login, true);
	const pgeYear = roadblocksQuery.data
		? parseInt(
				promotionYearToBachelorPromotion(
					roadblocksQuery.data.student.promo.promotion_year,
					getCurrentAcademicYear()
				)[3],
				10
			)
		: null;
	const roadblockConfigQuery = useRoadblockConfigQuery(pgeYear);
	const { error: roadblocksError } = roadblocksQuery;

	requestErrorToastManager("Student: Student", studentError);
	requestErrorToastManager("Student: Roadblocks", roadblocksError);

	if (isStudentFetching) return <StudentLoading />;
	if (!student) return <StudentNotFound login={login} />;
	return (
		<Section>
			<SectionHeader>
				<div className="d-flex">
					<Dropdown>
						<Dropdown.Toggle variant="primary">
							<FontAwesomeIcon icon={faEye} />
						</Dropdown.Toggle>
						<Dropdown.Menu style={{ minWidth: "250px" }}>
							<Dropdown.ItemText className="p-2">
								<InputGroup>
									<InputGroup.Prepend>
										<InputGroup.Text>
											<input
												type="checkbox"
												onChange={(event) =>
													setProjected(
														event.target.checked
													)
												}
												checked={projected}
											/>
										</InputGroup.Text>
									</InputGroup.Prepend>
									<div
										data-tooltip-content={t(
											"projection_description"
										).capfirst()}
										data-tooltip-id="student-overview-settings-tooltip"
										className="form-control text-nowrap"
										style={{ overflow: "hidden" }}
									>
										{t("use projection").capfirst()}
										<FontAwesomeIcon
											icon={faCircleQuestion}
											className="ml-2"
											style={{ opacity: 0.5 }}
										/>
									</div>
								</InputGroup>
							</Dropdown.ItemText>
							<Tooltip
								id="student-overview-settings-tooltip"
								place="right"
								style={{ textAlign: "center", width: 400 }}
							/>
						</Dropdown.Menu>
					</Dropdown>
					<h3 className="ml-2 font-weight-normal">
						{`${t("student profile").capfirst()}: `}
						<small>{login}</small>
					</h3>
				</div>
			</SectionHeader>
			<SectionContent>
				<Container fluid>
					<Row>
						<Col md={5} xl={4}>
							<Overview
								student={student}
								roadblocksQuery={
									projected
										? roadblockQueryProjected
										: roadblocksQuery
								}
							/>
						</Col>
						<Col md={7} xl={8} className="mb-3">
							<NavTabs
								student={student}
								roadblocksQuery={
									projected
										? roadblockQueryProjected
										: roadblocksQuery
								}
								roadblockConfigQuery={roadblockConfigQuery}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
