import { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltip from "../Utils/StudentTooltip";
import { getCurrentAcademicYear } from "../Utils/Utils";
import DiversityDataTableContainer from "./DiversityDataTableContainer";
import DiversityStatsChart from "./DiversityStatsChart";
import Filters from "./Filters";

export default function Diversity() {
	const scraper = useContext(ScraperApiHelperContext);
	const currentYear = getCurrentAcademicYear();
	const shouldRequestLaunch = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		year: { label: currentYear, value: currentYear },
		cities: [],
		curriculums: [],
		promotions: [],
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	useEffect(() => {
		if (!shouldRequestLaunch.current) {
			shouldRequestLaunch.current = true;
		}
	}, [activeFilters]);

	return (
		<Section>
			<SectionHeader
				title={t("Diversity - overview").capfirst().title()}
			/>
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9} className="mb-3">
							<DiversityStatsChart
								cities={
									activeFilters.cities.length === 0
										? scraper.userHelper.getCities()
										: activeFilters.cities
								}
								curriculums={activeFilters.curriculums}
								promotions={activeFilters.promotions}
								scholarYear={activeFilters.year}
							/>
						</Col>
						<Col lg={12}>
							<DiversityDataTableContainer
								cities={
									activeFilters.cities.length === 0
										? scraper.userHelper.getCities()
										: activeFilters.cities
								}
								curriculums={activeFilters.curriculums}
								promotions={activeFilters.promotions}
								year={activeFilters.year}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<StudentTooltip tooltipId="diversity-student-tooltip" />
		</Section>
	);
}
