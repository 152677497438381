import InvestmentChart from "../../../../InvestmentOverview/InvestmentStudentChart";
import {
	useInvestmentProgressionStatsQuery,
	useInvestmentStudentQuery,
} from "../../../../InvestmentOverview/Queries";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";

export default function Investment({ student }) {
	const { data, isFetching, error } = useInvestmentStudentQuery(
		student.login
	);
	const {
		data: stats,
		isFetching: isFetchingStats,
		error: errorStats,
	} = useInvestmentProgressionStatsQuery(
		[student.promo.city.code],
		[student.promo.promotion_year]
	);

	requestErrorToastManager("Student: Investment tab", error);
	requestErrorToastManager("Student: Investment tab", errorStats);

	if (isFetching || isFetchingStats)
		return <LoadingComponent className="text-blue" />;

	if (!data) return <NoDataAvailable />;
	return (
		<div style={{ height: "500px" }}>
			<InvestmentChart
				data={data.stats ?? []}
				student={student}
				stats={stats}
			></InvestmentChart>
		</div>
	);
}
