import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import { useCitiesQuery } from "./Queries";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const TEPITECH_MODULE = "B-ANG-058";
	const isResponsible = scraper.userHelper
		.getSupervisedModules()
		.includes(TEPITECH_MODULE);
	const yearsOptions = nValues(2015, getCurrentAcademicYear() - 2015 + 1)
		.reverse()
		.toFilterOptions();
	const { data: fetchedCities } = useCitiesQuery(isResponsible);
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			cities: {
				label: t("cities"),
				type: "select",
				multiple: true,
				options: citiesOptions,
				default:
					citiesOptions?.length === 1 && !isResponsible
						? [citiesOptions[0]]
						: [],
			},
			curriculums: {
				label: t("curriculums"),
				type: "select",
				multiple: true,
				options: ["PGE1", "PGE2", "PGE3"].toFilterOptions(),
				default: ["PGE1", "PGE2", "PGE3"].toFilterOptions(),
			},
			years: {
				label: t("years"),
				type: "select",
				multiple: true,
				options: yearsOptions,
				default: [yearsOptions[0]],
			},
			france: {
				label: t("france"),
				type: "bool",
				default: true,
			},
		});

	useEffect(() => {
		if (!fetchedCities) return;
		setFilterOptions("cities", fetchedCities);
	}, [fetchedCities]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
