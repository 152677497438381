import { getCurrentAcademicYear } from "../../Components/Utils/Utils";

export default class Tesseract {
	constructor(api) {
		this.api = api;
	}

	getAvailableModules = (year) =>
		this.api.processRequest("GET", "/tesseract/available_modules", {
			params: { year },
		});

	getModuleSkills = (year, module_code) =>
		this.api.processRequest(
			"GET",
			`/tesseract/skills/${year}/${module_code}`
		);

	getModuleValidations(
		offset = 0,
		year = getCurrentAcademicYear(),
		module = null,
		instance = null,
		limit = null
	) {
		const params = {
			offset,
			limit,
		};
		if (instance) params.instance = instance;
		return this.api.processRequest(
			"GET",
			`/tesseract/validations/${year}/${module}`,
			{
				params,
			}
		);
	}

	getModuleValidationStats = (year, module_code, instances = null) => {
		const params = {
			instances,
		};
		return this.api.processRequest(
			"GET",
			`/tesseract/validations/${year}/${module_code}/stats`,
			{
				params,
			}
		);
	};

	createConfiguration = (
		scholar_year,
		code_module,
		config_filepath,
		github_repository,
		visibility
	) => {
		const data = {
			scholar_year,
			code_module,
			config_filepath,
			github_repository,
			visibility,
		};
		return this.api.processRequest("POST", "tesseract/configurations", {
			data,
		});
	};

	deleteConfiguration = (primary_key) =>
		this.api.processRequest(
			"DELETE",
			`/tesseract/configurations/${primary_key}`
		);

	getConfigurations(
		offset = 0,
		scholar_year = getCurrentAcademicYear(),
		limit = 100
	) {
		const params = {
			ordering: "unit__code_module",
			offset,
			scholar_year,
			limit,
		};
		return this.api.processRequest("GET", "/tesseract/configurations", {
			params,
		});
	}

	updateConfiguration = (
		primary_key,
		github_repository,
		config_filepath,
		visibility
	) =>
		this.api.processRequest(
			"PUT",
			`/tesseract/configurations/${primary_key}`,
			{
				data: {
					github_repository,
					config_filepath,
					visibility,
				},
			}
		);

	getSkillValidationStats(year, module_code, instance = null) {
		const params = {
			instance,
		};
		return this.api.processRequest(
			"GET",
			`/tesseract/skills/validations/${year}/${module_code}/stats`,
			{
				params,
			}
		);
	}

	getModuleValidationLogs(year, module_code) {
		return this.api.processRequest(
			"GET",
			`tesseract/validation_logs/${year}/${module_code}`
		);
	}

	getUnstableModules(year) {
		return this.api.processRequest(
			"GET",
			`tesseract/unstable_module/${year}`
		);
	}
}
