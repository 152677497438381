import { DateTime } from "luxon";
import { t } from "../../Config/i18n";
import ExportButton from "../Sauron/ExportButton";
import IntuituProgressionGraph from "../Sauron/Graphs/IntuituProgressionGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { requestErrorToastManager } from "../Utils/Toasts";
import { downloadCSV, getLabelWeeks, sortObjectByKeys } from "../Utils/Utils";
import { useIntuituProgressionQuery } from "./Queries";

function onExport(intuituStats) {
	if (!intuituStats || Object.keys(intuituStats).length === 0) return;
	const header = [
		"city",
		"promotion",
		"week",
		"adm_status",
		"pedago_status",
		"nb_missing",
	];

	const columns = Object.entries(intuituStats).flatMap(
		([promotion, cityStats]) =>
			Object.entries(cityStats).flatMap(([city, weeksStats]) =>
				Object.entries(weeksStats).map(([week, stats]) => [
					city,
					promotion,
					`W${DateTime.fromISO(week).weekNumber}`,
					`${stats.adm_status}%`,
					`${stats.pedago_status}%`,
					stats.nb_missing,
				])
			)
	);
	const table = [header, ...columns];
	downloadCSV(table, "intuitu_personae_stats");
}

export default function IntuituPersonaeProgress({ cities, promotionYears }) {
	const {
		error,
		isFetching,
		data = { graph: {}, data: {} },
	} = useIntuituProgressionQuery(cities, promotionYears);
	const { graph: graphData, raw: exportData } = data;
	const sortedData = sortObjectByKeys(graphData);
	const maxLength = Object.values(sortedData).reduce((max, dataset) => {
		if (dataset.stats.length > max) return dataset.stats.length;
		return max;
	}, 0);
	const weeks = getLabelWeeks(maxLength, 2);

	requestErrorToastManager("Intuitu Personae Progress", error);

	return (
		<SauronChartsContainer
			title={t("intuitu personae progress").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
			topRightButton={
				<ExportButton
					onClick={() => {
						onExport(exportData);
					}}
					disabled={isFetching}
				/>
			}
		>
			<IntuituProgressionGraph
				stats={sortedData}
				labels={weeks.map((week) =>
					week.toLocaleString({
						day: "numeric",
						month: "long",
					})
				)}
			/>
		</SauronChartsContainer>
	);
}
