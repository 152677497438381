import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useEvaluationsAvailableInstancesQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["evaluations", "filters_options"],
		queryFn: () => scraper.grading_skills.getAvailableInstances(),
		select: (response) => response.data,
	});
};

export const useGradingSkillQuery = (grading_skill_id) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["grading_skills", grading_skill_id],
		queryFn: () => scraper.grading_skills.getFromId(grading_skill_id),
		select: (response) => response.data,
		enabled: !!grading_skill_id,
	});
};

export const useEvaluationsQuery = (year, scale_id, module) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["evaluations", "table_data", year, scale_id, module],
		queryFn: ({ pageParam }) =>
			scraper.grading_skills.getEvaluations(
				year,
				scale_id,
				module,
				pageParam
			),
		select: (data) => data.pages.flatMap((page) => page.data.results),
		initialPageParam: 0,
		getNextPageParam,
		enabled: !!year && !!scale_id && !!module,
	});
};
