import {
	Card,
	CardSection,
	Tabs,
	rem,
	useMantineColorScheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const determineActiveTabHash = (tabs, activeTabHash) => {
	const activeTab = tabs.find(
		(tab) => (tab?.hash ?? tab.label) === activeTabHash
	);
	if (activeTabHash && activeTab) return activeTab?.hash ?? activeTab.label;
	const firstEnabledTab = tabs.find((tab) => !tab.disable);
	if (!firstEnabledTab) return null;
	return firstEnabledTab?.hash ?? firstEnabledTab.label;
};

export default function SauronMantineNavtabs({
	tabs,
	activeTab,
	affectsURI,
	bonusTab,
}) {
	const navigate = affectsURI ? useNavigate() : () => {};
	const location = useLocation();
	const searchParams = location.search.substring(1);
	const firstActiveTab = affectsURI ? location.hash.substring(1) : activeTab;
	const displayedTabs = tabs.filter((entry) => !!entry.display);
	const [currentTab, setCurrentTab] = useState(null);
	const { colorScheme } = useMantineColorScheme();
	const backgroundColor = colorScheme === "light" ? "white" : "#3A4148";

	useEffect(() => {
		const activeTabHash = determineActiveTabHash(tabs, firstActiveTab);
		setCurrentTab(activeTabHash);
	}, []);

	if (!tabs || tabs.length === 0) return null;
	return (
		<Card style={{ backgroundColor }}>
			<Tabs
				variant="outline"
				value={currentTab}
				onTabChange={(key) => {
					navigate({ hash: key, search: searchParams });
					setCurrentTab(key);
				}}
			>
				<CardSection inheritPadding>
					<Tabs.List>
						{displayedTabs.map((tab) => {
							const hash = tab.hash ?? tab.label;
							let borderColor =
								hash === currentTab ? "primary" : "";
							if (borderColor) {
								borderColor =
									colorScheme === "light"
										? "lightBlue"
										: "grey";
							}
							return (
								<Tabs.Tab
									key={`${hash}_tab_header`}
									value={`${hash}`}
									style={{
										borderColor,
									}}
								>
									{hash}
								</Tabs.Tab>
							);
						})}
						{bonusTab ? bonusTab.component : null}
					</Tabs.List>
					{displayedTabs.map((tab) => {
						const hash = tab.hash ?? tab.label;
						return (
							<Tabs.Panel
								key={`${hash}_tab_content`}
								value={`${hash}`}
								style={{
									border: `${rem(1)} solid ${colorScheme === "light" ? "lightBlue" : "grey"}`,
								}}
							>
								{tab.component}
							</Tabs.Panel>
						);
					})}
				</CardSection>
			</Tabs>
		</Card>
	);
}
