import { t } from "../../Config/i18n";
import ProgressionGraph from "../Sauron/Graphs/ProgressionGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import {
	getCurrentAcademicYear,
	getLabelWeeks,
	promotionYearToCurriculum,
	sortObjectByKeys,
} from "../Utils/Utils";

export default function InvestmentProgression({ data, isFetching }) {
	const year = getCurrentAcademicYear();
	data = Object.keys(data || {}).reduce((acc, isoWeek) => {
		Object.keys(data[isoWeek]).forEach((promotionYear) => {
			const promotion = promotionYearToCurriculum(promotionYear, year);
			Object.keys(data[isoWeek][promotionYear]).forEach((city) => {
				const key = `${city} - ${promotion} - ${year}`;
				if (!(key in acc)) acc[key] = [];
				acc[key].push(data[isoWeek][promotionYear][city]);
			});
		});
		return acc;
	}, {});

	const datasets = sortObjectByKeys(data);
	const maxLength = Object.values(datasets).reduce((max, dataset) => {
		if (dataset.length > max) return dataset.length;
		return max;
	}, 0);
	const weeks = getLabelWeeks(maxLength);

	return (
		<SauronChartsContainer
			isLoading={isFetching}
			title={t("investment progression").capfirst()}
		>
			<ProgressionGraph
				stats={datasets}
				labels={weeks.map((week) =>
					week.toLocaleString({
						day: "numeric",
						month: "long",
					})
				)}
			/>
		</SauronChartsContainer>
	);
}
