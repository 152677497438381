import { DateTime } from "luxon";
import { Line } from "react-chartjs-2";
import { t } from "../../../../../../Config/i18n";

const options = {
	maintainAspectRatio: false,
	scales: {
		y: {
			suggestedMax: 100,
			min: 0,
		},
	},
};

export default function ADMStatus({ intuitus }) {
	const initialValue = {
		labels: [],
		datasets: [
			{
				label: t("administrative status").capfirst(),
				borderColor: "rgba(52,152,219,1)",
				backgroundColor: "rgba(52,152,219,0.2)",
				fill: true,
				type: "line",
				data: [],
			},
			{
				label: t("pedagogical status").capfirst(),
				borderColor: "rgba(231, 76, 60, 1)",
				backgroundColor: "rgba(231, 76, 60, 0.2)",
				fill: true,
				type: "line",
				data: [],
			},
		],
	};
	const data = intuitus.reduce((acc, intuitu) => {
		acc.labels.push(
			DateTime.fromISO(intuitu.date).toLocaleString(
				DateTime.DATETIME_SHORT
			)
		);
		acc.datasets[0].data.push(intuitu.adm_status);
		acc.datasets[1].data.push(intuitu.pedago_status);
		return acc;
	}, initialValue);
	return <Line data={data} options={options} />;
}
