import { Table } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../../../../../Config/i18n";

function getStatusBreakdownTooltip(activities) {
	if (activities.length === 0) return null;
	const tableContent = activities.reduce((acc, activity, idx) => {
		const isLinebreak = idx % 2 === 1;
		acc += isLinebreak ? "<td>" : "<tr><td>";
		acc +=
			activity.length > 50
				? `${activity.substring(0, 50)} [...]`
				: activity;
		acc += "</td>";
		if (isLinebreak) acc += "</tr>";
		return acc;
	}, "");
	return `<table><tbody>${tableContent}</tbody></table>`;
}

function StatusBreakdown({ activities, title }) {
	const titleToColor = {
		"presences": "text-success",
		"absences": "text-danger",
		"on going": "text-primary",
	};
	return (
		<div
			data-tooltip-id="student-hubtab-overview-tooltip"
			data-tooltip-html={getStatusBreakdownTooltip(activities)}
		>
			<p>
				{`${t(title).capfirst()}: `}
				<span className={`${titleToColor[title]}`}>
					{activities.length}
				</span>
			</p>
		</div>
	);
}

function StatisticsBreakdown({ data, isOrganization }) {
	const field = isOrganization ? "organizations" : "participations";
	return (
		<td>
			<StatusBreakdown
				activities={data[field].present}
				title="presences"
			/>
			<StatusBreakdown activities={data[field].absent} title="absences" />
			<StatusBreakdown
				activities={data[field].in_progress}
				title="on going"
			/>
		</td>
	);
}

function OverviewTableLine({ type, statistics }) {
	if (typeof statistics === "number") {
		return (
			<tr>
				<th>{t("number of experiments").capfirst()}</th>
				<td>{statistics}</td>
				<td>N/A</td>
			</tr>
		);
	}
	return (
		<tr>
			<th>{type.capfirst()}</th>
			<StatisticsBreakdown data={statistics} />
			<StatisticsBreakdown data={statistics} isOrganization />
		</tr>
	);
}

function OverviewTable({ activities }) {
	return (
		<Table striped responsive hover className="text-center">
			<thead className="thead-light">
				<tr>
					<th>{t("activities").capfirst()}</th>
					<th>{t("involvements").capfirst()}</th>
					<th>{t("organizations").capfirst()}</th>
				</tr>
			</thead>
			<tbody>
				{Object.entries(activities).map(([type, statistics]) => (
					<OverviewTableLine
						key={`hub_overview_${type}`}
						type={type}
						statistics={statistics}
					/>
				))}
			</tbody>
		</Table>
	);
}

export default function ActivitityOverview({ activities }) {
	return (
		<div>
			<h5 className="text-muted">{t("hub overview").capfirst()}</h5>
			<OverviewTable activities={activities} />
			<Tooltip id="student-hubtab-overview-tooltip" />
		</div>
	);
}
