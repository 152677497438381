export default class Cheaters {
	constructor(api) {
		this.api = api;
	}

	getCheaters(
		offset = 0,
		year = null,
		code_module = null,
		slug = null,
		cities = null,
		unmarked_only = false,
		limit = 50
	) {
		const params = {
			year,
			code_module,
			slug,
			city: cities,
			unmarked_only,
			offset,
			limit,
		};
		return this.api
			.processRequest("GET", "/cheaters", {
				params,
			})
			.then((res) => res.data);
	}

	getCheatersStats(year, code_module, slug, cities) {
		return this.api
			.processRequest("GET", "/cheaters/stats", {
				params: { year, code_module, slug, city: cities },
			})
			.then((res) => res.data);
	}

	getTotalUnmarkedCheatersStats(year, code_module, slug, cities) {
		return this.api
			.processRequest("GET", "/cheaters/stats/total_unmarked", {
				params: { year, code_module, slug, city: cities },
			})
			.then((res) => res.data);
	}

	updateCheaterStatus(couple_id, is_cheater) {
		const params = {
			is_cheater,
		};
		return this.api
			.processRequest("PUT", `/cheaters/${couple_id}`, {
				data: params,
			})
			.then((res) => res.data);
	}

	downloadCoupleDelivery(couple_id) {
		return this.api
			.processRequest(
				"GET",
				`/cheaters/${couple_id}/download`,
				null,
				null,
				"blob"
			)
			.then((res) => res.data);
	}

	getTepitechCheating = (
		city = null,
		curriculum = null,
		promotion = null,
		offset = 0,
		limit = 500
	) =>
		this.api.processRequest("GET", "/cheaters/tepitech", {
			params: { city, curriculum, promotion, offset, limit },
		});

	updateTepitechCheating = (id, cheated) => {
		const params = {
			cheated,
		};
		return this.api.processRequest("PUT", `/cheaters/tepitech/${id}`, {
			data: params,
		});
	};

	getTepitechCheatingUnmarked = (city, curriculum, promotion) =>
		this.api
			.processRequest("GET", "/cheaters/tepitech/unmarked", {
				params: { city, curriculum, promotion },
			})
			.then((res) => res.data);
}
