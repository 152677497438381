import { t } from "i18next";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CityGradesGraph from "../Graphs/GradeGraph";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { filterModuleStatsDataByYear } from "../Utils/Utils";
import { useGradesHistoryStats } from "./Queries";

export default function GradesByYear({ module_code, areRequestEnabled }) {
	const { data, error, isFetching } = useGradesHistoryStats(
		module_code,
		areRequestEnabled
	);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Module Validations Grades by cities: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("grades").capfirst()}
			subtitle={`${t("by")} ${t("years")}`}
			isLoading={isFetching}
		>
			{data ? (
				<CityGradesGraph stats={filterModuleStatsDataByYear(data)} />
			) : null}
		</SauronChartsContainer>
	);
}
