import NavTabs from "../../../../AdminLTE3/NavTabs";
import LoadingComponent from "../../../../Utils/Loading";
import NoDataAvailable from "../../../../Utils/NoDataAvailable";
import { requestErrorToastManager } from "../../../../Utils/Toasts";
import { getCurrentAcademicYear } from "../../../../Utils/Utils";
import { useHubQuery } from "../../../Queries";
import HubYearTab from "./YearTab/YearTab";

function sortByLabels(labelA, labelB) {
	if (labelA > labelB) return 1;
	if (labelB > labelA) return -1;
	return 0;
}

export default function Hub({ login, presencesQuery }) {
	const { data: presences, isFetching: arePresencesLoading } = presencesQuery;
	const { data: hubs, error, isFetching: isHubLoading } = useHubQuery(login);

	requestErrorToastManager("Student: Hub tab", error);

	if (isHubLoading || arePresencesLoading)
		return <LoadingComponent className="text-blue" />;

	if (!hubs?.length || !presences) return <NoDataAvailable />;

	const hubTabs = hubs
		.map((hub) => ({
			disable: false,
			display: true,
			label: hub.year,
			component: (
				<HubYearTab
					hub={hub}
					presences={
						presences?.[hub.year]?.["B-INN-000"]
							? Object.values(
									presences[hub.year]["B-INN-000"]
								).flat()
							: null
					}
				/>
			),
			hash: hub.year,
		}))
		.sort((a, b) => sortByLabels(a.label, b.label));
	return <NavTabs tabs={hubTabs} activeTab={getCurrentAcademicYear()} />;
}
