import { Col, Row } from "react-bootstrap";
import BachelorFilters from "./BachelorFilters.jsx";
import BachelorDataTableContainer from "./BachelorDataTableContainer.jsx";
import { useRef, useState } from "react";
import { getCurrentAcademicYear } from "../../Utils/Utils.jsx";
import { areFiltersValueDifferent } from "../../Utils/Filters.jsx";
import StudentAlertsChart from "../StudentAlertsChart.jsx";
import StudentSuccessChart from "../StudentSuccessChart.jsx";

const Bachelor = () => {
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		city: null,
		curriculum: null,
		promotion_bachelor: null,
		projection: false,
		scholar_year: getCurrentAcademicYear(),
	});
	const promotion_bachelor = activeFilters.promotion_bachelor
		? activeFilters.promotion_bachelor.value
		: null;
	const curriculum = activeFilters.curriculum
		? activeFilters.curriculum.value
		: "bachelor";
	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	return (
		<Row>
			<Col lg={3}>
				<BachelorFilters onFilter={updateActiveFilters} />
			</Col>
			<Col lg={4} className="mb-3">
				<StudentAlertsChart
					cities={activeFilters.city}
					curriculum={curriculum}
					promotion_bachelor={promotion_bachelor}
					projection={activeFilters.projection}
					type="bachelor"
				/>
			</Col>
			<Col lg={5} className="mb-3">
				<StudentSuccessChart
					city={activeFilters.city}
					curriculum={curriculum}
					promotion_bachelor={promotion_bachelor}
					projection={activeFilters.projection}
					type="bachelor"
				/>
			</Col>
			<Col lg={12} className="mt-3">
				<BachelorDataTableContainer
					city={activeFilters.city}
					scholar_year={activeFilters.scholar_year}
					curriculum={curriculum}
					promotion={promotion_bachelor}
					areRequestEnabled={areRequestEnabled.current}
					projection={activeFilters.projection}
				/>
			</Col>
		</Row>
	);
};

export default Bachelor;
