import { useContext, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import Navtabs from "../AdminLTE3/NavTabs";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import AlertsPerCitiesContainer from "./AlertsPerCities";
import DataTableContainer from "./DataTableContainer";
import Filters from "./Filters";

export default function ModuleAlerts() {
	const scraper = useContext(ScraperApiHelperContext);
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		cities: [],
		curriculums: [],
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	const tables = [
		{
			display: true,
			label: `${t("alerts").capfirst()} ${t("by")} ${t("instances")}`,
			component: (
				<DataTableContainer
					cities={activeFilters.cities}
					curriculums={activeFilters.curriculums}
					isRequestEnabled={areRequestEnabled.current}
				/>
			),
			authorized_groups: ["admin", "pedago"],
			hash: "alerts_by_instances",
		},
		{
			display: true,
			label: `${t("alerts").capfirst()} ${t("by")} ${t("modules")}`,
			component: (
				<DataTableContainer
					cities={activeFilters.cities}
					curriculums={activeFilters.curriculums}
					isRequestEnabled={areRequestEnabled.current}
					overview
				/>
			),
			authorized_groups: ["admin"],
			hash: "alerts_by_modules",
		},
	];

	return (
		<Section>
			<SectionHeader title={t("module alerts").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>
						<Col lg={9} className="mb-3">
							<AlertsPerCitiesContainer />
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							{scraper.userHelper.isAdmin() ? (
								<Navtabs tabs={tables} compact affectsURI />
							) : (
								<DataTableContainer
									boxed
									cities={activeFilters.cities}
									curriculums={activeFilters.curriculums}
									overview={activeFilters.overview}
									isRequestEnabled={areRequestEnabled.current}
								/>
							)}
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
