import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { requestErrorToastManager } from "../Utils/Toasts";
import { compare } from "../Utils/Utils";
import IntuituDataTable from "./DataTable";
import { useIntuituPersonaeInfiniteQuery } from "./Queries";

function IntuituWarning({ intuitus }) {
	const totalStudentsByAlert = intuitus.reduce(
		(acc, { alert }) => {
			if (!(alert in acc)) return acc;
			acc[alert] += 1;
			return acc;
		},
		{ late: 0, very_late: 0, not_defined: 0 }
	);
	const totalAlerts = Object.values(totalStudentsByAlert).reduce(
		(acc, nb) => acc + nb,
		0
	);
	if (totalAlerts === 0) return null;
	const veryLateText =
		totalStudentsByAlert.very_late > 0
			? `${t("you are very late for").capfirst()} ${t(
					"studentWithCount",
					{ count: totalStudentsByAlert.very_late }
				)}`
			: "";
	const lateText =
		totalStudentsByAlert.late > 0
			? `${t("you are late for").capfirst()} ${t("studentWithCount", {
					count: totalStudentsByAlert.late,
				})}`
			: "";
	const notDefinedText =
		totalStudentsByAlert.not_defined > 0
			? `${t("you never defined").capfirst()} ${t("studentWithCount", {
					count: totalStudentsByAlert.not_defined,
				})}`
			: "";
	return (
		<Card.Header className="card text-center">
			<h3 className="text-danger">{notDefinedText}</h3>
			<h3 className="text-danger">{veryLateText}</h3>
			<h3 className="text-danger">{lateText}</h3>
		</Card.Header>
	);
}

export default function DataTableContainer({
	cities,
	promotions,
	alertsOnly,
	areRequestEnabled,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading,
		isFetchingNextPage,
		error,
	} = useIntuituPersonaeInfiniteQuery(cities, promotions, areRequestEnabled);

	requestErrorToastManager("Intuitu Personae", error);

	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const intuitus =
		data?.pages
			.flatMap((page) => page)
			.sort((a, b) => compare(a.login, b.login)) ?? [];

	const filteredIntuitus = intuitus.filter(
		(intuitu) => (intuitu = !alertsOnly || intuitu.alert !== null)
	);

	const exportConfig = {
		login: "login",
		adm_status: "adm_status",
		pedago_status: "pedago_status",
		comment: "comment",
		promotion: "promotion",
		last_update: (row) => row.last_update.toISODate(),
	};

	return (
		<>
			<IntuituWarning intuitus={intuitus} />
			<IntuituDataTable
				isLoading={isLoading}
				isFetching={isFetching}
				data={filteredIntuitus}
				exportConfig={{
					columns: exportConfig,
					title: "intuitu_personae",
				}}
			/>
		</>
	);
}
