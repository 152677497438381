import { Fragment } from "react";
import { t } from "../../Config/i18n";
import { displayRange } from "../Utils/Utils";

const getCategory = (category) => {
	if (typeof category === "string")
		return <span key={`${category}_string_category`}>{category}</span>;
	return (
		<span key={`${category.name}_obj_category`}>
			{category.name}
			{Object.prototype.hasOwnProperty.call(category, "weight") && (
				<small>
					<em>
						&nbsp;&nbsp;({t("weight")}: {category.weight})
					</em>
				</small>
			)}
		</span>
	);
};

const getCategories = (categories) => {
	if (!categories) {
		return (
			<div
				className="mt-1"
				key={`${JSON.stringify(categories)}_getCategories_Fragment`}
			>
				<em className="discreetBorder">
					{t("all categories").capfirst()}
				</em>
			</div>
		);
	}
	return (
		<Fragment key={`${JSON.stringify(categories)}_getCategories_Fragment`}>
			<table key="getCategories_table" className="mt-1">
				<tbody key="getCategories_tbody">
					{categories.map((category) => (
						<tr
							key={`getCategories_tr_${JSON.stringify(category)}`}
						>
							<td
								key={`getCategories_td_${JSON.stringify(
									category
								)}`}
								className="discreetBorder"
							>
								{getCategory(category)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</Fragment>
	);
};

const getBlocks = (blocks, optional = false) => {
	if (!blocks && !optional) return null;
	if (!blocks && optional) {
		return (
			<div
				className="mt-1"
				key={`${JSON.stringify(blocks)}_getBlocks_Fragment`}
			>
				<em className="discreetBorder">{t("all blocks").capfirst()}</em>
			</div>
		);
	}
	return (
		<Fragment key={`${JSON.stringify(blocks)}_getBlocks_Fragment`}>
			<table key="getBlocks_table" className="mt-1">
				<tbody key="getBlocks_tbody">
					{blocks.map((block) => (
						<tr key={`_getBlocks_tr_${JSON.stringify(block)}`}>
							<td
								key={`getBlocks_td_${JSON.stringify(block)}`}
								className="discreetBorder"
							>
								{block}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</Fragment>
	);
};

const getSkillSources = () => ({
	argos_results: {
		name: t("Argos results"),
		outputs: {
			mark: {
				name: t("project mark"),
			},
			mark_in_range: {
				name: t(
					"1 if the project mark is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{displayRange(output)}
						</span>
					</div>
				),
			},
			prerequisites_mark: {
				name: t("project prerequisites mark"),
			},
			prerequisites_mark_in_range: {
				name: t(
					"1 if the project prerequisites mark is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{displayRange(output)}
						</span>
					</div>
				),
			},
			tests_ok_ratio: {
				name: t("ratio of successful tests for a set of categories"),
				display: (output) => getCategories(output.categories),
			},
			tests_ok_ratio_in_range: {
				name: t(
					"1 if the ratio of successful tests for a set of categories is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						{getCategories(output.categories)}
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			tests_ok_count: {
				name: t("number of successful tests for a set of categories"),
				display: (output) => getCategories(output.categories),
			},
			tests_ok_count_in_range: {
				name: t(
					"1 if the number of successful tests for a set of categories is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						{getCategories(output.categories)}
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			categories_ok_count: {
				name: t(
					"number of categories, within a specified set, having all of its tests successful"
				),
				display: (output) => getCategories(output.categories),
			},
			categories_ok_count_in_range: {
				name: t(
					"1 if the number of categories, within a specified set, having all of its tests successful, is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						{getCategories(output.categories)}
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			coding_style_mark_in_range: {
				name: t(
					"1 if the coding style mark is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						{output.must_pass_at_least_one_test !== false && (
							<div>
								<em>
									{t(
										"at least one test must also be successful, otherwise the result will always be 0"
									).capfirst()}
								</em>
							</div>
						)}
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			has_too_many_coding_style_errors: {
				name: t(
					"1 if the project has too many coding style errors, 0 otherwise"
				),
			},
			line_coverage_percent_in_range: {
				name: t(
					"1 if the line coverage percentage is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{displayRange(output)}
						</span>
					</div>
				),
			},
			branch_coverage_percent_in_range: {
				name: t(
					"1 if the branch coverage percentage is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{displayRange(output)}
						</span>
					</div>
				),
			},
			is_project_perfect: {
				name: t("1 if the project is perfect, 0 otherwise"),
			},
			is_project_delivered: {
				name: t("1 if there is a delivery, 0 otherwise"),
			},
			is_project_undelivered: {
				name: t("1 if there is no delivery, 0 otherwise"),
			},
		},
	},
	intranet_grading_scale_results: {
		name: t("Intranet grading scale results"),
		outputs: {
			mark: {
				name: t("evaluation mark"),
			},
			mark_in_range: {
				name: t(
					"1 if the evaluation mark is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{displayRange(output)}
						</span>
					</div>
				),
			},
			points_for_blocks: {
				name: t("total of points for a set of blocks"),
				display: (output) => getBlocks(output.blocks),
			},
			points_for_blocks_in_range: {
				name: t(
					"1 if the total of points for a set of blocks is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						{getBlocks(output.blocks)}
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			blocks_max_count: {
				name: t(
					"number of blocks, within a specified set, that have the maximum number of points"
				),
				display: (output) => getBlocks(output.blocks, true),
			},
			blocks_max_count_in_range: {
				name: t(
					"1 if the number of blocks, within a specified set, that have the maximum number of points is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						{getBlocks(output.blocks, true)}
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
		},
	},
	intranet_attendances: {
		name: t("Intranet attendances"),
		outputs: {
			attendance_count: {
				name: t("number of attendances"),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{output.activities_types
								? output.activities_types.join(", ")
								: t("all activities types").capfirst()}
						</span>
					</div>
				),
			},
			attendance_count_in_range: {
				name: t(
					"1 if the number of attendances is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						<div>
							<span className="discreetBorder">
								{output.activities_types
									? output.activities_types.join(", ")
									: t("all activities types").capfirst()}
							</span>
						</div>
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			absence_count: {
				name: t("number of absences"),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{output.activities_types
								? output.activities_types.join(", ")
								: t("all activities types").capfirst()}
						</span>
					</div>
				),
			},
			absence_count_in_range: {
				name: t(
					"1 if the number of absences is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						<div>
							<span className="discreetBorder">
								{output.activities_types
									? output.activities_types.join(", ")
									: t("all activities types").capfirst()}
							</span>
						</div>
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			attendance_duration: {
				name: t(
					"cumulative duration (in minutes) of all the activities the student attended"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{output.activities_types
								? output.activities_types.join(", ")
								: t("all activities types").capfirst()}
						</span>
					</div>
				),
			},
			attendance_duration_in_range: {
				name: t(
					"1 if the cumulative duration of attended activities is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						<div>
							<span className="discreetBorder">
								{output.activities_types
									? output.activities_types.join(", ")
									: t("all activities types").capfirst()}
							</span>
						</div>
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			absence_duration: {
				name: t(
					"cumulative duration (in minutes) of all the activities the student was absent from"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{output.activities_types
								? output.activities_types.join(", ")
								: t("all activities").capfirst()}
						</span>
					</div>
				),
			},
			absence_duration_in_range: {
				name: t(
					"1 if the cumulative duration of activities the student was absent from is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						<div>
							<span className="discreetBorder">
								{output.activities_types
									? output.activities_types.join(", ")
									: t("all activities types").capfirst()}
							</span>
						</div>
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			organization_count: {
				name: t("number of activities organized by the student"),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{output.activities_types
								? output.activities_types.join(", ")
								: t("all activities types").capfirst()}
						</span>
					</div>
				),
			},
			organization_count_in_range: {
				name: t(
					"1 if the number of activities organized by the student is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						<div>
							<span className="discreetBorder">
								{output.activities_types
									? output.activities_types.join(", ")
									: t("all activities types").capfirst()}
							</span>
						</div>
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
			organization_duration: {
				name: t(
					"cumulative duration (in minutes) of all the activities the student organized"
				),
				display: (output) => (
					<div className="mt-1">
						<span className="discreetBorder">
							{output.activities_types
								? output.activities_types.join(", ")
								: t("all activities types").capfirst()}
						</span>
					</div>
				),
			},
			organization_duration_in_range: {
				name: t(
					"1 1 if the cumulative duration of activities the student organized is in the specified range, 0 otherwise"
				),
				display: (output) => (
					<>
						<div>
							<span className="discreetBorder">
								{output.activities_types
									? output.activities_types.join(", ")
									: t("all activities types").capfirst()}
							</span>
						</div>
						<div className="mt-1">
							<span className="discreetBorder">
								{displayRange(output)}
							</span>
						</div>
					</>
				),
			},
		},
	},
});

export default getSkillSources;
