import { useContext, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import StudentTooltipWithCity from "../Utils/StudentTooltipWithCity";
import { getCurrentAcademicYear } from "../Utils/Utils";
import CheatersDataTableContainer from "./CheatersDataTableContainer";
import CheatersStats from "./CheatersStats";
import Filters from "./Filters";

export default function Cheaters() {
	const scraper = useContext(ScraperApiHelperContext);
	const currentYear = getCurrentAcademicYear();
	const citiesOptions = scraper.userHelper.getCities();
	const areRequestEnabled = useRef(false);
	const [activeFilters, setActiveFilters] = useState({
		year: { label: currentYear, value: currentYear },
		project: null,
		cities: [],
		unmarked_only: false,
	});
	const year = activeFilters.year?.value;
	const code_module = activeFilters.project?.value?.split("__")[0];
	const slug = activeFilters.project?.value?.split("__")[1];
	const cities =
		activeFilters.cities.length === 0
			? citiesOptions
			: activeFilters.cities;
	const updateActiveFilters = (selectedFilters) => {
		areRequestEnabled.current = selectedFilters.year !== null;
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
	};

	return (
		<Section>
			<SectionHeader title={t("cheaters").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row className="mb-3 gap-3">
						<Col lg={3}>
							<Filters
								onFilter={updateActiveFilters}
								cities={citiesOptions}
							/>
						</Col>
						<Col lg={9}>
							<CheatersStats
								year={year}
								code_module={code_module}
								slug={slug}
								cities={cities}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<CheatersDataTableContainer
								areRequestEnabled={areRequestEnabled.current}
								year={year}
								code_module={code_module}
								slug={slug}
								cities={cities}
								unmarked_only={activeFilters.unmarked_only}
								showSlug={!activeFilters.project}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
			<Tooltip id="cheaters-tooltip" />
			<StudentTooltipWithCity tooltipId="cheaters-student-tooltip" />
		</Section>
	);
}
