import { useContext } from "react";
import { t } from "../../../Config/i18n";
import { ScraperApiHelperContext } from "../../../contexts";
import NavTabs from "../../AdminLTE3/NavTabs";
import { requestErrorToastManager } from "../../Utils/Toasts";
import { hasPermissions } from "../../Utils/Utils";
import { usePresencesQuery } from "../Queries";
import EnglishResults from "./Tabs/EnglishResults/EnglishResults";
import GroupStats from "./Tabs/GroupStats/GroupStats";
import Hub from "./Tabs/Hub/Hub";
import IntuituPersonae from "./Tabs/IntuituPersonae/IntuituPersonae";
import Investment from "./Tabs/Investment/Investment";
import Logtime from "./Tabs/Logtime/Logtime";
import Presences from "./Tabs/Presences/Presences";
import Roadblocks from "./Tabs/Roadblocks/Roadblocks";
import TepitechOverview from "./Tabs/TepitechOverview/TepitechOverview";
import Transcripts from "./Tabs/Transcripts/Transcripts";
import UnregisteredProjects from "./Tabs/UnregisteredProjects/UnregisteredProjects";

function getStudentTabs(
	student,
	roadblocksQuery,
	roadblockConfigQuery,
	presencesQuery,
	account
) {
	const studentTabs = [
		{
			disable: false,
			display: true,
			label: t("roadblocks").capfirst(),
			component: (
				<Roadblocks
					roadblocksQuery={roadblocksQuery}
					roadblockConfigQuery={roadblockConfigQuery}
				/>
			),
			authorized_groups: ["admin", "pedago"],
			hash: "roadblocks",
		},
		{
			disable: false,
			display: true,
			label: t("logtime").capfirst(),
			component: <Logtime login={student.login} />,
			authorized_groups: ["admin", "pedago"],
			hash: "logtime",
		},
		{
			disable: false,
			display: true,
			label: t("presence").capfirst(),
			component: <Presences presencesQuery={presencesQuery} />,
			authorized_groups: ["admin", "pedago"],
			hash: "presence",
		},
		{
			disable: false,
			display: true,
			label: t("unregistered projects").capfirst(),
			component: <UnregisteredProjects login={student.login} />,
			authorized_groups: ["admin", "pedago"],
			hash: "unregistered_projects",
		},
		{
			disable: false,
			display: true,
			label: t("hub").capfirst(),
			component: (
				<Hub login={student.login} presencesQuery={presencesQuery} />
			),
			authorized_groups: ["admin", "pedago"],
			hash: "hub",
		},
		{
			disable: false,
			display: student.promo.cursus.code.includes("bachelor"),
			label: t("Tepitechs overview").capfirst(),
			component: <TepitechOverview login={student.login} />,
			authorized_groups: ["admin", "pedago", "english"],
			hash: "tepitechs_overview",
		},
		{
			disable: false,
			display: student.promo.cursus.code.includes("bachelor"),
			label: t("student english results").capfirst(),
			component: <EnglishResults login={student.login} />,
			authorized_groups: ["admin", "pedago", "english"],
			hash: "student_english_results",
		},
		{
			disable: false,
			display: student.promo.cursus.code.includes("bachelor"),
			label: t("intuitu Personae").capfirst(),
			component: <IntuituPersonae login={student.login} />,
			authorized_groups: ["admin", "dpr"],
			hash: "intuitu_personae",
		},
		{
			disable: false,
			display:
				student.promo.cursus.code.includes("bachelor") ||
				student.promo.cursus.code.includes("master"),
			label: t("transcripts").capfirst(),
			component: <Transcripts login={student.login} />,
			authorized_groups: ["admin", "pedago"],
			hash: "transcripts",
		},
		{
			disable: false,
			label: t("investment").capfirst(),
			display: true,
			component: <Investment student={student} />,
			authorized_groups: ["admin", "pedago"],
			hash: "investment",
		},
		{
			disable: false,
			label: t("contributions").capfirst(),
			display: true,
			component: <GroupStats student={student} />,
			authorized_groups: ["admin", "pedago"],
			hash: "group_stats",
		},
	];
	return studentTabs.filter((tab) => hasPermissions(tab, account));
}

export default function StudentNavTabs({
	student,
	roadblocksQuery,
	roadblockConfigQuery,
}) {
	const account = useContext(ScraperApiHelperContext).api.getSauronUserInfo();
	const presencesQuery = usePresencesQuery(student.login);

	const { error } = presencesQuery;
	const studentTabs = getStudentTabs(
		student,
		roadblocksQuery,
		roadblockConfigQuery,
		presencesQuery,
		account
	);

	requestErrorToastManager("Student: Presences", error);
	return <NavTabs tabs={studentTabs} affectsURI />;
}
