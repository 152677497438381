import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam, sortObjectByKeys } from "../Utils/Utils";

export const useCheatersQuery = (
	year,
	code_module,
	slug,
	cities,
	unmarked_only,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useInfiniteQuery({
		queryKey: [
			"cheaters",
			"table_data",
			year,
			code_module,
			slug,
			cityCodes,
			unmarked_only,
		],
		queryFn: ({ pageParam }) =>
			scraper.cheaters.getCheaters(
				pageParam,
				year,
				code_module,
				slug,
				cityCodes,
				unmarked_only
			),
		select: useCallback(
			(data) => data.pages.flatMap((page) => page.results),
			[]
		),
		initialPageParam: 0,
		getNextPageParam,
		enabled: areRequestEnabled,
	});
};

const findFilterLabelFromValue = (options, value) =>
	options.find((option) => value === String(option.value))?.label ??
	value.capfirst();

const selectCheatersStats = (data, options) => {
	const labeledData = Object.entries(data).reduce((acc, [value, stats]) => {
		acc[findFilterLabelFromValue(options, value)] = stats;
		return acc;
	}, {});
	return sortObjectByKeys(labeledData);
};

export const useCheatersStatsQuery = (year, code_module, slug, cities) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useQuery({
		queryKey: [
			"cheaters",
			"stats",
			"general",
			year,
			code_module,
			slug,
			cityCodes,
		],
		queryFn: () =>
			scraper.cheaters.getCheatersStats(
				year,
				code_module,
				slug,
				cityCodes
			),
		select: (data) => selectCheatersStats(data, cities),
	});
};

export const useTotalUnmarkedCheatersStatsQuery = (
	year,
	code_module,
	slug,
	cities
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useQuery({
		queryKey: [
			"cheaters",
			"stats",
			"total_unmarked",
			year,
			code_module,
			slug,
			cityCodes,
		],
		queryFn: () =>
			scraper.cheaters.getTotalUnmarkedCheatersStats(
				year,
				code_module,
				slug,
				cityCodes
			),
		select: (data) => data.unmarked,
	});
};

export const useCheaterStatusMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id, is_cheater }) =>
			scraper.cheaters.updateCheaterStatus(id, is_cheater),
		onError: (error) => {
			toast.error(
				`Cheaters: ${t(
					"an error occurred when saving your edits"
				).capfirst()}: ${error}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["cheaters"],
			});
		},
	});
};

export const useCheatersCoupleDownloadMutation = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id }) => scraper.cheaters.downloadCoupleDelivery(id),
		onSuccess: (data, { id }) => {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `couple-${id}.zip`);
			document.body.appendChild(link);
			link.click();
		},
		onError: () => {
			toast.error(
				`Cheaters: ${t("unable to download the delivery").capfirst()}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
	});
};
