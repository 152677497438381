import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./MicrosoftOAuthProvider";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.enableAccountStorageEvents();

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}

export default msalInstance;
