import { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box } from "../AdminLTE3/Box";
import {
	AdditionModalContext,
	AdditionModalContextProvider,
} from "./AdditionModalContext";
import Configurations from "./Configurations";
import { ErrorModalContextProvider } from "./ErrorModalContext";

function AddConfigurationButton({ year }) {
	const { setDisplayModal, setDefaultSettings } =
		useContext(AdditionModalContext);
	const onClick = () => {
		setDefaultSettings({
			year,
		});
		setDisplayModal(true);
	};
	return (
		<Button onClick={onClick}>{t("tesseract_add_configuration")}</Button>
	);
}

export default function ConfigurationsContainer({ year, errorsOnly, search }) {
	const scraper = useContext(ScraperApiHelperContext);
	const modules = scraper.userHelper
		.getSupervisedModules()
		.extract("code_module")
		.sort()
		.toFilterOptions();

	return (
		<Box className="p-3 w-100">
			<AdditionModalContextProvider modules={modules}>
				<Card.Header>
					<div className="d-flex justify-content-between align-items-center">
						<h4 className="font-weight-normal mb-0">
							Configurations
						</h4>
						<AddConfigurationButton year={year} />
					</div>
				</Card.Header>
				<ErrorModalContextProvider>
					<Configurations
						year={year}
						errorsOnly={errorsOnly}
						search={search}
					/>
				</ErrorModalContextProvider>
			</AdditionModalContextProvider>
		</Box>
	);
}
