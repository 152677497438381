import { DateTime } from "luxon";
import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import { instanceDataToLink } from "./DataTableContainer";

export function LinkedTitle({ title, link }) {
	return (
		<a
			style={{ fontWeight: "bold" }}
			href={link}
			target="_blank"
			rel="noreferrer"
		>
			{title}
		</a>
	);
}

export default function DataTable({
	data,
	exportConfig,
	isLoading,
	isFetching,
	boxed,
}) {
	const columns = [
		{
			accessorKey: "module_code",
			header: t("module code").capfirst(),
			size: 125,
		},
		{
			accessorKey: "module_title",
			header: t("title").capfirst(),
			size: 250,
			Cell: ({ row }) => (
				<LinkedTitle
					title={row.original.module_title}
					link={instanceDataToLink(
						row.original.year,
						row.original.module_code,
						row.original.instance_code
					)}
				/>
			),
		},
		{
			accessorKey: "instance_code",
			enableGlobalFilter: false,
			header: t("instance").capfirst(),
			size: 100,
		},
		{
			accessorKey: "end_date",
			enableGlobalFilter: false,
			header: t("end date").capfirst(),
			Cell: ({ row }) =>
				row.original.end_date.toLocaleString(DateTime.DATE_SHORT),
			size: 150,
		},
		{
			accessorKey: "missing",
			enableGlobalFilter: false,
			header: t("students not validated").capfirst(),
			size: 150,
		},
		{
			accessorKey: "registered",
			enableGlobalFilter: false,
			header: t("number of registrants").capfirst(),
			size: 150,
		},
	];

	return (
		<SauronMantineDataTable
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			title={!boxed ? undefined : t("modules").capfirst()}
			subtitle={!boxed ? undefined : t("per cities")}
			isCard={boxed}
		/>
	);
}
