import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { defaultBarOptions } from "../Utils/DefaultConst";
import * as Utils from "./NewUtils";

const GRADE_CONFIG = {
	"NA": {
		color: "#bdc3c7",
	},
	"-": {
		color: "#bdc3c7",
	},
	"A": {
		color: "#2ecc71",
	},
	"B": {
		color: "#2980b9",
	},
	"C": {
		color: "#f1c40f",
	},
	"D": {
		color: "#e67e22",
	},
	"Echec": {
		color: "#e74c3c",
		label: "failed",
	},
	"E": {
		color: "#e74c3c",
		label: "failed",
	},
	"Acquis": {
		color: "#33bdb6",
	},
};

const options = {
	...defaultBarOptions,
	legend: {
		reverse: true,
	},
};

export default function GradeGraph({ stats }) {
	if (!stats || Object.keys(stats).length === 0) {
		const data = {
			labels: [],
			datasets: Object.entries(GRADE_CONFIG).map(([key, config]) => ({
				label: t(config.label).capfirst() || key,
				backgroundColor: config.color,
				data: [],
			})),
		};
		return (
			<div style={{ height: 350 }}>
				<Bar options={options} data={data} />
			</div>
		);
	}
	const cleanStats = Utils.sortStatsByNewLabels(
		Utils.removeUselessCategories(stats)
	);
	const entries = Object.entries(GRADE_CONFIG)
		.filter(([key]) => key in cleanStats)
		.sort()
		.reverse();
	const data = {
		labels: cleanStats.labels,
		datasets: entries.map(([key, config]) => ({
			label: t(config.label).capfirst() || key,
			backgroundColor: config.color,
			data: cleanStats[key],
		})),
	};
	return (
		<div style={{ height: 350 }}>
			<Bar options={options} data={data} />
		</div>
	);
}
