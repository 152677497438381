import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useCurriculumsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["diversity", "filters_options", "curriculums"],
		queryFn: () => scraper.getPromotions(),
		select: (data) => data.toFilterOptions(),
	});
};

export const useDiversityStatsByCityQuery = (
	cities,
	promotions,
	curriculums,
	scholarYear
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"diversity",
			"stats",
			"diversity_stats_by_city",
			cities.map((city) => city.label),
			promotions.map((promotion) => promotion.label),
			curriculums.map((curriculum) => curriculum.label),
			scholarYear?.value,
		],
		queryFn: () =>
			scraper.diversity.getStats(
				cities.map((city) => city.value),
				promotions.map((promotion) => promotion.value),
				curriculums.map((curriculum) => curriculum.value),
				scholarYear?.value
			),
	});
};

export const useStudentsDiversityQuery = (
	cities,
	promotions,
	curriculums,
	scholarYear,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"diversity",
			"table_data",
			"students_diversity",
			cities.map((city) => city.label),
			promotions.map((promotion) => promotion.label),
			curriculums.map((curriculum) => curriculum.label),
			scholarYear?.value,
		],
		queryFn: ({ pageParam = 0 }) =>
			scraper.students.getDiversity(
				pageParam,
				cities.map((city) => city.value),
				promotions.map((promotion) => promotion.value),
				curriculums.map((curriculum) => curriculum.value),
				scholarYear?.value,
				200
			),
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};
