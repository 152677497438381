import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import { requestErrorToastManager } from "../../Utils/Toasts";
import { getCurrentAcademicYear } from "../../Utils/Utils";
import IconColoredCard from "../IconColoredCard";
import { useValidationLogsQuery } from "../Queries";

export default function TesseractUnstableModule({ moduleCode }) {
	const year = getCurrentAcademicYear();
	const {
		data: logsCount = { error: 0, warning: 0 },
		error,
		isFetching,
	} = useValidationLogsQuery(year, moduleCode);

	requestErrorToastManager(
		"Dashboard: Tesseract Unstable Module Card",
		error
	);
	if (logsCount.error === 0 && logsCount.warning === 0) return null;
	if (logsCount.error !== 0 && logsCount.warning !== 0) {
		return (
			<IconColoredCard
				title={`${moduleCode}`}
				text={`${t("encountered")} ${t("errorWithCount", {
					count: logsCount.error,
				})} ${t("and")} ${t("warningWithCount", {
					count: logsCount.warning,
				})} ${t("during its last validation")}`}
				color="error"
				detailPath="/tesseract"
				icon={faTriangleExclamation}
				isLoading={isFetching}
			/>
		);
	}
	const color = logsCount.error ? "danger" : "warning";
	const translation = logsCount.error ? "errorWithCount" : "warningWithCount";
	const count = logsCount.error || logsCount.warning;

	return (
		<IconColoredCard
			title={`${moduleCode}`}
			text={`${t("encountered")} ${t(translation, {
				count,
			})} ${t("during its last validation")}`}
			color={color}
			detailPath={`/tesseract_config?year=${year}&search=${moduleCode}`}
			icon={faTriangleExclamation}
			isLoading={isFetching}
		/>
	);
}
