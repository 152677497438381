import { faCheck, faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime, Interval } from "luxon";
import { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { t } from "../../../../../../Config/i18n";
import {
	getTranslatedAdmStatusComments,
	getTranslatedPedagoStatusComments,
} from "../../../../../Utils/Utils";
import { useIntuituPersonaeMutation } from "../../../../Queries";

function SmallButton({ variant, icon, onClick }) {
	return (
		<Button
			style={{ width: "4em" }}
			type="button"
			variant={variant}
			onClick={onClick}
		>
			<FontAwesomeIcon
				style={{
					verticalAlign: "middle",
				}}
				icon={icon}
				size="sm"
			/>
		</Button>
	);
}

const onDiscard = () => {
	toast.info(`Intuitu Personae: ${t("discarded your edits").capfirst()}`, {
		theme: "colored",
		autoClose: 2000,
	});
};

const onSave = (id, comment, admStatus, pedagoStatus, mutation) => {
	mutation.mutate({ id, comment, admStatus, pedagoStatus });
};

function EditTableLine({ intuitu, disableEditMode }) {
	const adm_status_comments = getTranslatedAdmStatusComments();
	const pedago_status_comments = getTranslatedPedagoStatusComments();
	const admOptions = Object.entries(adm_status_comments)
		.filter(([, string]) => string !== "N/A")
		.map(([number, string]) => ({
			label: string,
			value: parseInt(number, 10),
		}));
	const pedagoOptions = Object.entries(pedago_status_comments)
		.filter(([, string]) => string !== "N/A")
		.map(([number, string]) => ({
			label: string,
			value: parseInt(number, 10),
		}));
	const [selectedAdmOption, setSelectedAdmOption] = useState({
		label: adm_status_comments[intuitu.adm_status],
		value: parseInt(intuitu.adm_status, 10),
	});
	const [selectedPedagoOption, setSelectedPedagoOption] = useState({
		label: pedago_status_comments[intuitu.pedago_status],
		value: parseInt(intuitu.pedago_status, 10),
	});
	const [comment, setComment] = useState(intuitu.comment);
	const mutation = useIntuituPersonaeMutation();
	return (
		<tr>
			<td>
				<Select
					classNamePrefix="multiSelect"
					options={admOptions}
					value={selectedAdmOption}
					onChange={setSelectedAdmOption}
				/>
			</td>
			<td>
				<Select
					classNamePrefix="multiSelect"
					options={pedagoOptions}
					value={selectedPedagoOption}
					onChange={setSelectedPedagoOption}
				/>
			</td>
			<td>
				<InputGroup>
					<FormControl
						type="text"
						onChange={(event) => {
							setComment(event.target.value);
						}}
						value={comment}
					/>
				</InputGroup>
			</td>
			<td>
				{DateTime.fromISO(intuitu.date).toLocaleString(
					DateTime.DATETIME_SHORT
				)}
			</td>
			<td>{intuitu.author}</td>
			<td>
				<SmallButton
					onClick={() => {
						onSave(
							intuitu.id,
							comment,
							selectedAdmOption.value,
							selectedPedagoOption.value,
							mutation
						);
						disableEditMode();
					}}
					icon={faCheck}
					variant="success"
				/>
				<SmallButton
					onClick={() => {
						onDiscard();
						disableEditMode();
					}}
					icon={faXmark}
					variant="danger"
				/>
			</td>
		</tr>
	);
}

function InformationTableLine({ intuitu, editable, onClick }) {
	const adm_status_comments = getTranslatedAdmStatusComments();
	const pedago_status_comments = getTranslatedPedagoStatusComments();
	return (
		<tr>
			<td>{adm_status_comments[intuitu.adm_status]}</td>
			<td>{pedago_status_comments[intuitu.pedago_status]}</td>
			<td>{intuitu.comment}</td>
			<td>
				{DateTime.fromISO(intuitu.date).toLocaleString(
					DateTime.DATETIME_SHORT
				)}
			</td>
			<td>{intuitu.author}</td>
			{editable ? (
				<td>
					<SmallButton
						onClick={onClick}
						icon={faEdit}
						variant="primary"
					/>
				</td>
			) : null}
		</tr>
	);
}

function IntuituTableLine({ intuitu, editable }) {
	const [isEditEnabled, setIsEditEnabled] = useState(false);
	const enableEditMode = () => setIsEditEnabled(true);
	const disableEditMode = () => setIsEditEnabled(false);
	if (!isEditEnabled)
		return (
			<InformationTableLine
				intuitu={intuitu}
				editable={editable}
				onClick={enableEditMode}
			/>
		);
	return (
		<EditTableLine intuitu={intuitu} disableEditMode={disableEditMode} />
	);
}

// Since the start time is sent from a server, it is possible that start is superior to end.
// In order to prevent issues with Interval that doesn't handle that case we check it beforehand.
const getDaysDifference = (start, end) => {
	if (start > end) return 0;
	return Interval.fromDateTimes(start, end).length("days");
};

export default function IntuituPersonaeTable({ intuitus }) {
	const reversedIntuitus = [...intuitus].reverse();
	return (
		<table className="table table-striped table-borderless table-hover">
			<thead className="thead-light">
				<tr>
					<th scope="col">{t("administrative status").capfirst()}</th>
					<th scope="col">{t("pedagogical status").capfirst()}</th>
					<th scope="col">{t("comment").capfirst()}</th>
					<th scope="col">{t("last update").capfirst()}</th>
					<th scope="col">{t("author").capfirst()}</th>
					<th scope="col"> </th>
				</tr>
			</thead>
			<tbody>
				{reversedIntuitus.map((intuitu, idx) => {
					const start = DateTime.fromISO(intuitu.date);
					const end = DateTime.now();
					const daysSinceLastUpdate = getDaysDifference(start, end);
					const editable = idx === 0 && daysSinceLastUpdate <= 14;
					return (
						<IntuituTableLine
							key={`intuitu_table_line_${intuitu.id}`}
							intuitu={intuitu}
							editable={editable}
						/>
					);
				})}
			</tbody>
		</table>
	);
}
