import { useContext } from "react";
import {
	useMutation,
	useQuery,
	useQueryClient,
	useInfiniteQuery,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ScraperApiHelperContext } from "../../contexts";
import { t } from "../../Config/i18n";
import { getNextPageParam } from "../Utils/Utils";

const placeholder_types = [
	"city",
	"cursus",
	"promotions",
	"modules",
	"students",
	"student_grade",
	"grading_skills",
	"students_presences",
	"hub_projects",
	"evaluations",
	"tepitech",
	"tepitech_progression_stat",
	"logtime",
	"internships",
	"internships_history",
	"xpdiversity",
	"hub",
	"english_skills",
	"english_max_skills",
	"eip_referents",
	"roadblocks",
	"intuitu_personae",
	"intuitu_personae_stats",
	"transcripts",
	"projects",
	"moulitriche",
];

export const useScrapingTypes = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["scraping_history", "scraping_types"],
		queryFn: () => scraper.scraperInfos.getScrapingTypes(),
		initialData: placeholder_types,
	});
};

export const useScrapingStatus = (name) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["scraping_history", "infos", `status_${name}`],
		queryFn: () => scraper.scraperInfos.getScrapingStatus(name),
	});
};

export const useScrapingHistoryInfos = (id, areRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["scraper_infos", "infos", `infos_${id}`],
		queryFn: () => scraper.scraperInfos.getScrapingHistory(id),
		enabled: areRequestEnabled,
	});
};

export const useScrapingLogging = (id, areRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["scraper_infos", "infos", `logging_${id}`],
		queryFn: ({ pageParam }) =>
			scraper.scraperInfos.getScrapingLogging(id, pageParam),
		initialPageParam: 0,
		getNextPageParam,
		enabled: areRequestEnabled,
	});
};

export const usePreviousHistory = (name, areRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["scraper_infos", "infos", `history_${name}`],
		queryFn: () => scraper.scraperInfos.getAllScrapingHistoriy(name),
		enabled: areRequestEnabled,
	});
};

export const useHistoryDismissMutation = () => {
	const queryClient = useQueryClient();
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id }) =>
			scraper.scraperInfos.updateScrapingHistoryDismiss(id),
		onError: (error) => {
			toast.error(
				`Scraping History: ${t(
					"an error occurred when saving your edits"
				).capfirst()}: ${error}`,
				{
					theme: "colored",
					autoClose: 7500,
				}
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["scraper_infos", "infos"],
			});
		},
	});
};
