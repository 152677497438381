import { useContext } from "react";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageParam, sortObjectByKeys } from "../Utils/Utils";
import { ScraperApiHelperContext } from "../../contexts";

const findFilterLabelFromValue = (options, value) =>
	options.find((option) => value === String(option.value))?.label ??
	value.capfirst();

const selectMissingTranscripts = (response, options) => {
	const { data } = response;
	const labeledData = Object.entries(data).reduce((acc, [value, stats]) => {
		acc[findFilterLabelFromValue(options, value)] = stats;
		return acc;
	}, {});
	return sortObjectByKeys(labeledData);
};

export const useMissingTranscriptsPerCities = (cities, promotionYears) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useQuery({
		queryKey: [
			"transcripts",
			"missing_transcripts_per_cities",
			cityCodes,
			promotionYears,
		],
		queryFn: () =>
			scraper.students.getMissingTranscriptsPerCities(
				cityCodes,
				promotionYears
			),
		select: (response) => selectMissingTranscripts(response, cities),
	});
};

export const useMissingTranscriptsPerPromotions = (cityCodes, promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const promotionYears = promotions.map((promotion) => promotion.value);
	return useQuery({
		queryKey: [
			"transcripts",
			"missing_transcripts_per_promotions",
			cityCodes,
			promotionYears,
		],
		queryFn: () =>
			scraper.students.getMissingTranscriptsPerPromotions(
				cityCodes,
				promotionYears
			),
		select: (response) => selectMissingTranscripts(response, promotions),
	});
};

export const useTranscriptsInfiniteQuery = (
	cities,
	promotions,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	const promotionYears = promotions.map((promotion) => promotion.value);
	return useInfiniteQuery({
		queryKey: ["transcripts", "table_data", cityCodes, promotionYears],
		queryFn: ({ pageParam }) =>
			scraper.students.getTranscripts(
				pageParam,
				cityCodes,
				promotionYears
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
		select: (response) => {
			const ret = { ...response };
			ret.pages = response.pages.flatMap((page) =>
				page.data.results.map((result) => ({
					nb_missing: result.nb_missing,
					login: result.student.login,
					city: result.student.promo.city.name,
					promotion: findFilterLabelFromValue(
						promotions,
						String(result.student.promo.promotion_year)
					),
					transcripts: result.transcripts,
				}))
			);
			return ret;
		},
	});
};
