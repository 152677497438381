import { Navigate } from "react-router-dom";
import { SauronRoutes } from "../../Config/Routes";

export default function Home() {
	return (
		<div>
			<Navigate to={SauronRoutes.dashboard.path} />
		</div>
	);
}
