import { useContext } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxFooterButton, BoxTitle } from "../AdminLTE3/Box";
import FiltersForm from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { getPGEPromotionOptions } from "../Utils/Utils";

export default function Filters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const { filters, setSelectedOption, applyFilters } = useFilters({
		city: {
			label: t("city"),
			type: "select",
			multiple: false,
			options: citiesOptions,
			default: citiesOptions.length === 1 ? citiesOptions[0] : null,
		},
		promotion: {
			label: t("promotion"),
			type: "select",
			multiple: false,
			options: getPGEPromotionOptions(),
			default: null,
		},
		begin: {
			label: t("begin"),
			type: "date",
			default: null,
		},
		end: {
			label: t("end"),
			type: "date",
			default: null,
		},
		alert: {
			label: t("students in alert only"),
			type: "bool",
			selected: false,
		},
	});

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
					disabled={filters.promotion.selected === null}
				/>
			</Card.Footer>
		</Box>
	);
}
