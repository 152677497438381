export default class ProComNotes {
	constructor(api) {
		this.api = api;
	}

	postMarks(year, module_code, slug, project_instance, bttfMode, params) {
		return this.api.processRequest(
			"POST",
			`/validations/professional_communication/${year}/${module_code}/${slug}/${project_instance}`,
			{
				data: {
					bttf: bttfMode,
					results: params,
				},
			}
		);
	}
}
