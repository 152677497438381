import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../../Config/i18n.jsx";
import { ScraperApiHelperContext } from "../../../contexts.js";
import { Box, BoxFooterButton, BoxTitle } from "../../AdminLTE3/Box.jsx";
import FiltersForm from "../../Sauron/FiltersForm.jsx";
import useFilters from "../../Sauron/useFilters.jsx";
import {
	getCurrentAcademicYear,
	getCurrentBachelorPromotion,
	nValues,
} from "../../Utils/Utils.jsx";
import { useCurriculumsQuery } from "../Queries.jsx";
import { Tooltip } from "@mantine/core";

export default function BachelorFilters({ onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const citiesOptions = scraper.userHelper.getCities();
	const { data, error } = useCurriculumsQuery();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			scholar_year: {
				label: t("scholar_year"),
				type: "select",
				multiple: false,
				options: nValues(
					getCurrentAcademicYear() - 7,
					8
				).toFilterOptions(),
				default: {
					value: getCurrentAcademicYear(),
					label: getCurrentAcademicYear(),
				},
			},
			city: {
				label: t("city"),
				type: "select",
				multiple: false,
				options: citiesOptions,
				default: citiesOptions.length === 1 ? citiesOptions[0] : null,
			},
			curriculum: {
				label: t("curriculum"),
				type: "select",
				multiple: false,
				options: [],
				default: null,
			},
			promotion_bachelor: {
				label: t("promotion"),
				type: "select",
				multiple: false,
				options: getCurrentBachelorPromotion("bachelor"),
				default: null,
			},
			projection: {
				label: (
					<Tooltip
						label={t("projection_description").capfirst()}
						position="top"
						transition="fade"
						withArrow
						withinPortal
						multiline
						width={300}
					>
						<div>
							{t("use projection").capfirst()}
							<FontAwesomeIcon
								icon={faCircleQuestion}
								className="ml-2"
								style={{ opacity: 0.5 }}
							/>
						</div>
					</Tooltip>
				),
				type: "bool",
				default: false,
			},
		});

	useEffect(() => {
		if (!data) return;
		setFilterOptions("curriculum", data);
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Bachelor Filters: ${t("An error has occured")}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForm filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
