import {
	faBomb,
	faCircleInfo,
	faGears,
	faQuestionCircle,
	faTriangleExclamation,
	faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime, Duration } from "luxon";
import { useState } from "react";
import { Button, Card, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { t } from "../../Config/i18n";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import { requestErrorToastManager } from "../Utils/Toasts";
import {
	useHistoryDismissMutation,
	useScrapingHistoryInfos,
	useScrapingLogging,
} from "./Queries";
import StatusCell from "./StatusCell";

const ITEMS_PER_PAGE = 500;

function getColorFromLevel(level) {
	switch (level) {
		case 30:
			return "orange";
		case 40:
			return "red";
		case 50:
			return "red";
		default:
			return "white";
	}
}

function getIconFromLevel(level) {
	switch (level) {
		case 10:
			return faGears;
		case 20:
			return faCircleInfo;
		case 30:
			return faTriangleExclamation;
		case 40:
			return faXmarkCircle;
		case 50:
			return faBomb;
		default:
			return faQuestionCircle;
	}
}

export default function Logs({ history_id, areRequestEnabled }) {
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, error } =
		useScrapingLogging(history_id, areRequestEnabled);
	const { data: infos } = useScrapingHistoryInfos(
		history_id,
		areRequestEnabled
	);
	const [itemOffset, setItemOffset] = useState(0);
	const dismissMutation = useHistoryDismissMutation();

	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const requestData = data?.pages.flatMap((page) => page.results) ?? [];

	let date = "";
	let status = null;
	let mode = "";
	let duration = 0;
	if (infos) {
		date = DateTime.fromISO(infos.date).toLocaleString(
			{ hour: "2-digit", minute: "2-digit", second: "2-digit" },
			DateTime.DATETIME_SHORT_WITH_SECONDS
		);
		mode = infos.scraping_mode;
		status = infos.status;
		duration = Duration.fromISO(infos.duration).as("second");
	}

	const handleUpdateDismiss = (id) => {
		dismissMutation.mutate({
			id,
		});
	};
	const pageCount = Math.ceil(requestData.length / 500);
	const handlePageClick = (event) => {
		const newOffset =
			(event.selected * ITEMS_PER_PAGE) % requestData.length;
		setItemOffset(newOffset);
	};
	const showedMsgs = requestData.slice(
		itemOffset,
		itemOffset + ITEMS_PER_PAGE
	);

	const title = `${t("scraping infos")} ${infos ? ` - ${infos.scraping_name.toUpperCase()}` : ""}`;

	requestErrorToastManager("Scraping History: Logs", error);

	return (
		<Box style={{ height: "100%" }}>
			<div>
				<Card.Header>
					<BoxTitle title={title.capfirst()} />
				</Card.Header>
				<Card.Body>
					<Button
						disabled={infos?.status !== "failed"}
						style={{ float: "right" }}
						onClick={() => handleUpdateDismiss(history_id)}
					>
						{t(
							infos?.dismissed ? "undismiss" : "dismiss"
						).capfirst()}
					</Button>
					{infos && (
						<div>
							<div>Date: {date}</div>
							<span>
								Status: <StatusCell status={status} />
							</span>
							<div>Mode: {mode}</div>
							<div>
								{t("duration").capfirst()}: {duration}{" "}
								{t("seconds")}
							</div>
						</div>
					)}
				</Card.Body>
			</div>
			<div>
				<Card.Header>
					<BoxTitle title={t("console").capfirst()} />
				</Card.Header>
				<div className="console">
					{showedMsgs?.map((log) => {
						const icon = getIconFromLevel(log.level);
						const datetime = DateTime.fromISO(log.date);
						const color = getColorFromLevel(log.level);
						return (
							<div
								key={`log_${log.level}_${log.date}`}
								style={{ color, marginLeft: "10px" }}
							>
								<span className="bold">
									[
									{datetime.toLocaleString({
										hour: "2-digit",
										minute: "2-digit",
										second: "2-digit",
									})}
									]
								</span>
								<span className="tab1">
									<FontAwesomeIcon icon={icon} size="lg" />
									<span style={{ marginLeft: 10 }}>
										{log.msg}
									</span>
								</span>
							</div>
						);
					})}
				</div>
				<Row className="justify-content-center mt-4">
					{pageCount > 1 ? (
						<ReactPaginate
							breakLabel="..."
							nextLabel=">"
							onPageChange={handlePageClick}
							pageRangeDisplayed={500}
							pageCount={pageCount}
							previousLabel="<"
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null}
				</Row>
			</div>
		</Box>
	);
}
