import { t } from "../../../../../../Config/i18n";

export function TotalXP({ hub }) {
	const total =
		hub.participation_xp_acquired +
		hub.organization_xp_acquired +
		hub.project_xp_acquired;
	const inProgress =
		hub.participation_xp_in_progress +
		hub.organization_xp_in_progress +
		hub.project_xp_in_progress;
	if (inProgress === 0)
		return (
			<p className="text-center">
				{t("totalXpStudentPage")} {t("xpsWithCount", { count: total })}.
			</p>
		);
	return (
		<p className="text-center">
			{t("totalXpStudentPage")} {t("xpsWithCount", { count: total })}
			{t("maximumXPStudentPage")}
			{t("xpsWithCount", { count: total + inProgress })}.
		</p>
	);
}
