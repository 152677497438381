import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";

export const useSearchStudents = (search) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["main_header", "search_students", search],
		queryFn: () => scraper.students.searchStudent(search),
		select: (data) => data.data,
		enabled: !!search,
	});
};
