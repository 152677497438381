import { t } from "../../../../../../Config/i18n";
import { BoxFooterButton } from "../../../../../AdminLTE3/Box";
import { downloadCSV } from "../../../../../Utils/Utils";
import ActivitityOverview from "./ActivityOverview";
import ProjectOverview from "./ProjectOverview";
import Summary from "./Summary";
import { TotalXP } from "./TotalXP";

const decodeActivities = (activityCodes, presences) =>
	activityCodes.map((activityCode) => {
		const activityData = presences?.find((presence) =>
			presence.intra_url.includes(activityCode)
		);
		if (!activityData) return "Unknown Activity";
		return activityData.name;
	});
const decodeStatusObjects = (statusObjects, presences) =>
	Object.entries(statusObjects).reduce((acc, [status, activityCodes]) => {
		acc[status] = decodeActivities(activityCodes, presences);
		return acc;
	}, {});

const decodeCodedActivities = (codedActivities, presences) =>
	Object.entries(codedActivities).reduce(
		(acc, [activityType, attendanceTypes]) => {
			if (typeof attendanceTypes === "number") {
				acc.Experience = attendanceTypes;
				return acc;
			}
			acc[activityType] = {
				organizations: decodeStatusObjects(
					attendanceTypes.organizations,
					presences
				),
				participations: decodeStatusObjects(
					attendanceTypes.participations,
					presences
				),
			};
			return acc;
		},
		{}
	);

const exportFunction = (activities, titleCSV) => {
	const table = [["activity", "presence_type", "name", "status"]];
	Object.entries(activities).forEach(([activityType, presenceTypes]) => {
		if (typeof presenceTypes === "number") return;
		Object.entries(presenceTypes).forEach(
			([presenceType, presenceStatus]) => {
				Object.entries(presenceStatus).forEach(
					([status, activityNames]) => {
						activityNames.forEach((activity) => {
							table.push([
								`${activityType}`,
								`${presenceType}`,
								`${activity}`,
								`${status}`,
							]);
						});
					}
				);
			}
		);
	});
	downloadCSV(table, titleCSV);
};

export default function YearTab({ hub, presences }) {
	const namedHubActivities = decodeCodedActivities(hub.activities, presences);
	return (
		<div>
			<BoxFooterButton
				label={t("export to CSV").capfirst()}
				onClick={() =>
					exportFunction(
						namedHubActivities,
						`${hub.student.login}_${hub.year}_hub_activities`
					)
				}
				style={{
					width: "170px",
					position: "absolute",
					top: "3px",
					right: "3px",
				}}
			/>
			<ActivitityOverview activities={namedHubActivities} />
			<ProjectOverview projects={hub.projects} />
			<Summary hub={hub} />
			<TotalXP hub={hub} />
		</div>
	);
}
