import { faCheck, faHourglass } from "@fortawesome/free-solid-svg-icons";
import { t } from "../../../Config/i18n";
import { requestErrorToastManager } from "../../Utils/Toasts";
import IconColoredCard from "../IconColoredCard";
import { useModulesToValidateQuery } from "../Queries";

export default function ModulesToValidate({ city, curriculum }) {
	const {
		data: modulesNonValidated = 0,
		error,
		isFetching,
	} = useModulesToValidateQuery(city, curriculum);

	requestErrorToastManager("Dashboard: Hub Missing Objectives Card", error);

	return (
		<IconColoredCard
			title={`${modulesNonValidated}`}
			text={`${t("modules_missing_validations", {
				count: modulesNonValidated,
			})}`}
			color={modulesNonValidated === 0 ? "success" : "danger"}
			detailPath={`/module_alerts?cities=${
				city.value
			}&curriculums=${curriculum.capfirst()}`}
			icon={modulesNonValidated === 0 ? faCheck : faHourglass}
			isLoading={isFetching}
		/>
	);
}
