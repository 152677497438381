import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { defaultBarOptions } from "../Utils/DefaultConst";

export default function StatsChart({ data, config }) {
	if (!data || !config) {
		return null;
	}

	const options = defaultBarOptions;

	const stats = data.reduce(
		(acc, row) => {
			if (row.status in config) {
				acc[row.status] = (acc[row.status] || 0) + 1;
			}
			return acc;
		},
		Object.keys(config).reduce((acc, key) => ({ ...acc, [key]: 0 }), {})
	);

	const formattedData = {
		labels: [""],
		datasets: Object.entries(config).map(([key, c]) => ({
			label: t(key).capfirst(),
			backgroundColor: c.color,
			data: [stats[key]],
		})),
	};

	return (
		<div style={{ height: 350 }}>
			<Bar options={options} data={formattedData} />
		</div>
	);
}
