import { Button, Group, Text } from "@mantine/core";
import { t } from "../../../Config/i18n";

export default function DeletionModal({
	mutation,
	roadblock,
	configId,
	close,
}) {
	return (
		<>
			<Text>
				{t(
					"are you sure you want to delete this roadblock ?"
				).capfirst()}
			</Text>
			<Group
				style={{
					marginTop: "5%",
					justifyContent: "space-between",
				}}
			>
				<Button onClick={close}>
					{t("no don't delete it").capfirst()}
				</Button>
				<Button
					onClick={() =>
						mutation.mutate({ configId, roadblockId: roadblock.id })
					}
					color="red"
				>
					{t("delete roadblock").capfirst()}
				</Button>
			</Group>
		</>
	);
}
