import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { getCurrentAcademicYear, orderGraphByCity } from "../Utils/Utils";
import { ALERT_STATUS } from "./Constants";
import { useTepitechAlertsStatsQuery } from "./Queries";

export default function TepitechResultsChart({
	cities,
	curriculum,
	promotion,
}) {
	const requestedCities = [{ label: "National", value: null }];

	if (cities) {
		for (let idx = 0; idx < cities.length; idx++) {
			requestedCities.push(cities[idx]);
		}
	}
	const currentYear = getCurrentAcademicYear();
	const promotions = promotion || [
		currentYear + 3,
		currentYear + 4,
		currentYear + 5,
	];
	const results = useTepitechAlertsStatsQuery(
		requestedCities,
		promotions,
		curriculum
	);
	const requestData = results.data?.data ? results.data?.data : {};
	// eslint-disable-next-line no-unused-vars
	const { Global, Europe, ...filtered } = requestData;
	const { error } = results;
	const { isFetching } = results;
	const rules = Object.entries(ALERT_STATUS);
	const myCities = Object.keys(filtered).sort();
	let data = {
		labels: myCities,
		datasets: rules.map(([key, content]) => ({
			label: content.graph_label || t(content.label).capfirst(),
			backgroundColor: content.color,
			data: myCities.map((city) => filtered[city][key] || 0),
		})),
	};
	data = orderGraphByCity(data);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Tepitech alerts Alerts Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("Tepitech's results").capfirst()}
			subtitle={`${t("by")} ${t("cities")}`}
			isLoading={isFetching}
		>
			{data ? <Bar data={data} options={defaultBarOptions} /> : null}
		</SauronChartsContainer>
	);
}
