export function SectionHeader({ title, subtitle, children }) {
	return (
		<section className="content-header">
			<h3 className="font-weight-normal">
				{title || null}
				<small>{subtitle || null}</small>
			</h3>
			{children}
		</section>
	);
}

export function SectionContent({ children }) {
	return <section className="content">{children}</section>;
}

export function Section(props) {
	return <section {...props} />;
}
