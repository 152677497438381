import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { t } from "../../../Config/i18n";
import { Box, BoxTitle } from "../../AdminLTE3/Box";
import GradeGraph from "../../Graphs/NewGradeGraph";
import FiltersForm from "../../Sauron/FiltersForm";
import useFilters from "../../Sauron/useFilters";
import LoadingOverlay from "../../Utils/LoadingOverlay";
import { requestErrorToastManager } from "../../Utils/Toasts";
import {
	useGradeStatsPerCityQueries,
	useModuleFilterOptionsQuery,
} from "../Queries";

function Filters({ filters, onFilter }) {
	return (
		<Row className="mb-3">
			<Col lg={5}>
				<FiltersForm filters={filters} onChange={onFilter} />
			</Col>
		</Row>
	);
}

function ColoredGPA({ city, max, min }) {
	if (city > max) return <td className="text-green bold">{city}</td>;
	if (city < min) return <td className="text-red bold">{city}</td>;
	return <td className="text-blue bold">{city}</td>;
}

function GPATable({ global, cities }) {
	if (!cities || !global) return null;
	return (
		<table className="table table-striped table-borderless table-hover">
			<tbody>
				{Object.entries(cities).map(([city, gpa]) => (
					<tr key={`gpa_table_row_${city}`}>
						<td className="bold">GPA {city}</td>
						<ColoredGPA
							city={gpa}
							min={global.min}
							max={global.max}
						/>
					</tr>
				))}
				<tr>
					<td>{t("lowest city").capfirst()}</td>
					<td className="text-red">{global.min}</td>
				</tr>
				<tr>
					<td>{t("national average").capfirst()}</td>
					<td className="text-blue">{global.avg}</td>
				</tr>
				<tr>
					<td>{t("highest city").capfirst()}</td>
					<td className="text-green">{global.max}</td>
				</tr>
			</tbody>
		</table>
	);
}

function ModuleCard({ cities, module }) {
	const {
		data = {},
		error,
		isFetching,
	} = useGradeStatsPerCityQueries(cities, module);
	const graphData = Object.entries(data).reduce(
		(acc, [city, stats]) => {
			Object.keys(acc).forEach((key) => {
				acc[key].push(key === "labels" ? city : stats.grades[key]);
			});
			return acc;
		},
		{
			"A": [],
			"B": [],
			"C": [],
			"D": [],
			"Echec": [],
			"-": [],
			"Acquis": [],
			"labels": [],
		}
	);
	// eslint-disable-next-line no-unused-vars
	const { France, ...citiesData } = data;
	const citiesGPA = Object.keys(citiesData)
		.sort()
		.reduce((acc, city) => {
			acc[city] = data[city].gpa;
			return acc;
		}, {});

	requestErrorToastManager(`Module validations: ${module}`, error);

	return (
		<Col style={{ margin: "10px 0" }}>
			<Box style={{ height: "100%" }}>
				<LoadingOverlay loading={isFetching} />
				<Card.Header>
					<BoxTitle title={module} />
				</Card.Header>
				<Card.Body>
					<GradeGraph stats={graphData} />
					<GPATable global={data?.France?.gpa} cities={citiesGPA} />
				</Card.Body>
			</Box>
		</Col>
	);
}

function NeedsCityMessage() {
	return (
		<Col key="empty_city_field_message">
			<p className="text-red">
				{t("you must specify a city to load these graphs").capfirst()}
			</p>
		</Col>
	);
}

function NeedsModuleMessage() {
	return (
		<Col key="empty_module_field_message">
			<p>
				{t("you must specify unit(s) to load these graphs").capfirst()}
			</p>
		</Col>
	);
}

function ModuleCardsRow({ modules, cities }) {
	if (cities.length === 0) return <NeedsCityMessage />;
	if (modules.length === 0) return <NeedsModuleMessage />;
	return (
		<Row md={3}>
			{modules.map((module) => (
				<ModuleCard
					module={module}
					cities={cities}
					key={`${module}_module_card`}
				/>
			))}
		</Row>
	);
}

export default function ModuleValidation({ cities }) {
	const { data: modules } = useModuleFilterOptionsQuery();

	const { filters, setFilterOptions, setSelectedOption } = useFilters({
		modules: {
			label: t("module"),
			type: "select",
			multiple: true,
			options: [],
			selected: [],
		},
	});

	useEffect(() => {
		if (!modules) return;
		setFilterOptions("modules", modules);
	}, [modules]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("module validations").capfirst()} />
			</Card.Header>
			<Card.Body>
				<Filters filters={filters} onFilter={setSelectedOption} />
				<ModuleCardsRow
					modules={filters.modules.selected.map(
						(module) => module.value
					)}
					cities={cities}
				/>
			</Card.Body>
		</Box>
	);
}
