import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import { FilterSelect } from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { monoOption } from "../Utils/Utils";

export const getPossibleViewOptions = (city, scraper) => {
	const bachelorOption = monoOption("Bachelor");
	const ceiOption = monoOption("CEI");
	if (city.value !== "FR/PAR") return [bachelorOption];
	if (scraper.userHelper.isAdmin()) return [bachelorOption, ceiOption];
	return scraper.userHelper.isInGroup("cei") ? [ceiOption] : [bachelorOption];
};

export default function Filters({ cities, onFilter }) {
	const scraper = useContext(ScraperApiHelperContext);
	const firstViewOptions = getPossibleViewOptions(cities[0], scraper);
	const { filters, setSelectedOption, setFilterOptions, applyFilters } =
		useFilters({
			city: {
				label: t("city"),
				type: "select",
				multiple: false,
				options: cities,
				default: cities[0],
				clearable: false,
			},
			view: {
				label: "view",
				type: "select",
				multiple: false,
				options: firstViewOptions,
				default: firstViewOptions[0],
				dependsOn: "city",
				clearable: false,
			},
		});

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "city") {
			const options = getPossibleViewOptions(newValue, scraper);
			setFilterOptions("view", options);
			onSelectChange("view", options[0]);
		}
	};

	useEffect(() => {
		onSelectChange("city", filters.city.selected);
	}, []);

	useEffect(() => {
		applyFilters(onFilter);
	}, [filters]);

	const hasMultipleFilter =
		scraper.userHelper.isAdmin() && filters.view.options.length !== 1;

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				{filters.city.options.length !== 1 ? (
					<FilterSelect
						name="city"
						filter={filters.city}
						onChange={onSelectChange}
					/>
				) : null}
				{hasMultipleFilter ? (
					<div className="mt-3">
						<FilterSelect
							name="view"
							filter={filters.view}
							onChange={onSelectChange}
						/>
					</div>
				) : null}
			</Card.Body>
		</Box>
	);
}
