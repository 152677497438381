import { ListGroupItem } from "react-bootstrap";
import { t } from "../../../Config/i18n";

export default function ListEntry({
	label,
	value,
	tooltipContent = null,
	tooltipId = "student-overview-tooltip",
}) {
	return (
		<ListGroupItem>
			<b>{t(label).capfirst()}</b>
			<span
				className="float-right text-blue"
				data-tooltip-id={tooltipId}
				data-tooltip-html={tooltipContent}
			>
				{value}
			</span>
		</ListGroupItem>
	);
}
