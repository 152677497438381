import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useModuleStatsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["module_alerts", "modules_stats"],
		queryFn: () => scraper.module.getAlertsStats(),
		select: (response) => response.data,
	});
};

export const useModuleAlertsInfiniteQuery = (cities, isRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	const cityCodes = cities.map((city) => city.value);
	return useInfiniteQuery({
		queryKey: ["module_alerts", "table_data", cityCodes],
		queryFn: ({ pageParam }) =>
			scraper.instance.getGradesInAlerts(pageParam, cityCodes),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
		select: (data) => ({
			...data,
			pages: data.pages.map((page) =>
				page.data.results.reduce((acc, instanceAlert) => {
					acc.push({
						module_code: instanceAlert.instance.module.code_module,
						module_title: instanceAlert.instance.module.name,
						instance_code: instanceAlert.instance.code_instance,
						end_date: DateTime.fromISO(instanceAlert.instance.end),
						registered: Object.values(instanceAlert.grades).reduce(
							(total, num) => total + num,
							0
						),
						missing: instanceAlert.grades["-"],
						year: instanceAlert.instance.year,
					});
					return acc;
				}, [])
			),
		}),
	});
};
