import {
	AppShell,
	Box,
	ColorSchemeProvider,
	MantineProvider,
} from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import "dayjs/locale/fr";
import QS from "qs";
import { Suspense, useContext, useEffect, useState } from "react";
import {
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { SauronRoutes } from "../../Config/Routes";
import { ScraperApiHelperContext } from "../../contexts";
import NotFound from "../404/404";
import { usePreferedColorScheme } from "../AdminLTE3/DarkMode";
import SauronHeader from "../Sauron/SauronHeader.jsx";
import SauronNavbar from "../Sauron/SauronNavbar.jsx";
import { hasPermissions } from "../Utils/Utils";

const REG_TO_CALLBACK = [
	...[false, true, null, undefined].map((k) => ({
		test: new RegExp(`^${k}$`),
		value: k,
	})),
	{
		test: /^[+-]?([0-9]*[.])?[0-9]+$/,
		value: parseFloat,
	},
	{
		test: /^[-+]?\d+$/,
		value: parseInt,
	},
];

/* this function sets real types to stringlike params
 ** year: "2019" -> year: 2019
 */
function applyTypes(object) {
	Object.entries(object).forEach(([key, content]) => {
		if (typeof content === "object") {
			applyTypes(object[key]);
		} else if (Array.isArray(content)) {
			for (let i = 0; i < content.length; ++i) {
				applyTypes(content[i]);
			}
		} else {
			REG_TO_CALLBACK.forEach(({ test, value }) => {
				if (test.test(content)) {
					if (typeof value === "function") {
						object[key] = value(content);
					} else {
						object[key] = value;
					}
				}
			});
		}
	});
}

function Wrapper({ Component, ...props }) {
	if (!Component) return null;
	props.params = useParams();
	props.location = useLocation();
	props.navigate = useNavigate();
	props.query = QS.parse(props.location.search, { ignoreQueryPrefix: true });
	applyTypes(props.query);
	return <Component {...props} />;
}

function routeMappingFromConfig(routes, account) {
	return Object.entries(routes)
		.filter(([, page]) => hasPermissions(page, account))
		.map(([key, route]) => (
			<Route
				key={key}
				path={route.path}
				element={
					<Box>
						<Wrapper Component={route.component} />
					</Box>
				}
			/>
		));
}

function notFound() {
	return <Route element={<Wrapper Component={NotFound} />} />;
}

export default function SauronRouter() {
	const preferedColorScheme = usePreferedColorScheme();
	const [colorScheme, setColorScheme] = useState(preferedColorScheme);
	const toggleColorScheme = (value) => {
		const newScheme = value || (colorScheme === "dark" ? "light" : "dark");
		setColorScheme(newScheme);
		localStorage.setItem("colorScheme", newScheme);
		if (newScheme === "dark") {
			document.body.classList.add("dark-mode");
		} else {
			document.body.classList.remove("dark-mode");
		}
	};
	const scraper = useContext(ScraperApiHelperContext);
	const routeComponents = routeMappingFromConfig(
		SauronRoutes,
		scraper.api.getSauronUserInfo()
	);

	useEffect(() => {
		if (colorScheme === "dark") {
			document.body.classList.add("dark-mode");
		}
	}, []);
	const [opened, setOpened] = useState(false);

	return (
		<Suspense fallback={null}>
			<DatesProvider settings={{ locale: "fr" }}>
				<ColorSchemeProvider
					colorScheme={colorScheme}
					toggleColorScheme={toggleColorScheme}
				>
					<MantineProvider
						theme={{ colorScheme }}
						withGlobalStyles
						withNormalizeCSS
					>
						<Router>
							<AppShell
								navbarOffsetBreakpoint="sm"
								asideOffsetBreakpoint="sm"
								navbar={<SauronNavbar opened={opened} />}
								header={
									<SauronHeader
										opened={opened}
										setOpened={setOpened}
									/>
								}
							>
								<Routes>
									{routeComponents}
									{notFound()}
								</Routes>
							</AppShell>
						</Router>
					</MantineProvider>
				</ColorSchemeProvider>
			</DatesProvider>
		</Suspense>
	);
}
