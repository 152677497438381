import {
	faPersonCircleCheck,
	faPersonCircleExclamation,
	faPersonCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "../../../Config/i18n";

export default function AlertStatus({ status }) {
	if (status === "N/A") return null;
	const status_data = {
		not_defined: {
			comment: "student has never been updated",
			color: "#c0392b",
			icon: faPersonCircleExclamation,
		},
		very_late: {
			comment: "last update exceeds 8 weeks",
			color: "#c0392b",
			icon: faPersonCircleExclamation,
		},
		late: {
			comment: "last update exceeds 4 weeks",
			color: "#f1c40f",
			icon: faPersonCircleQuestion,
		},
		null: {
			comment: "up to date",
			color: "#2ecc71",
			icon: faPersonCircleCheck,
		},
	};

	return (
		<FontAwesomeIcon
			data-tooltip-id="intuitu-personae-tooltip"
			data-tooltip-content={t(status_data[status].comment).capfirst()}
			className="icon-intuitu-personae"
			style={{
				verticalAlign: "middle",
				color: status_data[status].color,
			}}
			icon={status_data[status].icon}
			size="2x"
		/>
	);
}
