import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { t } from "../../../../../Config/i18n";
import RadioFilters from "../../../../Sauron/RadioFilters";
import { getMonth, getWeek, getYear } from "./Utils";

const getRadioFilterOptions = () => [
	{
		label: t("unfiltered").capfirst(),
		value: "unfiltered",
		select_placeholder: "time periods",
	},
	{
		label: t("per year").capfirst(),
		value: "yearly",
		select_placeholder: "years",
	},
	{
		label: t("per month").capfirst(),
		value: "monthly",
		select_placeholder: "months",
	},
	{
		label: t("per week").capfirst(),
		value: "weekly",
		select_placeholder: "weeks",
	},
];

const getPlaceholder = (radioValue) => {
	const placeholders = {
		unfiltered: t("time periods"),
		yearly: t("years"),
		monthly: t("months"),
		weekly: t("weeks"),
	};
	return placeholders[radioValue];
};

const getTimeOptions = (radioValue, logtimes) => {
	if (radioValue === "unfiltered") return [];
	const optionsGetter = {
		yearly: getYear,
		monthly: getMonth,
		weekly: getWeek,
	};
	return Array.from(
		logtimes.reduce((acc, logtime) => {
			acc.add(optionsGetter[radioValue](logtime));
			return acc;
		}, new Set())
	);
};

export default function LogtimeFilters({
	logtimes,
	scale,
	timePeriod,
	updateFilters,
}) {
	const onChangeScale = (option) => {
		const timeOptions = getTimeOptions(option, logtimes)
			.reverse()
			.toFilterOptions();
		updateFilters({ scale: option, timePeriod: timeOptions[0] ?? null });
	};

	const onChangeTimePeriod = (option) => {
		updateFilters({ scale, timePeriod: option });
	};
	const radioFilterOptions = getRadioFilterOptions();
	const timeOptions = getTimeOptions(scale, logtimes)
		.reverse()
		.toFilterOptions();
	return (
		<Row>
			<Col>
				<Select
					classNamePrefix="multiSelect"
					placeholder={`${getPlaceholder(scale).capfirst()} ...`}
					options={timeOptions}
					value={timePeriod}
					onChange={onChangeTimePeriod}
					isDisabled={scale === "unfiltered"}
				/>
			</Col>
			<Col>
				<RadioFilters
					options={radioFilterOptions}
					value={scale}
					onChange={onChangeScale}
				/>
			</Col>
		</Row>
	);
}
