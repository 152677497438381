import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import { separateFiltersOptions } from "../Utils/Utils";
import DatatablesContainer from "./DatatablesContainer";
import Filters from "./Filters";
import InternshipAlertChart from "./InternshipAlertChart";

export default function InternshipPartTime() {
	const areRequestEnabled = useRef(false);
	const types = ["Full-time", "Part-time"];
	const [activeFilters, setActiveFilters] = useState({
		cities: [],
		curriculums: [],
		promotions: [],
		type: types[0],
		alerts: false,
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters)) {
			setActiveFilters(selectedFilters);
		}
		areRequestEnabled.current = true;
	};

	const { labels: selectedPromotions } = separateFiltersOptions(
		activeFilters.promotions
	);
	const { labels: selectedCurriculums } = separateFiltersOptions(
		activeFilters.curriculums
	);

	useEffect(() => {}, [activeFilters]);

	const full_time = activeFilters.type.value === "Full-time";

	return (
		<Section>
			<SectionHeader title={t("internships and part-time").capfirst()} />

			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters onFilter={updateActiveFilters} />
						</Col>

						<Col lg={9} className="mb-3">
							<InternshipAlertChart
								cities={activeFilters.cities}
								curriculums={selectedCurriculums}
								promotions={selectedPromotions}
								type={activeFilters.type}
							/>
						</Col>

						<Col lg={12}>
							<DatatablesContainer
								cities={activeFilters.cities}
								curriculums={selectedCurriculums}
								promotions={selectedPromotions}
								full_time={full_time}
								alertsOnly={activeFilters.alerts}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
