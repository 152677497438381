import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useState } from "react";
import { CardGroup, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { requestErrorToastManager } from "../Utils/Toasts";
import { filterFromSearch, getCurrentAcademicYear } from "../Utils/Utils";
import ConfigurationCard from "./ConfigurationCard";
import {
	useConfigurationsInfiniteQuery,
	useTesseractUnstableModulesQuery,
} from "./Queries";

const ITEMS_PER_PAGE = 8;

export default function Configurations({ year, errorsOnly, search }) {
	const scraper = useContext(ScraperApiHelperContext);
	const [itemOffset, setItemOffset] = useState(0);
	const { data: tesseractUnstableModules = [] } =
		useTesseractUnstableModulesQuery(year.value);
	const {
		data: paginatedConfigurations,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		error: configurationsError,
	} = useConfigurationsInfiniteQuery(year.value);

	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const configurations = useMemo(
		() =>
			paginatedConfigurations?.pages.flatMap((page) =>
				page.map((configuration) => ({
					...configuration,
					last_unit_responsibles_update: DateTime.fromISO(
						configuration.last_unit_responsibles_update
					),
					last_tesseract_update: configuration.last_tesseract_update
						? DateTime.fromISO(configuration.last_tesseract_update)
						: null,
					disabled:
						!scraper.userHelper.isAdmin() &&
						configuration.scholar_year !== getCurrentAcademicYear(),
				}))
			) ?? [],
		[paginatedConfigurations]
	);

	const filteredConfigurations = configurations.filter((configuration) => {
		if (
			errorsOnly &&
			configuration.error_message === "" &&
			!tesseractUnstableModules.includes(configuration.unit)
		)
			return false;
		return filterFromSearch([configuration.unit], search);
	});

	useEffect(() => {
		setItemOffset(0);
	}, [year, search, errorsOnly]);

	requestErrorToastManager(
		"Tesseract Configuration: Configuration Filter",
		configurationsError
	);

	const pageCount = Math.ceil(filteredConfigurations.length / 8);
	const handlePageClick = (event) => {
		const newOffset =
			(event.selected * ITEMS_PER_PAGE) % filteredConfigurations.length;
		setItemOffset(newOffset);
	};
	const showedConfigurations = filteredConfigurations.slice(
		itemOffset,
		itemOffset + ITEMS_PER_PAGE
	);

	return (
		<div style={{ width: "100%" }}>
			{filteredConfigurations.length === 0 ? (
				<Row className="justify-content-center mt-4">
					{t("no configuration found").capfirst()}
				</Row>
			) : (
				<CardGroup
					style={{
						display: "inline-flex",
						WebkitBoxPack: "justify",
						width: "100%",
					}}
					className="pl-3 pr-3"
				>
					{showedConfigurations.map((configuration) => (
						<ConfigurationCard
							configuration={configuration}
							key={`${configuration.unit}_${configuration.scholar_year}_configuration_card`}
						/>
					))}
				</CardGroup>
			)}
			<Row className="justify-content-center mt-4">
				{pageCount > 1 ? (
					<ReactPaginate
						breakLabel="..."
						nextLabel=">"
						onPageChange={handlePageClick}
						pageRangeDisplayed={5}
						pageCount={pageCount}
						previousLabel="<"
						breakClassName="page-item"
						breakLinkClassName="page-link"
						containerClassName="pagination"
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextClassName="page-item"
						nextLinkClassName="page-link"
						activeClassName="active"
						renderOnZeroPageCount={null}
					/>
				) : null}
			</Row>
		</div>
	);
}
