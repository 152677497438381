import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { setFranceToRight } from "../Utils/Utils";
import * as Utils from "./Utils";

const GRADE_CONFIG = {
	"NA": {
		color: "#bdc3c7",
	},
	"-": {
		color: "#bdc3c7",
	},
	"A": {
		color: "#2ecc71",
	},
	"B": {
		color: "#2980b9",
	},
	"C": {
		color: "#f1c40f",
	},
	"D": {
		color: "#e67e22",
	},
	"Echec": {
		color: "#e74c3c",
		label: "failed",
	},
	"E": {
		color: "#e74c3c",
		label: "failed",
	},
	"Acquis": {
		color: "#33bdb6",
	},
};

export default function GradeGraph({ stats, size }) {
	if (Object.keys(stats).length === 0) return null;
	const alphaStats = Utils.alphabeticalOrder(Utils.removeNoElements(stats));
	const options = {
		...defaultBarOptions,
		legend: {
			reverse: true,
		},
	};
	const entries = Object.entries(GRADE_CONFIG).filter(
		([key]) => key in alphaStats
	);
	let data = {
		labels: alphaStats.labels,
		datasets: entries
			.map(([key, config]) => ({
				label: t(config.label).capfirst() || key,
				backgroundColor: config.color,
				data: alphaStats[key],
			}))
			.sort((b, a) => a.label.localeCompare(b.label)),
	};
	data = setFranceToRight(data);
	return (
		<div style={{ height: size || 350 }}>
			<Bar options={options} data={data} />
		</div>
	);
}
