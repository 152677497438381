import { useContext, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { Section, SectionContent, SectionHeader } from "../AdminLTE3/Section";
import { areFiltersValueDifferent } from "../Utils/Filters";
import { getPGEPromotionOptions } from "../Utils/Utils";
import TranscriptsDataTableContainer from "./DataTableContainer";
import Filters from "./Filters";
import MissingPerCities from "./MissingPerCities";
import MissingPerPromotions from "./MissingPerPromotions";

export default function Transcripts() {
	const scraper = useContext(ScraperApiHelperContext);
	const areRequestEnabled = useRef(false);
	const citiesOptions = scraper.userHelper.getCities();
	const promotionsOptions = getPGEPromotionOptions(true);
	const [activeFilters, setActiveFilters] = useState({
		cities: [],
		promotions: [],
		alerts: false,
	});

	const updateActiveFilters = (selectedFilters) => {
		if (areFiltersValueDifferent(activeFilters, selectedFilters))
			setActiveFilters(selectedFilters);
		areRequestEnabled.current = true;
	};

	return (
		<Section>
			<SectionHeader title={t("transcripts overview").capfirst()} />
			<SectionContent>
				<Container fluid>
					<Row>
						<Col lg={3} className="mb-3">
							<Filters
								cities={citiesOptions}
								promotions={promotionsOptions}
								onFilter={updateActiveFilters}
							/>
						</Col>
						<Col
							lg={activeFilters.promotions.length === 1 ? 9 : 5}
							className="mb-3"
						>
							<MissingPerCities
								cities={
									activeFilters.cities.length === 0
										? citiesOptions
										: activeFilters.cities
								}
								promotions={activeFilters.promotions}
							/>
						</Col>
						<Col
							lg={activeFilters.promotions.length === 1 ? 0 : 4}
							className="mb-3"
						>
							<MissingPerPromotions
								cities={activeFilters.cities}
								promotions={
									activeFilters.promotions.length === 0
										? promotionsOptions
										: activeFilters.promotions
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<TranscriptsDataTableContainer
								cities={
									activeFilters.cities.length === 0
										? citiesOptions
										: activeFilters.cities
								}
								promotions={
									activeFilters.promotions.length === 0
										? promotionsOptions
										: activeFilters.promotions
								}
								alertsOnly={activeFilters.alerts}
								areRequestEnabled={areRequestEnabled.current}
							/>
						</Col>
					</Row>
				</Container>
			</SectionContent>
		</Section>
	);
}
