import { useMemo, useState } from "react";
import { t } from "../../Config/i18n";
import GradeGraph from "../Graphs/NewGradeGraph";
import TesseractRadioFilters from "../Sauron/RadioFilters";
import TesseractChartsContainer from "./TesseractChartsContainer";

export default function GradesByInstances({
	validationStats,
	gradation,
	isLoading,
}) {
	const filtersOptions = [
		{
			label: t("final grades").capfirst(true),
			value: "final",
		},
		{
			label: t("delivery grades").capfirst(true),
			value: "delivery",
		},
		{
			label: t("BTTF grades").capfirst(true),
			value: "bttf",
		},
		{
			label: t("provisional grades").capfirst(true),
			value: "provisional",
		},
	];
	const [selectedOption, setSelectedOption] = useState(
		filtersOptions[0].value
	);
	const gradesPerInstances = useMemo(() => {
		if (!validationStats || !gradation) return {};
		// eslint-disable-next-line no-unused-vars
		const { Global, Europe, ...filtered } = validationStats;
		const instances = Object.keys(filtered).sort();
		const grades = ["A", "B", "C", "D", "Acquis"]
			.filter((grade) => Object.keys(gradation).includes(grade))
			.concat("Echec");
		const data = { labels: [] };
		const franceStats = filtered.France;
		instances.forEach((instance) => {
			if (instance === "France") return;
			data.labels.push(instance);
			const stats = filtered[instance];
			grades.forEach((grade) => {
				if (!data[grade]) data[grade] = [];
				data[grade].push(stats.grades[selectedOption][grade] ?? 0);
			});
		});
		data.labels.push("France");
		grades.forEach((grade) => {
			data[grade].push(franceStats.grades[selectedOption][grade] ?? 0);
		});
		return data;
	}, [validationStats, gradation, selectedOption]);

	return (
		<TesseractChartsContainer
			title={t("grades").capfirst()}
			subtitle={`${t("by")} ${t("instances")}`}
			radioFilter={
				<TesseractRadioFilters
					options={filtersOptions}
					value={selectedOption}
					onChange={setSelectedOption}
				/>
			}
			isLoading={isLoading}
		>
			<GradeGraph stats={gradesPerInstances} />
		</TesseractChartsContainer>
	);
}
