import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { getDiversityCreditsAlerts } from "../Utils/Labels";
import { useDiversityStatsByCityQuery } from "./Queries";

export default function DiversityStatsChart({
	cities,
	curriculums,
	promotions,
	scholarYear,
}) {
	const { data, isFetching, error } = useDiversityStatsByCityQuery(
		cities,
		promotions,
		curriculums,
		scholarYear
	);
	const DiversityAlerts = getDiversityCreditsAlerts();

	const citiesName = [...cities.map((city) => city.label), "France"];
	const chartData = useMemo(() => {
		if (!data?.data) return null;
		return {
			labels: citiesName,
			datasets: Object.entries(DiversityAlerts).map(([key, alert]) => ({
				label: alert.label.title(),
				data: citiesName.map(
					(cityName) => data.data[cityName]?.[key] ?? 0
				),
				backgroundColor: alert.color,
			})),
		};
	}, [data]);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Promoting Chart: ${t("An error has occured")}: ${error.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("diversity_overview").capfirst()}
			subtitle={t("per cities")}
			isLoading={isFetching}
		>
			{chartData ? (
				<Bar data={chartData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
