import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { t } from "../../../Config/i18n.jsx";
import { Box, BoxFooterButton, BoxTitle } from "../../AdminLTE3/Box.jsx";
import FiltersForms from "../../Sauron/FiltersForm.jsx";
import useFilters from "../../Sauron/useFilters.jsx";
import { getCurrentBachelorPromotion } from "../../Utils/Utils.jsx";
import { useSemesters } from "../Queries.jsx";

// check why the url keep the semester= when we clear filters

export default function MasterFilters({ onFilter }) {
	const { data: dataSemesters, error: errorSemesters } = useSemesters();
	const { filters, setFilterOptions, setSelectedOption, applyFilters } =
		useFilters({
			semesters: {
				label: t("semesters"),
				type: "select",
				multiple: true,
				options: [],
				default: [],
			},
			promotion_master: {
				label: t("promotions"),
				type: "select",
				multiple: true,
				options: getCurrentBachelorPromotion("master"),
				default: [],
			},
		});

	useEffect(() => {
		if (!dataSemesters) return;
		setFilterOptions("semesters", dataSemesters);
	}, [dataSemesters]);

	useEffect(() => {
		if (!errorSemesters) return;
		toast.error(
			`Master Filters: ${t("An error has occured:")}: ${
				errorSemesters.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [errorSemesters]);

	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("filters").capfirst()} />
			</Card.Header>
			<Card.Body>
				<FiltersForms filters={filters} onChange={setSelectedOption} />
			</Card.Body>
			<Card.Footer>
				<BoxFooterButton
					label={t("filter").capfirst()}
					onClick={() => applyFilters(onFilter)}
				/>
			</Card.Footer>
		</Box>
	);
}
