import { createContext, useMemo, useState } from "react";

export const InvestmentContext = createContext(undefined);

export function InvestmentContextProvider({ children }) {
	const [students, setStudents] = useState(new Map());
	const value = useMemo(() => ({ students, setStudents }), [students]);
	return (
		<InvestmentContext.Provider value={value}>
			{children}
		</InvestmentContext.Provider>
	);
}
