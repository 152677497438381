export default class Diversity {
	constructor(api) {
		this.api = api;
	}

	getStats = (
		city = null,
		promotion = null,
		curriculum = null,
		scholar_year = null
	) =>
		this.api.processRequest("GET", `/diversity/stats`, {
			params: { city, promotion, curriculum, scholar_year },
		});
}
