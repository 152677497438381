import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "../../../../../Config/i18n";

const determineSemesterName = (name) => {
	if (name === "Master" || name === "Bachelor") return name;
	const [, number] = name.split(" ");
	return `${t("semester")} ${number}`.capfirst();
};

const languageTranscriptsToTableEntry = (semester, transcripts, language) => {
	if (transcripts.length === 0)
		return [{ semester, transcript: "missing", language }];
	return transcripts.map((transcript) => ({
		semester,
		transcript,
		language,
	}));
};

const semesterToTableEntries = ([semester, transcripts]) => {
	const semesterName = determineSemesterName(semester);
	return [
		...languageTranscriptsToTableEntry(
			semesterName,
			transcripts.fr,
			t("french").capfirst()
		),
		...languageTranscriptsToTableEntry(
			semesterName,
			transcripts.en,
			t("english").capfirst()
		),
	];
};

const formatTranscripts = (transcripts) =>
	Object.entries(transcripts).reduce(
		(acc, semester) => [...acc, ...semesterToTableEntries(semester)],
		[]
	);

function TranscriptCell({ transcript, login }) {
	if (transcript === "missing")
		return (
			<div className="align-middle text-red">
				{t("missing transcript").capfirst()}
			</div>
		);
	return (
		<a
			style={{ verticalAlign: "middle" }}
			href={`https://intra.epitech.eu/user/${login}/document/${transcript}`}
		>
			<FontAwesomeIcon icon={faFilePdf} size="lg" className="text-red" />{" "}
			{transcript}
		</a>
	);
}

function TranscriptLine({ semester, transcript, language, login }) {
	return (
		<tr>
			{semester ? (
				<td style={{ verticalAlign: "middle" }} rowSpan={2}>
					{semester}
				</td>
			) : null}
			<td style={{ verticalAlign: "middle" }}>{language}</td>
			<td>
				<TranscriptCell transcript={transcript} login={login} />
			</td>
		</tr>
	);
}

export default function TranscriptsTable({ transcripts, login }) {
	const entries = formatTranscripts(
		Object.fromEntries(
			Object.entries(transcripts).filter(([, value]) => value)
		)
	);
	return (
		<table className="table table-striped table-borderless table-hover">
			<thead className="thead-light">
				<tr>
					<th scope="col">{t("semesters").capfirst()}</th>
					<th scope="col">{t("language").capfirst()}</th>
					<th scope="col">{t("transcripts").capfirst()}</th>
				</tr>
			</thead>
			<tbody>
				{entries.map(({ semester, transcript, language }, idx) => (
					<TranscriptLine
						key={`${login}_${semester}_${transcript}_${language}`}
						semester={idx % 2 === 0 ? semester : null}
						transcript={transcript}
						language={language}
						login={login}
					/>
				))}
			</tbody>
		</table>
	);
}
