import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useProjectStatsQuery = (
	year,
	module_code,
	slug,
	project_instance
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"projects",
			"filters_options",
			"project",
			year,
			module_code,
			slug,
			project_instance,
		],
		queryFn: ({ pageParam }) =>
			scraper.projects.getProjectStats(
				pageParam,
				year,
				module_code,
				slug,
				project_instance
			),
		select: useCallback(
			(data) => data.pages.flatMap((page) => page.results),
			[]
		),
		initialPageParam: 0,
		getNextPageParam,
		enabled: !!year && !!module_code && !!slug && !!project_instance,
	});
};

export const useProjectStatsStudentQuery = (year, login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: ["projects", "stats", "student", year, login],
		queryFn: ({ pageParam }) =>
			scraper.projects.getStudentProjectStats(pageParam, year, login),
		select: useCallback(
			(data) => data.pages.flatMap((page) => page.results),
			[]
		),
		initialPageParam: 0,
		getNextPageParam,
		enabled: !!year && !!login,
	});
};

export const useProjectsAvailableYearQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["projects", "filters_options", "years"],
		queryFn: () => scraper.projects.getAvailableYears(),
		select: useCallback((data) => data.available_years, []),
	});
};

export const useAvailableProjectsQuery = (year, module_code = null) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"projects",
			"filters_options",
			"projects",
			year,
			module_code,
		],
		queryFn: ({ pageParam }) =>
			scraper.projects.getAvailableProjects(pageParam, year, module_code),
		select: useCallback(
			(data) => data.pages.flatMap((page) => page.results),
			[]
		),
		initialPageParam: 0,
		getNextPageParam,
	});
};

export const useAvailableProjectInstancesQuery = (year, module_code, slug) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"projects",
			"filters_options",
			"project_instances",
			year,
			module_code,
			slug,
		],
		queryFn: () =>
			scraper.projects.getAvailableProjectInstances(
				year,
				module_code,
				slug
			),
		enabled: !!year && !!module_code && !!slug,
	});
};

export const useProjectStatsGlobalQuery = (
	year,
	module_code,
	slug,
	project_instance
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"projects",
			"stats",
			"global",
			year,
			module_code,
			slug,
			project_instance,
		],
		queryFn: () =>
			scraper.projects.getProjectStatsGlobal(
				year,
				module_code,
				slug,
				project_instance
			),
		enabled: !!year && !!module_code && !!slug && !!project_instance,
	});
};

export const useProjectInstanceQuery = (
	year,
	module_code,
	slug,
	project_instance
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"projects",
			"project_instance",
			year,
			module_code,
			slug,
			project_instance,
		],
		queryFn: () =>
			scraper.projects.getProjectInstance(
				year,
				module_code,
				slug,
				project_instance
			),
		select: (response) => response.data,
		enabled: !!year && !!module_code && !!slug && !!project_instance,
	});
};
